import { useEffect, useState } from 'react'
import { useLocation } from 'wouter';

const useRouteChange = () => {
  const [location] = useLocation();

  const [isNavigateLoading, setIsNavigateLoading] = useState(false)

  useEffect(() => {
    const handlePageChange = (isLoading: boolean) => {
      setIsNavigateLoading(isLoading);

      setTimeout(() => {
        setIsNavigateLoading(true);
      }, 1000)
    };
    handlePageChange(false);
    return () => {
      handlePageChange(true);
    };
  }, [location]);

  return { isNavigateLoading }
}

export default useRouteChange;
