import {
  ActionIcon, Group, Popover, Radio, createStyles,
} from "@mantine/core"
import { Settings } from "tabler-icons-react";
import { WATCHLIST_DISPLAY_FORMATS, WATCHLIST_PREFERENCE } from "@constants/watchlist";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@redux/store";

const useStyles = createStyles((theme) => {
  const { colors } = theme.other
  return {
    settingsWrapper: {
      marginLeft: "auto",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingRight: 8,
    },
    dropdown: {
      left: "210px !important",
    },
    actionIcon: {
      transition: "color 100ms ease",
      "&:hover": {
        color: colors.primary.default,
      },
    },
  };
});

const WatchlistSettings = ({ open, opened, close }) => {
  const { classes } = useStyles()
  const watchlistDisplayFormat = useSelector((state:RootState) => state.watchlist.displayFormat)
  const [displayFormat, setDisplayFormat] = useState(watchlistDisplayFormat)
  const dispatch = useDispatch()

  const handleValueChange = (value) => {
    const dataToSet = {
      displayFormat: value,
    }
    localStorage.setItem(WATCHLIST_PREFERENCE, JSON.stringify(dataToSet))
    dispatch({ type: "watchlist/updateDisplayFormat", payload: value })
    setDisplayFormat(value)
  }

  useEffect(() => {
    const localStorageValue = localStorage.getItem(WATCHLIST_PREFERENCE)
    if (localStorageValue) {
      const data = JSON.parse(localStorageValue)
      setDisplayFormat(data.displayFormat)
    }
  }, [])

  const handleClickChange = () => {
    if (opened) {
      close()
    } else {
      open()
    }
  }
  return (
    <Popover
      arrowPosition="side"
      width={200}
      position="bottom-end"
      withArrow
      shadow="md"
      trapFocus
      opened={opened}
      offset={-10}
      arrowOffset={13}
      closeOnClickOutside
      clickOutsideEvents={["click"]}
      classNames={{
        dropdown: classes.dropdown,
      }}
      onClose={close}
    >
      <Popover.Target>
        <div className={classes.settingsWrapper}>
          <ActionIcon
            className={classes.actionIcon}
            onClick={handleClickChange}
            variant="transparent"
          >
            <Settings size="18px" />
          </ActionIcon>
        </div>
      </Popover.Target>
      <Popover.Dropdown>
        <Radio.Group
          name="displayFormat"
          label="Display Format"
          size="sm"
          value={displayFormat}
          onChange={(value:any) => {
            handleValueChange(value)
          }}

        >
          <Group mt="md">
            {
              WATCHLIST_DISPLAY_FORMATS.map((option) => (
                <Radio size="xs" key={option.value} value={option.value} label={option.label} />
              ))
            }
          </Group>
        </Radio.Group>
      </Popover.Dropdown>
    </Popover>
  )
}

export default WatchlistSettings
