/* eslint-disable max-len */
import {
  Box,
  Group,
  Radio,
  Switch,
  createStyles,
  Tooltip,
} from "@mantine/core"
import { useDispatch, useSelector } from "react-redux"
import { memo, useEffect, useState } from "react"
import useGetSocketVal from "src/hooks/common/useGetSocketVal"
import { RootState } from "src/redux/store"
import { ICurrentAsset } from "src/redux/sagas/trades"
import { setTradeModalInfo } from "@redux/sagas/trades/tradesSlice"
import { Flex } from '@uikit';
import { XboxA } from "tabler-icons-react"
import { exchanges } from "../../../utils/constant"
import FormattedName from "../formattedName"

const useStyles = createStyles(() => ({
  qtyStyle: {
    minWidth: 8,
    display: "inline-block",
    marginLeft: 4,
    marginRight: 4,
  },
  headerInner: {
    height: 24,
    paddingTop: 6,
  },
}))

interface ITradeHeader {
  currentAssetItem: ICurrentAsset
  selectedExchange: string
  setSelectedExchange: React.Dispatch<React.SetStateAction<string>>
  quantity: number
  setLtp: React.Dispatch<React.SetStateAction<number>>
  ltpVal: number
}

const TradeHeader = ({
  currentAssetItem,
  selectedExchange,
  setSelectedExchange,
  quantity,
  setLtp,
  ltpVal,
}: ITradeHeader) => {
  const { classes } = useStyles()

  const dispatch = useDispatch();

  const {
    tradeModalInfo,
  } = useSelector((state: RootState) => state.trade);

  const riskApiResponse = useSelector((state: RootState) => state.trade.freezeQty)
  const { buySellToggle } = tradeModalInfo

  const { ltp } = useGetSocketVal(currentAssetItem?.token || 0)

  const [firstTimeLtp, setFirstTimeLtp] = useState(false);
  const [isOrderSlicingReq, setIsOrderSlicingReq] = useState(false)
  const [opened, setOpened] = useState(true);

  useEffect(() => {
    if (riskApiResponse.data && riskApiResponse.data.length > 0) {
      setIsOrderSlicingReq(true)
    } else {
      setIsOrderSlicingReq(false)
    }
    return () => {
      setIsOrderSlicingReq(false)
    }
  }, [riskApiResponse.status])

  useEffect(() => {
    if (!Number.isNaN(ltp) && !firstTimeLtp && ltp !== 0 && ltp !== ltpVal) {
      setFirstTimeLtp(true)
      setLtp(ltp)
    }
  }, [setLtp, ltp])

  // useEffect(() => {
  //   if (!Number.isNaN(ltp) && ltp !== 0) {
  //     setLtp(ltp)
  //   } else {
  //     setLtp(0)
  //   }
  // }, [setLtp, ltp])

  const handleBuySellToggle = (isBuy: boolean) => {
    dispatch(
      setTradeModalInfo({
        ...tradeModalInfo,
        buySellToggle: isBuy,
      })
    );
  }

  return (
    <Group position="apart" align="center">
      <Box className="heading_sm_semibold">
        <Flex align="baseline" className="heading_2xs_semibold">
          {buySellToggle ? 'Sell' : 'Buy'}
          {' '}
          <div className="mx-1">
            <FormattedName
              name={currentAssetItem.niceName}
              expiryDay={currentAssetItem.expiryDay}
              isWeekly={currentAssetItem.isWeekly}
              symbol={currentAssetItem.symbol}
            />
          </div>
          {' '}
          x
          {' '}
          <span className={classes.qtyStyle}>
            {quantity}
          </span>
          {' '}
          Qty
        </Flex>

        <div className={classes.headerInner}>
          <Radio.Group
            label=""
            size="xs"
            value={selectedExchange}
            onChange={setSelectedExchange}
            className="trade-header"
          >
            <Radio
              value={exchanges.NSE}
              label={
                ltp > 0 ? (
                  <span>
                    {currentAssetItem.exchange || exchanges.NSE}
                    {' '}
                    ₹
                    <span className="changing-text">{(ltp).toFixed(2)}</span>
                  </span>
                ) : (
                  <span>
                    {currentAssetItem.exchange || exchanges.NSE}
                    {' '}
                    <span className="changing-text">N/A</span>
                  </span>
                )
              }
              color={buySellToggle ? "red" : 'blue'}
            />
          </Radio.Group>
        </div>
      </Box>
      <Flex>
        {isOrderSlicingReq && (
        <Tooltip
          opened={opened}
          onClick={() => setOpened((o) => !o)}
          width={300}
          multiline
          withArrow
          label="You are trying to place an order for more than the freeze quantity. Your order will be executed in parts."
        >
          <Box sx={{ cursor: 'pointer' }}>
            <XboxA size={18} />
          </Box>
        </Tooltip>
        )}

        <Switch
          ml={8}
          radius="md"
          className="buy-sell-toggle"
          checked={buySellToggle}
          onChange={(event) => handleBuySellToggle(event.currentTarget.checked)}
        />
      </Flex>
    </Group>
  )
}

export default memo(TradeHeader)
