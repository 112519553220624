import React from 'react';
import LoaderIcon from "@assets/icons/ring-loader.svg"
import { createStyles } from '@mantine/core';

const useStyles = createStyles(() => ({
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "90vh",
  },
  loader: {
    animationName: "rotate",
    animationDuration: "2s",
    animationTimingFunction: "linear",
    animationIterationCount: "infinite",
    width: "50px",
    height: "50px",
    "@keyframes rotate": {
      "100%": {
        transform: "rotate(360deg)",
      },
    },
  },
}));

const QuantinstiLoader: React.FC = () => {
  const { classes } = useStyles();
  return (
    <div className={classes.loaderContainer}>
      <img src={LoaderIcon} className={classes.loader} alt="Loading..." />
    </div>
  );
}

export default QuantinstiLoader;
