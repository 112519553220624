import { takeLatest } from "redux-saga/effects"

import {
  handleConvertPostion,
  handleCoverOrder,
  handleEditCoverOrder,
  handleEditRegularOrder,
  handleOrderMargin,
  handleRegularOrder,
  handleBracketOrder,
  handleEditBracketOrder,
  handleGetFreezeQty,
} from "./tradesHandlers"

import {
  orderMargin, regularOrder, coverOrder, editRegularOrder, editCoverOrder, convertPostionOrder,
  bracketOrder, editBracketOrder, getFreezeQty,
} from "./tradesSlice"

export function* regularOrderSaga() {
  yield takeLatest(regularOrder.type, handleRegularOrder)
}

export function* editOrderSaga() {
  yield takeLatest(editRegularOrder.type, handleEditRegularOrder)
}

export function* coverOrderSaga() {
  yield takeLatest(coverOrder.type, handleCoverOrder)
}

export function* editCoverOrderSaga() {
  yield takeLatest(editCoverOrder.type, handleEditCoverOrder)
}

export function* bracketOrderSaga() {
  yield takeLatest(bracketOrder.type, handleBracketOrder)
}

export function* editBracketOrderSaga() {
  yield takeLatest(editBracketOrder.type, handleEditBracketOrder)
}

export function* tradeMarginSaga() {
  yield takeLatest(orderMargin.type, handleOrderMargin)
}

export function* convertPositionSaga() {
  yield takeLatest(convertPostionOrder.type, handleConvertPostion)
}

export function* getFreezeQtySaga() {
  yield takeLatest(getFreezeQty.type, handleGetFreezeQty)
}
