import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ActionFailure, ActionSuccess } from "@redux/types";
import {
  ISetupInitialState,
  IResponseFailure,
  ISetupInitiateSuccess,
  ISetupOTPSuccess,
  ISetupPasswordSuccess,
  ISetup2FaSuccess,

  ISetupInitiatePayload,
  ISetupVerifyOTPPayload,
  ISetupPasswordPayload,
  ISetup2FaPayload,
  ISetupResendOtpPayload,
  ISetupResendOtpSuccess,
} from ".";

const initialState: ISetupInitialState = {
  initiate: {
    loading: false,
    status: '',
    data: null,
  },
  verifyOTP: {
    loading: false,
    status: '',
    data: null,
  },
  setPassword: {
    loading: false,
    status: '',
    data: null,
  },
  set2Fa: {
    loading: false,
    status: '',
    data: null,
  },
  setupResendOtp: {
    loading: false,
    status: '',
    data: null,
  },
};

export const setupSlice = createSlice({
  name: "setup",
  initialState,
  reducers: {
    setupInitiate: (state, action:PayloadAction<ISetupInitiatePayload>) => {
      state.initiate.loading = true;
      state.initiate.status = '';
    },
    setupInitiateSuccess: (state, action: ActionSuccess<ISetupInitiateSuccess>) => {
      const { payload } = action;
      state.initiate = {
        loading: false,
        status: payload.status,
        data: payload.data,
      };
    },
    setupInitiateFailure: (state, action: ActionFailure<IResponseFailure>) => {
      const { payload } = action;
      state.initiate = {
        ...state.initiate,
        loading: false,
        status: payload.status,
        errorData: payload.errorData,
        message: payload.message,
        errorCode: payload.errorCode,
      };
    },

    setupVerifyOTP: (state, action: PayloadAction<ISetupVerifyOTPPayload>) => {
      state.verifyOTP.loading = true;
      state.verifyOTP.status = '';
    },
    setupVerifyOTPSuccess: (state, action: ActionSuccess<ISetupOTPSuccess>) => {
      const { payload } = action;
      state.verifyOTP = {
        loading: false,
        status: payload.status,
        data: payload.data,
      };
    },
    setupVerifyOTPFailure: (state, action: ActionFailure<IResponseFailure>) => {
      const { payload } = action;
      state.verifyOTP = {
        ...state.verifyOTP,
        loading: false,
        status: payload.status,
        errorData: payload.errorData,
        message: payload.message,
        errorCode: payload.errorCode,
      };
    },

    setupPassword: (state, action: PayloadAction<ISetupPasswordPayload>) => {
      state.setPassword.loading = true;
      state.setPassword.status = '';
    },
    setupPasswordSuccess: (state, action: ActionSuccess<ISetupPasswordSuccess>) => {
      const { payload } = action;
      state.setPassword = {
        loading: false,
        status: payload.status,
        data: payload.data,
      };
    },
    setupPasswordFailure: (state, action: ActionFailure<IResponseFailure>) => {
      const { payload } = action;
      state.setPassword = {
        ...state.setPassword,
        loading: false,
        status: payload.status,
        errorData: payload.errorData,
        message: payload.message,
        errorCode: payload.errorCode,
      };
    },

    setup2Fa: (state, action: PayloadAction<ISetup2FaPayload>) => {
      state.set2Fa.loading = true;
      state.set2Fa.status = '';
    },
    setup2FaSuccess: (state, action: ActionSuccess<ISetup2FaSuccess>) => {
      const { payload } = action;
      state.set2Fa = {
        loading: false,
        status: payload.status,
        data: payload.data,
      };
    },
    setup2FaFailure: (state, action: ActionFailure<IResponseFailure>) => {
      const { payload } = action;
      state.set2Fa = {
        ...state.set2Fa,
        loading: false,
        status: payload.status,
        errorData: payload.errorData,
        message: payload.message,
        errorCode: payload.errorCode,
      };
    },

    setupResendOtp: (state, action: PayloadAction<ISetupResendOtpPayload>) => {
      state.setupResendOtp.loading = true;
      state.setupResendOtp.status = '';
    },
    setupResendOtpSuccess: (state, action: ActionSuccess<ISetupResendOtpSuccess>) => {
      const { payload } = action;
      state.setupResendOtp = {
        loading: false,
        status: payload.status,
        data: payload.data,
      };
    },
    setupResendOtpFailure: (state, action: ActionFailure<IResponseFailure>) => {
      const { payload } = action;
      state.setupResendOtp = {
        ...state.setupResendOtp,
        loading: false,
        status: payload.status,
        errorData: payload.errorData,
        message: payload.message,
        errorCode: payload.errorCode,
      };
    },
  },
});

export const {
  setupInitiate,
  setupInitiateSuccess,
  setupInitiateFailure,

  setupVerifyOTP,
  setupVerifyOTPSuccess,
  setupVerifyOTPFailure,

  setupPassword,
  setupPasswordSuccess,
  setupPasswordFailure,

  setup2Fa,
  setup2FaSuccess,
  setup2FaFailure,

  setupResendOtp,
  setupResendOtpSuccess,
  setupResendOtpFailure,
} = setupSlice.actions;

export default setupSlice.reducer;
