import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => {
  const { colors } = theme.other;
  return {
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      flexWrap: 'wrap',
      borderTop: `1px solid ${theme.other.colors.disabled.muted}`,
      background: "white",
      padding: '12px',
      paddingLeft: '32px',
    },
    greenColor: {
      color: colors.success.default,
    },
    redColor: {
      color: colors.error.default,
    },
    wrapper: {
      display: 'flex',
      padding: '12px 0',
      alignContent: 'center',
      alignItems: 'flex-end',
      flex: "1",
      [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
        flex: "1 1 50%",
      },
    },
  }
});

export default useStyles;
