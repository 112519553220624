import {
  Box, Button, Flex, List, Modal, Text, createStyles,
} from '@mantine/core'
import { useState } from 'react'
import megaphone from "@assets/images/megaphone.svg"
import arrow from "@assets/icons/arrow.svg"

const useStyles = createStyles((theme) => {
  const { colors } = theme.other

  return {
    modal: {
      backgroundColor: colors.base.white,
      "& .mantine-Modal-inner": {
        top: "15%",
        [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
          top: "0",
        },
      },
      "& .mantine-Modal-content": {
        overflowY: "visible",
      },
      "& .mantine-Modal-body": {
        padding: 0,
        width: "48rem",
        [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
          width: "100%",
        },
      },
    },
    modalHeader: {
      width: "100%",
      borderRadius: ".5rem .5rem 0 0",
      height: "6rem",
      background: colors.primary.muted,
      padding: "1rem",
      borderBottom: `1px solid ${colors.primary.subtle}`,
    },
    headerText: {
      fontSize: "1.5rem",
      fontWeight: 600,
      lineHeight: "2rem",
      [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
        fontSize: "1.125rem",
        lineHeight: "1.75rem",
      },
    },
    listText: {
      fontSize: ".875rem",
      fontWeight: 400,
      lineHeight: "1.25rem",
      [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
        fontSize: ".8125rem",
      },
    },
    sourceContainer: {
      width: "auto",
      background: colors.bg.muted,
      padding: "1rem",
      margin: ".5rem 1rem 1.5rem 1rem",
      borderRadius: ".5rem",
    },
    megaphone: {
      position: "absolute",
      right: "1rem",
      top: 0,
    },
    link: {
      color: colors.blue.default,
      outline: "none",
      "&:hover": {
        cursor: "pointer",
      },
    },
    btnContainer: {
      width: "100%",
      borderTop: `1px solid ${colors.disabled.muted}`,
    },
  }
})

const ArrowIcon = () => (
  <img src={arrow} alt="arrow" />
)

const DisclaimerModal = () => {
  const [opened, setOpened] = useState(localStorage.getItem('disclaimer') !== "true")
  const { classes } = useStyles()

  const handleClose = () => {
    setOpened(false)
    // setCookie("disclaimer", "true")
    localStorage.setItem("disclaimer", "true")
  }

  const dummyClose = () => { }

  return (
    <Box>
      {opened ? (
        <Modal opened={opened} onClose={dummyClose} withCloseButton={false} className={classes.modal}>
          <Flex align="center" className={classes.modalHeader} pos="relative">
            <Box w="70%">
              <Text className={`${classes.headerText} text_fg_default`}>
                Risk Disclosure on
                Derivatives
              </Text>
            </Box>

            <img src={megaphone} alt="megaphone" className={classes.megaphone} />
          </Flex>

          <Box>
            <List p="md" spacing="md">
              <List.Item icon={<ArrowIcon />} className={`text_fg_default ${classes.listText}`}>
                9 out of 10 individual traders in equity Futures and Options Segment, incurred net losses.
              </List.Item>
              <List.Item icon={<ArrowIcon />} className={`text_fg_default ${classes.listText}`}>
                On an average, loss makers registered net trading loss close to ₹50,000
              </List.Item>
              <List.Item icon={<ArrowIcon />} className={`text_fg_default ${classes.listText}`}>
                Over and above the net trading losses incurred, loss makers expended an additional 28%
                of net trading losses as transaction costs.
              </List.Item>
              <List.Item icon={<ArrowIcon />} className={`text_fg_default ${classes.listText}`}>
                Those making net trading profits, incurred between 15% to 50% of such profits as transaction cost.
              </List.Item>
            </List>

            <Box className={classes.sourceContainer}>
              <Text className="content_2xs_regular text_fg_subtle">
                Source:
                <a href="https://www.sebi.gov.in/" target="_blank" className={classes.link} rel="noreferrer"> SEBI </a>
                study dated January 25, 2023 on “Analysis of Profit and Loss of Individual Traders dealing in equity
                Futures and Options (F&O) Segment”, wherein Aggregate Level findings are based on annual Profit/Loss
                incurred by individual traders in equity F&O during FY 2021-22.
              </Text>
            </Box>
          </Box>

          <Flex justify="end" p="md" className={classes.btnContainer}>
            <Button
              onClick={handleClose}
            >
              I Understand
            </Button>
          </Flex>

        </Modal>
      ) : null}
    </Box>

  )
}

export default DisclaimerModal
