import {
  Box, Button, Divider, Group, Modal, Text,
} from '@mantine/core'
import useMediaCustom from 'src/hooks/common/useMediaCustom';

interface IConfirmCancelSingle {
  isVisible: boolean
  handleCloseModal: (isBoolean: boolean) => void
  handleConfirmCancel: () => void
  selectedRow: {
    id: string,
    symbol: string
  }
}

const ConfirmCancelSingle = ({
  isVisible,
  handleCloseModal,
  handleConfirmCancel,
  selectedRow,
}: IConfirmCancelSingle) => {
  const { isMobile } = useMediaCustom();
  return (
    <Modal
      onClose={() => null}
      opened={isVisible}
      size="md"
      withCloseButton={false}
      fullScreen={!!isMobile}
    >
      <Box mb={10}>
        <p className="heading_sm_semibold">Cancel the Order ?</p>
      </Box>
      <Divider />
      <Box py={20}>
        <Text>{selectedRow.symbol}</Text>
        <Text>
          #
          {selectedRow.id}
        </Text>
      </Box>
      {/* modal button */}
      <Divider />
      <Group position="right" align="flex-start" mt="md">

        <Button
          variant="outline"
          color="red"
          onClick={handleConfirmCancel}
        >
          Cancel
        </Button>
        <Button
          variant="outline"
          onClick={() => handleCloseModal(false)}
        >
          Close
        </Button>
      </Group>
    </Modal>
  )
}

export default ConfirmCancelSingle
