import {
  Box,
  Divider,
  Grid,
  Group,
  NumberInput,
  Radio,
  Text,
  useMantineTheme,
} from "@mantine/core"
import { ValidityRetention, varietyValues } from "../../../utils/constant"
import useStyles from "../styled"

interface IValidityOptions {
  activeTab: string
  validityVal: string
  setValidityVal: React.Dispatch<React.SetStateAction<string>>
  disclosedQty: number
  setDisclosedQty: React.Dispatch<React.SetStateAction<number>>
  isOpenOrder: boolean | undefined
}

const ValidityOptions = ({
  activeTab,
  validityVal,
  setValidityVal,
  disclosedQty,
  setDisclosedQty,
  isOpenOrder,
}: IValidityOptions) => {
  const { classes } = useStyles()
  const theme = useMantineTheme()
  const { colors } = theme.other
  return (
    <Box>
      <Divider my={10} color={colors.disabled.muted} />
      <Grid>
        <Grid.Col span={4}>
          <Text size="sm">Validity</Text>
          <Radio.Group
            label=""
            size="xs"
            value={validityVal}
            onChange={setValidityVal}
          >
            <Group>
              <Radio
                mt="xs"
                value={ValidityRetention.DAY}
                label="Day"
                color="blue"
              />
              <Radio
                mt="xs"
                disabled={activeTab === varietyValues.COVER || isOpenOrder}
                value={ValidityRetention.IOC}
                label="Immediate"
                color="blue"
            // TODO
            // setting disclosed qty to zero, as it's not applicable in validity of IOC,
            // later on when we enable minutes, separate the logic to reset state as per selected value of validity
                onClick={() => setDisclosedQty(0)}
              />
            </Group>
            {/* <Radio
            mt="xs"
            // temporary disabled this option as we do not support this
            // disabled={activeTab === varietyValues.COVER}
            disabled
            value="minutes"
            label="Minutes"
            color="blue"
          /> */}
          </Radio.Group>
        </Grid.Col>
        <Grid.Col span={4}>
          {/* <Select
          allowDeselect
          defaultValue="1m"
          description="Select time"
          disabled={
            validityVal === ValidityRetention.DAY
            || validityVal === ValidityRetention.IOC
          }
          data={[
            { value: "1m", label: "1 Minute" },
            { value: "5m", label: "5 Minute" },
            {
              value: "10m",
              label: "10 Minute",
            },
          ]}
        /> */}
        </Grid.Col>
        <Grid.Col span={4}>
          <NumberInput
            type="number"
            mt="xl"
            id="disclosed-qty"
            placeholder="Disclosed Qty"
            description="Disclosed Qty"
            disabled={
            validityVal === ValidityRetention.IOC
            || activeTab === varietyValues.COVER
          }
            min={1}
            value={disclosedQty}
            onChange={(val: number) => setDisclosedQty(val)}
            classNames={{
              control: classes.numberInputControl,
              rightSection: classes.numberInputRightSection,
            }}
          />
        </Grid.Col>
      </Grid>
    </Box>
  );
}

export default ValidityOptions
