import { call, put } from "redux-saga/effects"
import { filterErrorResponse } from "@utils/index"
import { getHoldingsSuccess, getHoldingsFailure } from "./holdingsSlice"
import { holdingsEndPoints } from '../../api/apiEndpoints'
import axios from '../../api/axiosInterceptors'

function* handleUserHoldings(): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(axios.request, {
      method: "get",
      url: holdingsEndPoints.userHoldingsUrl,
    })
    const updatedData = data.map((item: any) => {
      const {
        qty, t1Qty, brokerCollateralQty, unPledgedQty, authorizedQty, nonPOAQty, depositoryQty, usedQty,
      } = item;
      return {
        ...item,
        effectiveQty: Number(qty)
          + Number(t1Qty)
          + Number(brokerCollateralQty)
          + Number(unPledgedQty)
          + Number(authorizedQty)
          + Math.max(
            Number(nonPOAQty),
            Number(depositoryQty)
          ) - Number(usedQty),
        holdingQty: Number(item.holdingQty),
        avgPrice: Number(item.avgPrice),
        ltp: 0,
        netChg: 0,
        currentVal: 0,
        dayChg: 0,
        symbol: item.tradingSymbol.split("-")[0],
      }
    })
    yield put(getHoldingsSuccess({ data: updatedData, status }))
  } catch (error: any) {
    yield put(getHoldingsFailure(filterErrorResponse(error)))
  }
}

export default handleUserHoldings
