import { createSelector } from "reselect"
import { RootState } from "src/redux/store"

export const getSelectedWLIndex = (state: RootState) => state.watchlist.selectedWLIndex
export const getWatchlistData = (state: RootState) => state.watchlist.userWatchList.data
export const getWLSearchData = (state: RootState) => state.watchlist.searchWatchlists

export const selectSelectedWLIndex = createSelector(
  [getSelectedWLIndex],
  (data) => data
)

export const selectWatchlistData = createSelector(
  [getWatchlistData],
  (data) => data || []
)

export const selectWLSearchData = createSelector(
  [getWLSearchData],
  (data) => data || []
)
