import { all, spawn } from "redux-saga/effects"

import * as authSagas from "./sagas/auth/authSaga"
import * as tradesSagas from "./sagas/trades/tradesSaga"
import * as ordersSagas from "./sagas/orders/ordersSaga"
import * as positionSagas from "./sagas/positions/positionsSaga"
import * as holdingSagas from "./sagas/holdings/holdingsSaga"
import * as watchlistSagas from "./sagas/watchlists/watchlistsSaga"
import * as usersSagas from "./sagas/users/usersSaga"
import * as setupSagas from "./sagas/setup/setupSaga"

export default function* rootSaga() {
  yield all(
    [
      ...Object.values(watchlistSagas),
      ...Object.values(authSagas),
      ...Object.values(usersSagas),
      ...Object.values(ordersSagas),
      ...Object.values(tradesSagas),
      ...Object.values(positionSagas),
      ...Object.values(holdingSagas),
      ...Object.values(setupSagas),
    ].map(spawn)
  )
}
