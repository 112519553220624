import { orderStatusColor, productType } from "@utils/constant"
import { TransactionType } from "src/constants/common"
import { isPartialExecution, parsedSymbol } from "@utils/index"
import FormattedName from "@components/watchlist/formattedName"
import { Flex, Text, Badge } from "@uikit"
import { BuySellConst } from "@constants"
import useStyles from "./executedCard.styles"
import { IExecutedCardItemProps } from "./executedCard.types"

const ExecutedCardItem = ({ row, handleRowClick }: IExecutedCardItemProps) => {
  const { classes } = useStyles();

  const { symbol, exchange } = row;
  const parsedSymbolObj = parsedSymbol(symbol, exchange, row.token);

  const handleRowItemClick = () => {
    handleRowClick(row)
  }

  return (
    <Flex gap={8} className={classes.executedCardItem} onClick={handleRowItemClick}>
      <Flex direction="column" gap={4}>
        <Flex justify="space-between">
          <Flex gap={4} align="center">
            { parsedSymbolObj && (
              <FormattedName
                name={parsedSymbolObj.niceName}
                expiryDay={parsedSymbolObj.expiryDay}
                isWeekly={parsedSymbolObj.isWeekly}
                className="content_sm_medium"
              />
            )}
            <Flex gap={2}>
              <Text className="content_2xs_regular text_fg_subtle">{row.exchange}</Text>
              <Text className="content_2xs_regular text_fg_subtle">{productType[row.product]}</Text>
            </Flex>
          </Flex>
          <Text className="content_sm_medium">{row.averagePrice?.toFixed(2) || '0.00'}</Text>
        </Flex>

        <Flex justify="space-between">
          <Flex gap={4}>
            <Text className="content_2xs_regular text_fg_subtle">Qty.</Text>
            <Text className="content_2xs_regular">{`${row.fillShares || 0} / ${row.quantity}`}</Text>
          </Flex>
          <Flex gap={4}>
            <Text className="content_2xs_regular text_fg_subtle">Avg. Price</Text>
            <Text className="content_2xs_regular">{row.averagePrice?.toFixed(2) || '0.00'}</Text>
          </Flex>
        </Flex>
      </Flex>

      <Flex justify="space-between">
        <Flex gap={4}>
          <Badge size="md" radius="xs" color={row.transactionType === TransactionType.B ? "blue" : "red"}>
            {BuySellConst[row.transactionType]}
          </Badge>
          <Badge
            radius="xs"
            color={
              orderStatusColor[isPartialExecution(row.cancelQuantity, row.fillShares) ? 'PARTIAL' : row.orderStatus]
            }
            className="cursor-pointer"
          >
            {isPartialExecution(row.cancelQuantity, row.fillShares) ? 'PARTIALLY EXECUTED' : row.orderStatus}
          </Badge>
        </Flex>
        <Text className="content_2xs_regular">{row.timeStamp.slice(0, 9)}</Text>
      </Flex>
    </Flex>
  )
}

export default ExecutedCardItem
