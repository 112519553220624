import ReactDOM from "react-dom/client"
import "./index.css"
import { Provider } from "react-redux"
import * as Sentry from "@sentry/react";
import App from "./app"
import reportWebVitals from "./reportWebVitals"
import { store } from "./redux/store"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

Sentry.init({
  dsn: `${process.env.REACT_APP_SENTRY_DSN}`,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [`${process.env.REACT_APP_URL}`],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

root.render(
  <Provider store={store}>
    <App />
  </Provider>
)

reportWebVitals()
