import { Text } from '@mantine/core'
import { daySuffix, removeEQFromSymbol } from '../../utils'

const FormattedName = ({
  name,
  isWeekly,
  expiryDay = 0,
  symbol = "",
  size = "sm",
  className = '',
  isWatchlist = false,
}: {
  name: string,
  isWeekly: boolean,
  expiryDay: number
  symbol?: string,
  size?: "xs" | "xsm" | "sm" | "md" | "lg" | "xl"
  className?: string,
  isWatchlist? : boolean,
}) => (isWeekly ? (
  <Text className={className} size={size}>
    {name.split(" ")[0]}
    {' '}
    {expiryDay > 0 && (
      <span>
        {expiryDay}
        <sup>{daySuffix[expiryDay] || 'th'}</sup>
        {isWatchlist && isWeekly ? <sup className="weeklySymbol">w</sup> : null}
      </span>
    )}
    {' '}
    {name.split(" ").slice(2).join(" ")}
  </Text>
) : <Text className={className} size={size}>{name?.toUpperCase() || removeEQFromSymbol(symbol)?.toUpperCase()}</Text>)

export default FormattedName
