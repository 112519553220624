import { ProductTypes } from "../redux/sagas/orders"

export const varietyValues = {
  REGULAR: "regular",
  COVER: "cover",
  AMO: "amo",
  BRACKET: "bracket",
}

export const productOptions = [
  {
    label: "Intraday (MIS)",
    value: "I",
  },
  {
    label: "Longterm (CNC)",
    value: "C",
  },
  {
    label: "Overnight (NRML)",
    value: "M",
  },
  // for cover order
  {
    label: "Intraday (MIS)",
    value: "H",
  },
  // for bracket order
  {
    label: "Intraday (MIS)",
    value: "B",
  },
]

export const productOptionsDisplay = {
  INTRADAY: "I",
  LONGTERM: "C",
  COVER: "H",
  OVERNIGHT: "M",
  BRACKET: "B",
}

// the disabled array contains the order types that are disabled for the particular product type
export const OrderTypeML = [
  {
    label: "Market",
    value: "MKT",
    disabled: ["bracket"],
  },
  {
    label: "Limit",
    value: "LMT",
    disabled: [],
  },
]

export const OrderTypeSLM = [
  {
    label: "SL",
    value: "SL-LMT",
    disabled: ["cover"],
  },
  {
    label: "SL-M",
    value: "SL-MKT",
    disabled: ["cover", "bracket"],
  },
]

export const orderTypesDisplay = {
  MARKET: "MKT",
  LIMIT: "LMT",
  STOPLOSSLIMIT: "SL-LMT",
  STOPLOSSMARKET: "SL-MKT",
}

export const orderType = {
  MKT: "Market",
  LMT: "Limit",
  "SL-LMT": "SL",
  'SL-MKT': "SL-M",
}

export const productType: ProductTypes = {
  C: "CNC",
  I: "MIS",
  B: "BO",
  H: "CO",
  M: "NRML",
}

export const ValidityRetention = {
  DAY: "DAY",
  IOC: "IOC",
}

export const exchanges = {
  NSE: "NSE",
  BSE: "BSE",
  NFO: "NFO",
}

export const colors = {
  textDefault: "#444",
  // primaryRed: "#ff5722",
  primaryRed: "#fa5252",
  primaryBlue: "#4184f3",
}

export const orderTabTypes = {
  ORDERS: "orders",
  GTT: "gtt",
  BASKETS: "baskets",
  SIP: "sip",
  ALERTS: "alerts",
  IPO: "ipo",
}

export const orderStatus = {
  PENDING: "PENDING",
  CANCELED: "CANCELED",
  OPEN: "OPEN",
  REJECTED: "REJECTED",
  COMPLETE: "COMPLETE",
  TRIGGER_PENDING: "TRIGGER_PENDING",
  INVALID_STATUS_TYPE: "INVALID_STATUS_TYPE",
}

export const orderStatusColor = {
  PARTIAL: "orange",
  PENDING: "orange",
  CANCELED: "gray",
  OPEN: "gray",
  REJECTED: "red",
  COMPLETE: "green",
  TRIGGER_PENDING: "orange",
  INVALID_STATUS_TYPE: "red",
}

export const tablePage = {
  GTT: "GTT",
  ORDERS: "ORDERS",
  POSITION: "POSITION",
  OPEN: "OPEN",
}

export const socketUpdate = {
  ORDER_UPDATE: "orderUpdate",
}

export const MODE = {
  LTPC: "ltpc",
  QUOTE: "quote",
  FULL: "full",
}

export const MSG_TYPE = {
  SUBSCRIBE: "sub",
  UNSUBSCRIBE: "unsub",
  CONNECT: "connect",
}

export const SOCKET_CONNECT = {
  CONNECTING: "connecting",
  CONNECTED: "connected",
  RETRYING: "retrying",
}

export const OTP_PREFERENCE = {
  SMS: "SMS",
  EMAIL: "EMAIL",
}

export const tradeModalOpenedFrom = {
  WATCHLIST: "watchlist",
  ORDERTABLE: "ordertable",
}

export const gttOrderConst = {
  SINGLE: "single",
  OCO: "oco",
}

export const ErrorFallbackMsg = "Unable to process request, Pease retry"

export const INDICES = "INDICES";

export const EXCHANGE_COLOR = {
  NSE: "red",
  BSE: "blue",
  NFO: "gray",
  INDICES: "gray",
}

export const SHOW_MORE_COUNT = 20;

export const tableContainer = { width: "100%", height: "100%" }

export const tableType = {
  POSITION: 'postion_table',
  OPEN: 'open_table',
  EXECUTED: 'executed_table',
  TRADES: 'trades_table',
  COMPLETED: 'completed_table',
  HOLDINGS: 'holdings_table',
  GTT: 'gtt_table',
}

// API response statuses
export const SUCCESS = "success"
export const FAILURE = "failure"
