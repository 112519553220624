/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box, Breadcrumbs, Button, CopyButton, createStyles, Grid, List, PasswordInput, Text, TextInput, Tooltip,
} from '@mantine/core'
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'wouter';
import { selectUsersDetails } from 'src/redux/sagas/users/userSelectors';
import usePrevious from 'src/hooks/common/usePrevious';
import ContainerCard from '@components/cards/containerCard';
import { getTypographyStyles } from '@theme/themeConfig';
import { ArrowNarrowRight, Bulb, InfoCircle } from 'tabler-icons-react';
// import totpImg from "../../assets/images/totp.svg";
import { Flex } from '@uikit';
import useMediaCustom from '@hooks/common/useMediaCustom';
import {
  changePassword, enable2Fa, enable2FaSuccess, verify2Fa,
} from '@redux/sagas/users/usersSlice';
import { RootState } from '@redux/store';
import { FAILURE, SUCCESS } from '@utils/constant';
import { allowOnlyNumber, capitalizeFirstLetter, isEqual } from '../../utils';

const useStyles = createStyles((theme) => {
  const { colors } = theme.other;
  return {
    // fg/default
    linkActive: {
      color: colors.fg.default,
    },
    link: {
      color: 'gray',
    },
    secretInput: {
      background: "#f9f9f9",
      padding: "5px 10px",
      marginTop: "10px",
      fontSize: '10px',
      border: 'none',
      outline: 'none',
      width: '100%',
    },
    totp: {
      margin: "0 60px",
    },
    passwordLabel: {
      ...getTypographyStyles('content_sm_normal'),
    },
    visibilityToggle: {
      color: colors.primary.default,
    },
    input: {
      height: "2.625rem",
      border: `1px solid ${colors.disabled.muted}`,
      marginTop: 6,
    },
    innerInput: {
      padding: "18px 14px",
    },
    passwordTipsContainer: {
      background: colors.warning.subtle,
      padding: "12px 30px 12px 16px",
      borderRadius: "8px",
      color: colors.warning.default,
    },
  }
});

const EditProfile = () => {
  const { cx, classes } = useStyles();

  const items = [
    { title: 'Profile', href: '/profile' },
    { title: 'Password & Security', href: '/edit-profile' },
  ].map((item) => (
    <Link
      href={item.href}
      key={item.title}
      className={
        item.href === window.location.pathname
          ? cx(classes.link, classes.linkActive)
          : classes.link
      }
    >
      {item.title}
    </Link>
  ));

  const { isMobile } = useMediaCustom();

  const dispatch = useDispatch();

  const [totpVal, setTotpVal] = useState('')
  const [showError, setShowError] = useState(false)

  const {
    changePassword: changePasswordData,
    verify2Fa: verify2FaData,
  } = useSelector((state: RootState) => state.user)
  const { data: enable2FaData, status: enable2faStatus } = useSelector((state: RootState) => state.user.enable2Fa)

  const prevProps = usePrevious({ changePasswordData, verify2FaData })

  const { data: detailsData, status: detailsStatus } = useSelector(selectUsersDetails)

  const form = useForm({
    initialValues: { oldPassword: '', newPassword: "", confirmNewPassword: "" },
    validate: {
      oldPassword: (value) => (value?.length === 0 ? "Old password is required" : null),
      newPassword: (value) => (value?.length === 0 ? "New password is required" : null),
      confirmNewPassword: (value, values) => {
        if (value?.length === 0) {
          return "Confirm password is required"
        } if (value !== values.newPassword) {
          return 'Passwords did not match'
        }
        return null
      },
    },
  });

  const handleEnableTOTP = () => {
    dispatch(enable2Fa({}))
  }

  const handleChangeTOTP = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const input = document.getElementById("totp-input") as HTMLInputElement;
    input.type = "number";
    input.focus();
    if (allowOnlyNumber(value)) {
      setTotpVal(value)
      setShowError(false)
    }
  }

  const handleVerifyTOTP = () => {
    if (totpVal?.length !== 6) {
      setShowError(true)
    } else {
      setShowError(false)
    }
    dispatch(verify2Fa({ totp: totpVal }));
  }

  const handleCancelTOTP = () => {
    dispatch(enable2FaSuccess({
      // @ts-ignore
      data: {}, loading: false,
    }))
  }

  const handleSubmit = (value: { oldPassword: string, newPassword: string }) => {
    dispatch(changePassword({
      userId: detailsData ? detailsData.id : '',
      oldPassword: value.oldPassword,
      newPassword: value.newPassword,
    }))
  }

  useEffect(() => () => {
    form.reset();
  }, []);

  useEffect(() => {
    if (!isEqual(prevProps.changePasswordData.status, changePasswordData.status)
      && changePasswordData.status === FAILURE) {
      showNotification({
        color: "red",
        title: capitalizeFirstLetter(changePasswordData.message),
        message: 'Change password failed',
      })
    } else if (!isEqual(prevProps.changePasswordData.status, changePasswordData.status)
      && changePasswordData.status === SUCCESS) {
      form.reset();
      showNotification({
        color: "green",
        title: "Password reset successfully",
        message: '',
      })
    }
  }, [prevProps, changePasswordData])

  useEffect(() => {
    if (!isEqual(prevProps.verify2FaData.status, verify2FaData.status) && verify2FaData.status === FAILURE) {
      showNotification({
        color: "red",
        title: capitalizeFirstLetter(verify2FaData.message),
        message: 'Unable to enable 2FA',
      })
    } else if (!isEqual(prevProps.verify2FaData.status, verify2FaData.status) && verify2FaData.status === SUCCESS) {
      form.reset();
      showNotification({
        color: "green",
        title: "2FA enabled successfully",
        message: '',
      })
    }
  }, [verify2FaData, prevProps])

  return (
    <>
      <Breadcrumbs ml="lg" mt="lg">
        {items}
      </Breadcrumbs>
      <Box p="lg">
        {/* change password */}
        <Box mb={20}>
          <ContainerCard headerTitle="Update Password">
            <Grid>
              <Grid.Col md={12} lg={5}>
                <form onSubmit={form.onSubmit(handleSubmit)}>
                  <PasswordInput
                    label="Current Password"
                    placeholder="Enter Current Password"
                    {...form.getInputProps("oldPassword")}
                    id="oldPassword"
                    mb={10}
                    inputMode="numeric"
                    classNames={{
                      label: classes.passwordLabel,
                      visibilityToggle: classes.visibilityToggle,
                      input: classes.input,
                      innerInput: classes.innerInput,
                    }}
                  />
                  <PasswordInput
                    label="New Password"
                    placeholder="Enter New Password"
                    {...form.getInputProps("newPassword")}
                    classNames={{
                      label: classes.passwordLabel,
                      visibilityToggle: classes.visibilityToggle,
                      input: classes.input,
                      innerInput: classes.innerInput,
                    }}
                    id="newPassword"
                    mb={10}
                  />
                  <PasswordInput
                    label="Confirm New Password"
                    placeholder="Confirm New Password"
                    {...form.getInputProps("confirmNewPassword")}
                    id="confirmNewPassword"
                    classNames={{
                      label: classes.passwordLabel,
                      visibilityToggle: classes.visibilityToggle,
                      input: classes.input,
                      innerInput: classes.innerInput,
                    }}
                  />
                  <Button aria-label="signin" name="signin" mt="xl" type="submit">
                    Update Password
                  </Button>
                </form>
              </Grid.Col>
              <Grid.Col md={0} lg={1} />
              <Grid.Col md={12} lg={5}>
                <Box className={classes.passwordTipsContainer}>
                  <Flex>
                    <Bulb />
                    <Text ml="xs" className="heading_xs_semibold">
                      Password tips
                    </Text>
                  </Flex>
                  {/* Type and Number/Text sm/Regular */}
                  <List
                    className="content_sm_regular text_warning_default"
                    pl="xl"
                    size="sm"
                    mt="xs"
                  >
                    <List.Item>Choose long passwords.</List.Item>
                    <List.Item>
                      Meaningless phrases made up of multiple words make for
                      secure passwords. These are memorable and cannot be guessed
                      easily.
                      <span className="text_fg_muted">
                        For example: Rainbow colors paint the sky in a magical
                        dance.
                      </span>
                    </List.Item>
                  </List>
                </Box>
              </Grid.Col>
            </Grid>
          </ContainerCard>
        </Box>

        {/* External 2FA TOTP */}
        {detailsStatus === SUCCESS && detailsData && detailsData.totpEnabled ? null : (
          <ContainerCard headerTitle="Two Factor Authentication">
            {enable2faStatus === SUCCESS && enable2FaData && enable2FaData.image ? (
              <Box>
                <p className="text_fg_subtle text_md_regular">
                  Scan the QR code on your authenticator app, Once scanned, the
                  app should give you a 6 digit TOTP. Enter it here. Need help?
                </p>
                <Box mt={24} className={classes.passwordTipsContainer}>
                  <Flex>
                    <InfoCircle />
                    <Text ml="xs" className="heading_xs_semibold">
                      IMPORTANT:
                      {" "}
                    </Text>
                  </Flex>
                  <Text ml={32} mt={6} size="xs">
                    Once external TOTP is setup, you have to use the same mobile
                    authenticator app to generate a new 6-digit TOTP every time
                    you login.
                  </Text>
                </Box>
                <Flex mt={40} align="flex-start">
                  <Box>
                    <img
                      width={170}
                      height={170}
                      src={`data:image/png;base64,${enable2FaData.image}`}
                      alt="doc"
                    />
                    <Box ml={8}>
                      <CopyButton value={enable2FaData.secret} timeout={2000}>
                        {({ copied, copy }) => (
                          <Tooltip
                            label={copied ? "Copied" : "Copy"}
                            withArrow
                            position="right"
                          >
                            <Text
                              size={10}
                              className="text_primary_default"
                              sx={{ cursor: "pointer" }}
                              onClick={copy}
                            >
                              Can&apos;t scan? Copy the key.
                            </Text>
                          </Tooltip>
                        )}
                      </CopyButton>
                      <input
                        className={classes.secretInput}
                        value={enable2FaData.secret}
                      />
                    </Box>
                  </Box>
                  <Box mt={20} ml={24} w={300}>
                    <TextInput
                      label="Enter TOTP"
                      id="totp-input"
                      withAsterisk
                      error={showError ? "TOTP is required" : ""}
                      type="text"
                      placeholder="6 digit TOTP"
                      maxLength={6}
                      value={totpVal}
                      inputMode="numeric"
                      pattern="[0-9]*"
                      onChange={handleChangeTOTP}
                    />
                    <Flex justify="space-between">
                      <Button
                        w="48%"
                        variant="outline"
                        color="gray"
                        onClick={handleCancelTOTP}
                        mt={10}
                        mb={10}
                      >
                        Cancel
                      </Button>
                      <Button
                        w="48%"
                        onClick={handleVerifyTOTP}
                        mt={10}
                        mb={10}
                      >
                        Enable
                      </Button>
                    </Flex>
                  </Box>
                </Flex>
              </Box>
            ) : (
              <Flex direction={isMobile ? "column" : "row"}>
                {/* <img
                alt="logo"
                src={totpImg}
                width="120"
                className={classes.totp}
                height="216"
              /> */}
                <Box>
                  <Text className="content_sm_regular text_fg_default">
                    Use external TOTP apps like Google® Authenticator
                    on your phone or desktop to generate 6 digit TOTPs
                    for every login. You won&apos;t be able to login without enabling
                    2FA.
                  </Text>
                  <Text mt={6} className="content_md_regular text_fg_muted">
                    An OTP will be sent to your email for verification first.
                  </Text>
                  <Button
                    mt={20}
                    rightIcon={<ArrowNarrowRight />}
                    onClick={handleEnableTOTP}
                  >
                    Enable 2FA
                  </Button>
                </Box>
              </Flex>
            )}
          </ContainerCard>
        )}
      </Box>
    </>
  );
}

export default EditProfile
