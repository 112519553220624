import { all, call, put } from "redux-saga/effects"
import { filterErrorResponse, removeEQFromSymbol } from "@utils/index"
import { productOptionsDisplay } from "@utils/constant"
import {
  requestCancelRegularOrder,
  requestCancelCoverOrder,
  requestEditOcoAlert,
  requestEditSingleAlert,
  requestGttDetails,
  requestGttOrder,
  requestOcoAlert,
  requestOrderHistory,
  requestSingleAlert,
  requestUserOrders,
  requestUserTrades,
  requestCancelBracketOrder,
} from "./ordersRequests"

import {
  cancelOrderFailure,
  cancelOrderSuccess,
  cancelOrderUpdate,
  deleteGttOrderFailure,
  deleteGttOrderSuccess,
  editGttOcoOrderFailure,
  editGttOcoOrderSuccess,
  editGttSingleOrderFailure,
  editGttSingleOrderSuccess,
  getOrderHistoryFailure,
  getOrderHistorySuccess,
  getUserOrdersFailure,
  getUserOrdersSuccess,
  getUserTradesFailure,
  getUserTradesSuccess,
  gttDetailsFailure,
  gttDetailsSuccess,
  gttOcoOrderFailure,
  gttOcoOrderSuccess,
  gttSingleOrderFailure,
  gttSingleOrderSuccess,
} from "./ordersSlice"

import { IGttOrderDetail, IUserOrder, ICancelOrder } from "."

// handle regular order
export function* handleUserOrders(): Generator<any, any, any> {
  // const { ordersData = [] } = yield select((state: RootState) => state.order.orders);
  // yield put(setUserOrders({ loading: ordersData.length === 0, data: ordersData }))
  try {
    const { data: { data, status } } = yield call(requestUserOrders)
    const formattedData = data?.map((item: IUserOrder) => ({
      ...item,
      quantity: Number(item.quantity),
      price: Number(item.price),
      averagePrice: Number(item.averagePrice),
    }))
    yield put(getUserOrdersSuccess({ data: formattedData, status }))
  } catch (error:any) {
    yield put(getUserOrdersFailure(filterErrorResponse(error)))
  }
}

// handle regular order
export function* handleOrderHistory(action: any): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestOrderHistory, action?.payload?.orderId)
    yield put(getOrderHistorySuccess({ data, status }))
  } catch (error:any) {
    yield put(getOrderHistoryFailure(filterErrorResponse(error)))
  }
}

export function* handleCancelRegularOrder(action: ICancelOrder) {
  try {
    const orders = action.payload;
    yield put(cancelOrderUpdate({
      data: { isMultipleOrder: true, totalOrder: orders.length, successOrder: 0 },
    }))
    const batchSize = 5;
    for (let i = 0; i < orders.length; i += batchSize) {
      // here we want to call the api in batch of 5
      try {
        // calling api based on the product type
        const batchPromises = orders.slice(i, i + batchSize).map((order) => {
          if (order.product === productOptionsDisplay.COVER as 'H') {
            return call(requestCancelCoverOrder, order.snoOrderid);
          } if (order.product === productOptionsDisplay.BRACKET as 'B') {
            return call(requestCancelBracketOrder, order.snoOrderid)
          }
          return call(requestCancelRegularOrder, order.id);
        });
        yield all(batchPromises);
        yield put(cancelOrderUpdate({ data: { successOrder: i } }))
      } catch (error:any) {
        // We dont wanna cancel the whole saga just cause
        // one orders couldnt be exited
        // eslint-disable-next-line no-continue
        continue;
      }
    }
    yield put(cancelOrderSuccess({ data: {}, status: 'success' }))
  } catch (error:any) {
    yield put(cancelOrderFailure(filterErrorResponse(error)))
  }
}

// fetch user trades history
export function* handleUserTrades(): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestUserTrades)
    const formattedData = data?.map((item: IUserOrder) => ({
      ...item,
      quantity: Number(item.quantity),
      price: Number(item.price),
      averagePrice: Number(item.averagePrice),
    }))
    yield put(getUserTradesSuccess({ data: formattedData, status }))
  } catch (error:any) {
    yield put(getUserTradesFailure(filterErrorResponse(error)))
  }
}

// gtt : oco order
export function* handleGttOcoOrder(action: any): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestOcoAlert, action.payload)
    yield put(gttOcoOrderSuccess({ data, status }))
  } catch (error:any) {
    yield put(gttOcoOrderFailure(filterErrorResponse(error)))
  }
}

// gtt : update oco order
export function* handleEditOcoOrder(action: any): Generator<any, any, any> {
  const { data: payloadData, id } = action.payload
  try {
    const { data: { data, status } } = yield call(requestEditOcoAlert, payloadData, id)
    yield put(editGttOcoOrderSuccess({ data, status }))
  } catch (error:any) {
    yield put(editGttOcoOrderFailure(filterErrorResponse(error)))
  }
}

// gtt : single order
export function* handleGttSingleOrder(action: any): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestSingleAlert, action.payload)
    yield put(gttSingleOrderSuccess({ data, status }))
  } catch (error:any) {
    yield put(gttSingleOrderFailure(filterErrorResponse(error)))
  }
}

// gtt : edit single order
export function* handleEditSingleOrder(action: any): Generator<any, any, any> {
  const { data: payloadData, id } = action.payload
  try {
    const { data: { data, status } } = yield call(requestEditSingleAlert, payloadData, id)
    yield put(editGttSingleOrderSuccess({ data, status }))
  } catch (error:any) {
    yield put(editGttSingleOrderFailure(filterErrorResponse(error)))
  }
}

// gtt : get order details
export function* handleGttOrderDetails(): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestGttDetails)
    const formattedData = data?.map((item: IGttOrderDetail) => ({
      ...item,
      token: Number(item.token),
      symbol: removeEQFromSymbol(item.symbol),
      orderParams1: Number(item.leg1.price) || 0,
    }))
    yield put(gttDetailsSuccess({ data: formattedData, status }))
  } catch (error:any) {
    yield put(gttDetailsFailure(filterErrorResponse(error)))
  }
}

// gtt : delete order
export function* handleDeleteGttOrder(action: any): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestGttOrder, action.payload)
    yield put(deleteGttOrderSuccess({ data, status, loading: false }))
  } catch (error:any) {
    yield put(deleteGttOrderFailure(filterErrorResponse(error)))
  }
}
