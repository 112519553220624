import { Text, Tooltip, useMantineTheme } from '@mantine/core';
import { useSelector } from 'react-redux';
import { useLocation } from 'wouter';
import TextColor from 'src/utils/textColor';
import { RootState } from 'src/redux/store';
import FormattedName from '@components/watchlist/formattedName';
import { instrumentSearch, parsedSymbol } from 'src/utils';
import { Flex } from '@uikit';
import { WATCHLIST_DISPLAY_FORMATS } from '@constants/watchlist';
import useStyles from './styles';

interface NiftyHeaderProps {
  item: {
    symbol: string;
    token: number;
    exchange: string;
    segment: string;
  }
}

const AppBarSymbol = ({ item }: NiftyHeaderProps) => {
  const {
    symbol, token, segment, exchange,
  } = item;
  const { cx, classes } = useStyles();
  const { quoteSocketData } = useSelector((state: RootState) => state.sockets);
  const [, setLocation] = useLocation();
  const displayFormat = useSelector((state: RootState) => state.watchlist.displayFormat);
  const isPercentageAndAbsolute = displayFormat === WATCHLIST_DISPLAY_FORMATS[2].value;

  const currentSocket = quoteSocketData[token] || {};

  const { netChange = 0 } = currentSocket || {}
  const { ltp, close } = currentSocket || { ltp: 0, close: 0 };
  const absoluteChange = ((ltp - close) / 100);
  const theme = useMantineTheme();

  const chartData = parsedSymbol(symbol, exchange, token)

  const handleChart = (selectedExchange: string) => {
    if (token) {
      setLocation(`/charts/${selectedExchange}/${chartData?.tradingSymbol}/${token}`);
    }
  };
  const parsedSymbolObj = instrumentSearch.get(symbol, segment, exchange)

  const largeText = isPercentageAndAbsolute ? symbol.length > 9 : symbol.length > 14

  return (
    <Flex
      sx={{ width: "48%", cursor: "pointer", justifyContent: "center" }}
      direction={largeText ? "column" : "row"}
      align={largeText ? "flex-start" : "center"}
      onClick={() => handleChart(exchange)}
      pl={largeText ? 4 : 0}
    >
      {/* name */}
      <Text mr={6}>
        <Tooltip
          label="Open chart"
          transitionProps={{ transition: 'slide-down', duration: 100 }}
        >
          <div>
            <FormattedName
              name={parsedSymbolObj.niceName}
              expiryDay={parsedSymbolObj.expiryDay}
              isWeekly={parsedSymbolObj.isWeekly}
              size="xs"
            />
          </div>
        </Tooltip>
      </Text>

      {/* ltp */}
      <Flex
        sx={{ height: isPercentageAndAbsolute ? '28%' : "auto" }}
        align="baseline"
        mt={largeText ? 3 : 0}
      >
        <Text
          sx={TextColor(netChange)}
          className={cx(classes.ltp)}
          mr={3}
        >
          {(Number(currentSocket.ltp) / 100 || 0).toFixed(2)}
        </Text>

        <Flex pb="0.5px">
          {/* absolute change */}
          {displayFormat !== WATCHLIST_DISPLAY_FORMATS[0].value && (
          <Text
            mx={2}
            className={cx(
              "text_fg_muted",
              isPercentageAndAbsolute ? " content_2xs_medium" : "content_xs_medium"
            )}
          >
            {absoluteChange.toFixed(2)}
          </Text>
          )}

          {/* percentage */}
          {displayFormat !== WATCHLIST_DISPLAY_FORMATS[1].value && (
          <Text
            // className={classes.netChange}
            className={isPercentageAndAbsolute ? "content_2xs_medium" : "content_xs_medium"}
            ml={4}
            color={theme.colors.colorLight[0]}
          >
            (
            {netChange.toFixed(2)}
            {' '}
            <span className={isPercentageAndAbsolute ? "content_2xs_medium" : "content_xs_medium"}>
              %
            </span>
            )
          </Text>
          )}
        </Flex>

      </Flex>
    </Flex>
  );
};

export default AppBarSymbol
