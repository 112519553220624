import {
  call, fork, put, select,
} from "redux-saga/effects"
import { errorHandler } from "@redux/api/handler"
import { SUCCESS } from "@utils/constant"
import {
  requestAddWatchlistItem,
  requestRemoveWatchlistItem,
  requestUpdateSequence,
  requestWatchlists,
} from "./watchlistsRequests"
import {
  // setRemoveWatchlistItem,
  removeWlItemFailure,
  // setWatchlists,
  // setAddWatchlistItem,
  // setUpdateSequence,
  getWatchlistsSuccess,
  getWatchlistsFailure,
  addWLItemFailure,
  updateSequenceFailure,
} from "./watchlistsSlice"

// handle watchlist lists
export function* handleWatchlists(): Generator<any, any, any> {
  try {
    const { data: { data } } = yield call(requestWatchlists)

    // const sortedData = data.map((wlItem: any) => {
    //   const sorted = wlItem.items.sort((a: any, b: any) => b.sequence - a.sequence);
    //   return {
    //     items: sorted,
    //     ...wlItem,
    //   }
    // })
    yield put(getWatchlistsSuccess({ data, loading: false }))
  } catch (error: any) {
    yield put(getWatchlistsFailure({ error: error.message, isError: true }))
  }
}

// handle remove watchlist item
export function* handleRemoveWatchlistItem(action: any): Generator<any, any, any> {
  const watchlistsData = yield select(
    (state) => state.watchlist.userWatchList.data
  )
  const { mwId, watchlistItemId } = action.payload
  try {
    // todo: remove fork
    yield fork(requestRemoveWatchlistItem, mwId, watchlistItemId)

    const selectedWLIndex = watchlistsData.findIndex(
      (item: any) => item.id === mwId
    )
    const selectedWatchlistItem = watchlistsData[selectedWLIndex].items.filter(
      (item: any) => item.id !== watchlistItemId
    )
    const updatedWatchlistArr = [...watchlistsData]

    updatedWatchlistArr[selectedWLIndex] = {
      ...watchlistsData[selectedWLIndex],
      items: selectedWatchlistItem,
    }
    yield put(getWatchlistsSuccess({ data: updatedWatchlistArr, loading: false }))
  } catch (error: any) {
    yield put(removeWlItemFailure({ error: error.message, isError: true }))
  }
}

// handle add watchlist item
export function* handleAddWatchlistItem(action: any): Generator<any, any, any> {
  const watchlistsData = yield select(
    (state) => state.watchlist.userWatchList.data
  )
  // yield put(setAddWatchlistItem({ loading: true }))
  const { mwId, data: addData } = action.payload
  try {
    const { data: { data, status } } = yield call(requestAddWatchlistItem, mwId, addData)
    if (status === SUCCESS) {
      const selectedWLIndex = watchlistsData.findIndex(
        (item: any) => item.id === mwId
      )
      const updatedWatchlistArr = [...watchlistsData]

      updatedWatchlistArr[selectedWLIndex] = {
        ...watchlistsData[selectedWLIndex],
        items: [data, ...watchlistsData[selectedWLIndex].items],
      }
      yield put(
        getWatchlistsSuccess({
          data: updatedWatchlistArr,
          loading: false,
        })
      )
    }
  } catch (error: any) {
    const { message, status } = errorHandler(error)
    yield put(addWLItemFailure({
      error: message, status, isError: true, token: action.payload.data.token,
    }))
  }
}

// handle Update Sequence
export function* handleUpdateSequence(action: any): Generator<any, any, any> {
  const { data, mwId } = action.payload
  try {
    yield call(requestUpdateSequence, mwId, data)
    // handleWatchlists()
    const watchlistRes = yield call(requestWatchlists)
    yield put(getWatchlistsSuccess({ data: watchlistRes.data.data, loading: false }))
  } catch (error: any) {
    yield put(updateSequenceFailure({ error: error.message, isError: true }))
  }
}
