/* eslint-disable import/prefer-default-export */
function formatDate(inputDate) {
  const date = new Date(inputDate);

  let day:any = date.getDate();
  let month:any = date.getMonth() + 1;
  const year = date.getFullYear();

  if (day < 10) {
    day = `0${day}`;
  }
  if (month < 10) {
    month = `0${month}`;
  }

  return `${day}/${month}/${year}`;
}

export { formatDate }
