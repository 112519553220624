import { Button, PasswordInput, TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { RootState } from '@redux/store';
import React, { useCallback } from 'react'
import { useSelector } from 'react-redux';
import CaptchaContainer from "@containers/forgot/CaptchaContainer";
import { FAILURE } from '@utils/constant';
import useStyles from './index.styles';
import ExistingUser from './existingUser';

interface RenderLoggedInStateProps {
  existedUser: string
  userId: string
  userImg: string
  handleChangeUser: () => void
  validate2faRequestId: string
  focusTrapRef: (instance: HTMLElement | null) => void
  handleSubmit: (val: any) => void | null
  formLogin: UseFormReturnType<{
    userId: string;
    password: string;
    captchaValue: string;
  }, (values: {
    userId: string;
    password: string;
    captchaValue: string;
  }) => {
    userId: string;
    password: string;
    captchaValue: string;
  }>
}

const LoginInput = ({
  existedUser,
  userId,
  userImg,
  handleChangeUser,
  handleSubmit,
  formLogin,
}: RenderLoggedInStateProps) => {
  const loginState = useSelector((state: RootState) => state.auth.login);
  const { status, loading } = loginState;
  const isLoginFailure = status === FAILURE;
  const { classes } = useStyles();
  const handleInput = useCallback((event: React.FormEvent<HTMLInputElement>) => {
    const regex = /^[A-Za-z0-9]+$/;
    const { value } = event.currentTarget;
    if (!regex.test(value)) {
      // eslint-disable-next-line no-param-reassign
      event.currentTarget.value = value.replace(/[^A-Za-z0-9]/g, "");
    }
  }, []);

  return (
    <form onSubmit={formLogin.onSubmit(handleSubmit)}>
      {existedUser ? (
        <ExistingUser
          userId={userId}
          userImg={userImg}
          handleChangeUser={handleChangeUser}
        />
      ) : (
        <TextInput
          label="User Id"
          className={classes.upperCasing}
          placeholder="AB1234"
          maxLength={6}
          id="userId"
          onInput={handleInput}
          {...formLogin.getInputProps("userId")}
          onBlur={() => formLogin.validateField('userId')}
        />
      )}
      <PasswordInput
        label="Password"
        placeholder="Password"
        mt="md"
        {...formLogin.getInputProps("password")}
        id="password"
      />
      {
        isLoginFailure && loginState?.errorData?.captchaRequired ? (
          <CaptchaContainer callCaptcha form={formLogin} />
        ) : null
      }
      <Button
        aria-label="signin"
        name="signin"
        fullWidth
        mt="xl"
        type="submit"
        loading={loading}
      >
        Sign In
      </Button>
    </form>
  )
}

export default LoginInput
