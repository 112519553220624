import {
  memo, useEffect,
  useMemo,
} from "react"
import { Box } from "@mantine/core"
import { useDispatch, useSelector } from "react-redux"
import { bounceAnimation } from "src/utils"
import type { RootState } from "src/redux/store"
import useRouteChange from "@hooks/common/useRouteChange"
import HoldingZeroState from "@components/zeroState/holdingZeroState"
import Loading from "@components/loading/loading"
import ErrorElement from "@components/errorElement"
import { getHoldings } from "@redux/sagas/holdings/holdingsSlice"
import useMediaCustom from "@hooks/common/useMediaCustom"
import { FAILURE } from "@utils/constant"
import RenderBoundary from "@components/renderBoundary/renderBoundary"
import { useDeepCompareMemoize, useSubUnsub } from "@hooks/sockets"
import HoldingTableList from "./holdingTableList"

const Holdings = () => {
  const dispatch = useDispatch()
  const { isLargeScreen } = useMediaCustom();
  const holdingsState = useSelector((state: RootState) => state.holding.holdings);
  const { data: holdingsData, loading, status } = holdingsState
  const isHoldingsData = holdingsData && holdingsData.length > 0;
  const isError = status === FAILURE && !holdingsData
  const { isNavigateLoading } = useRouteChange()

  useEffect(() => {
    dispatch(getHoldings())
  }, [])

  const tokens = useMemo(() => {
    const tokensList = holdingsData?.map((item: any) => item.token)
    return tokensList || []
  }, [useDeepCompareMemoize(holdingsData)]);

  useSubUnsub(tokens, window.location.pathname);

  const secondLoading = loading && holdingsData

  return (
    <RenderBoundary
      loading={loading}
      status={status}
      data={holdingsData}
      errorState={(
        <Box p={isLargeScreen ? "lg" : "sm"}>
          <ErrorElement
            component="Holdings"
            message={isError && holdingsState.message}
          />
        </Box>
      )}
    >
      {isHoldingsData ? (
        <Box
          sx={secondLoading && isNavigateLoading ? bounceAnimation : {}}
          p={isLargeScreen ? "lg" : "sm"}
        >
          <HoldingTableList
            title="Holdings"
            data={holdingsData}
            loading={Boolean(secondLoading)}
            hydratedData={[]}
            isNoInternet={!!(isError && !holdingsData)}
          />
        </Box>
      ) : (
        <Box p={isLargeScreen ? "lg" : "sm"}>
          <HoldingZeroState />
          {loading ? <Loading /> : null}
        </Box>
      )}
    </RenderBoundary>
  );
}

export default memo(Holdings)
