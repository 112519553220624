import {
  Box, Button, createStyles, useMantineTheme,
} from '@mantine/core'
import { getTypographyStyles } from '@theme/themeConfig';
import { X } from 'tabler-icons-react';
import { useDispatch } from 'react-redux';
import { markNoticeAsRead } from '@redux/sagas/users/usersSlice';

export const useStyles = createStyles((theme, { bgColor }:any) => {
  const color = theme.other.colors;
  return {
    bannerContainer: {
      background: bgColor,
      padding: `${theme.spacing.sm} ${theme.spacing.lg}`,
      border: `1px solid ${color.warning.subtle}`,
      color: color.fg.default,
      fontSize: theme.fontSizes.sm,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      ...getTypographyStyles("content_sm_medium"),
    },
    viewMoreBtn: {
      opacity: '0.8',
      transition: 'all 0.2s ease-in-out',
      "&:hover": {
        opacity: 1,
        backgroundColor: 'transparent',
      },
    },
  };
})

interface BannerProps {
  title: string;
  viewMore: boolean;
  viewMoreHandler?: any;
  className?: string;
  type:string;
  id:string
}

const Banner = ({
  title,
  viewMore,
  viewMoreHandler,
  className,
  type,
  id,
}: BannerProps) => {
  const dispatch = useDispatch();
  const theme = useMantineTheme();
  const { colors } = theme.other;

  const getBackgroundColor = (bannerType:string) => {
    switch (bannerType) {
      case "info":
        return colors.warning.subtle;
      case "warning":
        return colors.error.muted;
      case "error":
        return colors.error.subtle;
      default:
        return colors.warning.subtle;
    }
  }
  const { classes, cx } = useStyles({ bgColor: getBackgroundColor(type) });

  const markAsRead = (noticeId:string) => {
    dispatch(markNoticeAsRead(noticeId));
  }

  return (
    <Box className={cx(classes.bannerContainer, className)}>
      {title}
      {viewMore ? (
        <Button className={classes.viewMoreBtn} onClick={viewMoreHandler} size="xs" variant="subtle">
          View more
        </Button>
      ) : (
        <Button variant="subtle" className={classes.viewMoreBtn} onClick={() => markAsRead(id)}>
          <X size={16} color={colors.fg.default} />
        </Button>
      )}
    </Box>
  );
};

export default Banner
