import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => {
  const { colors } = theme.other;
  return {
    dataWrapper: {
      marginTop: 4,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    barcodeImage: {
      height: 200,
      width: 200,
      margin: "auto",
    },
    inputDetailsWrapper: {
      width: "100%",
      marginTop: 16,
    },
    secretInput: {
      background: colors.bg.muted,
      padding: "5px 10px",
      marginTop: "10px",
      fontSize: "10px",
      border: "none",
      outline: "none",
      width: "100%",
    },
    link: {
      cursor: 'pointer',
      color: 'blue',
      fontWeight: 500,
    },
    radioInner: {
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    textLink: {
      cursor: 'pointer',
      // textDecoration: 'underline',
    },
    tutorialImage: {
      height: '600px',
      width: 'auto',
    },
    tutorialImageWrapper: {
      textAlign: 'center',
    },
    stepsContainer: {
      position: 'relative',
      '&::before': {
        content: '""',
        position: 'absolute',
        left: '28px',
        top: '24px',
        bottom: '32px',
        width: '1px',
        borderLeft: `2px dotted ${theme.colors.gray[5]}`,
      },
    },
    stepItem: {
      position: 'relative',
      zIndex: 1,
      marginBottom: theme.spacing.md,
    },
    stepNumberContainer: {
      backgroundColor: theme.white,
      // padding: '2px',
      marginRight: theme.spacing.md,
    },
    stepNumber: {
      width: 24,
      height: 24,
      borderRadius: '50%',
      backgroundColor: colors.primary.muted,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: '1px solid',
      borderColor: colors.primary.default,
    },
  };
});

export default useStyles;
