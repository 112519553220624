import { useMediaQuery } from "@mantine/hooks";
import theme from "@theme/theme";

const useMediaCustom = () => {
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints?.xs}px)`);
  const isSmallScreen = useMediaQuery(
    `(max-width: ${theme.breakpoints?.sm}px)`
  );
  const isLargeScreen = useMediaQuery(
    `(min-width: ${theme.breakpoints?.md}px)`,
    window.innerWidth > Number(theme.breakpoints?.md)
  );
  const isTablet = useMediaQuery(`(max-width: ${theme.breakpoints?.md}px)`);

  return {
    isMobile,
    isSmallScreen,
    isLargeScreen,
    isTablet,
  };
};

export default useMediaCustom;
