import { Box } from "@mantine/core"
import { memo, useState } from "react"
import { Draggable } from "react-beautiful-dnd"
import { useDispatch, useSelector } from "react-redux"
import { setFullToken } from "@redux/sagas/sockets/socketsSlice"
import { selectFullSocket } from "@redux/sagas/sockets/socketSelectors"
import type { IWatchlistItem } from "@redux/sagas/watchlists"
import { IFullSocketData } from "@redux/sagas/sockets"
import { instrumentSearch, isEqual } from "@utils/index"
import { RootState } from "@redux/store"
import MarketDepth from "./marketDepth"
import useStyles from "./styled"
import SymbolItem from "./symbolItem"
import WatchlistItem from "./watchlistItem"

interface IWatchlistItemContainer {
  item: IWatchlistItem
  index: number
  selectedWLItemIndex: number
  marketDepth: IFullSocketData | Record<string, string>
}

const WatchlistItemContainer = ({
  item,
  index,
  selectedWLItemIndex,
  marketDepth,
}: IWatchlistItemContainer) => {
  const { classes, cx } = useStyles()
  const dispatch = useDispatch()

  const fullSocketData = useSelector(selectFullSocket)
  const { addWLItem: { token } } = useSelector((state: RootState) => state.watchlist);

  const [showMarketDepth, setShowMarketDepth] = useState<boolean>(false)
  const [selectedToken, setSelectedToken] = useState<number>(0)

  const handleMarketDepth = (selectedAssetItem: any) => {
    setSelectedToken(selectedAssetItem?.token)
    if (marketDepth?.token === selectedAssetItem?.token) {
      if (showMarketDepth) {
        setShowMarketDepth(false)
        dispatch(setFullToken({ [selectedAssetItem?.token]: 0 }))
      } else {
        setShowMarketDepth(true)
      }
    } else {
      if (fullSocketData.token) {
        dispatch(setFullToken({ [fullSocketData.token]: 0 }))
      }
      if (showMarketDepth) {
        setShowMarketDepth(false)
        return
      }
      setShowMarketDepth(true)
      dispatch(setFullToken({ [selectedAssetItem?.token]: 1 }))
    }
  }
  const parsedSymbolObj = instrumentSearch.get(item.symbol, item.segment, item.exchange)

  return (
    <Box key={item.token} className={classes.itemContainer}>
      <Draggable key={item.token} index={index} draggableId={item.token.toString()}>
        {(provided: any, snapshot: any) => (
          <Box
            className={cx(
              classes.item,
              {
                [classes.itemDragging]: snapshot.isDragging,
              },
              selectedWLItemIndex === index && classes.styledSelectedWatchlistItem,
              token === item.token && classes.animateItem
            )}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            <WatchlistItem
              symbol={item?.symbol}
              token={item.token}
              exchange={item.exchange}
              segment={item.segment}
            />
            {showMarketDepth ? (
              <MarketDepth selectedToken={selectedToken} item={item} />
            ) : null}
          </Box>
        )}
      </Draggable>
      <SymbolItem
        isAdd={false}
        item={item}
        parsedSymbolObj={parsedSymbolObj}
        token={item.token}
        handleMarketDepth={handleMarketDepth}
      />
    </Box>
  )
}

export default memo(WatchlistItemContainer, (prevProps, nextProps) => {
  const {
    item, index, selectedWLItemIndex, marketDepth,
  } = prevProps

  const value = isEqual(
    {
      item,
      index,
      selectedWLItemIndex,
      marketDepth,
    },
    {
      item: nextProps.item,
      index: nextProps.index,
      selectedWLItemIndex: nextProps.selectedWLItemIndex,
      marketDepth: nextProps.marketDepth,
    }
  )
  return value
})
