function replaceUnderscoreWithSpace(str) {
  return str.replace(/_/g, ' ');
}

function convertToTitleCase(str) {
  const result = str.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export { replaceUnderscoreWithSpace, convertToTitleCase }
