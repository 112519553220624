import { productType } from "@utils/constant"
import { extractDate, parsedSymbol } from "@utils/index"
import FormattedName from "@components/watchlist/formattedName"
import { Flex, Text, Badge } from "@uikit"
import { BuySellConst } from "@constants"
import PnLText from "@components/atoms/pnlText"
import theme from "@theme/theme"
import useStyles from "./gttCard.styles"
import { IGttCardItemProps } from "./gttCard.types"

const GttCardItem = ({ row, handleRowClick }: IGttCardItemProps) => {
  const { classes } = useStyles();

  const { symbol, exchange } = row;
  const parsedSymbolObj = parsedSymbol(symbol, exchange, row.token);

  const handleRowItemClick = () => {
    handleRowClick(row)
  }

  const leg2TriggerPrice = row.variables.length === 2 ? row.variables[1].name : row.variables[0].name;

  return (
    <Flex gap={8} className={classes.executedCardItem} onClick={handleRowItemClick}>
      <Flex direction="column" gap={4}>
        <Flex justify="space-between">
          {parsedSymbolObj && (
            <FormattedName
              name={parsedSymbolObj.niceName}
              expiryDay={parsedSymbolObj.expiryDay}
              isWeekly={parsedSymbolObj.isWeekly}
              className="content_sm_medium"
            />
          )}
          <PnLText
            colorScheme={theme.colorScheme}
            netChange={row.ltp}
            value={row.ltp.toFixed(2) || 0.0}
            textClass="content_sm_medium"
          />
        </Flex>

        <Flex justify="space-between">
          <Flex gap={2}>
            <Text className="content_xs_regular text_fg_subtle">{row.exchange}</Text>
            <Text className="content_xs_regular text_fg_subtle">{productType[row.product]}</Text>
          </Flex>
          <Flex gap={4}>
            <Text className="content_2xs_regular text_fg_subtle">Trigger:</Text>
            <Flex gap={4} direction="row-reverse" align="baseline">
              <Text className="content_2xs_regular">
                {Number(row.ltp) !== 0
                  ? (
                    ((Number(leg2TriggerPrice) - Number(row.ltp)) / Number(row.ltp)) * 100).toFixed(2)
                  : 0}
                %
              </Text>
              <Text className="content_2xs_regular">{leg2TriggerPrice}</Text>
            </Flex>
            {row.variables.length === 2 ? (
              <Flex gap={4} mt={6} direction="row-reverse" align="baseline">
                <Text className="content_2xs_regular">
                  {Number(row.ltp) !== 0
                    ? (
                      ((Number(row.variables[0].name)
                        - Number(row.ltp))
                        / Number(row.ltp))
                      * 100
                    ).toFixed(2)
                    : 0}
                  %
                </Text>
                <Text className="content_2xs_regular">{row.variables[0].name}</Text>
              </Flex>
            ) : null}
          </Flex>
        </Flex>
      </Flex>

      <Flex justify="space-between">
        <Flex gap={4}>
          <Flex align="center">
            <Badge radius="xs" color="gray">
              {row.variables.length === 2 ? "OCO" : "Single"}
            </Badge>
            <Badge
              radius="xs"
              color={row.transactionType === "B" ? "blue" : "red"}
            >
              {BuySellConst[row.transactionType]}
            </Badge>
          </Flex>
          <Text className="content_2xs_regular text_fg_default">
            {
              row.alertType === "LMT_BOS_O"
                ? `${row.leg2.quantity} / ${row.leg1.quantity}`
                : row.quantity
            }
          </Text>
        </Flex>
        <Text className="content_2xs_regular text_fg_default">{extractDate(row.createdAt)}</Text>
      </Flex>
    </Flex>
  )
}

export default GttCardItem
