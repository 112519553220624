import { configureStore, Dispatch, AnyAction } from "@reduxjs/toolkit"
import createSagaMiddleware from "redux-saga"
import authReducer, { resetAuthSlice } from "./sagas/auth/authSlice"
import miscReducer, { resetMiscSlice } from "./sagas/misc/miscSlice"
import socketsReducer from "./sagas/sockets/socketsSlice"
import userReducer, { resetUsersSlice } from "./sagas/users/usersSlice"
import watchlistReducer, { resetWatchlistsSlice } from "./sagas/watchlists/watchlistsSlice"
import tradeReducer, { resetTradesSlice } from "./sagas/trades/tradesSlice"
import orderReducer, { resetOrdersSlice } from "./sagas/orders/ordersSlice"
import holdingsReducer, { resetHoldingsSlice } from "./sagas/holdings/holdingsSlice"
import positionsReducer, { resetPositionsSlice } from "./sagas/positions/positionsSlice"
import setupReducer from "./sagas/setup/setupSlice"
import rootSaga from "./rootSaga"

const sagaMiddleware = createSagaMiddleware()

export const store = configureStore({
  reducer: {
    auth: authReducer,
    misc: miscReducer,
    sockets: socketsReducer,
    user: userReducer,
    watchlist: watchlistReducer,
    trade: tradeReducer,
    order: orderReducer,
    holding: holdingsReducer,
    position: positionsReducer,
    setup: setupReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    thunk: false,
    serializableCheck: false,
  }).prepend(sagaMiddleware),
  devTools: process.env.REACT_APP_MODE !== "production",
})

export const resetAllSlices = (): ((dispatch: Dispatch<AnyAction>) => void) => (dispatch) => {
  dispatch(resetWatchlistsSlice())
  dispatch(resetUsersSlice());
  dispatch(resetTradesSlice())
  dispatch(resetPositionsSlice())
  dispatch(resetOrdersSlice());
  dispatch(resetMiscSlice())
  dispatch(resetHoldingsSlice())
  dispatch(resetAuthSlice())
};

sagaMiddleware.run(rootSaga)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
