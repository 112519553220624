import {
  useCallback, useEffect, useRef,
} from "react"
import { useSelector, useDispatch } from "react-redux"
import { setTradeModalInfo } from "src/redux/sagas/trades/tradesSlice";
import { removeWlItem } from "src/redux/sagas/watchlists/watchlistsSlice";
import { IWatchlist } from "src/redux/sagas/watchlists";
import type { RootState } from '@redux/store';
import { debounce, extractTradeModalData, isInputSelected } from "../../utils"

interface IeventsWLProps {
  selectedWatchlist: IWatchlist
  handleWLItemIndex?: (value: number) => void
  selectedWLItemIndexRef?: React.MutableRefObject<number>
}

const useWLEvents = ({
  selectedWatchlist,
  handleWLItemIndex = () => { },
  selectedWLItemIndexRef,
}: IeventsWLProps) => {
  const dispatch = useDispatch();

  const { searchWatchlists } = useSelector((state: RootState) => state.watchlist)
  const {
    tradeModalInfo: {
      showTradeModal,
    },
    processOrderInfo: { processOrder },
  } = useSelector((state: RootState) => state.trade)

  const selectedWatchlistRef = useRef(selectedWatchlist)
  const tempSearchWatchlists = useRef(searchWatchlists)
  const showTradeModalRef = useRef(false)
  const processOrderRef = useRef(processOrder)
  // const selectedWLItemIndexRef = useRef(-1)

  // const [selectedWLItemIndex, setSelectedWLItemIndex] = useState(-1)

  useEffect(() => {
    showTradeModalRef.current = showTradeModal
  }, [showTradeModal])

  useEffect(() => {
    selectedWatchlistRef.current = selectedWatchlist
  }, [selectedWatchlist])

  const processChange = useCallback(
    debounce(() => { tempSearchWatchlists.current = searchWatchlists }, 350),
    []
  )

  const handleSelectStock = (assetItem: any, isSell: boolean) => {
    dispatch(
      setTradeModalInfo({
        currentAssetItem: extractTradeModalData(assetItem),
        buySellToggle: isSell,
        showTradeModal: true,
      })
    );
  };

  useEffect(() => {
    processChange()
  }, [processChange, searchWatchlists])

  useEffect(() => {
    tempSearchWatchlists.current = searchWatchlists
  }, [searchWatchlists])

  useEffect(() => {
    processOrderRef.current = processOrder
  }, [processOrder])

  // const handleProceedOrder = ((token: number) => {
  //   dispatch(setProceedOrder({ selectedToken: token, processOrder: processOrderRef.current + 1 }))
  // })

  // const handleWLItemIndex = (value: number) => {
  //   if (searchWatchlists.length === 0) {
  //     setSelectedWLItemIndex(value)
  //     selectedWLItemIndexRef.current = value
  //   }
  // }

  // handling watchlist navigation via shortcut
  const keyboardClickEvent = (event: any) => {
    const name = event.key
    const tempSelectedWlIndex = Number(selectedWLItemIndexRef?.current || 0)

    // const selectedWatchlistItem = selectedWatchlistRef.current?.items[tempSelectedWlIndex]
    const tempWatchlistRef = selectedWatchlistRef.current?.items?.length
    const wlContainer = document.getElementById("wl-container") as HTMLElement
    const rect = wlContainer?.getBoundingClientRect();

    // const tradeQtyElement = document.getElementById('trade-qty');
    // const tradePriceElement = document.getElementById('trade-price');
    // const triggerPriceElement = document.getElementById('trigger-price');
    // const disclosedPriceElement = document.getElementById('disclosed-qty');
    // const isSelected = document.activeElement === tradeQtyElement
    //   || document.activeElement === tradePriceElement
    //   || document.activeElement === triggerPriceElement
    //   || document.activeElement === disclosedPriceElement;

    if (
      isInputSelected()
      && wlContainer
      && tempSearchWatchlists.current.length === 0
    ) {
      switch (name) {
        case "ArrowDown":
          if (tempWatchlistRef - 1 === tempSelectedWlIndex) {
            handleWLItemIndex(0)
            wlContainer.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
          } else {
            handleWLItemIndex(tempSelectedWlIndex + 1)
            if (tempSelectedWlIndex > 10) {
              wlContainer.scrollBy(rect.bottom, 46)
            }
          }
          return
        case "ArrowUp":
          if (tempSelectedWlIndex === 0) {
            wlContainer.scrollTo({
              top: tempWatchlistRef * 46,
              left: 0,
              behavior: "smooth",
            });
            handleWLItemIndex(tempWatchlistRef - 1)
          } else {
            handleWLItemIndex(tempSelectedWlIndex - 1)
            if (tempSelectedWlIndex > 10) {
              wlContainer.scrollBy(rect.bottom, -46)
            }
          }
          // return
          break
        default:
          break
      }
    }
    // previously this was placing an order on enter key press from the trade modal
    // But wrong token was being sent
    // so new logic is implemented in the tradeContainer.tsx

    // if (isSelected) {
    //   switch (name) {
    //     case "Enter":
    //       if (showTradeModalRef?.current) {
    //         console.log("selectedItemFromModal", selectedItemFromModal)
    //         handleProceedOrder(selectedItemFromModal)
    //         // handleWLItemIndex(tempSelectedWlIndex)
    //       }
    //       break;
    //     default:
    //       break
    //   }
    // }
  }

  const memoized = useCallback(
    () => {
      document.addEventListener(
        "keydown",
        keyboardClickEvent,
        false
      )
      return () => {
        window.removeEventListener('keydown', keyboardClickEvent);
      };
    },
    []
  )

  useEffect(() => {
    memoized()
  }, [])

  // handle trade-modal shortcuts
  const memoizedShortcut = useCallback(() => {
    document.addEventListener(
      "keypress",
      (event) => {
        const name = event.key
        const selectedWatchlistItem = selectedWatchlistRef.current?.items[selectedWLItemIndexRef?.current || 0]
        const tempSelectedWL = selectedWatchlistRef.current?.id

        if (
          isInputSelected()
          && selectedWatchlistItem
        ) {
          switch (name) {
            case "b":
              handleSelectStock(selectedWatchlistItem, false)
              break;
            case "s":
              handleSelectStock(selectedWatchlistItem, true)
              break;
            case "d":
              if (selectedWatchlistItem?.id) {
                dispatch(
                  removeWlItem({
                    mwId: tempSelectedWL,
                    watchlistItemId: selectedWatchlistItem.id,
                  })
                )
              }
              break;
            default:
              break
          }
        }
      },
      false
    )
    return () => {
      document.removeEventListener(
        "keyup",
        (event) => {
          const name = event.key;
          switch (name) {
            default:
              handleSelectStock(0, false)
              break;
          }
        },
        false
      );
    };
  }, [])

  useEffect(() => {
    memoizedShortcut()
  }, [])

  // return { selectedWLItemIndex }
  return { selectedIndex: 1 }
}

export default useWLEvents
