import { useCallback, useEffect } from "react";
import { isInputSelected } from "src/utils";
import { useLocation } from "wouter";

const usePageShortcut = () => {
  const [, setLocation] = useLocation();

  const handleRedirectOrder = useCallback((routeName: string) => {
    if (isInputSelected()) {
      setLocation(routeName);
    }
  }, [setLocation])

  // handling page shortcut
  useEffect(() => {
    document.addEventListener(
      "keyup",
      (event) => {
        const name = event.key;
        switch (name) {
          case "o":
            handleRedirectOrder("/orders");
            return;
          case "h":
            handleRedirectOrder("/holdings");
            return
          case "p":
            handleRedirectOrder("/positions");
            return
          case "f":
            handleRedirectOrder("/funds");
            return
          case "m":
            handleRedirectOrder("/profile");
            break;
          default:
            break;
        }
      },
      false
    );
    return () => {
      document.removeEventListener(
        "keyup",
        (event) => {
          const name = event.key;
          switch (name) {
            default:
              handleRedirectOrder("/profile");
              break;
          }
        },
        false
      );
    };
  }, [handleRedirectOrder]);

  return null
}

export default usePageShortcut
