import { createStyles, rem } from "@mantine/core";

const useStyles = createStyles((theme) => {
  const { colors } = theme.other;
  return {
    executedCardItem: {
      borderBottom: `1px solid ${colors.disabled.muted}`,
      borderLeft: `1px solid ${colors.disabled.muted}`,
      borderRight: `1px solid ${colors.disabled.muted}`,
      padding: `${rem(12)} ${rem(16)}`,
      flexDirection: 'column',
      '&:first-of-type': {
        borderTop: `1px solid ${colors.disabled.muted}`,
      },
    },
  }
})

export default useStyles;
