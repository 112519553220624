import Drawer from "@uikit/drawer"
import { useDisclosure } from "@mantine/hooks";
import { useState } from "react";
import { IExtendedPositionItem } from "@redux/sagas/positions";
import { tableUseStyles, pnlStyles } from "@components/table/styled";
import { Box } from "@mantine/core";
import { IPositionCardContainerProps } from "./positionCard/positionCard.types";
import PositionCard from "./positionCard/positionCard";
import DrawerItems from "./drawerItems/drawerItems";
import useStyles from "../styled";

const PositionCardContainer = ({
  positionsData, totalPnL, getPnLClassName, handlePositionDetails, handleConvertPosition,
}: IPositionCardContainerProps) => {
  const { classes, cx } = useStyles();
  const { theme } = tableUseStyles();
  const { classes: pnlClasses } = pnlStyles();
  const [opened, { open, close }] = useDisclosure(false);
  const [selectedCard, setSelectedCard] = useState({} as IExtendedPositionItem)

  const handleRowClick = (row: IExtendedPositionItem) => {
    open();
    setSelectedCard(row);
  }

  return (
    <>
      <Box mb="4.25rem">
        {positionsData.map((row) => <PositionCard key={row.symbol} row={row} handleRowClick={handleRowClick} />)}
      </Box>
      <Box className={`${positionsData.length >= 10 ? classes.fixContainerMobile : ""} 
              ${classes.totalContainer}`}
      >
        <p
          className={cx(
            "content_md_semibold",
            classes.totalValue,
            getPnLClassName(totalPnL, theme.colorScheme, pnlClasses)
          )}
        >
          {totalPnL.toFixed(2)}
        </p>
        <p className={classes.totalText}>Total</p>
      </Box>
      <Drawer
        opened={opened}
        onClose={close}
        title="Asset Action"
      >
        <DrawerItems
          selectedCard={selectedCard}
          close={close}
          handlePositionDetails={handlePositionDetails}
          handleConvertPosition={handleConvertPosition}
        />
      </Drawer>
    </>
  )
}

export default PositionCardContainer
