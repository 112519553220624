import { selectSelectedWLIndex, selectWatchlistData } from '@redux/sagas/watchlists/watchlistSelectors';
import { addWLItem } from '@redux/sagas/watchlists/watchlistsSlice';
import { INDICES } from '@utils/constant';
import { getNewItemSequence, parsedSymbol } from '@utils/index';
import { useSelector, useDispatch } from 'react-redux';

interface IWatchlistItem {
  exchange: string;
  symbol: string;
  token: number;
  tradingSymbol?: string;
}

const useAddToWatchlist = <T extends IWatchlistItem>() => {
  const dispatch = useDispatch();

  const watchlistsData = useSelector(selectWatchlistData)
  const selectedWLIndex = useSelector(selectSelectedWLIndex)
  const selectedWatchlist = watchlistsData[selectedWLIndex]

  const handleAddWatchlistItem = (item: T) => {
    const parsedSymbolObj = parsedSymbol(item.symbol, item.exchange, item.token)
    const payload = {
      exchange: item.exchange === INDICES ? "NSE" : item.exchange,
      segment: parsedSymbolObj?.segment,
      token: Number(item.token),
      symbol: parsedSymbolObj?.tradingSymbol || item.tradingSymbol || item.symbol,
      sequence: getNewItemSequence(selectedWatchlist.items),
    };
    dispatch(
      addWLItem({
        data: payload,
        mwId: selectedWatchlist?.id,
      })
    );
  };

  return { handleAddWatchlistItem }
}

export default useAddToWatchlist
