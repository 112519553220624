import { setGttModalInfo } from '@redux/sagas/misc/miscSlice';
import { IGttOrderDetail } from '@redux/sagas/orders';
import { useDispatch } from 'react-redux';

const useModifyGtt = () => {
  const dispatch = useDispatch();

  const handleEditGttOrder = (item: IGttOrderDetail) => {
    if (item) {
      dispatch(setGttModalInfo({ isEdit: true, showGttModal: true, gttOrderDetails: item }))
    }
  };

  return { handleEditGttOrder }
}

export default useModifyGtt;
