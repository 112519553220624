import { ReactNode } from 'react';
import FallbackLoader from './loader';

const RenderBoundary = ({
  children,
  loading,
  status,
  data,
  customLoader,
  errorState,
}:{
  children: ReactNode,
  loading: boolean,
  status: string,
  data: any,
  customLoader?: JSX.Element,
  errorState?: JSX.Element,
}) => {
  const Loader = customLoader || <FallbackLoader />;
  // request blocked by browser
  if (status === '' && !data && !loading) {
    return (
      <div className="center">
        <p>There seems to be a network issue.</p>
      </div>
    )
  }
  // initial load
  if (loading && data === null) {
    return Loader
  }
  // initial load error
  if (!loading && status === 'failure' && data === null) {
    return errorState || <div>default error state</div>
  }

  if (data) {
    return children
  }

  return Loader;
}

export default RenderBoundary;
