/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unstable-nested-components */
import {
  Box,
  Checkbox,
  Button,
  Badge,
  Text,
} from "@mantine/core"
import { tableUseStyles, pnlStyles } from "src/components/table/styled"
import {
  Table, SortDirection, WindowScroller, AutoSizer,
} from 'react-virtualized';
import {
  useCallback, useEffect, useMemo, useState,
} from "react";
import { productType, tableContainer, productOptionsDisplay } from "@utils/constant";
import { IPositionsItem, IPositionItem, IExtendedPositionItem } from "@redux/sagas/positions";
import {
  formatSocket, isEqual,
  numberWithCommas,
  openNewWindow,
} from "@utils/index";
import PnLCell from "@components/table/pnLCell";
import usePrevious from "@hooks/common/usePrevious";
// import ConfirmCancelSingle from "@components/modals/order/confirmCancelSingle";
import useCSVDownload from "@hooks/common/useCSVDownload";
import ConfirmExitPosition from "@components/modals/order/confirmCancel/confirmExitPosition";
import { connect, useSelector } from "react-redux";
import { RootState } from "@redux/store";
import TitleHeading from "@components/atoms/titleHeading";
import TableHeaderItem from "@components/table/tableHeaderItem";
import TableItem from "@components/table/tableItem";
import NotFoundState from "@components/zeroState/NotFoundZeroState";
import useExitPosition from "@hooks/common/useExitPosition";
import useMediaCustom from "@hooks/common/useMediaCustom";
import { searchTicker } from "@utils/search";
import { Link } from "tabler-icons-react";
import useStyles from "./styled";
import PositionInstrument from "./positionInstrument";
import PositionCardContainer from "./positionsCards/positionsCardContainer";
import {
  getBadgeColor, getPnLClassName, sortAndFilterPositions,
} from "./utils";

const optionChainUrl = process.env.REACT_APP_OPTION_URL

type HydratedData = IExtendedPositionItem;

type IPositionTableList = {
  title: string;
  data: IPositionsItem;
  loading: number | boolean;
  hideCheckbox?: boolean;
  hydratedData: HydratedData[];
  isNoInternet: boolean;
  handlePositionDetails: (item: IExtendedPositionItem) => void;
  handleConvertPosition: (item: IExtendedPositionItem) => void;
  showDisabledState: boolean;
};

const PositionTableList = ({
  title,
  data,
  loading,
  hideCheckbox,
  hydratedData,
  isNoInternet,
  handlePositionDetails,
  handleConvertPosition,
  showDisabledState,
}: IPositionTableList) => {
  const { theme } = tableUseStyles();
  const { classes, cx } = useStyles();
  const { classes: pnlClasses } = pnlStyles();
  const { isLargeScreen } = useMediaCustom();

  const {
    regularOrder: regularOrderData,
  } = useSelector((state: RootState) => state.trade)

  const prevProps = usePrevious({ regularOrderData })
  const rowCap = useMemo(() => Math.round((window.innerHeight - 260) / 50), []);

  const [sortedData, setSortedData] = useState(hydratedData)
  const [selectAllTable, setSelectAllTable] = useState(false);
  const [showConfirmCancel, setShowConfirmCancel] = useState<boolean>(false);
  const [searchVal, setSearchVal] = useState("");
  const [sortInfo, setSortInfo] = useState({
    sortBy: '',
    sortDirection: SortDirection.ASC,
  });
  const [selectedOrderMap, setSelectedOrderMap] = useState({});

  const { downloadCSV } = useCSVDownload(
    ["product", "symbol", "qty", "avgPrice", "ltp", "pnl", "netChg"],
    "positions"
  );

  const isAnyRowSelected = Object.values(selectedOrderMap).some((value) => value);

  const countRowSelected = Object.values(selectedOrderMap).filter((value) => value).length;
  const rowWithQty = sortedData.filter((item) => item.qty !== 0);

  useEffect(() => {
    if (countRowSelected === rowWithQty.length && rowWithQty.length !== 0) {
      setSelectAllTable(true)
    } else {
      setSelectAllTable(false)
    }
  }, [countRowSelected, sortedData])

  const handleSelectRow = (row: IPositionItem, isCurrentVal: boolean) => {
    const uniqueSymbol = row.symbol + row.product;
    setSelectedOrderMap({ ...selectedOrderMap, [uniqueSymbol]: !isCurrentVal });
  }

  const handleSelectAll = (isVal: Boolean) => {
    const tempOrderMap = {};
    sortedData.forEach((item) => {
      if (item.qty !== 0) {
        tempOrderMap[item.symbol + item.product] = !isVal;
      }
    });
    setSelectedOrderMap(tempOrderMap)
    // setSelectAllTable(!isVal)
  }

  const selectedRows = sortedData.filter((item) => {
    const uniqueSymbol = item.symbol + item.product;
    return Object.prototype.hasOwnProperty.call(selectedOrderMap, uniqueSymbol) && selectedOrderMap[uniqueSymbol];
  });

  const { handleExitPosition, fetchFreezeQty } = useExitPosition(hideCheckbox);

  const handleExitRow = (isBoolean: boolean) => {
    setShowConfirmCancel(isBoolean)
    if (isBoolean) {
      fetchFreezeQty(selectedRows)
    }
  }

  const handleConfirmExit = () => {
    handleExitPosition(selectedRows)
  }

  const sortData = useCallback(() => {
    if (sortInfo.sortBy === '') {
      const sortedDataWithZeroQtyAtLast = sortAndFilterPositions(hydratedData);
      setSortedData(sortedDataWithZeroQtyAtLast);
      return
    }
    const tempSortedData = Array.from(hydratedData).sort((a, b) => {
      if (a[sortInfo.sortBy] < b[sortInfo.sortBy]) {
        return sortInfo.sortDirection === SortDirection.ASC ? -1 : 1;
      }
      if (a[sortInfo.sortBy] > b[sortInfo.sortBy]) {
        return sortInfo.sortDirection === SortDirection.ASC ? 1 : -1;
      }
      return 0;
    });
    setSortedData(tempSortedData);
  }, [sortInfo, hydratedData]);

  useEffect(() => {
    sortData();
  }, [sortInfo]);

  useEffect(() => {
    if ((sortedData.length === 0 && hydratedData.length) || !isEqual(hydratedData, prevProps.regularOrderData)) {
      // check if sort is enabled then sort and set the data
      if (sortInfo.sortBy !== '') {
        sortData();
      } else {
        // else simply set the data
        const customSortedData = sortAndFilterPositions(hydratedData)
        setSortedData(customSortedData)
      }
    }
  }, [hydratedData]);

  useEffect(() => {
    if (searchVal) {
      const tempSearchData = searchTicker(hydratedData, searchVal)
      setSortedData(tempSearchData)
    }
    // this was stetting the data at last, commented it out
    // else if (!sortInfo.sortBy) {
    //   setSortedData(hydratedData)
    // }
  }, [hydratedData, searchVal])

  const tableClassName = sortedData.length < 10 ? "table-min-height" : ""

  // Handle sorting
  const handleSort = ({ sortBy: sortFieldBy, sortDirection: sortFieldDirection }) => {
    // Sort the data array based on the selected column
    const tempSortedData = Array.from(sortedData).sort((a, b) => {
      if (a[sortFieldBy] < b[sortFieldBy]) return sortFieldDirection === "ASC" ? -1 : 1;
      if (a[sortFieldBy] > b[sortFieldBy]) return sortFieldDirection === "ASC" ? 1 : -1;
      return 0;
    });
    setSortedData(tempSortedData);
    setSortInfo({
      sortBy: sortFieldBy,
      sortDirection: sortFieldDirection,
    })
  };

  // Return the data for a given row index
  const rowGetter = ({ index }) => sortedData[index];

  const handleSearchChange = useCallback((e) => {
    const { value } = e.target;
    setSearchVal(value)
  }, []);

  const headerRowRenderer = () => (
    <section className="table-header-row">
      {!hideCheckbox ? (
        <div
          aria-hidden="true"
          className="tableHeaderCell w-50 pl-10"
        >
          <Checkbox
            radius={2}
            size="xs"
            disabled={rowWithQty.length === 0}
            checked={selectAllTable}
            onChange={() => handleSelectAll(selectAllTable)}
          />
        </div>
      ) : <div />}

      <TableHeaderItem
        width={!hideCheckbox ? 80 : 130}
        label="Product"
        value="product"
        handleSort={handleSort}
        sortInfo={sortInfo}
        rightAlign={false}
      />
      <TableHeaderItem
        width={270}
        label="Instrument"
        value="symbol"
        handleSort={handleSort}
        sortInfo={sortInfo}
        rightAlign={false}
      />
      <TableHeaderItem
        width={95}
        label="Qty"
        value="qty"
        handleSort={handleSort}
        sortInfo={sortInfo}
      />
      <TableHeaderItem
        width={125}
        label="Avg. Cost"
        value="avgPrice"
        handleSort={handleSort}
        sortInfo={sortInfo}
      />
      <TableHeaderItem
        width={125}
        label="LTP"
        value="ltp"
        handleSort={handleSort}
        sortInfo={sortInfo}
      />
      <TableHeaderItem
        width={125}
        label="P&L"
        value="pnl"
        handleSort={handleSort}
        sortInfo={sortInfo}
      />
      <TableHeaderItem
        width={125}
        label="Net Chg."
        value="netChg"
        handleSort={handleSort}
        sortInfo={sortInfo}
        className="pr-10"
      />
    </section>
  )

  let totalPnL = 0;
  // Iterate through each row in sortedData and add up the PnL values
  for (let i = 0; i < sortedData.length; i++) {
    const row = sortedData[i];
    if (row) {
      totalPnL += row.qty === 0 ? Number(row.realisedPnL) : Number(row.realisedPnL) + row.pnl;
    }
  }

  // Render each row with multiple columns
  const rowRenderer = ({ index, key, style }) => {
    const row = sortedData[index];
    if (row) {
      const uniqueSymbol = row.symbol + row.product;
      const qty = title === "Days's history" ? Number(row.dayBuyQty) - Number(row.daySellQty) : row.qty;
      // represents exited positions
      const isRowFunctionalityDisabled = row.qty === 0 && showDisabledState;
      // represents sold holdings,
      const isRowColorDisabled = row.product === productOptionsDisplay.LONGTERM && qty < 0;
      const disabledClass = (isRowFunctionalityDisabled || isRowColorDisabled) ? "disabled-row" : "";

      return (
        <div key={key} style={style} className={cx("table-row", disabledClass)}>
          {!hideCheckbox ? (
            <TableItem
              width={50}
              value={(
                <Checkbox
                  classNames={{
                    input: classes.checkboxInput,
                  }}
                  radius={2}
                  size="xs"
                  disabled={row.qty === 0}
                  checked={Boolean(selectedOrderMap[uniqueSymbol])}
                  onChange={() => handleSelectRow(
                    row,
                    Boolean(selectedOrderMap[uniqueSymbol]) || false
                  )}
                />
              )}
              rightAlign={false}
              className="pl-10"
            />
          ) : (
            <div />
          )}
          <TableItem
            width={!hideCheckbox ? 80 : 130}
            value={(
              <Badge
                size="md"
                radius="xs"
                color={getBadgeColor(productType[row.product])}
                opacity={(isRowFunctionalityDisabled || isRowColorDisabled) ? 0.5 : 1}
              >
                {productType[row.product]}
              </Badge>
            )}
            rightAlign={false}
          />
          <PositionInstrument
            row={row}
            handlePositionDetails={handlePositionDetails}
            handleConvertPosition={handleConvertPosition}
            width={270}
            showDisabledState={showDisabledState}
          />
          {/* Adding colors for Qty Alone. */}
          <TableItem
            width={95}
            value={qty}
            className={
              Number(qty) === 0
                ? classes.qtyDisabledColor : qty > 0
                  ? classes.qtyBuyColor : classes.qtySellColor
            }
          />
          {/* Adding Special Font weight for Avg Price */}
          <TableItem
            width={125}
            value={parseInt((row.netUploadPrice || "0.00"), 10)
              ? Number(row.netUploadPrice).toFixed(2)
              : Number(row.avgPrice).toFixed(2)}
          />
          <TableItem
            width={125}
            value={(
              <PnLCell
                colorScheme={theme.colorScheme}
                netChange={row.qty === 0 ? 0 : row.ltp}
                value={row.ltp || "0.00"}
                isDisabled={isRowColorDisabled}
              />
            )}
          />
          <TableItem
            width={125}
            value={(
              <PnLCell
                colorScheme={theme.colorScheme}
                netChange={
                  row.qty === 0 ? 0 : Number(row.realisedPnL) + row.pnl
                }
                value={
                  row.qty === 0
                    ? Number(row.realisedPnL)
                    : Number(row.realisedPnL) + row.pnl
                }
                isDisabled={isRowColorDisabled}
              />
            )}
          />
          <TableItem
            width={125}
            value={(
              <PnLCell
                colorScheme={theme.colorScheme}
                netChange={row.qty === 0 ? 0 : row.netChg}
                value={row.netChg === Infinity ? "0.00" : row.netChg || "0.00"}
                showPercentage={row.netChg === Infinity ? false : true || false}
                isDisabled={isRowColorDisabled}
              />
            )}
            className="pr-10"
          />
        </div>
      );
    }
    return null
  };

  const secondaryIconClickHandler = () => {
    if (optionChainUrl) {
      openNewWindow(optionChainUrl)
    }
  }

  return (
    <Box mt={12} mb={24}>
      <TitleHeading
        title={title}
        length={data.length}
        searchVal={searchVal}
        handleSearchChange={handleSearchChange}
        downloadCSV={() => downloadCSV(hydratedData)}
        loading={Boolean(loading)}
        secondaryTitle=""
        isNoInternet={isNoInternet}
        additionalButton={{ title: "Option Chain", icon: Link, onClick: secondaryIconClickHandler }}
      />
      {
        sortedData.length ? (
          isLargeScreen ? (
            <>
              <WindowScroller>
                {({
                  height,
                  isScrolling,
                  registerChild,
                  onChildScroll,
                  scrollTop,
                }) => (
                  <AutoSizer
                    style={tableContainer}
                    ref={() => registerChild(null)}
                    disableHeight
                  >
                    {({ width }) => (
                      <Table
                        className={tableClassName}
                        autoHeight
                        width={width}
                        height={height}
                        rowCount={sortedData.length}
                        rowGetter={rowGetter}
                        rowHeight={50}
                        rowRenderer={rowRenderer}
                        headerRowRenderer={headerRowRenderer}
                        scrollTop={scrollTop}
                        isScrolling={isScrolling}
                        onScroll={onChildScroll}
                      />
                    )}
                  </AutoSizer>
                )}
              </WindowScroller>

              <Box
                component="div"
                className={cx(classes.totalContainer, sortedData.length >= rowCap && classes.fixContainer)}
              >
                <p
                  className={cx(
                    'content_md_semibold',
                    classes.totalValue,
                    getPnLClassName(totalPnL, theme.colorScheme, pnlClasses)
                  )}
                >
                  {numberWithCommas(totalPnL.toFixed(2))}
                </p>

                <Text className={classes.totalText}>Total</Text>

                {isAnyRowSelected && sortedData.length >= rowCap && (
                  <Button mr="auto" ml="md" variant="filled" onClick={() => handleExitRow(true)}>
                    {`Exit ${countRowSelected} position${countRowSelected > 1 ? "s" : ""}`}
                  </Button>
                )}
              </Box>

              {isAnyRowSelected && sortedData.length < rowCap && (
                <Button mt="lg" variant="filled" onClick={() => handleExitRow(true)}>
                  {`Exit ${countRowSelected} position${countRowSelected > 1 ? "s" : ""}`}
                </Button>
              )}
            </>
          ) : (
            <PositionCardContainer
              positionsData={sortedData}
              totalPnL={totalPnL}
              getPnLClassName={getPnLClassName}
              handlePositionDetails={handlePositionDetails}
              handleConvertPosition={handleConvertPosition}
            />
          )
        ) : (
          <NotFoundState />
        )
      }

      {/* show confirm modal before cancel order */}
      {showConfirmCancel && (
      <ConfirmExitPosition
        isVisible={showConfirmCancel}
        handleCloseModal={handleExitRow}
        handleConfirmExit={handleConfirmExit}
        selectedRows={selectedRows}
        handleSelectAll={handleSelectAll}
      />
      )}
    </Box>
  )
}
// export default PositionTableList

const mapStateToProps = (state: RootState, ownProps: IPositionTableList) => {
  const hydratedData = ownProps.data.map((item) => {
    const data = state.sockets.quoteSocketData[item.token] || {};
    const {
      qty, avgPrice, netUploadPrice,
    } = item;
    const {
      ltp = 0,
      pnl = 0,
      netChg = 0,
      currentVal = 0,
    } = formatSocket(data, qty, avgPrice, Number(netUploadPrice))
    return {
      ...item,
      ltp,
      currentVal,
      pnl,
      netChg,
      dayChg: Number(data.netChange || 0.00),
    }
  });
  return { hydratedData }
}

export default connect(mapStateToProps, null)(PositionTableList)
