import axios from "../../api/axiosInterceptors"
import { positionsEndPoints } from "../../api/apiEndpoints"

function requestUserPositions() {
  return axios.request({
    method: "get",
    url: positionsEndPoints.userPositionsUrl,
  })
}

export default requestUserPositions
