import {
  Anchor, Avatar, Box, Text,
} from "@mantine/core";
import { Link } from "wouter";
import useStyles from "./index.styles";

interface ExistingUserProps {
  userImg: string,
  userId: string,
  handleChangeUser: () => void
}

const ExistingUser = ({ userImg, userId, handleChangeUser }: ExistingUserProps) => {
  const { classes } = useStyles();
  return (
    <Box className={classes.boxStyle}>
      <Avatar
        mx="auto"
        radius={32}
        size="lg"
        src={userImg}
      />
      <Text mt={4}>{userId}</Text>
      <Anchor component={Link} to="#" onClick={handleChangeUser} size="sm">
        Change User
      </Anchor>
    </Box>
  )
}

export default ExistingUser
