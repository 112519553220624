/* eslint-disable max-len */
import { all, call, put } from "redux-saga/effects"
import { showNotification } from "@mantine/notifications"
import { capitalizeFirstLetter, filterErrorResponse } from "@utils/index"
import {
  requestCoverOrder,
  requestOrderMargin,
  requestRegularOrder,
  requestEditRegularOrder,
  requestEditCoverOrder,
  requestConvertPostionUrl,
  requestBracketOrder,
  requestEditBracketOrder,
  requestFreezeQty,
} from "./tradesRequests"
import {
  convertPostionOrderFailure,
  convertPostionOrderSuccess,
  coverOrderFailure,
  coverOrderSuccess,
  editCoverOrderFailure,
  editCoverOrderSuccess,
  editRegularOrderFailure,
  editRegularOrderSuccess,
  orderMarginFailure,
  orderMarginSuccess,
  regularOrderUpdate,
  regularOrderFailure,
  regularOrderSuccess,
  bracketOrderSuccess,
  bracketOrderFailure,
  editBracketOrderSuccess,
  editBracketOrderFailure,
  getFreezeQtyFailure,
  getFreezeQtySuccess,
} from "./tradesSlice"
import { IBracketOrder, IRegularMultipleOrder, IRegularOrderPayload } from "."

// handle regular trade
export function* handleRegularOrder(action: IRegularMultipleOrder): Generator<any, any, any> {
  const orders = action.payload
  try {
    yield put(regularOrderUpdate({
      data: { isMultipleOrder: true, totalOrder: orders.length, successOrder: 0 },
    }))
    const batchSize = 5;
    for (let i = 0; i < orders.length; i += batchSize) {
      try {
        const batchPromises = orders.slice(i, i + batchSize).map((order:IRegularOrderPayload) => call(requestRegularOrder, order));
        yield all(batchPromises);
        yield put(regularOrderUpdate({ data: { successOrder: i === 0 ? (6 - orders.length) : i } }))
      } catch (error:any) {
        showNotification({
          color: "red",
          title: 'Order failed',
          message: capitalizeFirstLetter(error?.response ? error.response.data.message : error.message),
        })
        // eslint-disable-next-line no-continue
        continue;
      }
    }
    yield put(regularOrderSuccess({ data: { }, status: 'success' }))
  } catch (error:any) {
    yield put(regularOrderFailure(filterErrorResponse(error)))
  }
}

// edit regular order
export function* handleEditRegularOrder(action: any): Generator<any, any, any> {
  const { orderId, data: payloadData } = action.payload
  try {
    const { data: { data, status } } = yield call(requestEditRegularOrder, orderId, payloadData)
    yield put(editRegularOrderSuccess({ data, status }))
  } catch (error:any) {
    yield put(editRegularOrderFailure(filterErrorResponse(error)))
  }
}

// handle cover order
export function* handleCoverOrder(action: any): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestCoverOrder, action.payload)
    yield put(coverOrderSuccess({ data, status }))
  } catch (error:any) {
    yield put(coverOrderFailure(filterErrorResponse(error)))
  }
}

// edit cover order
export function* handleEditCoverOrder(action: any): Generator<any, any, any> {
  const { orderId, data: payloadData } = action.payload
  try {
    const { data: { data, status } } = yield call(requestEditCoverOrder, orderId, payloadData)
    yield put(editCoverOrderSuccess({ data, status }))
  } catch (error:any) {
    yield put(editCoverOrderFailure(filterErrorResponse(error)))
  }
}
// handle bracket order
export function* handleBracketOrder(action: IBracketOrder): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestBracketOrder, action.payload)
    yield put(bracketOrderSuccess({ data, status }))
  } catch (error:any) {
    yield put(bracketOrderFailure(filterErrorResponse(error)))
  }
}

// edit bracket order
export function* handleEditBracketOrder(action: any): Generator<any, any, any> {
  const { orderId, data: payloadData } = action.payload
  try {
    const { data: { data, status } } = yield call(requestEditBracketOrder, orderId, payloadData)
    yield put(editBracketOrderSuccess({ data, status }))
  } catch (error:any) {
    yield put(editBracketOrderFailure(filterErrorResponse(error)))
  }
}

// show trade margin
export function* handleOrderMargin(action: any): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestOrderMargin, action.payload)
    yield put(orderMarginSuccess({ data, status }))
  } catch (error: any) {
    yield put(orderMarginFailure(filterErrorResponse(error)))
  }
}

export function* handleConvertPostion(action: any): Generator<any, any, any> {
  const { data: payloadData } = action.payload
  try {
    const { data: { data, status } } = yield call(requestConvertPostionUrl, payloadData);
    yield put(convertPostionOrderSuccess({ data, status }))
  } catch (error: any) {
    yield put(convertPostionOrderFailure(filterErrorResponse(error)))
  }
}

export function* handleGetFreezeQty(action: any): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestFreezeQty, action.payload);
    yield put(getFreezeQtySuccess({ data, status }))
  } catch (error: any) {
    yield put(getFreezeQtyFailure(filterErrorResponse(error)))
  }
}
