import {
  Dispatch,
  SetStateAction,
  memo,
} from "react";
import {
  Badge,
  Box,
  createStyles,
  Group,
  NumberInput,
  Radio,
  Text,
} from "@mantine/core";
import { ArrowNarrowRight } from "tabler-icons-react";
import useMediaCustom from "@hooks/common/useMediaCustom";
import { getTypographyStyles } from "@theme/themeConfig";
import { Flex } from '@uikit';
import { exchanges } from "@utils/constant";

interface TriggerCncLimitPriceProps {
  ltpPercent: number;
  setLtpPercent: Dispatch<SetStateAction<number>>;
  gttPrice: number;
  setGttPrice: Dispatch<SetStateAction<number>>;
  gttQuantity: number;
  setGttQuantity: Dispatch<SetStateAction<number>>;
  triggerPrice: number;
  setTriggerPrice: Dispatch<SetStateAction<number>>;
  badgeValue: string;
  refLtp: number | undefined,
  lotSize: number;
  exchange: string;
}

const useStyles = createStyles(() => ({
  triggerInput: {
    width: "36px",
    fontSize: 10,
    borderBottom: "0.3px solid #ddd",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    outline: "none",
    paddingLeft: 4,
    paddingRight: 2,
  },
  numberInputlabel: {
    ...getTypographyStyles("content_xs_regular"),
    paddingBottom: 4,
  },
}));

const TriggerCncLimitPrice = ({
  ltpPercent,
  setLtpPercent,
  gttPrice,
  setGttPrice,
  gttQuantity,
  setGttQuantity,
  triggerPrice,
  setTriggerPrice,
  badgeValue,
  refLtp,
  exchange,
  lotSize = 1,
}: TriggerCncLimitPriceProps) => {
  const { classes } = useStyles();
  const { isMobile } = useMediaCustom();

  function calculateXWithNewZ(oldX, oldZ, newZ) {
    if (newZ === 0 && !newZ) {
      return 0;
    }
    const oldY = oldX / (1 + oldZ / 100);
    const newX = oldY + (newZ / 100) * oldY;
    return newX;
  }

  const handleSetLtpPercent = (e: any) => {
    const ltpPercentVal = Number(e.target.value);
    setLtpPercent(ltpPercentVal);
    const newX = calculateXWithNewZ(triggerPrice, ltpPercent, ltpPercentVal || 0);
    setTriggerPrice(newX);
  };

  const updateXBasedOnTriggerPrice = (newTriggerPrice, price) => {
    const rupeePrice = price / 100;
    const newX = ((newTriggerPrice - rupeePrice) / rupeePrice) * 100;
    return newX;
  };

  const handleSetTriggerPrice = (e: any) => {
    const newTriggerPrice = Number(e);
    setTriggerPrice(newTriggerPrice);
    const newZ = updateXBasedOnTriggerPrice(newTriggerPrice || 0, refLtp);
    setLtpPercent(newZ);
  };

  return (
    <Group position="apart" py="md" px="lg">
      <Box w={isMobile ? "100%" : "22%"} mt={badgeValue ? "10px" : "44px"}>
        {badgeValue && (
          <Badge radius="xs" size="xs" color="indigo" mb={14}>
            {badgeValue}
          </Badge>
        )}

        <NumberInput
          type="number"
          label="Trigger price"
          min={0}
          step={0.05}
          precision={2}
          value={triggerPrice === 0 ? 0 : Number(triggerPrice.toFixed(2))}
          classNames={{
            label: classes.numberInputlabel,
          }}
          onChange={handleSetTriggerPrice}
        />
        <Flex mt={6}>
          <input
            type="number"
            value={ltpPercent === 0 ? 0 : Number(ltpPercent.toFixed(2))}
            onChange={handleSetLtpPercent}
            className={classes.triggerInput}
          />
          <Text size={10}>% of LTP</Text>
        </Flex>
      </Box>

      {!isMobile && (
        <Group w="22%" spacing="xs" mt="46px">
          <Text size={10}>Places order</Text>
          <ArrowNarrowRight size={14} />
        </Group>
      )}

      <Box sx={{ width: isMobile ? "100%" : "48%" }}>
        <Flex justify="space-between">
          {exchange === exchanges.NFO ? (
            <Radio
              size="xs"
              disabled
              defaultChecked
              className="radio-xxs"
              value="nrml"
              label="NRML"
            />
          ) : (
            <Radio
              size="xs"
              disabled
              defaultChecked
              className="radio-xxs"
              value="cnc"
              label="CNC"
            />
          )}
          <Radio
            size="xs"
            disabled
            defaultChecked
            className="radio-xxs"
            value="limit"
            label="LIMIT"
          />
        </Flex>
        <Flex mt={14}>
          <NumberInput
            type="number"
            min={lotSize}
            step={lotSize}
            label="Qty"
            value={gttQuantity}
            onChange={(val: number) => setGttQuantity(val)}
            classNames={{
              label: classes.numberInputlabel,
            }}
          />
          <NumberInput
            type="number"
            min={0.01}
            step={0.05}
            precision={2}
            ml="md"
            label="Price"
            value={gttPrice}
            onChange={(val: number) => setGttPrice(val)}
            classNames={{
              label: classes.numberInputlabel,
            }}
          />
        </Flex>
      </Box>
    </Group>
  );
};
export default memo(TriggerCncLimitPrice);
