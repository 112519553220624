/* eslint-disable max-len */
const IconWlZero = ({
  color,
}:{ color:string }) => (
  <svg width="86" height="86" viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_1631_16647" maskUnits="userSpaceOnUse" x="0" y="0" width="86" height="86">
      <path d="M85.8333 0.163956H0.166573V85.8306H85.8333V0.163956Z" fill="white" />
    </mask>
    <g mask="url(#mask0_1631_16647)">
      <path d="M56.6914 11.48C57.588 9.94432 59.2535 8.91231 61.1603 8.91231C61.6376 8.91231 62.0996 8.97723 62.5383 9.09837C63.9384 6.7673 66.491 5.20756 69.408 5.20756C73.8307 5.20756 77.4158 8.79268 77.4158 13.2154H78.2794C80.4818 13.2154 82.2834 15.0171 82.2834 17.2193C82.2834 19.3956 80.4898 21.2232 78.3058 21.2232H63.1915" fill="#ABD3CD" />
      <path d="M56.6914 11.48C57.588 9.94432 59.2535 8.91231 61.1603 8.91231C61.6376 8.91231 62.0996 8.97723 62.5383 9.09837C63.9384 6.7673 66.491 5.20756 69.408 5.20756C73.8307 5.20756 77.4158 8.79268 77.4158 13.2154H78.2794C80.4818 13.2154 82.2834 15.0171 82.2834 17.2193C82.2834 19.3956 80.4898 21.2232 78.3058 21.2232H63.1915" stroke={color} strokeWidth="2" strokeMiterlimit="22.926" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.04033 51.0565C3.75254 46.0512 1.83942 40.0624 1.83942 33.6262C1.83942 16.0689 16.0725 1.83583 33.6298 1.83583C51.1871 1.83583 65.4202 16.0689 65.4202 33.6262C65.4202 51.1835 51.1871 65.4166 33.6298 65.4166C28.3979 65.4166 23.4617 64.1523 19.109 61.9134" stroke={color} strokeWidth="2" strokeMiterlimit="22.926" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M45.3158 11.4132C41.8252 9.57307 37.8492 8.52984 33.6291 8.52984C19.7682 8.52984 8.53148 19.7666 8.53148 33.6275C8.53148 39.0364 10.2426 44.0455 13.153 48.1433M32.0942 58.6788C32.6945 58.7218 33.0178 58.7252 33.6291 58.7252C47.4902 58.7252 58.7268 47.4886 58.7268 33.6275C58.7268 29.4078 57.6839 25.4316 55.8438 21.9417" stroke={color} strokeWidth="2" strokeMiterlimit="22.926" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M52.4584 59.4249L74.5901 82.5206C76.7718 84.7022 80.3415 84.7022 82.5232 82.5206C84.7048 80.3391 84.7048 76.7692 82.5232 74.5875L59.5804 52.6008" stroke={color} strokeWidth="2" strokeMiterlimit="22.926" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M65.6877 59.3929L59.3946 65.6861" stroke={color} strokeWidth="2" strokeMiterlimit="22.926" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M41.9963 33.6275H41.9945" stroke={color} strokeWidth="3.34635" strokeMiterlimit="22.926" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M33.6301 33.6275H33.6282" stroke={color} strokeWidth="3.34635" strokeMiterlimit="22.926" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M25.2639 33.6275H25.262" stroke={color} strokeWidth="3.34635" strokeMiterlimit="22.926" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M27.9064 61.9107H5.60377C3.53322 61.9107 1.83912 60.2166 1.83912 58.1461C1.83912 56.0755 3.53322 54.3814 5.60377 54.3814C5.80322 51.7073 8.0359 49.5998 10.761 49.5998C11.2382 49.5998 11.7003 49.6647 12.139 49.7859C13.539 47.4548 16.0917 45.8951 19.0088 45.8951C23.4315 45.8951 27.0166 49.4802 27.0166 53.9029H27.8801C30.0824 53.9029 31.884 55.7046 31.884 57.9068C31.884 60.0831 30.0906 61.9107 27.9064 61.9107Z" fill="#ABD3CD" stroke={color} strokeWidth="2" strokeMiterlimit="22.926" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M51.3782 15.8795H51.3763" stroke={color} strokeWidth="2" strokeMiterlimit="2.613" strokeLinecap="round" strokeLinejoin="round" />
    </g>
  </svg>
)

export default IconWlZero
