import { takeLatest } from 'redux-saga/effects'
import {
  handleSetupInitiate,
  handleSetupOtp,
  handleSetupPassword,
  handleSetup2Fa,
  handleSetupResendOtp,
} from './setupHandler'

import {
  setupInitiate,
  setupVerifyOTP,
  setupPassword,
  setup2Fa,
  setupResendOtp,
} from './setupSlice'

export function* setupInitiateSaga() {
  yield takeLatest(setupInitiate.type, handleSetupInitiate)
}

export function* setupOtpSaga() {
  yield takeLatest(setupVerifyOTP.type, handleSetupOtp)
}

export function* setupPasswordSaga() {
  yield takeLatest(setupPassword.type, handleSetupPassword)
}

export function* setup2FaSaga() {
  yield takeLatest(setup2Fa.type, handleSetup2Fa)
}

export function* setupResendOtpSaga() {
  yield takeLatest(setupResendOtp.type, handleSetupResendOtp)
}
