import {
  Box, Checkbox, Grid, NumberInput, createStyles,
} from "@mantine/core"
import { useSelector } from "react-redux"
import { RootState } from "@redux/store"
import { productOptions, varietyValues } from "../../../utils/constant"

interface IGttTradePart {
  productValue: string,
  activeTab: string
  stoplossState: boolean
  setStoplossState: React.Dispatch<React.SetStateAction<boolean>>
  targetState: boolean,
  setTargetState: React.Dispatch<React.SetStateAction<boolean>>
}

const useStyles = createStyles(() => ({
  numberInput: {
    alignItems: "center",
  },
}));

const GttTradePart = ({
  productValue,
  activeTab,
  stoplossState,
  setStoplossState,
  targetState,
  setTargetState,
}: IGttTradePart) => {
  const { classes } = useStyles();
  const {
    tradeModalInfo: {
      buySellToggle,
    },
  } = useSelector((state: RootState) => state.trade);
  return (
    <Box>
      {productOptions[1].value === productValue
        && activeTab === varietyValues.REGULAR && !buySellToggle
        ? (
          <Grid
            align="center"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            className="mt-12"
          >
            <Grid.Col span={2}>
              <Checkbox
                radius={2}
                color="blue"
                size="xs"
                label="Stoploss"
                checked={stoplossState}
                onChange={(event) => setStoplossState(event.currentTarget.checked)}
              />
            </Grid.Col>
            <Grid.Col span={3}>
              <NumberInput
                type="number"
                mt=""
                size="xs"
                placeholder="Ex. 10.00"
                min={0.05}
                step={0.05}
                disabled={!stoplossState}
                styles={{ input: { width: "100%" } }}
              />
            </Grid.Col>
            <Grid.Col ml={-12} span={1}>%</Grid.Col>
            <Grid.Col span={2}>
              <Checkbox
                radius={2}
                color="blue"
                size="xs"
                label="Target"
                checked={targetState}
                onChange={(event) => setTargetState(event.currentTarget.checked)}
              />
            </Grid.Col>
            <Grid.Col span={3} className={classes.numberInput}>
              <NumberInput
                type="number"
                mt=""
                size="xs"
                placeholder=""
                min={0.05}
                step={0.05}
                disabled={!targetState}
                styles={{ input: { width: "100%" } }}
              />
            </Grid.Col>
            <Grid.Col ml={-12} span={1}>%</Grid.Col>
          </Grid>
        ) : null}
    </Box>
  )
}

export default GttTradePart
