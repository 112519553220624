import { setTradeModalInfo } from "@redux/sagas/trades/tradesSlice";
import { extractTradeModalData, getNiceName } from "@utils/index";
import { useDispatch } from "react-redux";

const useTradeOrder = (token, exchange, symbol) => {
  const dispatch = useDispatch();

  const niceName = getNiceName(token, exchange, symbol);

  const handleBuyOrder = (item) => {
    dispatch(
      setTradeModalInfo({
        currentAssetItem: extractTradeModalData(item, 0, niceName),
        buySellToggle: false,
        showTradeModal: true,
      })
    );
  };

  const handleSellOrder = (item) => {
    dispatch(
      setTradeModalInfo({
        currentAssetItem: extractTradeModalData(item, 0, niceName),
        buySellToggle: true,
        showTradeModal: true,
      })
    );
  };

  return { handleBuyOrder, handleSellOrder }
}

export default useTradeOrder
