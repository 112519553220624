import {
  Box, Button, Flex, Text,
} from '@mantine/core'
import introducing from "@assets/icons/introducing.svg"
import arrowLink from "@assets/icons/arrow-link.svg";
import useStyles from "./exploreCard.styles";

interface ExploreCardProps {
  title: string;
  description: string;
  img: string;
  imgAlt: string;
  imgBgClass: string;
  url: string | undefined;
}

const ExploreCard = ({
  title, description, img, url, imgAlt, imgBgClass,
}: ExploreCardProps) => {
  const { classes } = useStyles();

  return (
    <a href={url} target="_blank" rel="noreferrer" className={classes.container}>
      <Box className={`${classes.imgBlock} ${classes[imgBgClass]}`}>
        <Box className={classes.imgContainer}>
          <img className={classes.img} src={img} alt={imgAlt} />
        </Box>
      </Box>
      <Flex h="8.5rem" pb="md" p="sm" direction="column" justify="space-between">
        <Flex align="center">
          <img src={introducing} alt="introducing" />
          <Text className="content_sm_semibold text_primary_default" ml="xs">
            Introducing
          </Text>
        </Flex>
        <Flex align="center" justify="space-between">
          <Text className="content_lg_semibold">
            {title}
          </Text>
          <Button variant="white" p="2px" m="0">
            <img src={arrowLink} alt="link" />
          </Button>
        </Flex>
        <Text className="content_sm_regular text_fg_subtle">
          {description}
        </Text>
      </Flex>
    </a>
  )
}

export default ExploreCard
