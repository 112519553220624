import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useMemo } from 'react'
import { setQuoteTokens } from 'src/redux/sagas/sockets/socketsSlice'
import { RootState } from '@redux/store';

const useSubUnsub = (tokensData: string[] | number [], source: string | null = null) => {
  const dispatch = useDispatch();
  const socketState = useSelector((state:RootState) => state.sockets.quoteTokens)

  const tokens = useMemo(() => tokensData, [tokensData]);

  useEffect(() => {
    const subscribedTokens = Object.keys(socketState).filter((key) => socketState[key] === -1)

    // Subscribe to quote tokens
    if (tokens && tokens.length > 0) {
      const allTokensRequired = tokens || []
      const tokensToSubscribe = allTokensRequired.filter((token) => !subscribedTokens.includes(token as string))
      const subsObj: Record<string, number> = {};

      tokensToSubscribe.forEach((token) => {
        subsObj[token] = 1
      })
      dispatch(setQuoteTokens(subsObj))
    }
    // unsubscribe to quote tokens
    return () => {
      if (tokens && tokens.length > 0) {
        if (source && window.location.pathname !== source) {
          const unSubObj: Record<string, number> = {};
          const uSubscribesTokens = tokens || []
          uSubscribesTokens.forEach((token) => {
            unSubObj[token] = 0
          })
          dispatch(setQuoteTokens(unSubObj))
        }
      }
    }
  }, [tokens])

  return null
}

export default useSubUnsub
