import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Box, Text } from "@mantine/core";
import { useSelector, useDispatch } from "react-redux";
import { Search, X } from "tabler-icons-react";
import useWLSearch from "@hooks/events/useWLSearch";
import {
  selectSelectedWLIndex,
  selectWLSearchData,
  selectWatchlistData,
} from "@redux/sagas/watchlists/watchlistSelectors";
import {
  setSearchWatchlists,
} from "@redux/sagas/watchlists/watchlistsSlice";
import { selectLoadingSocket } from "@redux/sagas/sockets/socketSelectors";
import { debounce, instrumentSearch } from "@utils/index";
import useStyles from "./styled";
import { SearchWLItemProps } from "./index.d";
import WatchlistSearchItem from "./watchlistSearchItem";

type CustomInputRef = HTMLInputElement & { callFunction: () => void };

const WatchlistHeader = forwardRef<CustomInputRef, any>(
  ({ handleFocusNull, handleWLItemIndex }, ref) => {
    const { classes } = useStyles();
    const dispatch = useDispatch();
    const searchWatchlists = useSelector(selectWLSearchData);
    const loadingSocket = useSelector(selectLoadingSocket);
    const watchlistsData = useSelector(selectWatchlistData);
    const selectedWLIndex = useSelector(selectSelectedWLIndex);

    const selectedWatchlist = watchlistsData[selectedWLIndex];

    const [selectedWatchlistObj, setSelectedWatchlistObj] = useState<any>({});
    const [searchValue, setSearchValue] = useState("");

    const [showSymbol, setShowSymbol] = useState(false);

    const [selectedWLItemIndex, setSelectedWLItemIndex] = useState(-1)
    const selectedSearchWLIndexRef = useRef(-1);

    const handleSearchWLItemIndex = (value: number) => {
      setSelectedWLItemIndex(value);
      selectedSearchWLIndexRef.current = value;
    };

    const handleSearch = (val: string) => {
      setSearchValue(val);
      if (!val) {
        handleSearchWLItemIndex(0);
      }
    };
    useImperativeHandle(ref, () => ({
      callFunction: () => handleSearch(""),
    }) as CustomInputRef);

    // handle wl search events
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { unusedVal } = useWLSearch(
      handleSearch,
      handleFocusNull,
      handleWLItemIndex,
      selectedSearchWLIndexRef,
      handleSearchWLItemIndex
    )

    useEffect(() => {
      const selectedWlObj = {};
      if (selectedWatchlist?.items?.length) {
        for (let i = 0; i < selectedWatchlist?.items?.length; i++) {
          const { token, symbol } = selectedWatchlist.items[i]
          selectedWlObj[token] = symbol
        }
      }
      setSelectedWatchlistObj(selectedWlObj);
    }, [selectedWatchlist]);

    const processChange = useCallback(
      debounce((val: string) => {
        const searchResults = instrumentSearch.search(val);
        // searchResults?.forEach((item: SearchWLItemProps) => {
        //   if (item.niceName?.includes("-EQ")) {
        //     item.niceName = item.symbol
        //   }
        // })
        dispatch(setSearchWatchlists(searchResults));
      }, 60),
      []
    )

    useEffect(() => {
      if (searchValue) {
        processChange(searchValue)
      } else {
        dispatch(setSearchWatchlists([]));
      }
    }, [searchValue])

    const checkExisting = (searchItem: SearchWLItemProps) => {
      if (Object.keys(selectedWatchlistObj)?.length && selectedWatchlistObj[searchItem?.exchangeToken]) {
        return true;
      }
      return null;
    };

    return (
      <Box>
        <Box className={classes.searchInputContainer}>
          <Box className={classes.searchIcon}>
            <Search size={15} color="#444" />
          </Box>
          <input
            ref={ref}
            id="watchlist-search"
            className={classes.searchInput}
            type="search"
            placeholder="Search Ex: Nifty, Index, Reliance"
            autoComplete="off"
            value={searchValue}
            onChange={(event) => handleSearch(event.currentTarget.value)}
          />
          {searchValue ? (
            <Box className={classes.wlRightIcon} onClick={() => handleSearch("")}>
              <X size={18} color="#444" />
            </Box>
          ) : (
            <Box className={classes.wlRightIcon}>
              <Text size={14} color="#ccc">
                {selectedWatchlist?.items.length || 0}
                /50
              </Text>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            height: searchWatchlists.length > 0 ? 'calc(100vh - 150px)' : 'auto',
          }}
          className={classes.searchContainer}
        >
          {searchWatchlists?.map((item: SearchWLItemProps, index: number) => {
            const isExisted = checkExisting(item);
            return (
              <WatchlistSearchItem
                key={item.instrumentToken}
                setShowSymbol={setShowSymbol}
                item={item}
                selectedWLItemIndex={selectedWLItemIndex}
                index={index}
                isExisted={isExisted}
                showSymbol={showSymbol}
                handleSearch={handleSearch}
              // parsedSymbolObj={parsedSymbolObj}
              />
            );
          })}
        </Box>
        {loadingSocket ? <div className="loader-line" /> : null}
      </Box>
    );
  }
);

export default memo(WatchlistHeader);
