import { createSlice } from "@reduxjs/toolkit"
import { ActionFailure, ActionSuccess } from "@redux/types"
import { PositionsSlice, IPositionsItem } from "."

const initialState: PositionsSlice = {
  positions: {
    loading: false,
    status: '',
    data: null,
  },
}

export const positionSlice = createSlice({
  name: "position",
  initialState,
  reducers: {
    getUserPositions: (state) => {
      state.positions.loading = true
      state.positions.status = "";
    },
    getUserPositionsSuccess: (state, action: ActionSuccess<IPositionsItem>) => {
      const { payload } = action
      state.positions = {
        loading: false,
        status: payload.status,
        data: payload.data,
      }
    },
    getUserPositionsFailure: (state, action: ActionFailure<{}>) => {
      const { payload } = action;
      state.positions = {
        ...state.positions,
        loading: false,
        status: payload.status,
        errorData: payload.errorData,
        message: payload.message,
        errorCode: payload.errorCode,
      };
    },

    resetPositionsSlice: () => initialState,
  },
})

// Action
export const {
  getUserPositions,
  getUserPositionsSuccess,
  getUserPositionsFailure,

  resetPositionsSlice,
} = positionSlice.actions

export default positionSlice.reducer
