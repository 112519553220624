import { getUserOrders } from "@redux/sagas/orders/ordersSlice";
import { getUserPositions } from "@redux/sagas/positions/positionsSlice";
import { useDispatch } from "react-redux";
import { debounceFn } from "@utils/index";
import { orderStatus } from "@utils/constant";
import { getHoldings } from "@redux/sagas/holdings/holdingsSlice";

type IOrderStatus =
  | "PENDING"
  | "CANCELED"
  | "OPEN"
  | "REJECTED"
  | "COMPLETE"
  | "INVALID_STATUS_TYPE";

interface IParsedData {
  id: string;
  status: IOrderStatus;
  type: string;
  userId: string;
  qty: string;
  price: string;
  product: string;
  remarks: string;
  reason: string;
  reportType: string;
  order: string;
  transactionType: string;
  exchange: string;
  symbol: string;
  avgPrice: string;
}

const useWebhookCallback = () => {
  const dispatch = useDispatch();
  const fetchUserOrders = debounceFn(() => dispatch(getUserOrders()), 200);
  const fetchUserPositions = debounceFn(() => dispatch(getUserPositions()), 150);
  const fetchUserHoldings = debounceFn(() => dispatch(getHoldings()), 150);
  const API_CALL_DELAY_TIME = 400;
  const SECOND_API_CALL_DELAY_TIME = 1200;

  const handleFetchAPIAfterDelay = (fn: () => void, delay:number) => {
    setTimeout(() => {
      fn();
    }, delay);
  }

  const revalidateData = (parsedData: IParsedData) => {
    const { status } = parsedData;
    if (
      [
        orderStatus.REJECTED,
        orderStatus.OPEN,
        orderStatus.CANCELED,
        orderStatus.TRIGGER_PENDING,
        orderStatus.PENDING,
        orderStatus.INVALID_STATUS_TYPE,
      ].includes(status)
    ) {
      fetchUserOrders();
    } else if (status === orderStatus.COMPLETE) {
      fetchUserOrders();
      handleFetchAPIAfterDelay(fetchUserPositions, API_CALL_DELAY_TIME);
      handleFetchAPIAfterDelay(fetchUserPositions, SECOND_API_CALL_DELAY_TIME);

      handleFetchAPIAfterDelay(fetchUserHoldings, API_CALL_DELAY_TIME);
    }
    if (status === orderStatus.OPEN && parsedData.reportType === "Fill") {
      fetchUserOrders();
      handleFetchAPIAfterDelay(fetchUserPositions, API_CALL_DELAY_TIME);
      handleFetchAPIAfterDelay(fetchUserPositions, SECOND_API_CALL_DELAY_TIME);

      handleFetchAPIAfterDelay(fetchUserHoldings, API_CALL_DELAY_TIME);
    }
  };

  return { revalidateData };
};

export default useWebhookCallback;
