import { numberWithCommas } from 'src/utils';
import { Box, Text } from '@uikit';
import useStyles from './summary.styles';

interface ISummaryItem {
  title: string;
  value: number;
  isColorApplicable?: boolean;
  percentageValue?: number;
}

const SummaryItem = ({
  title, value, percentageValue, isColorApplicable = false,
}: ISummaryItem) => {
  const { classes } = useStyles()
  let className = "color-grey-2"
  if (isColorApplicable) {
    if (Number(value) === 0) {
      className = "color-grey-2"
    } else if (value > 0) {
      className = classes.greenColor
    } else {
      className = classes.redColor
    }
  }
  return (
    <Box className={classes.wrapper}>
      <Box>
        <Text className="content_xs_medium text_fg_muted">{title}</Text>
        <Text
          className={`content_lg_semibold ${className}`}
        >
          {numberWithCommas(value.toFixed(2))}
        </Text>
      </Box>
      <Text pl={4} pb={2} className={`content_2xs_medium ${className}`}>
        {percentageValue ? `${percentageValue.toFixed(2)}%` : ""}
      </Text>
    </Box>
  )
}

export default SummaryItem
