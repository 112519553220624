import { parsedSymbol } from "@utils/index"
import FormattedName from "@components/watchlist/formattedName"
import { Text, Flex } from "@uikit"
import theme from "@theme/theme"
import PnLText from "@components/atoms/pnlText"
import useStyles from "./holdingCard.styles"
import { IHoldingCardProps } from "./holdingCard.types"
import HoldingCardItem from "./holdingCardItem"

const HoldingCard = ({ row, handleRowClick }: IHoldingCardProps) => {
  const { classes } = useStyles();

  const {
    symbol, exchange, avgPrice, currentVal, effectiveQty, ltp, netChg, pnl,
  } = row;
  const parsedSymbolObj = parsedSymbol(symbol, exchange, row.token);

  const handleRowItemClick = () => {
    handleRowClick(row)
  }

  return (
    <Flex className={classes.holdingCardItem} onClick={handleRowItemClick}>
      <Flex justify="space-between">
        <Flex gap={2} align="flex-end">
          {parsedSymbolObj && (
            <FormattedName
              name={parsedSymbolObj.niceName}
              expiryDay={parsedSymbolObj.expiryDay}
              isWeekly={parsedSymbolObj.isWeekly}
              className="content_sm_medium"
            />
          )}
          <Text className="content_2xs_regular text_fg_muted">{row.exchange}</Text>
        </Flex>
        <Flex wrap="nowrap" gap={4} align="baseline">
          <PnLText
            colorScheme={theme.colorScheme}
            netChange={pnl}
            value={pnl.toFixed(2)}
            textClass="content_sm_medium"
          />
          <PnLText
            colorScheme={theme.colorScheme}
            netChange={netChg}
            value={netChg.toFixed(2)}
            showPercentage
          />
        </Flex>
      </Flex>

      <Flex justify="space-between">
        <HoldingCardItem title="Avg. Cost" value={avgPrice.toFixed(2)} />
        <HoldingCardItem title="Curr. Value" value={currentVal.toFixed(2)} />
      </Flex>

      <Flex justify="space-between">
        <HoldingCardItem title="Qty:" value={effectiveQty} />
        <HoldingCardItem title="LTP:" value={ltp.toFixed(2)} />
      </Flex>
    </Flex>
  )
}

export default HoldingCard
