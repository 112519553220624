import {
  Group, Box, Anchor,
} from '@mantine/core'
import { Link } from "wouter";

const AccountBlocked = () => (
  <Box p={12}>
    <p className="content_md_regular">
      Your account has been temporarily suspended due to five unsuccessful
      attempts to verify your TOTP.
    </p>
    <p className="content_md_regular">
      To regain access to your account, please click the button below to
      reset your password.
    </p>
    <Group position="center" mt="lg">
      <Anchor component={Link} to="/forgot" size="sm">
        Forgot password?
      </Anchor>
    </Group>
  </Box>
)

export default AccountBlocked
