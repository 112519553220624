import {
  Minus, Plus, AlignCenter, Settings, Telescope, TrendingUp, Copy, InfoCircle,
} from 'tabler-icons-react'
import {
  useAddToWatchlist, useCreateGttModal, useRedirectChart, useTradePositions,
} from '@hooks'
import { DrawerItem } from '@components/drawers'
import useMarketDepth from '@hooks/common/useMarketDepth'
import { IExtendedPositionItem } from '@redux/sagas/positions'
import IDrawerItemsProps from './drawerItems.types'

const DrawerItems = ({
  selectedCard, close, handlePositionDetails, handleConvertPosition,
}: IDrawerItemsProps) => {
  const { token, exchange } = selectedCard

  const { handleExitPosition, handleAddPosition } = useTradePositions();
  const { handleMarketDepth } = useMarketDepth();
  const { handleChart } = useRedirectChart<IExtendedPositionItem>();
  const { handleAddWatchlistItem } = useAddToWatchlist<IExtendedPositionItem>();
  const { handleGttModal } = useCreateGttModal(token, exchange);

  const handleBuyItem = () => {
    handleExitPosition(selectedCard);
    close();
  }

  const handleSellItem = () => {
    handleAddPosition(selectedCard);
    close();
  }

  const handleConvertItem = () => {
    handleConvertPosition(selectedCard);
    close();
  }

  const handleInfoItem = () => {
    handlePositionDetails(selectedCard);
    close();
  }

  const handleDrawerMarketDepth = () => {
    handleMarketDepth(selectedCard)
    close();
  }

  const handleDrawerChart = () => {
    handleChart(selectedCard)
    close();
  }

  const handleDrawerAddWatchlist = () => {
    handleAddWatchlistItem(selectedCard)
    close();
  }

  const handleDrawerGttModal = () => {
    handleGttModal(true, selectedCard)
    close();
  }

  return (
    <div>
      <DrawerItem icon={Minus} title="Exit" handleDrawerItem={handleBuyItem} />
      <DrawerItem icon={Plus} title="Add" handleDrawerItem={handleSellItem} />
      <DrawerItem icon={Copy} title="Convert" handleDrawerItem={handleConvertItem} />
      <DrawerItem icon={InfoCircle} title="Info" handleDrawerItem={handleInfoItem} />
      {/* Repeating this common just for mainting types */}
      <DrawerItem icon={Settings} title="Create GTT" handleDrawerItem={handleDrawerGttModal} />
      <DrawerItem icon={AlignCenter} title="Market depth" handleDrawerItem={handleDrawerMarketDepth} />
      <DrawerItem icon={TrendingUp} title="Chart" handleDrawerItem={handleDrawerChart} />
      <DrawerItem icon={Telescope} title="Add to marketwatch" handleDrawerItem={handleDrawerAddWatchlist} />
    </div>
  )
}

export default DrawerItems
