import {
  Box, Space,
} from "@mantine/core"
import notFound from "@assets/icons/not-found.svg"

interface ZeroStateProps {
  title?: string
}

const NotFoundState = ({
  title = "No result found",
}: ZeroStateProps) => (
  <Box sx={{ textAlign: "center", marginTop: 60 }}>
    <img src={notFound} alt="position-bag" />
    <Space h={20} />
    <p className="heading_2xs_semibold">
      {title}
    </p>
    {/* <Box mt={15}>
      <Button>Get Started</Button>
    </Box> */}
  </Box>
)

export default NotFoundState
