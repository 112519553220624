import {
  Text, Radio,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Flex } from "@uikit";
import { setup2fa } from "@redux/sagas/auth/authSlice";
import { setup2Fa } from "@redux/sagas/setup/setupSlice";
import { RootState } from "@redux/store";
import { showNotification } from "@mantine/notifications";
import { useLocation } from "wouter";
import Logo from "@components/logo";
import { capitalizeFirstLetter } from "@utils/index";
import { ErrorFallbackMsg, FAILURE, SUCCESS } from "@utils/constant";
import { Setup2FaRoutes } from "@constants/routes";
import MethodExternal from "./methodExternal";
import MethodAppCode from "./methodAppCode";
import useStyles from "./styles";
import TotpSetup2FaInput from "./totpSetup2faInput";

interface ITwoFaData {
  image: string;
  key: string;
  requestId: string;
  session?: string;
}

// Setup TOTP Screen - can come in 2 flows
// 1. In Account setup flow (new account, first time password creation)
// -- all data will come from setPassword redux state
// 2. In Reset password flow
// -- all data will come from resetPassword redux state

const Setup2Fa = () => {
  const dispatch = useDispatch();
  const { classes } = useStyles()
  const [, setLocation] = useLocation();
  const { location } = window;
  const [totpVal, setTotpVal] = useState("");
  const [enable2FaData, setTwoFaData] = useState<ITwoFaData | null>(null);
  const [totpSourceValue, setTotpSourceValue] = useState<"app" | "external">(
    "app"
  );
  const reset2FaState = useSelector((state: RootState) => state.auth.setup2fa);
  const {
    reset: resetPasswordData,
    loginOtp: loginOtpData,
  } = useSelector((state: RootState) => state.auth);
  const {
    setPassword: createPasswordState,
    set2Fa: setup2FaState,
  } = useSelector((state: RootState) => state.setup);

  useEffect(() => {
    if (location.pathname === Setup2FaRoutes.CREATE_PASSWORD) {
      setTwoFaData(createPasswordState.data);
    } else if (location.pathname === Setup2FaRoutes.RESET_PASSWORD) {
      setTwoFaData(resetPasswordData.data);
    } else if (location.pathname === Setup2FaRoutes.VALIDATE_OTP) {
      setTwoFaData(loginOtpData.data);
    }
  }, [location.pathname]);

  const dispatchVerifyResetTotp = () => {
    if (enable2FaData?.requestId) {
      const { requestId } = enable2FaData;
      dispatch(setup2fa({ code: totpVal, requestId }));
    }
  }

  const dispatchVerifySetupTotp = () => {
    if (enable2FaData && enable2FaData?.session) {
      const { session, requestId } = enable2FaData;
      dispatch(setup2Fa({ code: totpVal, requestId, session }))
    }
  }
  const handleVerifyTOTP = () => {
    if (location.pathname === Setup2FaRoutes.CREATE_PASSWORD) {
      dispatchVerifySetupTotp();
    } else {
      dispatchVerifyResetTotp();
    }
  };
  // if user refreshes the page, we need to redirect him to the home page
  useEffect(() => {
    if (!createPasswordState.data && !loginOtpData.data && !resetPasswordData.data) {
      setLocation("/");
      showNotification({
        color: "red",
        title: "2FA Verification Failed",
        message: "Your two-factor authentication setup was interrupted. Please restart the process",
        autoClose: 5000,
      });
    }
  }, []);

  useEffect(() => {
    if (reset2FaState.status === SUCCESS) {
      setLocation("/");
      showNotification({
        color: "green",
        title: "TOTP set successfully",
        message: "",
      });
    }
    if (reset2FaState.status === FAILURE) {
      setTotpVal("");
      if (reset2FaState.errorCode === 422) {
        showNotification({
          color: "red",
          title: "Request timeout",
          message: "Please start the flow again",
        });
        setLocation("/");
      } else {
        showNotification({
          color: "red",
          title:
            capitalizeFirstLetter(reset2FaState.message) || ErrorFallbackMsg,
          message: "",
        });
      }
    }
  }, [reset2FaState.status]);

  useEffect(() => {
    if (setup2FaState.status === SUCCESS) {
      setLocation("/");
      showNotification({
        color: "green",
        title: "Account setup completed",
        message: "",
      });
    }
  }, [setup2FaState.status])

  const handleChangeTOTPSource = () => {
    setTotpSourceValue((prev) => (prev === "external" ? "app" : "external"));
  };

  useEffect(() => {
    if (totpVal.length === 6) {
      handleVerifyTOTP();
    }
  }, [totpVal]);

  return (
    <div className="loggedOutCard loggedOutCardExtended">
      <div className="loggedOutCardHeader">
        <Logo />
        <h2 className="loggedOutCardTitle">Enable Two-Factor Authentication (2FA)</h2>
      </div>
      <Radio.Group
        value={totpSourceValue}
        onChange={handleChangeTOTPSource}
      >
        <Flex direction="column" mt={12}>
          <Radio
            classNames={{
              inner: classes.radioInner,
            }}
            value="app"
            label={<Text className="content_md_medium">Method 1: Using the Tiqs Mobile App</Text>}
          />
          {totpSourceValue === "app" && (
          <MethodAppCode />
          )}
          <Radio
            mt={24}
            value="external"
            classNames={{
              inner: classes.radioInner,
            }}
            label={<Text className="content_md_medium">Method 2: Using an External Authenticator App</Text>}
          />
          {totpSourceValue === "external"
            && (
            <MethodExternal
              enable2FaData={enable2FaData}
            />
            )}
        </Flex>
      </Radio.Group>
      <TotpSetup2FaInput
        totpVal={totpVal}
        setTotpVal={setTotpVal}
        handleVerifyTOTP={handleVerifyTOTP}
        totpSourceValue={totpSourceValue}
      />
    </div>
  );
};

export default Setup2Fa;
