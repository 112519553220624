import { Global, useMantineTheme } from "@mantine/core";
import { fadeInAnimation } from "@utils/index";
import {
  getTextStyles,
  getThemeClasses,
  getTypographyStyles,
} from "./themeConfig";

const GlobalStyles = () => {
  const theme = useMantineTheme();
  const { colors } = theme.other;
  const isDarkMode = theme.colorScheme === 'dark';
  return (
    <Global
      styles={() => ({
        "*, *::before, *::after": {
          margin: 0,
          padding: 0,
          boxSizing: "border-box",
          textDecoration: "none",
        },
        body: {
          color: colors.fg.default,
          overscrollBehavior: "none",
        },
        ".label": {
          color: "red",
        },
        ".textRight": {
          textAlign: "right",
          width: "100%",
        },
        ".tableHeader": {
          background: colors.bg.subtle,
          border: `1px solid ${colors.disabled.muted}`,
        },
        // react-vitualized styling
        ".table-header-row": {
          backgroundColor: colors.base.white,
          borderTop: `1px solid ${colors.disabled.muted}`,
          borderBottom: `1px solid ${colors.disabled.muted}`,
          color: colors.fg.default,
          ...getTypographyStyles("heading_2xs_semibold"),
          display: "flex",
          alignItems: "center",
          height: "3.375rem",
          zIndex: 1,
          cursor: "pointer",
        },
        ".sticky": {
          position: "sticky",
          top: "55px",
        },
        "table-container": {
          width: "100%",
          height: "100%",
        },
        ".table-row": {
          display: "flex",
          alignItems: "center",
          overflow: "unset !important",
          borderBottom: `1px solid ${colors.disabled.muted}`,
          backgroundColor: colors.base.white,
          ":hover": {
            background: colors.bg.subtle,
            ".dots": {
              visibility: "visible",
            },
          },
        },
        ".tableDropDowns": {
          ":hover": {
            background: colors.bg.subtle,
            ".dots": {
              visibility: "visible",
            },
          },
        },
        ".table-cell": {
          paddingRight: "1rem",
        },
        ".tableHeaderCell": {
          display: "flex",
          alignItems: "center",
          height: "100%",
          transition: "0.1s",
          ...getTypographyStyles("content_xs_semibold"),
          padding: "0 0.8rem",
          "&:hover": {
            backgroundColor: colors.bg.subtle,
          },
        },
        ".tableHeaderCellActive": {
          backgroundColor: colors.bg.subtle,
        },
        ".tableDataCells": {
          display: "flex",
          alignItems: "center",
          height: "100%",
          padding: "0 0.8rem",
        },
        ".ReactVirtualized__Table__headerRow": {
          backgroundColor: colors.bg.subtle,
          border: `1px solid ${colors.disabled.muted}`,
          ".ReactVirtualized__Table__headerColumn": {
            p: {
              color: colors.fg.default,
              ...getTypographyStyles("heading_2xs_semibold"),
            },
          },
        },
        // override height to show table dropdown when there is less than 5 order
        ".table-min-height .ReactVirtualized__Table__Grid": {
          // height: "400px !important",
        },
        ".table-min-height .ReactVirtualized__Grid__innerScrollContainer": {
          // minHeight: "400px !important",
        },
        ".ReactVirtualized__Table": {
          // width: `calc(100vw - ${40}px) !important`,
          // [`@media (min-width: ${theme.breakpoints.md}px)`]: {
          //   width: `calc(100vw - ${Number(theme.other.watchlistWidth) + 40}px) !important`,
          // },
        },
        ".ReactVirtualized__Table__row": {
          borderBottom: `1px solid ${colors.disabled.muted}`,
          backgroundColor: colors.base.white,
          overflow: "unset !important",
          ":hover": {
            background: colors.bg.subtle,
          },
          ".ReactVirtualized__Table__rowColumn": {
            p: {
              // color: theme.other.colors.fg.default,
              ...getTypographyStyles("content_sm_regular"),
            },
          },
        },
        ".center": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        },
        ".cardTitle": {
          color: colors.fg.default,
          ...getTypographyStyles("heading_sm_semibold"),
        },
        ".subtleButton": {
          "&:hover": {
            backgroundColor: colors.primary.muted,
          },
        },
        ".fullWidth": {
          width: "100%",
        },
        ".mantine-Navbar-root": {
          left: "auto",
          borderRight: `1px solid ${colors.disabled.muted}`,
        },
        ".mantine-Header-root ": {
          backgroundColor: colors.bg.default,
          border: `1px solid ${colors.disabled.muted}`,
        },
        a: {
          all: "unset",
        },
        ".renderBoundaryLoader": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "12rem",
        },
        // Typography
        ...getTextStyles(),
        // Colors
        ...getThemeClasses(theme),

        // Customizing Scrollbar
        "::-webkit-scrollbar": {
          width: "4px",
          backgroundColor: colors.bg.muted,
        },
        "::-webkit-scrollbar-thumb": {
          backgroundColor: colors.disabled.subtle,
          borderRadius: "4px",
        },
        // Horizontal scroll disable
        ".mantine-AppShell-main": {
          overflowX: "hidden",
        },
        // Logged out state cards
        ".loggedOutCard": {
          backgroundColor: colors.base.white,
          width: 420,
          boxShadow: isDarkMode
            ? "0px 2px 8px 0px rgba(0, 0, 0, 0.60)"
            : " 0px 0px 44px 0px #EAECF0",
          borderColor: isDarkMode
            ? colors.disabled.default
            : colors.disabled.muted,
          borderRadius: "12px",
          padding: 24,
          ...fadeInAnimation,
        },
        ".loggedOutCardTitle": {
          marginTop: 16,
          ...getTypographyStyles("heading_lg_medium"),
        },
        ".loggedOutCardHeader": {
          textAlign: "center",
          marginBottom: 24,
        },
        ".loggedOutCardExtended": {
          width: 500,
        },

        // mantine notifications override
        ".mantine-Notification-root": {
          minHeight: 100,
          background: colors.base.white,
          borderTop: `1px solid ${colors.disabled.muted}`,
          borderBottom: `1px solid ${colors.disabled.muted}`,
          borderRight: `1px solid ${colors.disabled.muted}`,
          boxShadow:
            "0px -2px 8px -4px rgba(16, 24, 40, 0.04), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
          borderRadius: 4,
          marginBottom: "-6.5rem",
          maxHeight: "fit-content",
          transitionDuration: "350ms",
          marginLeft: "auto",
          marginRight: "auto",
          [`@media (max-width: ${theme.breakpoints.xs})`]: {
            top: -8,
            left: -8,
            width: "calc(100% + 16px)",
          },
        },
        ".mantine-Notification-body": {
          marginRight: "-10px",
        },
        //  icon wrapper
        // ".mantine-1hmlz5i , .mantine-150n7gq, , .mantine-kc9f56": {
        //   backgroundColor: isDarkMode ? "" : "unset",
        //   marginLeft: 8,
        //   marginRight: 8,
        // },

        // success toast
        ".mantine-sjcduc": {
          borderLeft: `8px solid ${colors.success.default}`,
        },
        ".mantine-sjcduc::before": {
          display: "none",
        },

        // error toast
        ".mantine-zg1k6o": {
          borderLeft: `8px solid ${colors.error.default}`,
        },
        ".mantine-zg1k6o::before": {
          display: "none",
        },

        // warning toast orange
        ".mantine-r9nphv": {
          borderLeft: `8px solid ${colors.warning.default}`,
        },
        ".mantine-r9nphv::before": {
          display: "none",
        },

        // blue
        ".mantine-14eybgu": {
          borderLeft: `8px solid ${colors.blue.default}`,
        },
        ".mantine-14eybgu::before": {
          display: "none",
        },

        // title and message
        ".mantine-Notification-title": {
          ...getTypographyStyles("content_md_medium"),
          marginBottom: 0,
        },
        ".mantine-Notification-description": {
          ...getTypographyStyles("content_sm_regular"),
        },

        ".mantine-14zcsvh": {
          bottom: "20%",
        },
        // poping out top 2 notification
        ".mantine-Notification-root:nth-last-of-type(2)": {
          marginBottom: 0,
          width: "102%",
          right: "4px",
        },
        ".mantine-Notification-root:nth-last-of-type(1)": {
          width: "102%",
          right: "4px",
        },

        // mantine badge override to display badge in multiple lines if the text is too long
        ".mantine-Badge-root": {
          height: "auto",
          overflow: "unset",
        },
        ".mantine-Badge-inner": {
          overflow: "unset",
        },
        // mantine number input override
        ".mantine-NumberInput-input": {
          borderColor: colors.disabled.muted,
          borderRadius: 2,
        },
      })}
    />
  );
};

export default GlobalStyles;
