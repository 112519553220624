import { orderStatusColor, productType } from "@utils/constant"
import { TransactionType } from "src/constants/common"
import { isPartialExecution, parsedSymbol } from "@utils/index"
import FormattedName from "@components/watchlist/formattedName"
import { Flex, Text, Badge } from "@uikit"
import { BuySellConst } from "@constants"
import { validateQty } from "@utils/number"
import theme from "@theme/theme"
import PnLText from "@components/atoms/pnlText"
import { IOpenCardItemProps } from "./openCard.types"
import useStyles from "./openCard.styles"

const OpenCardItem = ({ row, handleRowClick }: IOpenCardItemProps) => {
  const { classes } = useStyles();

  const { symbol, exchange } = row;
  const parsedSymbolObj = parsedSymbol(symbol, exchange, row.token);

  const handleRowItemClick = () => {
    handleRowClick(row)
  }

  return (
    <Flex gap={8} className={classes.executedCardItem} onClick={handleRowItemClick}>
      <Flex direction="column" gap={4}>
        <Flex justify="space-between">
          {parsedSymbolObj && (
            <FormattedName
              name={parsedSymbolObj.niceName}
              expiryDay={parsedSymbolObj.expiryDay}
              isWeekly={parsedSymbolObj.isWeekly}
              className="content_sm_medium"
            />
          )}
          <Text className="content_sm_medium text_fg_default">{Number(row.price).toFixed(2)}</Text>
        </Flex>

        <Flex justify="space-between">
          <Flex gap={2}>
            <Text className="content_xs_regular text_fg_subtle">{row.exchange}</Text>
            <Text className="content_xs_regular text_fg_subtle">{productType[row.product]}</Text>
          </Flex>
          <Flex gap={4}>
            <Text className="content_2xs_regular text_fg_subtle">LTP:</Text>
            <PnLText
              colorScheme={theme.colorScheme}
              netChange={row.ltp}
              value={row.ltp.toFixed(2)}
              textClass="content_2xs_regular"
            />
          </Flex>
        </Flex>
      </Flex>

      <Flex justify="space-between">
        <Flex gap={4}>
          <Badge size="md" radius="xs" color={row.transactionType === TransactionType.B ? "blue" : "red"}>
            {BuySellConst[row.transactionType]}
          </Badge>
          <Text className="content_2xs_regular text_fg_default">
            {/* TODO: need to discuss with Abhishek */}
            {`0 / ${validateQty(row.fillShares, row.quantity)}`}
          </Text>
        </Flex>
        <Flex gap={4}>
          <Text className="content_2xs_regular text_fg_default">{row.timeStamp.slice(0, 9)}</Text>
          <Badge
            radius="xs"
            color={
              orderStatusColor[isPartialExecution(row.cancelQuantity, row.fillShares) ? 'PARTIAL' : row.orderStatus]
            }
            className="cursor-pointer"
          >
            {isPartialExecution(row.cancelQuantity, row.fillShares) ? 'PARTIALLY EXECUTED' : row.orderStatus}
          </Badge>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default OpenCardItem
