import {
  AlignCenter,
  Copy, InfoCircle, Minus, Plus, Settings, Telescope, TrendingUp,
} from 'tabler-icons-react'
import {
  useAddToWatchlist, useCreateGttModal, useRedirectChart, useRepeatOrder, useTradeOrder,
} from '@hooks'
import { DrawerItem } from '@components/drawers'
import useMarketDepth from '@hooks/common/useMarketDepth'
import { IUserOrder } from '@redux/sagas/orders'
import IDrawerItemsProps from './drawerItems.types'

const DrawerItems = ({ selectedCard, close, handleOrderDetailsModal }: IDrawerItemsProps) => {
  const { token, exchange, symbol } = selectedCard

  const { handleBuyOrder, handleSellOrder } = useTradeOrder(token, exchange, symbol);
  const { handleRepeatOrder } = useRepeatOrder(token);
  const { handleMarketDepth } = useMarketDepth();
  const { handleChart } = useRedirectChart<IUserOrder>();
  const { handleAddWatchlistItem } = useAddToWatchlist<IUserOrder>();
  const { handleGttModal } = useCreateGttModal(token, exchange);

  const handleBuyItem = () => {
    handleBuyOrder(selectedCard);
    close();
  }

  const handleSellItem = () => {
    handleSellOrder(selectedCard);
    close();
  }

  const handleRepeatItem = () => {
    handleRepeatOrder(selectedCard);
    close();
  }

  const handleInfoItem = () => {
    handleOrderDetailsModal(selectedCard);
    close();
  }

  const handleDrawerGttModal = () => {
    handleGttModal(true, selectedCard)
    close();
  }

  const handleDrawerMarketDepth = () => {
    handleMarketDepth(selectedCard)
    close();
  }

  const handleDrawerChart = () => {
    handleChart(selectedCard)
    close();
  }

  const handleDrawerAddWatchlist = () => {
    handleAddWatchlistItem(selectedCard)
    close();
  }

  return (
    <div>
      <DrawerItem icon={Plus} title="Buy" handleDrawerItem={handleBuyItem} />
      <DrawerItem icon={Minus} title="Sell" handleDrawerItem={handleSellItem} />
      <DrawerItem icon={Copy} title="Repeat" handleDrawerItem={handleRepeatItem} />
      <DrawerItem icon={InfoCircle} title="Info" handleDrawerItem={handleInfoItem} />
      {/* Repeating this common just for mainting types */}
      <DrawerItem icon={Settings} title="Create GTT" handleDrawerItem={handleDrawerGttModal} />
      <DrawerItem icon={AlignCenter} title="Market depth" handleDrawerItem={handleDrawerMarketDepth} />
      <DrawerItem icon={TrendingUp} title="Chart" handleDrawerItem={handleDrawerChart} />
      <DrawerItem icon={Telescope} title="Add to marketwatch" handleDrawerItem={handleDrawerAddWatchlist} />
    </div>
  )
}

export default DrawerItems
