import { showNotification } from "@mantine/notifications"
import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useLocation } from "wouter"
import { RootState } from "src/redux/store"
import { capitalizeLetter, isEqual } from "src/utils"
import { validateReset } from "@redux/sagas/auth/authSlice"
import { ErrorFallbackMsg, FAILURE, SUCCESS } from "@utils/constant"
import usePrevious from "../common/usePrevious"

interface OtpPayloadProps {
  otp: string
  requestId: string
}

const useValidateOtp = () => {
  const dispatch = useDispatch()
  const [, setLocation] = useLocation()

  const validateResetData = useSelector(
    (state: RootState) => state.auth.validateReset
  )
  const { status } = validateResetData

  const prevProps = usePrevious({ validateResetData })

  const handleValidateOtp = (payload: OtpPayloadProps) => {
    dispatch(validateReset(payload))
  }
  useEffect(() => {
    if (!isEqual(prevProps.validateResetData.status, validateResetData.status) && status === FAILURE) {
      if (validateResetData.errorCode === 422) {
        showNotification({
          color: "red",
          title: "Request timeout",
          message: "Please start the flow again",
        })
        setLocation("/forgot")
      } else if (validateResetData.message === "max attempts reached") {
        showNotification({
          color: "red",
          title: "You have reached the maximum number of attempts",
          message: "Please start the flow again",
        })
        setLocation("/forgot")
      } else {
        showNotification({
          color: "red",
          title: capitalizeLetter(validateResetData.message) || ErrorFallbackMsg,
          message: "",
        })
      }
    } else if (!isEqual(prevProps.validateResetData.status, validateResetData.status) && status === SUCCESS) {
      showNotification({
        color: "green",
        title: "OTP validated successfully",
        message: "",
      })
      setLocation(`/reset?requestId=${validateResetData.data && validateResetData.data.requestID}`)
    }
  }, [prevProps, validateResetData])

  return { handleValidateOtp }
}

export default useValidateOtp
