import {
  memo, useEffect, useState,
} from "react"
import {
  Tabs, Box, createStyles, useMantineTheme, TabsValue,
} from "@mantine/core"
import { useDispatch, useSelector } from "react-redux"
import type { RootState } from "src/redux/store"
import useMediaCustom from "src/hooks/common/useMediaCustom"
import { bounceAnimation, isEqual, filterByOrderStatus } from "src/utils"
import { getTypographyStyles } from "@theme/themeConfig"
import useRouteChange from "@hooks/common/useRouteChange"
import OrderDetailsModal from "@components/modals/order/OrderDetailsModal"
import OrderHistoryModal from "@components/modals/order/OrderHistoryModal"
import usePrevious from '@hooks/common/usePrevious'
import {
  gttDetails,
  getUserOrders,
} from "@redux/sagas/orders/ordersSlice"
import { IGttOrderDetail, IUserOrder } from "@redux/sagas/orders"
import { selectOrderUpdate } from "@redux/sagas/sockets/socketSelectors"
import GttDetailsModal from "@components/modals/order/gttDetailsModal"
import { showNotification } from "@mantine/notifications"
import RenderBoundary from "@components/renderBoundary/renderBoundary"
import ErrorElement from "@components/errorElement"
import useCancelOrder from "@hooks/common/useCancelOrder"
import {
  FAILURE, SUCCESS, orderTabTypes,
} from "../../utils/constant"
import OpenOrderTab from "./open/OpenOrderTab"
import GttOrderTab from "./gtt/GttOrderTab"

const useStyles = createStyles((theme) => ({
  tabLabel: {
    ...getTypographyStyles('heading_xs_semibold'),
  },
  orderBox: {
    minHeight: "87vh",
    [`@media (min-width: ${theme.breakpoints.xl}px)`]: {
      width: "97%",
    },
  },
}))

const Orders = () => {
  const { classes } = useStyles()
  const dispatch = useDispatch()
  const { isLargeScreen } = useMediaCustom();
  const theme = useMantineTheme();
  const { colors } = theme.other
  const { onCancelConfirmationPress } = useCancelOrder()

  const ordersState = useSelector((state: RootState) => state.order.orders)
  const isOrdersStateFailure = ordersState.status === FAILURE
  const gttDetailsState = useSelector((state: RootState) => state.order.gttDetails)
  const isGttDetailsStateFailure = gttDetailsState.status === FAILURE
  const {
    cancelOrder,
    deleteGtt,
  } = useSelector((state: RootState) => state.order)

  const orderUpdate = useSelector(selectOrderUpdate) as any;
  const prevProps = usePrevious({ cancelOrder, orderUpdate, deleteGtt })

  const [showOrderDetails, setShowOrderDetails] = useState(false)
  const [showOrderHistory, setShowOrderHistory] = useState(false)
  const [showGttDetails, setShowGttDetails] = useState(false)

  const [selectedOrderDetails, setSelectedOrderDetails] = useState<Partial<IUserOrder>>({})
  const [selectedGttDetails, setSelectedGttDetails] = useState({} as IGttOrderDetail);

  const { isNavigateLoading } = useRouteChange()

  const [activeTab, setActiveTab] = useState<string | null>(orderTabTypes.ORDERS);
  const [reloadTab, setReloadTab] = useState<number>(0);
  const openOrdersData = ordersState.data
    ? filterByOrderStatus(ordersState.data, ['OPEN', 'TRIGGER_PENDING', 'PENDING'], true) : [];

  const executedOrders = ordersState.data
    ? filterByOrderStatus(ordersState.data, ['OPEN', 'TRIGGER_PENDING', 'PENDING'], false) : [];

  useEffect(() => {
    if (activeTab === orderTabTypes.ORDERS) {
      dispatch(getUserOrders())
      // dispatch(getUserTrades())
    } else if (activeTab === orderTabTypes.GTT) {
      dispatch(gttDetails([]))
    }
  }, [activeTab, reloadTab])

  useEffect(() => {
    if (!isEqual(deleteGtt.status, prevProps.deleteGtt.status) && deleteGtt.status === SUCCESS) {
      dispatch(gttDetails([]))
      showNotification({
        color: 'green',
        title: "Deleted",
        message: "GTT deleted successfully",
      })
    }
  }, [deleteGtt, prevProps])

  const handleTab = (currentTab: TabsValue) => {
    setReloadTab((prev) => prev + 1)
    setActiveTab(currentTab);
  }

  const handleOrderDetailsModal = (item: any) => {
    setShowOrderDetails(true)
    setSelectedOrderDetails(item)
  }

  const handleOrderDetailsCloseModal = () => {
    setShowOrderDetails(false)
  }

  const handleGttDetailsCloseModal = () => {
    setShowGttDetails(false)
  }

  const handleGttDetailsModal = (item: any) => {
    setShowGttDetails(true)
    setSelectedGttDetails(item)
  }

  const handleOrderHistoryModal = () => {
    setShowOrderDetails(false)
    setShowOrderHistory(true)
  }

  const handleOrderDetailsHistoryModal = () => {
    setShowOrderDetails(true)
    setShowOrderHistory(false)
  }

  const handleOrderHistoryCloseModal = () => {
    setShowOrderHistory(false)
  }

  const handleCancelOrder = (order:IUserOrder) => {
    onCancelConfirmationPress([order])
  }

  const loadingCheck = (ordersState.loading || gttDetailsState.loading)
    && (executedOrders.length
      || (gttDetailsState.data && gttDetailsState.data.length)
      || openOrdersData.length)
    && isNavigateLoading
    ? bounceAnimation
    : {};

  return (
    <Box
      sx={loadingCheck}
      p={isLargeScreen ? "lg" : "sm"}
      className={classes.orderBox}
    >
      <Tabs
        keepMounted={false}
        classNames={{ tabLabel: classes.tabLabel }}
        styles={() => ({
          tab: {
            color: theme.other.colors.fg.muted,
            '&[data-active]': {
              color: colors.primary.default,
            },
          },
        })}
        value={activeTab}
        onTabChange={(val) => handleTab(val)}
      >
        <Tabs.List>
          <Tabs.Tab value={orderTabTypes.ORDERS}>Orders</Tabs.Tab>
          <Tabs.Tab value={orderTabTypes.GTT}>GTT</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value={orderTabTypes.ORDERS} pt="xs">
          <RenderBoundary
            loading={ordersState.loading}
            status={ordersState.status}
            data={ordersState.data}
            errorState={(
              <ErrorElement component="Orders" message={isOrdersStateFailure && ordersState.message} />
            )}
          >
            <OpenOrderTab
              ordersLoading={ordersState.loading}
              filteredOrdersData={executedOrders}
              openOrdersData={openOrdersData}
              ordersIsError={isOrdersStateFailure}
              ordersErrMessage={isOrdersStateFailure ? ordersState.message : ''}
              handleOrderDetailsModal={handleOrderDetailsModal}
              handleCancelOrder={handleCancelOrder}
            />
          </RenderBoundary>

        </Tabs.Panel>

        <Tabs.Panel value={orderTabTypes.GTT} pt="xs">
          <GttOrderTab
            gttLoading={gttDetailsState.loading}
            gttDetailsData={gttDetailsState.data ? gttDetailsState.data : []}
            gttIsError={isGttDetailsStateFailure}
            gttErrMessage={isGttDetailsStateFailure ? gttDetailsState.message : ''}
            handleGttDetailsModal={handleGttDetailsModal}
          />
        </Tabs.Panel>
      </Tabs>

      {showOrderDetails ? (
        <OrderDetailsModal
          isVisible={showOrderDetails}
          handleOrderDetailsCloseModal={handleOrderDetailsCloseModal}
          handleOrderHistoryModal={handleOrderHistoryModal}
          selectedOrderDetails={selectedOrderDetails}
        />
      ) : null}

      {showGttDetails ? (
        <GttDetailsModal
          isVisible={showGttDetails}
          handleGttDetailsCloseModal={handleGttDetailsCloseModal}
          selectedGttDetails={selectedGttDetails}
        />
      ) : null}

      {showOrderHistory ? (
        <OrderHistoryModal
          selectedOrderId={selectedOrderDetails?.id!}
          isVisible={showOrderHistory}
          handleOrderDetailsHistoryModal={handleOrderDetailsHistoryModal}
          handleOrderHistoryCloseModal={handleOrderHistoryCloseModal}
        />
      ) : null}
    </Box>
  )
}

export default memo(Orders)
