import { createStyles } from "@mantine/core"

const useStyles = createStyles(() => ({
  upperCasing: {
    "& input": {
      textTransform: "uppercase",
    },
  },
  boxStyle: {
    textAlign: 'center',
  },
}))

export default useStyles
