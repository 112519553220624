import {
  Header,
  Group,
  Menu,
  Avatar,
  useMantineTheme,
  Box,
  Button,
  Text,
} from "@mantine/core";
import { useLocalStorage, useMediaQuery } from "@mantine/hooks";
import {
  Settings,
  Logout,
  BallFootball,
  ChevronRight,
  Bulb,
  SquareLetterH,
  Book2,
} from "tabler-icons-react";
import {
  memo, useEffect, useMemo, useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link, useLocation } from "wouter";
import { selectUserPreferences, selectUsersDetails } from "src/redux/sagas/users/userSelectors";
import useMediaCustom from "src/hooks/common/useMediaCustom";
import {
  logoutUser,
} from "@redux/sagas/auth/authSlice";
import { getUserOrders } from "@redux/sagas/orders/ordersSlice";
import { getHoldings } from "@redux/sagas/holdings/holdingsSlice";
import { getUserPositions } from "@redux/sagas/positions/positionsSlice";
import { getUserLimits } from "@redux/sagas/users/usersSlice";
import LogoUrlLight from "@assets/images/logo_light.svg";
import LogoUrlDark from "@assets/images/logo_dark.svg"
import { generateRandomUUID, getFirstLetterOfWord } from "@utils/index";
import { resetAllSlices, RootState } from "@redux/store";
import { Flex } from '@uikit';
import { ReactComponent as BulbIcon } from "@assets/icons/bulb.svg";
import { useSubUnsub } from "@hooks/sockets";
import useStyles from "./styles";
import AppBarSymbol from "./appBarSymbol";
import links from "./links";

interface AppBarSymbolProps {
  token: number;
  symbol: string;
  exchange: string;
  expiry: number;
  segment: string;
}

const AppBar = (): JSX.Element => {
  const [userName] = useLocalStorage({
    key: "name",
  });
  // TODO: Update the Hub URL when ready
  const HUB_URL = process.env.REACT_APP_HUB_URL || "https://hub.tiqs.in";
  const WEBSITE_URL = process.env.REACT_APP_WEBSITE_URL || "https://tiqs.in";
  const APPLOGIN_URL = process.env.REACT_APP_APPLOGIN_URL
  const showAlgoTradeOnNav = useMediaQuery("(min-width: 1230px)");
  // const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const { isLargeScreen } = useMediaCustom();
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const dispatch = useDispatch();
  // location cause re-rendering
  const [location, setLocation] = useLocation();
  const { data: detailsData } = useSelector(selectUsersDetails);
  const { data: preferencesData } = useSelector(selectUserPreferences)
  const logoutStatus = useSelector((state: RootState) => state.auth.logout.status)

  const [showAvatar, setShowAvatar] = useState(false);

  const filteredLinks = links.filter((item) => (isLargeScreen ? item.label !== "Watchlist" : item));

  // redirecting user to homepage if user are in watchlist page in big screen
  useEffect(() => {
    if (isLargeScreen && location === "/watchlist") {
      setLocation("/home");
    }
  }, [isLargeScreen, location, setLocation]);

  useEffect(() => {
    if (logoutStatus === "success") {
      resetAllSlices()(dispatch)
    }
  }, [logoutStatus])

  const handleLogout = () => {
    dispatch(logoutUser());
  };

  const tempPinnedInstruments = preferencesData.pinnedInstruments;
  const tokens = useMemo(() => tempPinnedInstruments.map((item) => item.token), [tempPinnedInstruments])
  useSubUnsub(tokens, null)

  const handleLinkClick = (currentTo: string) => {
    if (currentTo === location) {
      setLocation(location)
      if (links[1].to === location) {
        dispatch(getUserLimits())
        dispatch(getHoldings())
      } else if (links[2].to === location) {
        dispatch(getUserOrders())
        // dispatch(getUserTrades())
      } else if (links[3].to === location) {
        dispatch(getHoldings())
      } else if (links[4].to === location) {
        dispatch(getUserPositions())
      } else if (links[5].to === location) {
        dispatch(getUserLimits())
      }
    }
  };

  const items = filteredLinks?.map((link) => (
    <Link
      key={link.label}
      to={link.to}
      className={location !== link.to ? classes.link : classes.linkActive}
      onClick={() => handleLinkClick(link.to)}
    >
      {isLargeScreen ? (
        link.label
      ) : (
        <span
          className={location !== link.to ? classes.link : classes.linkActive}
        >
          {link.icon}
        </span>
      )}
    </Link>
  ));

  return (
    <Header
      className={classes.header}
      height={`${Number(theme.other.navHeight) / 16}rem`}
    >
      <div className={classes.headerInner}>
        {/* Indices Ticker */}
        {isLargeScreen && (
          <Flex
            justify="space-between"
            className={classes.watchlistHeaderWrapper}
          >
            {preferencesData?.pinnedInstruments.map(
              (item: AppBarSymbolProps) => (
                // eslint-disable-next-line react/no-array-index-key
                <AppBarSymbol item={item} key={generateRandomUUID()} />
              )
            )}
          </Flex>
        )}
        {/* Navbar */}
        <div className={classes.inner}>
          <Flex>
            {isLargeScreen && (
              <Flex align="center" mr={20}>
                <img
                  alt="logo"
                  src={
                    theme.colorScheme === "dark" ? LogoUrlDark : LogoUrlLight
                  }
                  width="80"
                  height="24"
                />
              </Flex>
            )}
            <Group>{items}</Group>
          </Flex>

          <Group className={classes.navItemsContainer}>
            {showAlgoTradeOnNav && (
              <Button
                size="xs"
                mr={16}
                classNames={{
                  label: "content_xs_semibold",
                }}
                radius={8}
                rightIcon={<ChevronRight size={16} />}
                leftIcon={(
                  <span className={classes.algoBtnLeftIcon}>
                    <BulbIcon />
                  </span>
                )}
                onClick={() => window.open(APPLOGIN_URL, "_blank")}
              >
                AlgoTrade
              </Button>
            )}
            <Menu
              transitionProps={{ transition: "pop", duration: 100 }}
              position="bottom-end"
              offset={10}
            >
              <Menu.Target>
                <Box className={classes.menuTarget}>
                  {detailsData && detailsData.image && !showAvatar ? (
                    <img
                      width={32}
                      height={32}
                      alt="user"
                      className={classes.avatarImg}
                      src={detailsData.image}
                      onError={() => setShowAvatar(true)}
                    />
                  ) : (
                    <Avatar color="cyan" size={32} radius={32}>
                      {getFirstLetterOfWord(userName)}
                    </Avatar>
                  )}
                  {showAlgoTradeOnNav && (
                    <Text pl={12} className="text_fg_subtle content_sm_semibold">
                      {detailsData?.id}
                    </Text>
                  )}
                </Box>
              </Menu.Target>
              <Menu.Dropdown>
                {!showAlgoTradeOnNav && (
                  <Menu.Item className={classes.uccMobileBtn}>
                    <Text pl={22} className="text_fg_subtle content_sm_semibold">
                      {detailsData?.id}
                    </Text>
                  </Menu.Item>
                )}
                {!showAlgoTradeOnNav && (
                  <a target="_blank" href={`${APPLOGIN_URL}`} rel="noreferrer">
                    <Menu.Item icon={<Bulb size={14} />}>
                      AlgoTrade
                    </Menu.Item>
                  </a>
                )}
                <Link to="/profile">
                  <Menu.Item icon={<Settings size={14} />}>
                    My Profile / Settings
                  </Menu.Item>
                </Link>
                <a target="_blank" href={HUB_URL} rel="noreferrer">
                  <Menu.Item icon={<SquareLetterH size={14} />}>
                    Hub
                  </Menu.Item>
                </a>
                <a target="_blank" href={`${WEBSITE_URL}/contact`} rel="noreferrer">
                  <Menu.Item icon={<BallFootball size={14} />}>
                    Support
                  </Menu.Item>
                </a>
                {/* <Menu.Item
                  icon={
                    theme.colorScheme === "dark" ? (
                      <SunHigh size={14} />
                    ) : (
                      <MoonStars size={14} />
                    )
                  }
                >
                  <Switch
                    radius="md"
                    checked={colorScheme === "dark"}
                    onChange={() => toggleColorScheme()}
                    // onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    //   handleOrderUpdates(event.target.value, "theme");
                    // }}
                    size="md"
                    color={theme.colorScheme === "dark" ? "gray" : "dark"}
                  />
                </Menu.Item> */}
                <Link to="/contract-note">
                  <Menu.Item
                    icon={<Book2 size={14} />}
                  >
                    Contract Note
                  </Menu.Item>
                </Link>

                <Menu.Item
                  onClick={handleLogout}
                  color="red"
                  icon={<Logout size={14} />}
                >
                  Logout
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>
        </div>
      </div>
    </Header>
  );
};

export default memo(AppBar);
