import {
  InfoCircle, Edit, X, Settings, AlignCenter, TrendingUp, Telescope,
} from 'tabler-icons-react'
import { DrawerItem } from '@components/drawers'
import {
  useAddToWatchlist, useCreateGttModal, useDeleteGtt, useModifyGtt, useRedirectChart,
} from '@hooks/orders'
import useMarketDepth from '@hooks/common/useMarketDepth'
import { IGttOrderDetail } from '@redux/sagas/orders'
import IDrawerItemsProps from './drawerItems.types'

const DrawerItems = ({
  selectedCard, close, handleOrderDetailsModal,
}: IDrawerItemsProps) => {
  const { token, exchange } = selectedCard

  const { handleEditGttOrder } = useModifyGtt()
  const { handleMarketDepth } = useMarketDepth();
  const { handleChart } = useRedirectChart<IGttOrderDetail>();
  const { handleAddWatchlistItem } = useAddToWatchlist<IGttOrderDetail>();
  const { handleGttModal } = useCreateGttModal(token, exchange);
  const { handleDeleteGttOrder } = useDeleteGtt();

  const handleDeleteGttItem = () => {
    handleDeleteGttOrder(selectedCard);
    close();
  }

  const handleModifyItem = () => {
    handleEditGttOrder(selectedCard);
    close();
  }

  const handleInfoItem = () => {
    handleOrderDetailsModal(selectedCard);
    close();
  }

  const handleDrawerGttModal = () => {
    handleGttModal(true, selectedCard)
    close();
  }

  const handleDrawerMarketDepth = () => {
    handleMarketDepth(selectedCard)
    close();
  }

  const handleDrawerChart = () => {
    handleChart(selectedCard)
    close();
  }

  const handleDrawerAddWatchlist = () => {
    handleAddWatchlistItem(selectedCard)
    close();
  }

  return (
    <div>
      <DrawerItem icon={Edit} title="Edit" handleDrawerItem={handleModifyItem} />
      <DrawerItem icon={X} title="Delete" handleDrawerItem={handleDeleteGttItem} />
      <DrawerItem icon={InfoCircle} title="Info" handleDrawerItem={handleInfoItem} />
      {/* Repeating this common just for mainting types */}
      <DrawerItem icon={Settings} title="Create GTT" handleDrawerItem={handleDrawerGttModal} />
      <DrawerItem icon={AlignCenter} title="Market depth" handleDrawerItem={handleDrawerMarketDepth} />
      <DrawerItem icon={TrendingUp} title="Chart" handleDrawerItem={handleDrawerChart} />
      <DrawerItem icon={Telescope} title="Add to marketwatch" handleDrawerItem={handleDrawerAddWatchlist} />
    </div>
  )
}

export default DrawerItems
