import axios from "../../api/axiosInterceptors"
import { tradesEndPoints } from "../../api/apiEndpoints"
import { ITradeMargin, IRegularOrderPayload, IBracketOrderPayload } from "."

export function requestRegularOrder(data: IRegularOrderPayload) {
  return axios.request({
    method: "post",
    url: tradesEndPoints.regularOrderUrl,
    data,
  })
}

export function requestEditRegularOrder(orderId: string, data: any) {
  return axios.request({
    method: "patch",
    url: `${tradesEndPoints.regularOrderUrl}/${orderId}`,
    data,
  })
}

export function requestCoverOrder(data: ITradeMargin) {
  return axios.request({
    method: "post",
    url: tradesEndPoints.coverOrderUrl,
    data,
  })
}

export function requestEditCoverOrder(orderId: string, data: ITradeMargin) {
  return axios.request({
    method: "patch",
    url: `${tradesEndPoints.coverOrderUrl}/${orderId}`,
    data,
  })
}

export function requestBracketOrder(data: IBracketOrderPayload) {
  return axios.request({
    method: "post",
    url: tradesEndPoints.bracketOrderUrl,
    data,
  })
}

export function requestEditBracketOrder(orderId: string, data: ITradeMargin) {
  return axios.request({
    method: "patch",
    url: `${tradesEndPoints.bracketOrderUrl}/${orderId}`,
    data,
  })
}

export function requestOrderMargin(data: ITradeMargin) {
  return axios.request({
    method: "post",
    url: tradesEndPoints.orderMarginUrl,
    data,
  })
}

export function requestConvertPostionUrl(data: any) {
  return axios.request({
    method: "patch",
    url: tradesEndPoints.convertPositionUrl,
    data,
  })
}

export function requestFreezeQty(data: any) {
  return axios.request({
    method: "post",
    url: tradesEndPoints.freezeQtyUrl,
    data,
  })
}
