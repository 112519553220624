import { productOptionsDisplay, varietyValues } from "@utils/constant";

const getTabValue = (productVal: string) => {
  if (productVal) {
    if (productVal === productOptionsDisplay.COVER) {
      return varietyValues.COVER;
    }
    if (productVal === productOptionsDisplay.INTRADAY || productVal === productOptionsDisplay.LONGTERM) {
      return varietyValues.REGULAR;
    }
    if (productVal === productOptionsDisplay.BRACKET) {
      return varietyValues.BRACKET;
    }
    if (productVal === "AMO") {
      return varietyValues.AMO;
    }
    return varietyValues.REGULAR;
  }
  return varietyValues.REGULAR;
};

const validateBracketOrderValues = (bracketInputValues) => {
  const { stopLossPrice, profitPrice } = bracketInputValues
  if (!stopLossPrice) {
    return "stopLossPrice"
  }
  if (!profitPrice) {
    return "profitPrice"
  }
  return ""
}

// to set quantity in multiple of lot size or round off to the nearest lot size
const roundOffQty = (val: number, lotSizeVal: number) => {
  if (val % lotSizeVal === 0) {
    return val
  }
  const remainder = val % lotSizeVal
  if (remainder > lotSizeVal / 2) {
    return val + (lotSizeVal - remainder)
  }
  return val - remainder
}

export { getTabValue, validateBracketOrderValues, roundOffQty }
