import { Flex, Text } from '@uikit'
import { ComponentType } from 'react'
import { IPositionItemProps } from './positionCard.types'

const HoldingCardItem: ComponentType<IPositionItemProps> = ({ title, value }) => (
  <Flex gap={4}>
    <Text className="content_xs_regular text_fg_muted">{title}</Text>
    <Text className="content_xs_regular">{value}</Text>
  </Flex>
)

export default HoldingCardItem
