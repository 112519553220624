import { call, put } from "redux-saga/effects"
import { capitalizeLetter, filterErrorResponse } from "@utils/index"
import { notifications } from "@mantine/notifications"
import {
  requestChangePassword,
  requestEnable2fa,
  requestGetContractNote,
  requestGetNotices,
  requestMarkNoticeAsRead,
  requestUpdateUserPreferences,
  requestUserAddImage,
  requestUserDetails,
  requestUserLimits,
  requestUserPreferences,
  requestVerify2fa,
} from "./usersRequests"
import {
  getUserDetailsFailure,
  getUserDetailsSuccess,
  getUserPreferencesFailure,
  getUserPreferencesSuccess,
  getUserLimitsSuccess,
  getUserLimitsFailure,
  changePasswordSuccess,
  changePasswordFailure,
  enable2FaSuccess,
  enable2FaFailure,
  verify2FaSuccess,
  verify2FaFailure,
  getNoticesSuccess,
  getNoticesFailure,
  getContractNoteSuccess,
  getContractNoteFailure,
  markNoticeAsReadSuccess,
  markNoticeAsReadFailure,
  getNotices,
} from "./usersSlice"

// get user details
export function* handleUserDetails(): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestUserDetails)
    yield put(getUserDetailsSuccess({ data, status }))
  } catch (error: any) {
    yield put(getUserDetailsFailure(filterErrorResponse(error)))
  }
}

// handle user limits
export function* handleUserLimits(): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestUserLimits);
    yield put(getUserLimitsSuccess({ data, status }))
  } catch (error: any) {
    yield put(getUserLimitsFailure(filterErrorResponse(error)))
  }
}

// handle user preferences
export function* handleUserPreferences(): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestUserPreferences)
    yield put(getUserPreferencesSuccess({ data, status }))
  } catch (error: any) {
    yield put(
      getUserPreferencesFailure(filterErrorResponse(error))
    )
  }
}

// handle update user preferences
export function* handleUpdateUserPreferences(action: any): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestUpdateUserPreferences, action.payload)
    yield put(getUserPreferencesSuccess({ data, status, loading: false }))
  } catch (error: any) {
    yield put(getUserPreferencesFailure(filterErrorResponse(error)))
  }
}

// handle upload user image
export function* handleUserAddImage(action: any): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestUserAddImage, action.payload)
    yield put(getUserDetailsSuccess({ data, status }))
  } catch (error: any) {
    yield put(getUserDetailsFailure(filterErrorResponse(error)))
  }
}

// enable 2 factor
export function* handleEnable2fa(): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestEnable2fa)
    yield put(enable2FaSuccess({ data, status }))
  } catch (error: any) {
    yield put(enable2FaFailure(filterErrorResponse(error)))
  }
}

// verify 2 factor
export function* handleVerify2fa(action: any): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestVerify2fa, action.payload)
    yield put(verify2FaSuccess({ data, status }))
  } catch (error:any) {
    yield put(verify2FaFailure(filterErrorResponse(error)))
  }
}

// change password
export function* handleChangePassword(action: any): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestChangePassword, action.payload)
    yield put(changePasswordSuccess({ data, status }))
  } catch (error:any) {
    yield put(changePasswordFailure(filterErrorResponse(error)))
  }
}

// fetch notices
export function* handleGetNotices(): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestGetNotices)
    yield put(getNoticesSuccess({ data, status }))
  } catch (error:any) {
    yield put(getNoticesFailure(filterErrorResponse(error)))
  }
}

// read notices
export function* handleMarkNoticeAsRead(action): Generator<any, any, any> {
  const noticeId = action.payload
  try {
    const { data: { data, status } } = yield call(requestMarkNoticeAsRead, noticeId)
    yield put(markNoticeAsReadSuccess({ data, status }))
    yield put(getNotices())
  } catch (error:any) {
    yield put(markNoticeAsReadFailure(filterErrorResponse(error)))
  }
}

export function* handleGetContractNote(action): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestGetContractNote, { date: action.payload })
    yield put(getContractNoteSuccess({ data, status }))
    notifications.show({
      title: "Success",
      message: capitalizeLetter(data),
      color: "green",
    })
  } catch (error:any) {
    const errorData = filterErrorResponse(error)
    notifications.show({
      title: "Error",
      message: errorData.message,
      color: "red",
    })
    yield put(getContractNoteFailure(filterErrorResponse(error)))
  }
}
