import { Flex } from '@uikit';
import { Text, useMantineTheme } from '@mantine/core';
import { IGttOrderDetail } from '@redux/sagas/orders';
import { parsedSymbol } from '@utils/index';
import FormattedName from '@components/watchlist/formattedName';
import GttDropdown from '@containers/orders/gtt/gttDropdown';

interface ITableInstrumentItem {
  row: IGttOrderDetail,
  width?: number
  handleOrderDetailsModal?: any
}

const GttIntrument = ({
  row,
  width = 200,
  handleOrderDetailsModal,
}: ITableInstrumentItem) => {
  const theme = useMantineTheme()
  const { symbol, exchange } = row;
  const parsedSymbolObj = parsedSymbol(symbol, exchange, row.token);

  return (
    <div
      style={{ width: `${width}px` }}
      className="table-intrument"
    >
      <Flex align="baseline">
        { parsedSymbolObj && (
        <FormattedName
          name={parsedSymbolObj.niceName}
          expiryDay={parsedSymbolObj.expiryDay}
          isWeekly={parsedSymbolObj.isWeekly}
        />
        )}
        <Text size={10} ml={3} color={theme.other.colors.fg.muted}>
          {row.exchange}
        </Text>
      </Flex>
      <GttDropdown
        row={row}
        handleOrderDetailsModal={handleOrderDetailsModal}
      />
    </div>
  )
}

export default GttIntrument
