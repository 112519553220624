/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  useMantineTheme,
  Grid,
  ActionIcon,
  Box,
  Flex,
} from "@mantine/core"
import {
  memo, useEffect,
} from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "src/redux/store"
import useMediaCustom from "@hooks/common/useMediaCustom"
import useRouteChange from "@hooks/common/useRouteChange"
import { getUserLimits } from "@redux/sagas/users/usersSlice"
import { getHoldings } from "@redux/sagas/holdings/holdingsSlice"
import { IHoldingItem } from '@redux/sagas/holdings/holdingsTypes'
import { FAILURE, SUCCESS } from "@utils/constant"
import { bounceAnimation } from "../../utils"
import useStyles from "./styles"
// import DisclaimerModal from "./DisclaimerModal"
import RenderMarginCard from "./renderMargin/renderMargin"
import ExploreTrading from "./explore/exploreTrading"
import Launches from "./launch/launches"

const Home = () => {
  const theme = useMantineTheme()
  const { colors } = theme.other
  const dispatch = useDispatch()
  const { classes } = useStyles()
  const { isTablet } = useMediaCustom()
  const { isNavigateLoading } = useRouteChange()

  const userLimitsState = useSelector((state: RootState) => state.user.limits)
  const { data, loading, status } = useSelector(
    (state: RootState) => state.holding.holdings
  )

  const result = data ? data.reduce(
    (accumulator, holdingItem) => {
      const effectiveQty = Number(holdingItem.effectiveQty);
      const avgPrice = Number(holdingItem.avgPrice);
      const close = Number(holdingItem.close);
      const ltp = Number(holdingItem.ltp)

      accumulator.investedValue += effectiveQty * avgPrice;
      accumulator.currentValue += effectiveQty * ltp;
      accumulator.todaysPnL += effectiveQty * (ltp - close);

      return accumulator;
    },
    { investedValue: 0, currentValue: 0, todaysPnL: 0 }
  ) : { investedValue: 0, currentValue: 0, todaysPnL: 0 };

  const { investedValue, currentValue, todaysPnL } = result;

  useEffect(() => {
    dispatch(getUserLimits())
    dispatch(getHoldings())
  }, [dispatch])

  // TODO
  //  const loadingCheck = loading && isNavigateLoading && limitsData.length;
  const loadingCheck = loading && isNavigateLoading && userLimitsState.status === SUCCESS
  const limitLoadingCheck = userLimitsState.loading
    && isNavigateLoading
    && userLimitsState.status === SUCCESS;

  return (
    <Box sx={loadingCheck ? bounceAnimation : {}} className={classes.wrapper}>
      <Flex gap="xl" direction={isTablet ? "column" : "row"}>
        <RenderMarginCard
          limitsLoading={userLimitsState.loading}
          limitsData={userLimitsState.data || []}
          // limitsErrMessage={limitsErrMessage}
          limitsIsError={userLimitsState.status === FAILURE}
          loadingCheck={limitLoadingCheck}
        />
        {/* <Launches /> */}
      </Flex>
      <ExploreTrading />
    </Box>
  );
}

export default memo(Home)
