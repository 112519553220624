import {
  Badge, Box, Button, Divider, Group, Modal,
  useMantineTheme,
} from "@mantine/core"
import {
  orderType, productType,
} from "src/utils/constant"
import { memo } from "react"
import useMediaCustom from "@hooks/common/useMediaCustom"
import { IGttOrderDetail, OrderParamsLeg } from "@redux/sagas/orders"
import { increaseYear } from '@utils/index'
import { BuySellConst } from "@constants"

interface GttDetailsModalProps {
  isVisible: boolean
  handleGttDetailsCloseModal: () => void
  selectedGttDetails: IGttOrderDetail
}

interface OrderDetailListItemProps {
  title: string
  value: string | number | undefined
}

const OrderDetailListItem = ({ title, value }: OrderDetailListItemProps) => (
  <Group mb="sm" position="apart" align="center">
    <p className="content_sm_regular text_fg_muted">
      {title}
    </p>
    <p className="content_sm_regular text_fg_default">{value || '0.00'}</p>
  </Group>
)

const GttOrderLeg = ({
  item,
  dividerColor,
  title,
  triggerPrice,
}: {
  item: OrderParamsLeg;
  dividerColor: string;
  title: string;
  triggerPrice: string;
}) => (
  <>
    <p className="content_sm_medium text_content_default">
      {title}
      {' '}
      @
      {triggerPrice}
    </p>
    <Divider color={dividerColor} mt="sm" />
    <Group
      sx={{ gap: "2rem" }}
      position="apart"
      align="flex-start"
      mt="xl"
      mb="xl"
    >
      <Box sx={{ flex: 1 }}>
        <OrderDetailListItem
          title="Transaction type"
          value={String(BuySellConst[item.transactionType]).toUpperCase()}
        />
        <OrderDetailListItem
          title="Product"
          value={productType[item.product]}
        />
        <OrderDetailListItem
          title="Order type"
          value={String(orderType[item.orderType]).toUpperCase()}
        />
        <OrderDetailListItem title="Order ID" value="---" />
      </Box>
      <Box sx={{ flex: 1 }}>
        <OrderDetailListItem title="Quantity" value={item.quantity} />
        <OrderDetailListItem title="Price" value={item.price} />
        <OrderDetailListItem
          title="Trigger at price"
          value={Number(triggerPrice)}
        />
        <OrderDetailListItem title="Triggered at" value="---" />
      </Box>
    </Group>
  </>
);

const GttDetailsModal = ({
  isVisible,
  handleGttDetailsCloseModal,
  selectedGttDetails,
}: GttDetailsModalProps) => {
  const { isMobile } = useMediaCustom();
  const {
    exchange,
    symbol,
    leg1,
    leg2,
    createdAt,
    ltp,
    variables,
  } = selectedGttDetails;

  const expiresAt = increaseYear(createdAt)
  const theme = useMantineTheme()
  const { colors } = theme.other

  return (
    <Modal
      onClose={handleGttDetailsCloseModal}
      opened={isVisible}
      transitionProps={{
        transition: "fade",
        duration: 500,
        timingFunction: "linear",
      }}
      size="lg"
      withCloseButton={false}
      fullScreen={!!isMobile}
    >
      <Group position="apart" mb="sm">
        <Group position="apart">
          <Badge
            color={leg2.quantity ? "orange" : "purple"}
            radius="xs"
            variant="light"
          >
            {leg2.quantity ? "TWO LEG" : "SINGLE"}
          </Badge>
          <p className="heading_xs_medium">{symbol}</p>
          <p className="text_fg_muted content_2xs_regular">{exchange}</p>
        </Group>
        <Badge color="green" radius="xs" variant="light">
          Active
        </Badge>
      </Group>
      <Divider color={colors.disabled.subtle} />

      {/* modal data */}
      <Group
        sx={{ gap: "2rem" }}
        position="apart"
        align="flex-start"
        mt="xl"
        mb="xl"
      >
        <Box sx={{ flex: 1 }}>
          <OrderDetailListItem title="Created at" value={createdAt} />
          {/* Backend needs to add an extra field of Updated At */}
          <OrderDetailListItem title="Updated at" value={createdAt} />
        </Box>
        <Box sx={{ flex: 1 }}>
          <OrderDetailListItem title="Expires at" value={expiresAt} />
          <OrderDetailListItem title="LTP created at" value={ltp} />
        </Box>
      </Group>

      {leg2.quantity ? (
        <GttOrderLeg
          item={leg2}
          dividerColor={colors.disabled.subtle}
          title="Stoploss trigger"
          triggerPrice={variables[0].name}
        />
      ) : null}

      <GttOrderLeg
        item={leg1}
        dividerColor={colors.disabled.subtle}
        title="Target trigger"
        triggerPrice={
          variables.length === 2 ? variables[1].name : variables[0].name
        }
      />

      {/* modal button */}
      <Divider color={colors.disabled.subtle} />
      <Group position="right" align="flex-start" mt="md">
        <Button
          variant="outline"
          color="gray"
          onClick={handleGttDetailsCloseModal}
        >
          Close
        </Button>
      </Group>
    </Modal>
  );
}

export default memo(GttDetailsModal)
