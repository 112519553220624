import React, { useState, useEffect } from 'react';
import {
  NumberInput as MantineNumberInput, Flex, Text, Box as MantineBox,
  Group,
  Tooltip,
  createStyles,
} from '@mantine/core';
import {
  ChevronDown,
  ChevronUp,
  Box,
  Stack2,
  CurrencyRupee,
} from "tabler-icons-react"

interface NumberInputProps {
  lotsize: number;
  initialQuantity: number;
  onQuantityChange: (quantity: number, lotsize: number) => void;
  price: number;
}

const useStyles = createStyles((theme) => {
  const { colors } = theme.other
  return {
    item: {
      height: 'auto !important',
      minHeight: 'auto !important',
    },
    tradeItem: {
      width: "32%",
      [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
        width: "100%",
        marginBottom: 24,
      },
    },
    decBtn: {
      all: 'unset',
      border: 'none',
      background: 'blue',
      cursor: 'pointer',
      padding: 0,
      margin: 0,
      height: 12,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 1,
      backgroundColor: colors.bg.muted,
      '&:hover': {
        backgroundColor: colors.disabled.muted,
      },

    },
    toggleBtn: {
      all: 'unset',
      cursor: 'pointer',
      backgroundColor: colors.bg.muted,
      color: colors.primary.subtle,
      height: 34,
      width: 34,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderTopRightRadius: 2,
      borderBottomRightRadius: 2,
      '&:hover': {
        color: colors.primary.default,
      },
    },
  }
});
const NumberInput: React.FC<NumberInputProps> = ({
  lotsize,
  initialQuantity,
  onQuantityChange,
  price,
}) => {
  const [isAlternateInput, setIsAlternateInput] = useState(false);
  const [inputValue, setInputValue] = useState(initialQuantity);
  const { classes } = useStyles();

  const isNFO = lotsize > 1;

  const getInputValue = () => {
    if (isNFO) {
      return isAlternateInput ? Math.floor(initialQuantity / lotsize) : initialQuantity;
    }
    return isAlternateInput ? Math.round(initialQuantity * price) : initialQuantity;
  };

  useEffect(() => {
    setInputValue(getInputValue());
  }, [isAlternateInput, initialQuantity, lotsize, price, isNFO]);

  const handleInputChange = (val: number) => {
    setInputValue(val);
    let newQuantity;
    if (isNFO) {
      newQuantity = isAlternateInput ? val * lotsize : val;
    } else {
      newQuantity = isAlternateInput ? Math.round(val / price) : val;
    }
    onQuantityChange(newQuantity, lotsize);
  };

  const toggleInputType = () => {
    setIsAlternateInput(!isAlternateInput);
  };

  const incrementValue = () => {
    if (isNFO) {
      handleInputChange(inputValue + (isAlternateInput ? 1 : lotsize));
    } else {
      handleInputChange(inputValue + (isAlternateInput ? price : 1));
    }
  };

  const decrementValue = () => {
    if (isNFO) {
      handleInputChange(Math.max(inputValue - (isAlternateInput ? 1 : lotsize), isAlternateInput ? 1 : lotsize));
    } else {
      handleInputChange(Math.max(inputValue - (isAlternateInput ? price : 1), isAlternateInput ? price : 1));
    }
  };

  const getPlaceholder = () => {
    if (isAlternateInput) {
      return isNFO ? "Lots" : "Price";
    }
    return "Qty";
  };

  const getDescription = () => {
    if (isAlternateInput) {
      return isNFO ? "Lots" : "Price";
    }
    return "Qty";
  };

  const getMinValue = () => {
    if (isNFO) {
      return isAlternateInput ? 1 : lotsize;
    }
    return isAlternateInput ? price : 1;
  };

  const getStepValue = () => {
    if (isNFO) {
      return isAlternateInput ? 1 : lotsize;
    }
    return isAlternateInput ? price : 1;
  };

  const getToggleButtonIcon = () => {
    if (isAlternateInput) {
      return isNFO ? <Stack2 size={16} /> : <CurrencyRupee size={16} />;
    }
    return <Box size={16} />;
  };

  const getToggleTooltipLabel = () => (isNFO ? "Toggle Lot/Qty" : "Toggle Price/Qty");

  const getInfoText = () => {
    if (isNFO) {
      if (isAlternateInput) {
        return `${inputValue * lotsize} qty`;
      }
      return lotsize > 1 ? `${Math.floor(inputValue / lotsize)} lot(s)` : '';
    }
    return isAlternateInput ? `${Math.round(inputValue / price)} qty` : '';
  };

  return (
    <MantineBox className={classes.tradeItem}>
      <Flex>
        <MantineNumberInput
          type="number"
          id="trade-qty"
          mt="xl"
          placeholder={getPlaceholder()}
          description={getDescription()}
          min={getMinValue()}
          step={getStepValue()}
          disabled={false}
          value={inputValue}
          onChange={handleInputChange}
          data-autofocus
          radius={2}
          rightSection={(
            <Group spacing={0} position="right">
              <Flex mr={8} gap={2} direction="column">
                <button
                  aria-label="Increment"
                  type="button"
                  className={classes.decBtn}
                  onClick={incrementValue}
                >
                  <ChevronUp size={14} strokeWidth={1.5} />
                </button>
                <button
                  aria-label="Decrement"
                  type="button"
                  onClick={decrementValue}
                  className={classes.decBtn}
                >
                  <ChevronDown size={14} strokeWidth={1.5} />
                </button>
              </Flex>
              <Tooltip label={getToggleTooltipLabel()}>
                <button
                  className={classes.toggleBtn}
                  type="button"
                  aria-label={getToggleTooltipLabel()}
                  onClick={toggleInputType}
                >
                  {getToggleButtonIcon()}
                </button>
              </Tooltip>
            </Group>
          )}
          rightSectionWidth={58}
        />
      </Flex>
      <Text color="dimmed" size="sm" pt={8}>
        {getInfoText()}
      </Text>
    </MantineBox>
  );
};

export default NumberInput;
