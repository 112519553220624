import {
  Text,
  Box,
  Flex,
} from '@mantine/core'
import useStyles from './styles'
import TutorialModal from './tutorialModal';

const steps = [
  {
    title: 'Step 1:',
    description: 'Download the Tiqs mobile app',
  },
  {
    title: 'Step 3:',
    description: ' Log in to Tiqs app with your new password and OTP',
  },
  {
    title: 'Step 4:',
    description: 'Find the App Code in the Account section',
  },
  {
    title: 'Step 5:',
    description: 'Enter the App Code below to complete login',
  },
]

const MethodAppCode = () => {
  const { classes } = useStyles()

  return (
    <div>
      <Box className={classes.stepsContainer} p={16}>
        {steps.map((step, index) => (
          <Flex key={step.title} align="center" className={classes.stepItem}>
            <Box className={classes.stepNumberContainer}>
              <Text className={`${classes.stepNumber} content_sm_regular text_fg_default`}>
                {index + 1}
              </Text>
            </Box>
            <Text className="content_sm_regular text_fg_default">{step.description}</Text>
          </Flex>
        ))}
      </Box>
      <TutorialModal />
    </div>

  )
}

export default MethodAppCode
