import {
  Button,
  createStyles,
} from "@mantine/core"
import { useForm } from "@mantine/form"
import MantinePasswordInput from "@components/inputs/passwordInput"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "@redux/store"
import { useEffect } from "react"
import useLocation from "wouter/use-location"
import { getTypographyStyles } from "@theme/themeConfig"
import logo from '@assets/images/logo_light.svg';
import { SUCCESS } from "@utils/constant"
import { Setup2FaRoutes } from "@constants/routes"
import { setupPassword } from "@redux/sagas/setup/setupSlice"

const useStyles = createStyles((theme) => {
  const { colors } = theme.other;
  const isDarkMode = theme.colorScheme === 'dark';
  return {
    wrapper: {
      backgroundColor: colors.base.white,
      margin: "auto",
      width: 420,
      boxShadow: isDarkMode ? '0px 2px 8px 0px rgba(0, 0, 0, 0.60)' : ' 0px 0px 44px 0px #EAECF0',
      borderColor: isDarkMode ? colors.disabled.default : colors.disabled.muted,
      borderRadius: '12px',
      padding: '48px 24px',
    },
    headerWrapper: {
      textAlign: 'center',
      marginBottom: 24,
    },
    headerTitle: {
      marginTop: 16,
      ...getTypographyStyles('heading_lg_medium'),
    },
    btn: {
      maxWidth: '21.25rem',
      margin: 'auto',
    },
  }
})

const CreatePassword = () => {
  const dispatch = useDispatch()
  const { classes } = useStyles()
  const [, setLocation] = useLocation();
  const { location } = window
  const params = new URLSearchParams(location.search);
  const requestId = params.get('requestId');
  const session = params.get('session');
  const createPasswordState = useSelector((state: RootState) => state.setup.setPassword)
  const form = useForm({
    initialValues: { password: "", repeatPassword: "" },
    validate: {
      password: (value) => (value?.length < 7 ? "User Id must have at least 8 letters" : null),
      repeatPassword: (value, values) => (value !== values.password ? "Passwords did not match" : null),
    },
  })
  const handleSubmit = async (val: typeof form.values) => {
    if (requestId && session) {
      const payload = {
        password: val.password,
        requestId,
        session,
      }
      dispatch(setupPassword(payload))
    }
  }
  // Redirect to enable 2fa page if password is created successfully
  useEffect(() => {
    if (createPasswordState.status === SUCCESS) {
      setLocation(Setup2FaRoutes.CREATE_PASSWORD)
    }
  }, [createPasswordState.status])

  return (
    <div className={classes.wrapper}>
      <div className={classes.headerWrapper}>
        <img src={logo} alt="logo" />
        <h2 className={classes.headerTitle}>Create Password</h2>
      </div>
      <div>
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <MantinePasswordInput
            label="New Password"
            placeholder="******"
            formHandlers={{ ...form.getInputProps("password") }}
          />

          <MantinePasswordInput
            label="Repeat New Password"
            placeholder="******"
            formHandlers={{ ...form.getInputProps("repeatPassword") }}
            error={form.errors.repeatPassword}
          />

          <Button
            className={classes.btn}
            fullWidth
            mt="xl"
            type="submit"
          >
            Continue
          </Button>
        </form>
      </div>
    </div>
  );
}

export default CreatePassword
