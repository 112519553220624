import {
  Box, Button, Flex, Grid, Loader, Text,
} from "@mantine/core"
import { useSelector, useDispatch } from "react-redux"
import { Rotate } from "tabler-icons-react"
import { setChargesModalInfo } from "src/redux/sagas/trades/tradesSlice"
import type { RootState } from '@redux/store'
import { useEffect } from "react"
import { colors } from "../../../utils/constant"
import useStyles from "../styled"

interface ITradeModalFooter {
  marginUsed: string
  totalCharge: number,
  buySellToggle: boolean
  handleCancelTrade: () => void
  currentToken: number
  triggerPriceError: string
  handleProceedOrder: (token: number) => void
  handleOrderMargin: () => void | undefined
}

const TradeModalFooter = ({
  marginUsed,
  totalCharge = 0,
  buySellToggle,
  handleCancelTrade,
  currentToken,
  triggerPriceError,
  handleProceedOrder,
  handleOrderMargin,
}: ITradeModalFooter) => {
  const { classes } = useStyles()
  const dispatch = useDispatch()
  const {
    tradeModalInfo: { editOrderId = "" },
  } = useSelector((state: RootState) => state.trade);

  const {
    orderMargin: { loading: tradeMarginLoading, data: orderMarginData },
  } = useSelector((state: RootState) => state.trade)

  const handleChargesModal = () => {
    if (orderMarginData && 'charge' in orderMarginData) {
      dispatch(setChargesModalInfo({
        showModal: true,
        data: orderMarginData.charge,
      }))
    }
  }

  let buttonText;
  if (editOrderId) {
    buttonText = "Modify"
  } else if (buySellToggle) {
    buttonText = "Sell"
  } else {
    buttonText = "Buy"
  }

  // TODO check this why is this in footer and not in the main component
  // Close trade modal on escape key press
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleCancelTrade();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleCancelTrade]);

  return (
    <Grid
      justify="space-between"
      align="center"
      className={classes.tradeFooterContainer}
    >
      <Grid.Col xs={6} xl={6}>
        <Flex>
          <Flex align="center">
            <Text mr={4} size="xs">
              Margin
              {" "}
              <span style={{ color: colors.primaryBlue }}>
                ₹
                {marginUsed === undefined ? "N/A" : Number(marginUsed).toFixed(2)}
              </span>
            </Text>
          </Flex>
          <Flex ml={3} align="center">
            <Text mr={4} size="xs" onClick={handleChargesModal}>
              Charges
              {" "}
              <span className="cursor-pointer" style={{ color: colors.primaryBlue }}>
                ₹
                {totalCharge.toFixed(2) || "N/A"}
              </span>
            </Text>
            {
              tradeMarginLoading ? (
                <Loader variant="dots" size="xs" />
              ) : (
                <Rotate color={colors.primaryBlue} onClick={handleOrderMargin} cursor="pointer" size={12} />
              )
            }
          </Flex>
        </Flex>
      </Grid.Col>
      <Grid.Col xs={6} xl={6}>
        <Box className={classes.tradeFooterBtnContainer}>
          <Button
            disabled={Boolean(triggerPriceError)}
            onClick={() => handleProceedOrder(currentToken)}
            className="mr-10"
            color={buySellToggle ? "red" : "blue"}
          >
            {buttonText}
          </Button>
          <Button onClick={handleCancelTrade} variant="outline" color="gray">
            Cancel
          </Button>
        </Box>
      </Grid.Col>
    </Grid>
  )
}

export default TradeModalFooter
