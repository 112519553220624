import Flex from '@uikit/flex'
import { createElement } from 'react'
import IDrawerItemProps from './drawerItem.types'

const DrawerItem = ({ icon, title, handleDrawerItem }: IDrawerItemProps) => (
  <Flex py={10} gap={12} align="center" onClick={handleDrawerItem}>
    {createElement(icon, { size: 16 })}
    {title}
  </Flex>
)

export default DrawerItem
