import Drawer from "@uikit/drawer"
import { useDisclosure } from "@mantine/hooks";
import { useState } from "react";
import { IUserOrder } from "@redux/sagas/orders";
import DrawerItems from "./drawerItems/drawerItems";
import { ITradesCardContainerProps } from "./tradesCard.types";
import TradesCardItem from "./tradesCardItem";

const TradesCardContainer = ({ tradesData }: ITradesCardContainerProps) => {
  const [opened, { open, close }] = useDisclosure(false);
  const [selectedCard, setSelectedCard] = useState({} as IUserOrder)

  const handleRowClick = (row: IUserOrder) => {
    open();
    setSelectedCard(row);
  }

  return (
    <>
      {tradesData.map((row) => <TradesCardItem key={row.id} row={row} handleRowClick={handleRowClick} />)}
      <Drawer
        opened={opened}
        onClose={close}
        title="Asset Action"
      >
        <DrawerItems
          selectedCard={selectedCard}
          close={close}
        />
      </Drawer>
    </>
  )
}

export default TradesCardContainer
