import axios from "axios";

import { getCookie, removeCookiesOnLogout } from "../../utils";
import { baseURL } from "./apiEndpoints";

axios.interceptors.request.use(
  (config) => {
    const tempConfig = config;
    tempConfig.baseURL = baseURL;
    tempConfig.withCredentials = true;
    tempConfig.timeout = 15000;

    const session = getCookie("session");
    const token = getCookie("token");

    if (session) {
      tempConfig.headers.session = session;
    }
    if (token) {
      tempConfig.headers.token = token;
    }
    return tempConfig;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      removeCookiesOnLogout();
      window.location.href = "/";
    } else if (error?.response?.status === 500) {
      if (error.config?.url === "auth/logout") {
        removeCookiesOnLogout();
        window.location.href = "/";
      }
    }
    return Promise.reject(error);
  }
);

export default axios;
