import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => {
  const { colors } = theme.other
  return {
    wrapper: {
      padding: 24,
    },
    border: {
      height: "1px",
      width: "100%",
      background: colors.primary.muted,
    },
    // Funds card
    walletIconWrapper: {
      background: colors.primary.muted,
      borderRadius: 12,
      height: 56,
      width: 56,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginRight: 16,
    },
    // Equity Funds card
    equityFundsCardWrapper: {
      marginTop: 24,
    },
    eqFundsContent: {
      marginTop: -16,
    },
    eqFundsContentLeft: {
      borderLeft: `1px solid ${colors.primary.muted}`,
      [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
        border: "none",
      },
    },
    fullWidth: {
      width: "100%",
    },
    primaryCard: {
      [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
        width: "100%",
        justifyContent: "space-between",
      },
      [`@media (max-width: ${theme.breakpoints.md}px)`]: {
        width: "100%",
        justifyContent: "space-between",
      },
    },
    fundsCardHeader: {
      [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
        display: "flex",
        flexDirection: 'column',
        width: "100%",
        alignItems: "stretch",
      },
    },
    cardTitleFunds: {
      [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
        display: "none",
      },
    },
  };
})

export default useStyles;
