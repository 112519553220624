import Drawer from "@uikit/drawer"
import { useDisclosure } from "@mantine/hooks";
import { useState } from "react";
import { IGttOrderDetail } from "@redux/sagas/orders";
import { IGttCardContainerProps } from "./gttCard.types";
import DrawerItems from "./drawerItems/drawerItems";
import GttCardItem from "./gttCardItem";

const GttCardContainer = ({ gttData, handleOrderDetailsModal }: IGttCardContainerProps) => {
  const [opened, { open, close }] = useDisclosure(false);
  const [selectedCard, setSelectedCard] = useState({} as IGttOrderDetail)

  const handleRowClick = (row: IGttOrderDetail) => {
    open();
    setSelectedCard(row);
  }

  return (
    <>
      {gttData.map((row) => <GttCardItem key={row.token} row={row} handleRowClick={handleRowClick} />)}
      <Drawer
        opened={opened}
        onClose={close}
        title="Asset Action"
      >
        <DrawerItems
          selectedCard={selectedCard}
          close={close}
          handleOrderDetailsModal={handleOrderDetailsModal}
        />
      </Drawer>
    </>
  )
}

export default GttCardContainer
