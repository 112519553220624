import { Box } from "@mantine/core"
import {
  RefObject,
  memo, useEffect, useMemo, useRef, useState,
} from "react"
import { DragDropContext, Droppable } from "react-beautiful-dnd"
import { useDispatch, useSelector } from "react-redux"
import { IWatchlist, IWatchlistItem } from "src/redux/sagas/watchlists"
import usePrevious from "@hooks/common/usePrevious"
import { isEqual } from "@utils/index"
import { showNotification } from "@mantine/notifications"
import { AppDispatch, RootState } from "@redux/store"
import { updateSequence, getWatchlistsSuccess } from "@redux/sagas/watchlists/watchlistsSlice"
import useWLEvents from "@hooks/events/useWLEvents"
import {
  selectSelectedWLIndex,
  // selectWLSearchData,
  selectWatchlistData,
} from "@redux/sagas/watchlists/watchlistSelectors"
import { selectFullSocket } from "@redux/sagas/sockets/socketSelectors"
import { useSubUnsub } from "@hooks/sockets"
import { WATCHLIST_PREFERENCE, WATCHLIST_DISPLAY_FORMATS } from "@constants/watchlist"
import WatchlistHeader from "./watchlistHeader"
import WatchlistFooter from "./watchlistFooter"
import useStyles from "./styled"
import WatchlistItemContainer from "./watchlistItemContainer"
import WatchlistZeroState from "../zeroState/watchlistZeroState"

type CustomInputRef = HTMLInputElement & { callFunction: () => void };

const Watchlist = () => {
  const { classes } = useStyles()
  const dispatch: AppDispatch = useDispatch()
  const searchRef = useRef<HTMLInputElement>(null)
  const ref = useRef(null)

  const container = document.createElement("div")
  document.body.appendChild(container)

  const watchlistsData = useSelector(selectWatchlistData)
  const selectedWLIndex = useSelector(selectSelectedWLIndex)
  const marketDepth = useSelector(selectFullSocket)
  const { addWLItem: addWLItemData } = useSelector((state: RootState) => state.watchlist);
  // const searchWatchlists = useSelector(selectWLSearchData);

  const prevProps = usePrevious({ addWLItemData })

  const selectedWatchlist = watchlistsData[selectedWLIndex || 0]
  // const [localWLsData, setLocalWLsData] = useState(watchlistsData)

  const handleFocusNull = () => {
    searchRef.current?.blur();
  }
  const selectedWLItemIndexRef = useRef(-1)

  const [selectedWLItemIndex, setSelectedWLItemIndex] = useState(-1)

  const handleWLItemIndex = (value: number) => {
    setSelectedWLItemIndex(value)
    selectedWLItemIndexRef.current = value
  }

  // handling the keyboard click events
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  useWLEvents({
    selectedWatchlist,
    handleWLItemIndex,
    selectedWLItemIndexRef,
  })

  // Watchlist display format
  useEffect(() => {
    const localStorageValue = localStorage.getItem(WATCHLIST_PREFERENCE)
    if (localStorageValue) {
      const data = JSON.parse(localStorageValue)
      dispatch({ type: "watchlist/updateDisplayFormat", payload: data.displayFormat })
    } else {
      const dataToSet = {
        displayFormat: WATCHLIST_DISPLAY_FORMATS[0].value,
      }
      localStorage.setItem(WATCHLIST_PREFERENCE, JSON.stringify(dataToSet))
    }
  }, [])

  const tokens = useMemo(
    () => watchlistsData[selectedWLIndex]?.items?.map((item: IWatchlistItem) => item.token),
    [watchlistsData, selectedWLIndex]
  );
  useSubUnsub(tokens, null)

  useEffect(() => {
    if (!isEqual(prevProps?.addWLItemData.isError, addWLItemData.isError) && addWLItemData.isError) {
      if (addWLItemData.errMessage !== "Duplicate scrip or sequence number") {
        showNotification({
          color: "red",
          title: 'Adding watchlist failed',
          message: addWLItemData.errMessage,
        })
      }
    }
  }, [prevProps, addWLItemData])

  const handleAddInstrument = () => {
    searchRef?.current?.focus()
  }

  const watchlistItems = watchlistsData?.map((watchlistItem: IWatchlist) => {
    if (watchlistItem?.id === selectedWatchlist?.id) {
      return watchlistItem.items.length > 0 ? (
        <Box key={watchlistItem.id} className={classes.watchlistItems}>
          {watchlistItem.items?.map((item: IWatchlistItem, index: number) => (
            <WatchlistItemContainer
              key={item.token}
              item={item}
              index={index}
              selectedWLItemIndex={selectedWLItemIndex}
              marketDepth={marketDepth?.token === item.token ? marketDepth : {}}
            />
          ))}
        </Box>
      ) : (
        <WatchlistZeroState key={watchlistItem.id} handleAddInstrument={handleAddInstrument} />
      )
    }
    return null
  })

  const reorder = (list: any, startIndex: number, endIndex: number) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const handleDragOrder = (destination: any, source: any) => {
    const reorderedSubItems = reorder(
      selectedWatchlist?.items,
      source.index,
      destination?.index
    )
    const reorderedWatchlistLength = reorderedSubItems.length
    const apiData = reorderedSubItems.reduce((acc: any, elem: any, index) => {
      acc[elem?.id] = reorderedWatchlistLength - index // or what ever object you want inside
      return acc
    }, {})
    dispatch(
      updateSequence({
        data: apiData,
        mwId: watchlistsData[selectedWLIndex]?.id,
      })
    )
    let newItems = watchlistsData
    newItems = newItems.map((item: any) => {
      const objCopy = { ...item }
      if (item.id === selectedWatchlist?.id) {
        objCopy.items = reorderedSubItems
      }
      return objCopy
    })
    // setLocalWLsData(newItems)
    dispatch(getWatchlistsSuccess({ data: newItems }))
  }

  const handleUnFocusWatchlist = () => {
    // @ts-ignore
    searchRef.current.callFunction();
  };
  useEffect(() => {
    function handleClickOutside(event) {
      // @ts-ignore
      if (ref.current && !ref.current.contains(event.target)) {
        handleUnFocusWatchlist()
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <Box
      id="wl-container"
      className={classes.watchlistContainer}
      ref={ref}
    >
      <WatchlistHeader
        ref={searchRef as RefObject<CustomInputRef>}
        handleFocusNull={handleFocusNull}
        handleWLItemIndex={handleWLItemIndex}
      />
      <div
        className={classes.scrollY}
      >
        <DragDropContext
          onDragEnd={({ destination, source }) => handleDragOrder(destination, source)}
        >
          <Droppable droppableId="dnd-list" direction="vertical">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {watchlistItems}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <WatchlistFooter />
    </Box>
  );
}

export default memo(Watchlist)
