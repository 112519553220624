import { call, put } from "redux-saga/effects"
import {
  filterErrorResponse,
  isLocalhost, removeCookiesOnLogout, setCookie,
} from "@utils/index"
import { clearLocalStorage } from "@redux/api/localStorage"
import type { IActionHandleLogin } from "@hooks/handleApiCall"
import { SUCCESS } from "@utils/constant"
import { IActionAppLoginGenToken } from "./action.types"
import {
  requestCaptcha,
  requestForgotPassword,
  requestLogin,
  requestLogoutUser,
  requestReloadCaptcha,
  requestResetPassword,
  requestValidateReset,
  requestValidate2fa,
  requestResendOtp,
  requestSetup2fa,
  requestAppLoginGenerateToken,
  requestValidateLoginOtp,
} from "./authRequests"
import {
  loginUserFailure,
  loginUserSuccess,
  validate2faSuccess,
  validate2faFailure,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  getCaptchaSuccess,
  getCaptchaFailure,
  reloadCaptchaSuccess,
  reloadCaptchaFailure,
  resetPasswordSuccess,
  resetPasswordFailure,
  validateResetSuccess,
  validateResetFailure,
  resendOtpSuccess,
  resendOtpFailure,
  logoutUserSuccess,
  logoutUserFailure,
  setup2faSuccess,
  setup2faFailure,
  appLoginGenerateTokenSuccess,
  appLoginGenerateTokenFailure,
  loginOtpSuccess,
  loginOtpFailure,
} from "./authSlice"
import { ISetup2faSuccess } from "."

const setLoginCookie = (data: any) => {
  setCookie("session", data.session)
  setCookie("token", data.token)
}

// login user
export function* handleLogin(action: IActionHandleLogin): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestLogin, action.payload)
    if (isLocalhost()) {
      setLoginCookie(data)
    }
    yield put(loginUserSuccess({ data, status }))
  } catch (error:any) {
    yield put(
      loginUserFailure(filterErrorResponse(error))
    )
  }
}

// resend otp
export function* handleResendOtp(action: any): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestResendOtp, action.payload)
    yield put(resendOtpSuccess({ data, status }))
  } catch (error:any) {
    yield put(
      resendOtpFailure(filterErrorResponse(error))
    )
  }
}

// validate 2fa
export function* handleValidate2fa(action: any): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestValidate2fa, action.payload)
    if (data.redirectUrl) {
      window.location.href = data.redirectUrl
      return
    }
    if (isLocalhost()) {
      setLoginCookie(data)
    }
    yield put(validate2faSuccess({ data, status }))
  } catch (error: any) {
    yield put(validate2faFailure(filterErrorResponse(error)))
  }
}

// validate login otp
export function* handleValidateLoginOtp(action: any): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestValidateLoginOtp, action.payload)
    if (data.redirectUrl) {
      window.location.href = data.redirectUrl
      return
    }
    if (isLocalhost()) {
      setLoginCookie(data)
    }
    yield put(loginOtpSuccess({ data, status }))
  } catch (error: any) {
    yield put(loginOtpFailure(filterErrorResponse(error)))
  }
}

// forgot user
export function* handleForgotPassword(action: any): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestForgotPassword, action.payload)
    yield put(forgotPasswordSuccess({ data, status }))
  } catch (error: any) {
    yield put(
      forgotPasswordFailure(filterErrorResponse(error))
    )
  }
}

// get captch
export function* handleCaptcha(): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestCaptcha)
    yield put(getCaptchaSuccess({ data, status }))
  } catch (error: any) {
    yield put(getCaptchaFailure(filterErrorResponse(error)))
  }
}

// handle reload captcha
export function* handleReloadCaptcha(action: any): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestReloadCaptcha, { id: action.payload })
    yield put(reloadCaptchaSuccess({ data, status }))
  } catch (error: any) {
    yield put(reloadCaptchaFailure(filterErrorResponse(error)))
  }
}

// handle reset password
export function* handleResetPassword(action: any): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestResetPassword, action.payload)
    yield put(resetPasswordSuccess({ data, status }))
  } catch (error: any) {
    yield put(
      resetPasswordFailure(filterErrorResponse(error))
    )
  }
}

// handle validate reset
export function* handleValidateReset(action: any): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestValidateReset, action.payload)
    yield put(
      validateResetSuccess({ data, status })
    )
  } catch (error: any) {
    yield put(
      validateResetFailure(filterErrorResponse(error))
    )
  }
}

// logout user
export function* handleLogoutUser(action: any): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestLogoutUser, action.payload)
    removeCookiesOnLogout()
    clearLocalStorage()
    yield put(logoutUserSuccess({ data, status }))
  } catch (error:any) {
    yield put(
      logoutUserFailure(filterErrorResponse(error))
    )
  }
}

//  2fa setup
export function* handleSetup2fa(action: any): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestSetup2fa, action.payload)
    yield put(setup2faSuccess({ data, status }))
    const responseData:ISetup2faSuccess = data
    if (isLocalhost()) {
      setLoginCookie(responseData)
    }
  } catch (error: any) {
    yield put(
      setup2faFailure(filterErrorResponse(error))
    )
  }
}

export function* handleAppLoginGenerateToken(action: IActionAppLoginGenToken): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestAppLoginGenerateToken, action.payload)
    if (status === SUCCESS) {
      yield put(appLoginGenerateTokenSuccess({ data, status }))
    }
  } catch (error: any) {
    yield put(appLoginGenerateTokenFailure(filterErrorResponse(error)))
  }
}
