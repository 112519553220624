import { notifications } from "@mantine/notifications";
import { Text } from "@mantine/core";

type IOrderStatus =
  | "PENDING"
  | "CANCELED"
  | "OPEN"
  | "REJECTED"
  | "COMPLETE"
  | "INVALID_STATUS_TYPE";

interface IParsedData {
  id: string;
  status: IOrderStatus;
  type: string;
  userId: string;
  qty: string;
  price: string;
  product: string;
  remarks: string;
  reason: string;
  reportType: string;
  order: string;
  transactionType: string;
  exchange: string;
  symbol: string;
  avgPrice: string;
  fillQty: string;
}

const useNotification = () => {
  const getStatus = (status: IOrderStatus, reportType: string) => {
    if (status === "OPEN") {
      if (reportType === "Fill") {
        return "Partially Executed";
      }
      return "PLACED";
    } return status;
  };
  const getColor = (status: IOrderStatus, reportType: string) => {
    switch (status) {
      case "REJECTED":
      case "CANCELED":
      case "INVALID_STATUS_TYPE":
        return "red";
      case "PENDING":
        return "blue";
      case "OPEN":
        if (reportType === "Fill") {
          return "orange";
        }
        return "blue";
      case "COMPLETE":
        return "green";
      default:
        return "blue";
    }
  };

  const createNotificationBody = (parsedData: IParsedData) => {
    const { status, reportType } = parsedData;
    const isPartiallyExecutedOrder = reportType === "Fill";
    return (
      <>
        <Text>
          {parsedData.transactionType === "B" ? "BUY" : "SELL"}
          {" "}
          {isPartiallyExecutedOrder ? parsedData.fillQty : parsedData.qty}
          {' '}
          x
          {' '}
          {parsedData.symbol}
          {' '}
          {isPartiallyExecutedOrder ? "" : "is"}
          {" "}
          {isPartiallyExecutedOrder ? "" : getStatus(status, reportType).toLocaleLowerCase()}
          .
        </Text>
        <Text>{parsedData.reason}</Text>
        <Text>
          #
          {parsedData.id}
        </Text>
      </>
    )
  }

  const updateNotificationById = (parsedData) => {
    notifications.update({
      id: parsedData.id,
      color: getColor(parsedData.status, parsedData.reportType),
      title: getStatus(parsedData.status, parsedData.reportType),
      message: createNotificationBody(parsedData),
      icon: null,
    });
  };

  const notify = (parsedData: IParsedData) => {
    const { status, reportType } = parsedData;
    notifications.show({
      id: parsedData.id,
      color: getColor(status, reportType),
      title: getStatus(status, parsedData.reportType),
      message: createNotificationBody(parsedData),
      icon: null,
    });
    updateNotificationById(parsedData);
  };

  return { notify };
};

export default useNotification;
