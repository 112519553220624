const errorHandlerOld = (error: any): string => {
  const { request, response } = error
  if (response) {
    const message = response?.data?.message
    // const status = response.status
    return message
  } if (request) {
    // request sent but no response received
    const message = "Server timed out";
    return message
    // status: 503,
  }
  // Something happened in setting up the request that triggered an Error
  const message = "Server timed out"
  return message
}

const errorHandler = (error: any): { message: string, status: number } => {
  const { request, response } = error
  if (response) {
    const message = response?.data?.message
    const { status } = response
    return { message, status }
  } if (request) {
    // request sent but no response received
    const message = "Server timed out";
    const status = request.status || 503
    return { message, status }
  }
  // Something happened in setting up the request that triggered an Error
  const message = "Server timed out"
  const status = 408
  return { message, status }
}

export {
  errorHandlerOld,
  errorHandler,
}
