import { Flex } from '@uikit';
import { Text, useMantineTheme, Badge } from '@mantine/core';
import { instrumentSearch } from '@utils/index';
import { IExtendedPositionItem, IPositionItem } from '@redux/sagas/positions';
import FormattedName from '@components/watchlist/formattedName';
import PositionDropdown from '@containers/positions/positionDropdown';
import { productOptionsDisplay } from "@utils/constant";

interface ITableInstrumentItem {
  row: IPositionItem,
  width?: number
  handlePositionDetails: (item: IExtendedPositionItem) => void
  handleConvertPosition: (item: IExtendedPositionItem) => void
  showDisabledState?: boolean
}

const PositionInstrument = ({
  row,
  width = 220,
  handlePositionDetails,
  handleConvertPosition,
  showDisabledState,
}: ITableInstrumentItem) => {
  const theme = useMantineTheme()
  const { symbol, exchange } = row;
  const instrumentName = instrumentSearch.tokenMap[row.token]
  const segment = instrumentSearch.getSegment(instrumentName, exchange);
  const parsedSymbolName = instrumentName || symbol
  const parsedSymbolObj = instrumentSearch.get(parsedSymbolName, segment, exchange)
  const isStockHolding = row.product === productOptionsDisplay.LONGTERM
  const isStockHoldingSold = isStockHolding && row.qty <= 0

  return (
    <div
      style={{ width: `${width}px` }}
      className="holding-intrument"
    >
      <Flex align="baseline">
        <FormattedName
          name={parsedSymbolObj.niceName}
          expiryDay={parsedSymbolObj.expiryDay}
          isWeekly={parsedSymbolObj.isWeekly}
        />
        <Text size={10} ml={3} color={theme.other.colors.fg.muted}>
          {row.exchange}
        </Text>
        {
          isStockHolding && (
          <Badge
            size="xs"
            radius="xs"
            color={isStockHoldingSold ? "gray" : "teal"}
            ml={6}
            opacity={isStockHoldingSold ? 0.6 : 1}
          >
            {isStockHoldingSold ? 'Sold Holding' : 'Holding'}
          </Badge>
          )
        }
      </Flex>
      {
        showDisabledState ? (
          <PositionDropdown
            row={row}
            handlePositionDetails={handlePositionDetails}
            handleConvertPosition={handleConvertPosition}
          />
        ) : null
      }
    </div>
  )
}

export default PositionInstrument
