import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { ActionSuccess, ActionFailure } from "@redux/types"
import {
  IBracketOrderPayload,
  IConvertOrderErr,
  IConvertOrderRes,
  IOrderRes,
  IRegularOrderPayload,
  IRegularOrderRes,
  TradesSlice,
  IFreezeQtyPayload,
} from "."

export const chargesInitialData = {
  brokerage: 0,
  sebiCharges: 0,
  exchangeTxnFee: 0,
  stampDuty: 0,
  transactionTax: 0,
  ipft: 0,
  gst: {
    cgst: 0, sgst: 0, igst: 0, total: 0,
  },
  total: 0,
}

const tradeModalInitialData = {
  quantity: 0,
  price: 0,
  orderType: "MKT",
  product: "I",
  validity: "DAY",
  ltp: 0,
  exchange: "NSE",
  token: 0,
  tradingSymbol: "",
  lotSize: 0,
  niceName: "",
  expiryDay: 0,
  isWeekly: false,
  symbol: "",
  amo: "",
}

export const processOrderInitialState = {
  selectedToken: 0,
  processOrder: 0,
}

const initialState: TradesSlice = {
  regularOrder: {
    loading: false,
    status: '',
    data: null,
  },
  editRegular: {
    loading: false,
    status: '',
    data: null,
  },
  orderMargin: {
    loading: false,
    status: '',
    data: null,
  },
  coverOrder: {
    loading: false,
    status: '',
    data: null,
  },
  editCover: {
    loading: false,
    status: '',
    data: null,
  },
  bracketOrder: {
    loading: false,
    status: '',
    data: null,
  },
  editBracket: {
    loading: false,
    status: '',
    data: null,
  },
  convertOrder: {
    loading: false,
    status: '',
    data: null,
  },
  // TODO
  tradeModalInfo: {
    currentAssetItem: tradeModalInitialData,
    showTradeModal: false,
    buySellToggle: false,
  },
  chargesModal: {
    showModal: false,
    data: chargesInitialData,
  },
  processOrderInfo: processOrderInitialState,
  freezeQty: {
    loading: false,
    status: '',
    data: null,
  },
}

export const tradeSlice = createSlice({
  name: "trade",
  initialState,
  reducers: {
    regularOrder: (state, action:PayloadAction<Array<IRegularOrderPayload>>) => {
      state.regularOrder.loading = true;
      state.regularOrder.status = "";
    },
    regularOrderUpdate: (state, action) => {
      const { payload } = action
      state.regularOrder = {
        ...state.regularOrder,
        data: { ...state.regularOrder.data, ...payload.data },
      }
    },
    regularOrderSuccess: (state, action:ActionSuccess<IRegularOrderRes>) => {
      const { payload } = action;
      state.regularOrder = {
        loading: false,
        status: payload.status,
        data: { ...state.regularOrder.data, ...payload.data },
      };
    },
    regularOrderFailure: (state, action:ActionFailure<{}>) => {
      const { payload } = action;
      state.regularOrder = {
        ...state.regularOrder,
        loading: false,
        status: payload.status,
        errorData: payload.errorData,
        message: payload.message,
        errorCode: payload.errorCode,
      };
    },

    editRegularOrder: (state, action) => {
      state.editRegular.loading = true;
      state.editRegular.status = "";
    },
    editRegularOrderSuccess: (state, action:ActionSuccess<IOrderRes>) => {
      const { payload } = action;
      state.editRegular = {
        loading: false,
        status: payload.status,
        data: payload.data,
      };
    },
    editRegularOrderFailure: (state, action:ActionFailure<{}>) => {
      const { payload } = action;
      state.editRegular = {
        ...state.editRegular,
        loading: false,
        status: payload.status,
        errorData: payload.errorData,
        message: payload.message,
        errorCode: payload.errorCode,
      };
    },

    orderMargin: (state, action) => {
      state.orderMargin.loading = true;
      state.orderMargin.status = "";
    },
    orderMarginSuccess: (state, action) => {
      const { payload } = action;
      state.orderMargin = {
        loading: false,
        status: payload.status,
        data: payload.data,
      };
    },
    orderMarginFailure: (state, action:ActionFailure<{}>) => {
      const { payload } = action;
      state.orderMargin = {
        ...state.orderMargin,
        loading: false,
        status: payload.status,
        errorData: payload.errorData,
        message: payload.message,
        errorCode: payload.errorCode,
      };
    },
    clearOrderMargin: (state) => {
      state.orderMargin = {
        loading: false,
        status: "",
        data: null,
      };
    },
    coverOrder: (state, action) => {
      state.coverOrder.loading = true;
      state.coverOrder.status = "";
    },
    coverOrderSuccess: (state, action:ActionSuccess<IOrderRes>) => {
      const { payload } = action;
      state.coverOrder = {
        loading: false,
        status: payload.status,
        data: payload.data,
      };
    },
    coverOrderFailure: (state, action:ActionFailure<{}>) => {
      const { payload } = action;
      state.coverOrder = {
        ...state.coverOrder,
        loading: false,
        status: payload.status,
        errorData: payload.errorData,
        message: payload.message,
        errorCode: payload.errorCode,
      };
    },

    editCoverOrder: (state, action) => {
      state.editCover.loading = true;
      state.editCover.status = "";
    },
    editCoverOrderSuccess: (state, action:ActionSuccess<IOrderRes>) => {
      const { payload } = action;
      state.editCover = {
        loading: false,
        status: payload.status,
        data: payload.data,
      };
    },
    editCoverOrderFailure: (state, action:ActionFailure<{}>) => {
      const { payload } = action;
      state.editCover = {
        ...state.editCover,
        loading: false,
        status: payload.status,
        errorData: payload.errorData,
        message: payload.message,
        errorCode: payload.errorCode,
      };
    },

    convertPostionOrder: (state, action) => {
      state.convertOrder.loading = true;
      state.convertOrder.status = "";
    },
    convertPostionOrderSuccess: (state, action:ActionSuccess<IConvertOrderRes>) => {
      const { payload } = action;
      state.convertOrder = {
        loading: false,
        status: payload.status,
        data: payload.data,
      };
    },
    convertPostionOrderFailure: (state, action:ActionFailure<IConvertOrderErr>) => {
      const { payload } = action;
      state.convertOrder = {
        ...state.convertOrder,
        loading: false,
        status: payload.status,
        errorData: payload.errorData,
        message: payload.message,
        errorCode: payload.errorCode,
      };
    },

    bracketOrder: (state, action:PayloadAction<IBracketOrderPayload>) => {
      state.bracketOrder.loading = true;
      state.bracketOrder.status = "";
    },
    bracketOrderSuccess: (state, action:ActionSuccess<IOrderRes>) => {
      const { payload } = action;
      state.bracketOrder = {
        loading: false,
        status: payload.status,
        data: payload.data,
      };
    },
    bracketOrderFailure: (state, action:ActionFailure<{}>) => {
      const { payload } = action;
      state.bracketOrder = {
        ...state.bracketOrder,
        loading: false,
        status: payload.status,
        errorData: payload.errorData,
        message: payload.message,
        errorCode: payload.errorCode,
      };
    },

    editBracketOrder: (state, action) => {
      state.editBracket.loading = true;
      state.editBracket.status = "";
    },
    editBracketOrderSuccess: (state, action:ActionSuccess<IOrderRes>) => {
      const { payload } = action;
      state.editBracket = {
        loading: false,
        status: payload.status,
        data: payload.data,
      };
    },
    editBracketOrderFailure: (state, action:ActionFailure<{}>) => {
      const { payload } = action;
      state.editBracket = {
        ...state.editBracket,
        loading: false,
        status: payload.status,
        errorData: payload.errorData,
        message: payload.message,
        errorCode: payload.errorCode,
      };
    },

    // TODO check kar
    setTradeModalInfo: (state, action) => {
      state.tradeModalInfo = action.payload
    },
    setChargesModalInfo: (state, action) => {
      state.chargesModal = action.payload
    },
    setProceedOrder: (state, action) => {
      state.processOrderInfo = action.payload
    },
    getFreezeQty: (state, action:PayloadAction<IFreezeQtyPayload>) => {
      state.freezeQty.loading = true
      state.freezeQty.status = ""
    },
    getFreezeQtySuccess: (state, action) => {
      state.freezeQty = {
        loading: false,
        status: action.payload.status,
        data: action.payload.data,
      }
    },
    getFreezeQtyFailure: (state, action) => {
      state.freezeQty = {
        ...state.freezeQty,
        loading: false,
        status: action.payload.status,
        errorData: action.payload.errorData,
        message: action.payload.message,
        errorCode: action.payload.errorCode,
      }
    },
    resetFreezeQty: (state) => {
      state.freezeQty = {
        loading: false,
        status: "",
        data: null,
      }
    },
    resetTradesSlice: () => initialState,
  },
})

// Action
export const {
  regularOrder,
  regularOrderUpdate,
  regularOrderSuccess,
  regularOrderFailure,
  editRegularOrder,
  editRegularOrderSuccess,
  editRegularOrderFailure,
  orderMargin,
  orderMarginSuccess,
  orderMarginFailure,
  clearOrderMargin,
  coverOrder,
  coverOrderSuccess,
  coverOrderFailure,
  editCoverOrder,
  editCoverOrderSuccess,
  editCoverOrderFailure,
  setTradeModalInfo,
  setChargesModalInfo,
  setProceedOrder,
  resetTradesSlice,

  convertPostionOrder,
  convertPostionOrderSuccess,
  convertPostionOrderFailure,

  bracketOrder,
  bracketOrderSuccess,
  bracketOrderFailure,
  editBracketOrder,
  editBracketOrderSuccess,
  editBracketOrderFailure,

  getFreezeQty,
  getFreezeQtySuccess,
  getFreezeQtyFailure,
  resetFreezeQty,
} = tradeSlice.actions

export default tradeSlice.reducer
