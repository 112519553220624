import { setGttDeleteModalInfo } from '@redux/sagas/misc/miscSlice';
import { IGttOrderDetail } from '@redux/sagas/orders';
import { useDispatch } from 'react-redux';

const useDeleteGtt = () => {
  const dispatch = useDispatch();

  const handleDeleteGttOrder = (currentItem: IGttOrderDetail) => {
    if (currentItem) {
      dispatch(setGttDeleteModalInfo({
        alertId: currentItem.alertID,
        showDeleteGttModal: true,
        gttOrderDetails: currentItem,
      }))
    }
  };

  return { handleDeleteGttOrder }
}

export default useDeleteGtt;
