import { productType } from "@utils/constant"
import { TransactionType } from "src/constants/common"
import { parsedSymbol } from "@utils/index"
import FormattedName from "@components/watchlist/formattedName"
import { Flex, Text, Badge } from "@uikit"
import { BuySellConst } from "@constants"
import useStyles from "./tradesCard.styles"
import { ITradesCardItemProps } from "./tradesCard.types"

const TradesCardItem = ({ row, handleRowClick }: ITradesCardItemProps) => {
  const { classes } = useStyles();

  const { symbol, exchange } = row;
  const parsedSymbolObj = parsedSymbol(symbol, exchange, row.token);

  const handleRowItemClick = () => {
    handleRowClick(row)
  }

  return (
    <Flex gap={8} className={classes.executedCardItem} onClick={handleRowItemClick}>
      <Flex direction="column" gap={4}>
        <Flex justify="space-between" align="center">
          {parsedSymbolObj && (
          <FormattedName
            name={parsedSymbolObj.niceName}
            expiryDay={parsedSymbolObj.expiryDay}
            isWeekly={parsedSymbolObj.isWeekly}
            className="content_sm_medium"
          />
          )}
          <Text className="content_sm_medium">{row.averagePrice.toFixed(2) || '0.00'}</Text>
        </Flex>

        <Flex justify="space-between">
          <Flex gap={4}>
            <Text className="content_2xs_regular text_fg_subtle">{row.exchange}</Text>
            <Text className="content_2xs_regular text_fg_subtle">{productType[row.product]}</Text>
          </Flex>
          <Flex gap={4}>
            <Text className="content_2xs_regular text_fg_muted">Trade ID</Text>
            <Text className="content_2xs_regular text_fg_default">{row.id}</Text>
          </Flex>
        </Flex>
      </Flex>

      <Flex justify="space-between">
        <Flex gap={4}>
          <Badge size="md" radius="xs" color={row.transactionType === TransactionType.B ? "blue" : "red"}>
            {BuySellConst[row.transactionType]}
          </Badge>
          {/* TODO: need to discuss with abhishek */}
          <Text className="content_xs_regular">{`0 / ${row.quantity}`}</Text>
        </Flex>
        <Flex gap={4}>
          <Text className="content_2xs_regular text_fg_muted">Fill Time</Text>
          <Text className="content_2xs_regular">{row.timeStamp.slice(0, 9)}</Text>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default TradesCardItem
