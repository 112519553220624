import { createStyles, rem } from "@mantine/core";
import { getTypographyStyles } from "@theme/themeConfig"

const useStyles = createStyles((theme) => {
  const { colors } = theme.other
  return {
    header: {
      zIndex: 100,
      boxShadow:
        theme.colorScheme === "dark"
          ? "0 1px 5px 0 #0d0d0d"
          : "0 1px 5px 0 rgba(0, 0, 0, 0.1)",
      margin: "0 auto",
      width: "100%",
      [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
        padding: "0 10px",
      },
    },
    headerInner: {
      margin: "0 auto",
      display: "flex",
      alignItems: "center",
      [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
        maxWidth: rem(theme.other.screenWidth),
      },
      height: '100%',
    },
    watchlistHeaderWrapper: {
      width: rem(theme.other.watchlistWidth),
      fontSize: 12,
      fontWeight: 600,
      height: rem(theme.other.navHeight),
    },
    inner: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: `calc(100% - ${theme.other.watchlistWidth}px)`,
      padding: "0 30px",
      [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
        padding: "0 12px",
      },
      [`@media (max-width: ${theme.breakpoints.md}px)`]: {
        padding: "0 12px",
        width: "100%",
      },
    },
    search: {
      [theme.fn.smallerThan("xs")]: {
        display: "none",
      },
    },
    link: {
      display: 'flex',
      padding: `0 ${rem(1)}`,
      textDecoration: 'none',
      cursor: 'pointer',
      color: colors.fg.muted,
      height: rem(56),
      justifyContent: 'center',
      alignItems: 'center',
      border: '2px solid transparent',
      ...getTypographyStyles('heading_2xs_regular'),
      '&:hover': {},
      [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
        padding: `0 ${rem(12)}`,
      },
      [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
        padding: `0 ${rem(4)}`,
      },
      [`@media (max-width: 330px`]: {
        padding: `0 ${rem(1)}`,
      },
    },
    linkActive: {
      display: 'flex',
      padding: `0 ${rem(1)}`,
      textDecoration: 'none',
      cursor: 'pointer',
      height: rem(56),
      justifyContent: 'center',
      alignItems: 'center',
      color: colors.primary.default,
      borderBottom: `2px solid ${colors.primary.default}`,
      ...getTypographyStyles('heading_2xs_semibold'),
      '&:hover': {
      },
      [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
        padding: `0 ${rem(12)}`,
      },
      [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
        padding: `0 ${rem(4)}`,
      },
      [`@media (max-width: 330px`]: {
        padding: `0 ${rem(1)}`,
      },

    },
    navItemsContainer: {
      gap: 5,
      display: "flex",
      justifyContent: "space-between",
    },
    ltp: {
      minWidth: 25,
      fontVariantNumeric: "tabular-nums",
      fontSize: 12,
    },
    absoluteChange: {
      fontSize: 10,
    },
    netChange: {
      fontSize: 10,
    },
    avatarImg: {
      borderRadius: "50%",
      objectFit: "cover",
    },
    algoBtnLeftIcon: {
      paddingTop: 4,
    },
    menuTarget: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
    },
    uccMobileBtn: {
      borderBottom: `1px solid ${colors.disabled.subtle}`,
      borderRadius: 0,
    },
  }
});

export default useStyles;
