import optionChain from "@assets/images/optionChain.webp"
import fatafat from "@assets/images/fatafat.webp"
import algo from "@assets/images/algo.webp"

const algoUrl = process.env.REACT_APP_APPLOGIN_URL
const fatafatUrl = process.env.REACT_APP_FATAFAT_URL
const optionUrl = process.env.REACT_APP_OPTION_URL

const exploreCards = [
  {
    title: "Algo Trading",
    description: "Unlock the full power of algo trading with Tiqs!",
    img: algo,
    imgAlt: "Algo",
    imgBgClass: "yellowGradient",
    url: algoUrl,
  },
  {
    title: "Fatafat",
    description: "Trail your stop loss and target with a simple click!",
    img: fatafat,
    imgAlt: "Fatafat",
    imgBgClass: "greenGradient",
    url: fatafatUrl,
  },
  {
    title: "Option Chain",
    description: "Analyse the option chain, construct baskets, and execute your trades!",
    img: optionChain,
    imgAlt: "Option chain",
    imgBgClass: "blueGradient",
    url: optionUrl,
  },
]

const cards = [
  {
    id: 1,
    title: "Earn Now",
    heading: "Referral scheme",
    description: "Refer your friends and earn 30% of their brokerage as bonus!",
  },
  // {
  //   id: 2,
  //   title: "Trade Now",
  //   heading: "Fatafat Scalping",
  //   description: "Trail your stop loss and target with a simple click!",
  // },
  // {
  //   id: 3,
  //   title: "Algos Now",
  //   heading: "Algos for everyone",
  //   description: "Unlock the full power of Algo trading!",
  // },
];

export { exploreCards, cards }
