import {
  Box,
  Button,
  Grid,
  Loader,
} from "@mantine/core"
import { ChartPie, Wallet, ExternalLink } from "tabler-icons-react"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Flex } from '@uikit';
import { getUserLimits } from "@redux/sagas/users/usersSlice"
import { IUserLimit } from "@redux/sagas/users"
import PlainCard from "@components/plainCard"
import useMediaCustom from "src/hooks/common/useMediaCustom";
import useRouteChange from "@hooks/common/useRouteChange"
import ErrorElement from "@components/errorElement"
import ZeroState from '@components/zeroState'
import { RootState } from "@redux/store"
import { FAILURE } from "@utils/constant"
import RenderBoundary from "@components/renderBoundary/renderBoundary"
import { handleAddFunds } from "@utils/redirection";
import ErrorBoundary from "../../ErrorBoundary"

import {
  bounceAnimation, formatToINR,
} from "../../utils"
import useStyles from "./styles";

const HUB_FUNDS_URL = process.env.REACT_APP_HUB_URL

const PrimaryCard = ({
  title,
  value,
  isLargeScreen,
}: {
  title: string;
  value: string | number;
  isLargeScreen: boolean
}) => {
  const { classes } = useStyles()
  return (
    <Flex
      className={classes.primaryCard}
      direction={isLargeScreen ? "column" : "row"}
      align="flex-start"
    >
      <p className="heading_xs_regular text_fg_subtle">{title}</p>
      <p className="content_xl_regular">{value}</p>
    </Flex>
  );
}

const SecondaryCard = ({
  title,
  value,
}: {
  title: string;
  value: string | number;
}) => {
  const { isLargeScreen } = useMediaCustom();
  return (
    <Flex justify="space-between" mr={16} my={12} ml={isLargeScreen ? 16 : 0}>
      <p className="heading_sm_regular text_fg_subtle">{title}</p>
      <p className="content_lg_regular">{value || 'N/A'}</p>
    </Flex>
  );
}

const FundsCardContent = ({ data }: { data: IUserLimit | [] }) => {
  const { isLargeScreen } = useMediaCustom();
  const { classes } = useStyles()
  const {
    cash = 0, marginUsed = 0, payIn = 0, payOut = 0, dayCash = 0, collateral = 0,
  } = data as IUserLimit;

  const totalCash = Number(cash) + Number(dayCash) + Number(payIn) - Number(payOut)
  const marginAvailable = totalCash - Number(marginUsed) + Number(collateral)

  return (
    <Flex
      pt={12}
      sx={{ justifyContent: "space-between" }}
      direction={isLargeScreen ? "row" : "column"}
    >
      <Flex className={isLargeScreen ? '' : classes.fullWidth}>
        {isLargeScreen ? (
          <div className={classes.walletIconWrapper}>
            <Wallet className="text_fg_subtle" size={26} />
          </div>
        ) : null}
        <PrimaryCard
          title="Margin Available"
          value={formatToINR(Number(marginAvailable))}
          isLargeScreen={isLargeScreen}
        />
      </Flex>
      <PrimaryCard
        title="Margin Used"
        value={formatToINR(Number(marginUsed))}
        isLargeScreen={isLargeScreen}
      />
      <PrimaryCard
        title="Total Cash"
        value={formatToINR(Number(totalCash))}
        isLargeScreen={isLargeScreen}
      />
    </Flex>
  );
}

const EquityFundsCardContent = ({ data }: { data: IUserLimit | [] }) => {
  const { classes } = useStyles()
  const {
    payIn,
    payOut,
    premium,
    deliveryMargin,
    exposure,
    span,
    collateral,
    liquidCollateralAmount,
    equityCollateralAmount,
  } = data as IUserLimit
  const { isLargeScreen } = useMediaCustom();
  return (
    <Grid className={classes.eqFundsContent}>
      <Grid.Col span={isLargeScreen ? 7 : 12}>
        <div>
          <Flex justify="space-between" mr={12} my={12}>
            <p className="heading_sm_semibold">Opening Balance</p>
            <p className="heading_sm_semibold">N/A</p>
          </Flex>
          <div className={classes.border} />
          <Flex justify="space-between" mr={12} my={12}>
            <p className="heading_xs_regular">Pay-in</p>
            <p className="content_md_regular">
              {formatToINR(Number(payIn))}
            </p>
          </Flex>
          <Flex justify="space-between" mr={12} mt={12} mb={48}>
            <p className="heading_xs_regular">Pay-out</p>
            <p className="content_md_regular">
              {formatToINR(Number(payOut))}
            </p>
          </Flex>
        </div>
        <div>
          <Flex justify="space-between" mr={12} my={12}>
            <p className="heading_sm_semibold">Total Collateral</p>
            <p className="heading_sm_semibold">{formatToINR(Number(collateral))}</p>
          </Flex>
          <div className={classes.border} />
          <Flex justify="space-between" mr={12} my={12}>
            <p className="heading_xs_regular">Liquid Collateral</p>
            <p className="content_md_regular">{formatToINR(Number(liquidCollateralAmount))}</p>
          </Flex>
          <Flex justify="space-between" mr={12} my={12}>
            <p className="heading_xs_regular">Equity Collateral</p>
            <p className="content_md_regular">{formatToINR(Number(equityCollateralAmount))}</p>
          </Flex>
        </div>
      </Grid.Col>
      <Grid.Col className={classes.eqFundsContentLeft} span={isLargeScreen ? 5 : 12}>
        <SecondaryCard title="Option Premium" value={premium} />
        <SecondaryCard title="Exposure" value={exposure} />
        <SecondaryCard title="Span" value={span} />
        <SecondaryCard title="Delivery Margin" value={deliveryMargin} />
      </Grid.Col>
    </Grid>
  );
}
// Main Component
const Funds = () => {
  const { classes, cx } = useStyles()
  const dispatch = useDispatch()
  const { isNavigateLoading } = useRouteChange()
  const { isMobile } = useMediaCustom()

  const limitsState = useSelector((state: RootState) => state.user.limits)
  const { status, loading, data } = limitsState;
  const isLimitsData = !!(data && data.length > 0);
  const isError = status === FAILURE;

  useEffect(() => {
    dispatch(getUserLimits())
  }, [dispatch])

  const secondLoading = loading && isNavigateLoading && isLimitsData;

  return (
    <Box className={classes.wrapper} sx={secondLoading ? bounceAnimation : {}}>
      <RenderBoundary
        loading={loading}
        status={status}
        data={data}
        errorState={(
          <ErrorElement component="Funds" message={isError && limitsState.message} />
        )}
      >
        {
          isLimitsData ? (
            <ErrorBoundary>
              {/* Funds Card */}
              <PlainCard
                headerClassName={classes.fundsCardHeader}
                leftContent={(
                  <Flex>
                    <p className={cx("cardTitle", classes.cardTitleFunds)}>
                      {" "}
                      Funds
                    </p>
                    {secondLoading ? <Loader size="sm" ml="md" /> : null}
                  </Flex>
                )}
                rightContent={(
                  <Flex direction={isMobile ? "column" : "row"}>
                    <Button
                      mr={isMobile ? 0 : 12}
                      mb={isMobile ? 12 : 0}
                      className="bg_primary_default"
                      variant="filled"
                      onClick={handleAddFunds}
                      fullWidth={isMobile}
                    >
                      Add Funds
                    </Button>
                    <Button
                      component="a"
                      href={`${HUB_FUNDS_URL}/funds/withdrawal`}
                      variant="outline"
                      target="_blank"
                      rel="noreferrer"
                      leftIcon={<ExternalLink size="0.9rem" />}
                      fullWidth={isMobile}
                    >
                      Withdraw
                    </Button>
                  </Flex>
                )}
                mainContent={<FundsCardContent data={data[0]} />}
              />

              {/* Equity Funds Card */}
              <PlainCard
                className={classes.equityFundsCardWrapper}
                leftContent={<p className="cardTitle">Equity Funds</p>}
                mainContent={<EquityFundsCardContent data={data[0]} />}
              />
            </ErrorBoundary>
          ) : (<ZeroState title="Equity" icon={<ChartPie size={18} />} />)
        }

      </RenderBoundary>
    </Box>
  );
}

export default Funds
