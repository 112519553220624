import { Modal, createStyles } from "@mantine/core";
import { INoticesRes } from "@redux/sagas/users";
import Banner from "./Banner";

const useStyles = createStyles(() => ({
  bannerWrapper: {
    marginBottom: 16,
  },
}))

interface INoticesModal {
  opened: boolean,
  close: () => void,
  data: INoticesRes[]
}

const NoticesModal = ({ opened, close, data }: INoticesModal) => {
  const { classes } = useStyles()

  return (
    <Modal opened={opened} onClose={close} title="Notice" size="xl">
      <div>
        {data.map((item: INoticesRes) => (
          <Banner
            key={item.id}
            title={item.message}
            viewMore={false}
            className={classes.bannerWrapper}
            type={item.type}
            id={item.id}
          />
        ))}
      </div>
    </Modal>
  );
}

export default NoticesModal
