import { useRef } from "react";
import { isEqual } from "@utils/index";

function useDeepCompareMemoize(value) {
  const ref = useRef();
  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }
  return ref.current;
}

export default useDeepCompareMemoize
