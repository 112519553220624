import { useState, useEffect } from 'react';
import {
  Flex, Modal, Skeleton, Text,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import useStyles from './styles';

const TUTORIAL_GIF_URL = "https://assets.tiqs.in/gifs/app-code-tutorial.gif";

const TutorialModal = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const { classes, cx } = useStyles();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const img = new Image();
    img.src = TUTORIAL_GIF_URL;
    img.onload = () => setLoading(false);
  }, []);

  return (
    <>
      <Modal opened={opened} onClose={close} title="App code on Tiqs mobile app">
        <div className={classes.tutorialImageWrapper}>
          {loading ? (
            <Skeleton height={600} width={300} radius="sm" m="auto" />
          ) : (
            <img src={TUTORIAL_GIF_URL} alt="Tutorial GIF" className={classes.tutorialImage} />
          )}
        </div>
      </Modal>

      <Flex justify="left">
        <Text
          className={cx('content_sm_medium text_primary_default', classes.textLink)}
          onClick={open}
        >
          Not able to find App code? Watch Video
          {' '}
          {'>'}
        </Text>
      </Flex>
    </>
  );
};

export default TutorialModal;
