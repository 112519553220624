/* eslint-disable import/prefer-default-export */
import { getCookie } from "@utils/index"

const colorScheme = getCookie("tiqs-theme")

const handleAddFunds = () => {
  const url = process.env.REACT_APP_FUNDS_URL
  window.open(
    `${url}/add-funds?theme=${colorScheme}`,
    '_blank',
    `left=${window.screen.width / 2 - 550 / 2},top=50,height=750,width=550`
  )
}

const handleRefer = () => {
  const url = process.env.REACT_APP_HUB_URL
  window.open(
    `${url}/refer`,
    '_blank'
  )
}

export { handleAddFunds, handleRefer }
