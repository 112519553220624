import { showNotification } from "@mantine/notifications"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { capitalizeLetter, isEqual } from "src/utils";
import {
  loginUser, validate2fa,
} from "@redux/sagas/auth/authSlice"
import type { RootState } from '@redux/store';
import type { ILoginPayload } from "@redux/sagas/auth";
import { FAILURE } from "@utils/constant"
import { useLocation } from "wouter";
import usePrevious from "../common/usePrevious";

const useHandleLogin = (formLogin, formValidate, setValidate2faRequestId) => {
  const dispatch = useDispatch()
  const [,setLocation] = useLocation()

  const loginState = useSelector((state:RootState) => state.auth.login)
  const { status: loginStatus } = loginState

  const validate2faState = useSelector((state:RootState) => state.auth.validate2fa)
  const { status: validate2faStatus } = validate2faState

  const [isResetRequired, setIsResetRequired] = useState(false)

  const prevProps = usePrevious({ loginState, validate2faState })
  const [isAccountBlocked, setIsAccountBlocked] = useState(false);
  const handleLogin = (payload:ILoginPayload) => {
    dispatch(loginUser(payload))
  }

  const handleValidate2fa = (payload) => {
    dispatch(validate2fa(payload))
  }

  useEffect(() => {
    if (validate2faStatus === 'failure') {
      const { errorData, message, errorCode } = validate2faState
      // session expire
      if (!isEqual(prevProps.validate2faState.status, validate2faStatus) && errorCode === 422) {
        setValidate2faRequestId("")
        formValidate.reset();
        formLogin.setFieldValue("password", "")
        showNotification({
          color: "red",
          title: "Request Expired",
          message: "Please enter password again",
        })
        setLocation("/login")
      } else if (!isEqual(prevProps.validate2faState.status, validate2faStatus) && errorCode === 403) {
        setIsAccountBlocked(true)
        showNotification({
          color: "red",
          title: 'User account blocked',
          message: "Please follow the on screen instructions",
        })
      } else if (!isEqual(prevProps.validate2faState.status, validate2faStatus) && errorData?.resetRequired) {
        showNotification({
          color: "red",
          title: 'Error',
          message: "Please reset your password",
        })
        setIsResetRequired(true)
      } else if (!isEqual(prevProps.validate2faState.status, validate2faStatus) && errorData) {
        const remainingAttempts = errorData.maxAttemptsAllowed - errorData.totalWrongAttempts
        showNotification({
          color: "red",
          title: capitalizeLetter(message),
          message: remainingAttempts > 0 ? `${remainingAttempts}
        attempt(s) remain before the account is locked.` : 'Something went wrong',
        })
      } else if (!isEqual(prevProps.validate2faState.status, validate2faStatus)) {
        showNotification({
          color: "red",
          title: capitalizeLetter(message),
          message: '',
        })
      }
    }
  }, [validate2faState, prevProps])

  useEffect(() => {
    if (!isEqual(prevProps.loginState.status, loginStatus) && loginStatus === FAILURE) {
      const { message, errorCode, errorData } = loginState
      if (message === "invalid password" || message === "wrong password") {
      // if invalid password
        showNotification({
          color: "red",
          title: "Incorrect password",
          message: "Please recheck your password",
        })
      } else if (errorData && errorData.isBlocked && errorCode === 403) {
        setIsAccountBlocked(true)
        showNotification({
          color: "red",
          title: "User account blocked",
          message: "Please follow the on screen instructions",
        })
      } else if (errorData && errorData.resetRequired) {
        showNotification({
          color: "red",
          title: "Error",
          message: "Please reset your password",
        })
        setLocation("/forgot")
      } else {
        // default
        showNotification({
          color: "red",
          title: capitalizeLetter(message),
          message: '',
        })
      }
    }
  }, [loginState, prevProps])

  return {
    handleLogin, handleValidate2fa, isAccountBlocked, isResetRequired,
  }
}

export default useHandleLogin
