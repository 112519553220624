import {
  Box, Button, Space,
} from "@mantine/core"
import orderBag from "@assets/icons/book.svg"

interface ZeroStateProps {
  title?: string
}

const OrdersZeroState = ({
  title = "You haven't placed any orders today ",
}: ZeroStateProps) => (
  <Box sx={{ textAlign: "center", marginTop: 60 }}>
    <img src={orderBag} alt="position-bag" />
    <Space h={20} />
    <p className="heading_2xs_semibold">
      {title}
    </p>
    <Box mt={15}>
      <Button>Get Started</Button>
    </Box>
  </Box>
)

export default OrdersZeroState
