/* eslint-disable react/no-unstable-nested-components */
import {
  Badge,
  Box,
  createStyles,
} from "@mantine/core"
import {
  Table, SortDirection, WindowScroller, AutoSizer,
} from 'react-virtualized';
import {
  memo, useEffect, useRef, useState,
} from "react";
import useCSVDownload from "@hooks/common/useCSVDownload";
import TitleHeading from "@components/atoms/titleHeading";
import { IUserOrder } from "@redux/sagas/orders"
import NotFoundState from "@components/zeroState/NotFoundZeroState";
import TableHeaderItem from "@components/table/tableHeaderItem";
import TableItem from "@components/table/tableItem";
import { getTypographyStyles } from "@theme/themeConfig";
import {
  FAILURE, productType, SHOW_MORE_COUNT, tableContainer,
} from "@utils/constant"
import { getUserTrades } from "@redux/sagas/orders/ordersSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@redux/store";
import { BuySellConst, TransactionType } from "@constants"
import useMediaCustom from "@hooks/common/useMediaCustom";
import { searchTicker } from "@utils/search";
import TradesInstrument from "./tradesIntrument";
import TradesCardContainer from "./tradesCard/tradesCardContainer";

type TradesOrderTableProps = {
  title: string;
  isCollapsible?: boolean
};

const useStyles = createStyles((theme) => ({
  showMoreLess: {
    ...getTypographyStyles('content_md_regular'),
    color: theme.other.colors.primary.default,
    cursor: 'pointer',
    textAlign: 'center',
    height: 45,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderBottom: `1px solid ${theme.other.colors.disabled.muted}`,
  },
}))

const TradesTableList = ({
  title,
  isCollapsible = false,
}: TradesOrderTableProps) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { isLargeScreen } = useMediaCustom();

  const userTradersState = useSelector((state: RootState) => state.order.userTrades)
  const { data, status, loading } = userTradersState
  const isDataAvailable = !!data
  const isStatusFailure = status === FAILURE

  const [sortedData, setSortedData] = useState<IUserOrder[]>([]);
  const [sortInfo, setSortInfo] = useState({
    sortBy: '',
    sortDirection: SortDirection.ASC,
  });
  const [searchVal, setSearchVal] = useState("");
  const [showTableData, setShowTableData] = useState(false);
  const [showAllData, setShowAllData] = useState(false);

  const { downloadCSV } = useCSVDownload(
    ["timeStamp", "transactionType", "symbol", "product", "quantity", "averagePrice", "orderStatus"],
    "orders"
  );

  let tableData: IUserOrder[] = [];
  if (sortedData.length) {
    tableData = sortedData
  } else if (sortedData.length === 0 && searchVal.length) {
    tableData = [];
  } else {
    tableData = isDataAvailable ? data : []
  }

  const [manipulatedTable, setManipulatedTable] = useState<IUserOrder[]>([]);
  // const manipulatedTableRef = useRef<IUserOrder[]>(tableData);

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchVal(value)
    const tempSearchData = searchTicker(data, value);
    // manipulatedTableRef.current = tempSearchData;
    setManipulatedTable(tempSearchData.slice(0, showAllData ? undefined : SHOW_MORE_COUNT))
    setSortedData(tempSearchData);
  }

  const handleSort = ({ sortBy: sortFieldBy, sortDirection: sortFieldDirection }) => {
    // Sort the data array based on the selected column
    const tempSortedData = Array.from(tableData).sort((a, b) => {
      if (a[sortFieldBy] < b[sortFieldBy]) return sortFieldDirection === "ASC" ? -1 : 1;
      if (a[sortFieldBy] > b[sortFieldBy]) return sortFieldDirection === "ASC" ? 1 : -1;
      return 0;
    });
    // manipulatedTableRef.current = tempSortedData;
    setManipulatedTable(tempSortedData.slice(0, showAllData ? undefined : SHOW_MORE_COUNT))
    setSortedData(tempSortedData);
    setSortInfo({
      sortBy: sortFieldBy,
      sortDirection: sortFieldDirection,
    })
  };

  const handleTabs = (): void => {
    if (!showTableData) {
      dispatch(getUserTrades())
    }
    setShowTableData(!showTableData);
  }

  const handleShowMoreTable = (isShowAll: boolean) => {
    // manipulatedTableRef.current = [...tableData].slice(0, isShowAll ? -1 : SHOW_MORE_COUNT)
    const tempData = [...tableData].slice(0, isShowAll ? undefined : SHOW_MORE_COUNT)
    setManipulatedTable(tempData);
  }

  const handleShowMore = (isShowAll: boolean) => {
    setShowAllData(isShowAll);
    handleShowMoreTable(isShowAll)
  }
  const showMoreLessCheck = (!isCollapsible || (isCollapsible && showTableData))
    && tableData.length >= SHOW_MORE_COUNT + 1

  const inititalRender = useRef(false);
  useEffect(() => {
    if (!inititalRender.current) {
      handleShowMore(false);
      inititalRender.current = true
    } else {
      handleShowMore(!showMoreLessCheck);
    }
  }, [data]);

  const tableClassName = manipulatedTable.length < 10 ? "table-min-height" : ""

  // Return the data for a given row index
  const rowGetter = ({ index }) => manipulatedTable[index];

  const headerRowRenderer = () => (
    <section className="table-header-row">
      <TableHeaderItem
        width={160}
        label="Trade Id"
        value="id"
        handleSort={handleSort}
        sortInfo={sortInfo}
        rightAlign={false}
        className="pl-10"
      />
      <TableHeaderItem
        width={120}
        label="Fill time"
        value="timeStamp"
        handleSort={handleSort}
        sortInfo={sortInfo}
        rightAlign={false}
      />
      <TableHeaderItem
        width={120}
        label="Type"
        value="transactionType"
        handleSort={handleSort}
        sortInfo={sortInfo}
        rightAlign={false}
      />
      <TableHeaderItem
        width={280}
        label="Instrument"
        value="symbol"
        handleSort={handleSort}
        sortInfo={sortInfo}
        rightAlign={false}
      />
      <TableHeaderItem
        width={80}
        label="Product"
        value="product"
        handleSort={handleSort}
        sortInfo={sortInfo}
        rightAlign={false}
      />
      <TableHeaderItem
        width={95}
        label="Qty"
        value="quantity"
        handleSort={handleSort}
        sortInfo={sortInfo}
      />
      <TableHeaderItem
        width={125}
        label="Avg. Price"
        value="averagePrice"
        handleSort={handleSort}
        sortInfo={sortInfo}
        className="pr-10"
      />
    </section>
  )

  // Render each row with multiple columns
  const rowRenderer = ({ index, key, style }) => {
    const row = manipulatedTable[index];

    if (row) {
      return (
        <div key={key} style={style} className="table-row">
          <TableItem width={160} value={row.id} rightAlign={false} className="pl-10" />
          <TableItem width={120} value={row.timeStamp.slice(0, 9)} rightAlign={false} />
          <TableItem
            width={120}
            value={(
              <Badge size="md" radius="xs" color={row.transactionType === TransactionType.B ? "blue" : "red"}>
                {BuySellConst[row.transactionType]}
              </Badge>
            )}
            rightAlign={false}
          />
          <TradesInstrument
            width={280}
            row={row}
          />
          <TableItem width={80} value={productType[row.product]} rightAlign={false} />
          <TableItem width={95} value={`${row.fillQuantity} / ${row.quantity}`} />
          <TableItem width={125} value={row.averagePrice?.toFixed(2) || '0.00'} className="pr-10" />
        </div>
      );
    }
    return null
  };
  const RenderTable = () => {
    if (!isCollapsible || (isCollapsible && showTableData)) {
      return tableData.length ? (
        <Box>
          {isLargeScreen ? (
            <WindowScroller>
              {({
                height, registerChild, isScrolling, onChildScroll, scrollTop,
              }) => (
                <AutoSizer style={tableContainer} ref={() => registerChild(null)} disableHeight>
                  {({ width }) => (
                    <Table
                      className={tableClassName}
                      autoHeight
                      width={width}
                      height={height}
                      rowCount={manipulatedTable.length}
                      rowGetter={rowGetter}
                      rowHeight={50}
                      rowRenderer={rowRenderer}
                      headerRowRenderer={headerRowRenderer}
                      scrollTop={scrollTop}
                      isScrolling={isScrolling}
                      onScroll={onChildScroll}
                    />
                  )}
                </AutoSizer>
              )}
            </WindowScroller>
          ) : <TradesCardContainer tradesData={manipulatedTable} />}

          {showMoreLessCheck ? (
            <p className={classes.showMoreLess} aria-hidden="true" onClick={() => handleShowMore(!showAllData)}>
              Show
              {' '}
              {showAllData ? "Less" : "More"}
            </p>
          ) : null}
        </Box>
      ) : <NotFoundState />
    }
    return null
  }

  return (
    <Box mt={12} mb={20}>
      <TitleHeading
        title={title}
        length={isDataAvailable ? data.length : 0}
        searchVal={searchVal}
        handleSearchChange={handleSearchChange}
        downloadCSV={() => downloadCSV(data)}
        loading={Boolean(loading)}
        isCollapsible={isCollapsible}
        showTableData={showTableData}
        handleTabs={handleTabs}
        isNoInternet={!!(isStatusFailure && userTradersState)}
        isDataAvailable={!!isDataAvailable}
      />
      {
        loading && !data ? (
          null
        ) : (
          <RenderTable />
        )
      }
    </Box>
  )
}
export default memo(TradesTableList)
