import { Box, Button } from "@mantine/core"
import { WlZeroIcon } from "@assets/icons"

interface WatchlistZeroStateProps {
  handleAddInstrument: () => void
}

const WatchlistZeroState = ({ handleAddInstrument }: WatchlistZeroStateProps) => (
  <Box sx={{ textAlign: "center", marginTop: 50 }}>
    <WlZeroIcon color="#963a72" />
    <p className="heading_2xs_semibold">
      Looks like your watchlist is empty
    </p>
    <Button onClick={handleAddInstrument} mt={20}>
      Add Instruments
    </Button>
  </Box>
)

export default WatchlistZeroState
