import {
  Loader, TextInput, Title, createStyles,
} from '@mantine/core'
import {
  ChevronDown, ChevronUp, Download, Lock, Search,
} from 'tabler-icons-react'
import { memo } from 'react'
import useMediaCustom from '@hooks/common/useMediaCustom'
import { Flex } from '@uikit'
import TitleIcon from './titleIcon'
import ReloadIcons from './ReloadIcons'
import { ITitleHeading } from './titleHeading.types'

const useStyles = createStyles(() => ({
  textInputWrapper: {
    width: 82,
    transition: 'width 0.2s ease-in-out',
    '&:focus-within': {
      width: 170,
    },
  },
}))

const TitleHeading = ({
  title,
  length,
  searchVal,
  handleSearchChange,
  downloadCSV,
  loading = false,
  secondaryTitle = "",
  isCollapsible = false,
  showTableData = false,
  handleTabs = () => { },
  isNoInternet = false,
  isDataAvailable = true,
  additionalButton,
}: ITitleHeading) => {
  const { classes } = useStyles();
  const { isLargeScreen } = useMediaCustom();
  return (
    <Flex justify="space-between" mb={10}>
      <Flex align="center">
        <Title mr={4} className="content_xl_semibold">
          {title}
          {" "}
          {length > 0 ? (
            <span className="content_md_regular text_fg_muted">
              (
              {length}
              )
            </span>
          ) : null}
        </Title>
        {isCollapsible ? (
          <p
            className="cursor-pointer mt-12 mr-6"
            aria-hidden="true"
            onClick={handleTabs}
          >
            {showTableData ? (
              <ChevronUp size={18} />
            ) : (
              <ChevronDown size={18} />
            )}
          </p>
        ) : null}

        {isNoInternet ? <ReloadIcons /> : null}
        {loading && <Loader ml="md" size="sm" />}
      </Flex>
      {isDataAvailable && isLargeScreen ? (
        <Flex align="center" gap="sm">
          <TextInput
            id="order-search"
            placeholder="Search by ticker"
            icon={<Search size={16} strokeWidth={1.5} />}
            mr="md"
            value={searchVal}
            onChange={handleSearchChange}
            autoComplete="off"
            size="xs"
            classNames={{
              wrapper: classes.textInputWrapper,
            }}
          />
          {secondaryTitle ? (
            <TitleIcon mr={10} icon={Lock} title={secondaryTitle} />
          ) : null}
          <TitleIcon onClick={downloadCSV} icon={Download} title="Download" />
          {additionalButton ? (
            <TitleIcon
              onClick={additionalButton.onClick}
              icon={additionalButton.icon}
              title={additionalButton.title}
            />
          ) : null}
        </Flex>
      ) : null}
    </Flex>
  );
};

export default memo(TitleHeading)
