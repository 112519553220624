import { parsedSymbol } from "@utils/index";
import { useLocation } from "wouter";

interface IChartItem {
  exchange: string;
  symbol: string;
  token: number;
}

const useRedirectChart = <T extends IChartItem>() => {
  const [, setLocation] = useLocation();

  const handleChart = (item: T) => {
    const parseSymbolObj = parsedSymbol(item.symbol, item.exchange, item.token);
    setLocation(`/charts/${item.exchange}/${parseSymbolObj?.tradingSymbol}/${item.token}`);
  };

  return { handleChart }
}

export default useRedirectChart
