import {
  Badge,
  Box, Button, Divider, Group, Modal, Text,
} from '@mantine/core'
import { setGttDeleteModalInfo } from '@redux/sagas/misc/miscSlice';
import { deleteGttOrder } from '@redux/sagas/orders/ordersSlice';
import { RootState } from '@redux/store';
import { useDispatch, useSelector } from 'react-redux';
import useMediaCustom from 'src/hooks/common/useMediaCustom';
import { Flex } from '@uikit';

interface IConfirmCancelSingle {
  isVisible: boolean
  // handleCloseModal: (isBoolean: boolean) => void
  // handleConfirmDelete: () => void
  // selectedRow: {
  //   id: string,
  //   symbol: string
  // }
}

const ConfirmDelete = ({
  isVisible,
}: IConfirmCancelSingle) => {
  const { isMobile } = useMediaCustom();
  const dispatch = useDispatch();

  const {
    deleteGttModalInfo: { alertId, gttOrderDetails: selectedRow },
  } = useSelector((state: RootState) => state.misc);

  const handleCloseModal = () => {
    dispatch(setGttDeleteModalInfo({
      alertId: "",
      showDeleteGttModal: false,
      gttOrderDetails: {},
    }))
  };

  const handleConfirmDelete = () => {
    dispatch(deleteGttOrder(alertId))
    handleCloseModal();
  }

  return (
    <Modal
      onClose={() => null}
      opened={isVisible}
      size="md"
      withCloseButton={false}
      fullScreen={!!isMobile}
    >
      <Box mb={10}>
        <p className="heading_sm_semibold">Delete GTT?</p>
      </Box>
      <Divider />
      <Box py={20}>
        <Flex mb={10} direction="row" justify="space-between">
          <Text>{selectedRow.symbol}</Text>
          <Badge radius={2}>{selectedRow.variables.length === 2 ? 'OCO' : 'Single'}</Badge>
        </Flex>
        <p className="content_sm_regular text_fg_default">
          Created on
          {' '}
          {selectedRow.createdAt}
        </p>
      </Box>
      {/* modal button */}
      <Divider />
      <Group position="right" align="flex-start" mt="md">
        <Button
          variant="outline"
          onClick={handleConfirmDelete}
        >
          Delete
        </Button>
        <Button
          variant="outline"
          onClick={handleCloseModal}
          color="gray"
        >
          Close
        </Button>
      </Group>
    </Modal>
  )
}

export default ConfirmDelete
