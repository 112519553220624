import { call, put } from "redux-saga/effects"
import { removeEQFromSymbol, filterErrorResponse } from "@utils/index"
import { IPositionItem } from "."
import requestUserPositions from "./positionsRequests"
import { getUserPositionsFailure, getUserPositionsSuccess } from "./positionsSlice"

function* handleUserPositions(): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestUserPositions)
    const updatedData = data?.map((item: IPositionItem) => ({
      ...item,
      qty: Number(item.qty),
      avgPrice: Number(item.avgPrice),
      symbol: removeEQFromSymbol(item.symbol),
    }))
    yield put(getUserPositionsSuccess({ data: updatedData, status }))
  } catch (error: any) {
    yield put(getUserPositionsFailure(filterErrorResponse(error)))
  }
}

export default handleUserPositions
