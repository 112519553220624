import { createStyles } from '@mantine/core';
import { getTernaryValue } from '@utils/index';
import { ReactNode } from 'react';
import { Link } from 'wouter';

interface IContainerCard {
  headerTitle: string
  children: ReactNode
  secondaryTitle?: string
  linkUrl?: string
}

const useStyles = createStyles((theme) => {
  const { colors } = theme.other
  return {
    wrapper: {
      background: theme.other.colors.base.white,
      padding: 24,
      borderRadius: 8,
      width: "100%",
      boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
      border: `1px solid ${colors.disabled.muted}`,
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingBottom: 16,
      borderBottom: `1px solid ${colors.disabled.muted}`,
    },
    mainContent: {
      paddingTop: 20,
    },
  };
});

const ContainerCard = ({
  headerTitle, secondaryTitle, children, linkUrl,
}: IContainerCard) => {
  const { classes, cx } = useStyles();

  return (
    <div className={cx(classes.wrapper)}>
      <div
        className={cx(
          classes.header
        )}
      >
        <p className="cardTitle">{headerTitle}</p>
        {getTernaryValue(
          [Boolean(linkUrl && secondaryTitle), Boolean(secondaryTitle)],
          [linkUrl && secondaryTitle ? (
            <Link href={linkUrl}>
              <p className="heading_xs_semibold text_primary_default cursor-pointer">{secondaryTitle}</p>
            </Link>
          ) : null,
            <p className="heading_xs_semibold text_primary_default">{secondaryTitle}</p>,
            null]
        )}
      </div>
      <div className={classes.mainContent}>
        {children}
      </div>
    </div>
  )
}

export default ContainerCard
