enum TransactionType {
  B = 'B',
  S = 'S'
}

enum BuySellConst {
  B = "Buy",
  S = "Sell"
}

enum SortDirection {
  ASC = "ASC",
  DESC = "DESC"
}

export { TransactionType, BuySellConst, SortDirection }
