/* eslint-disable max-len */
import {
  Text, Tooltip, CopyButton, Box,
} from '@mantine/core'
import useStyles from './styles'

const MethodExternal = ({ enable2FaData }) => {
  const { classes } = useStyles()

  return (
    <div>
      <Text className="content_sm_regular" ml={28}>
        Use any TOTP compatible authenticator (such as Google Authenticator) to
        generate TOTP codes for login to Tiqs Web.
      </Text>
      <Text
        mt={10}
        align="center"
        className="text_fg_subtle content_sm_regular"
      >
        Scan the QR code with your authenticator app
      </Text>
      <div className={classes.dataWrapper}>
        {enable2FaData ? (
          <div>
            <img
              className={classes.barcodeImage}
              src={`data:image/png;base64,${enable2FaData.image}`}
              alt="doc"
            />
            <Box>
              <CopyButton value={enable2FaData.key} timeout={2000}>
                {({ copied, copy }) => (
                  <Tooltip
                    label={copied ? 'Copied' : 'Copy'}
                    withArrow
                    position="right"
                  >
                    <Text
                      align="center"
                      size={12}
                      className="text_primary_default"
                      onClick={copy}
                      sx={{ cursor: 'pointer' }}
                      pt={6}
                    >
                      Can&apos;t scan? Copy the key.
                    </Text>
                  </Tooltip>
                )}
              </CopyButton>
              <input
                className={classes.secretInput}
                value={enable2FaData.key}
              />
            </Box>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default MethodExternal
