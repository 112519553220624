import axios from "../../api/axiosInterceptors"
import { authEndPoints } from "../../api/apiEndpoints"
import { ILoginPayload } from './index'

export function requestLogin(data: ILoginPayload) {
  return axios.request({
    method: "post",
    url: data.isAppLogin ? authEndPoints.appLogin : authEndPoints.login,
    data,
  })
}

export function requestValidate2fa(data: any) {
  return axios.request({
    method: "post",
    url: authEndPoints.validate2faUrl,
    data,
  })
}

export function requestValidateLoginOtp(data: any) {
  return axios.request({
    method: "post",
    url: authEndPoints.validate2faUrl,
    data,
  })
}

export function requestForgotPassword(data: any) {
  return axios.request({
    method: "post",
    url: authEndPoints.forgotPassword,
    data,
  })
}

export function requestResendOtp(data: any) {
  return axios.request({
    method: "post",
    url: authEndPoints.resendOtp,
    data,
  })
}

export function requestCaptcha() {
  return axios.request({
    method: "get",
    url: authEndPoints.captchaUrl,
  })
}

export function requestReloadCaptcha(data: any) {
  return axios.request({
    method: "post",
    url: authEndPoints.reloadCaptchaUrl,
    data,
  })
}

export function requestResetPassword(data: any) {
  return axios.request({
    method: "post",
    url: authEndPoints.resetPasswordUrl,
    data,
  })
}

export function requestValidateReset(data: any) {
  return axios.request({
    method: "post",
    url: authEndPoints.validateResetUrl,
    data,
  })
}

export function requestLogoutUser(data: any) {
  return axios.request({
    method: "post",
    url: authEndPoints.logout,
    data,
  })
}

export function requestSetup2fa(data: any) {
  return axios.request({
    method: "post",
    url: authEndPoints.setup2faUrl,
    data,
  })
}

export function requestAppLoginGenerateToken(data: { apiKey: string }) {
  return axios.request({
    method: "post",
    url: authEndPoints.appLoginGenerateToken,
    data,
  })
}
