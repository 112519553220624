const WATCHLIST_DISPLAY_FORMATS = [
  {
    label: "Percentage",
    value: "percentage",
  },
  {
    label: "Absolute",
    value: "absolute",
  },
  {
    label: "Percentage & Absolute",
    value: "percentageAndAbsolute",
  },
]

const WATCHLIST_PREFERENCE = "watchlistPreference"

export { WATCHLIST_DISPLAY_FORMATS, WATCHLIST_PREFERENCE }
