import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import type { ActionFailure } from "@redux/types"
import { IGttOrderRes, OrdersSlice, ICancelOrderPayload } from "."

const initialState: OrdersSlice = {
  orders: {
    loading: false,
    status: '',
    data: null,
  },
  orderHistory: {
    loading: false,
    status: '',
    data: null,
  },
  cancelOrder: {
    loading: false,
    status: '',
    data: null,
  },
  userTrades: {
    loading: false,
    status: '',
    data: null,
  },
  gttOco: {
    loading: false,
    status: '',
    data: null,
  },
  editGttOco: {
    loading: false,
    status: '',
    data: null,
  },
  gttSingle: {
    loading: false,
    status: '',
    data: null,
  },
  editGttSingle: {
    loading: false,
    status: '',
    data: null,
  },
  gttDetails: {
    loading: false,
    status: '',
    data: null,
  },
  deleteGtt: {
    loading: false,
    status: '',
    data: null,
  },
}

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    getUserOrders: (state) => {
      state.orders.loading = true
      state.orders.status = ""
    },
    getUserOrdersSuccess: (state, action) => {
      const { payload } = action
      state.orders = {
        loading: false,
        status: payload.status,
        data: payload.data,
      }
    },
    getUserOrdersFailure: (state, action:ActionFailure<{}>) => {
      const { payload } = action;
      state.orders = {
        ...state.orders,
        loading: false,
        status: payload.status,
        errorData: payload.errorData,
        message: payload.message,
        errorCode: payload.errorCode,
      };
    },
    getOrderHistory: (state, action) => {
      state.orderHistory.loading = true
      state.orderHistory.status = ""
      state.orderHistory.data = []
    },
    getOrderHistorySuccess: (state, action) => {
      const { payload } = action
      state.orderHistory = {
        loading: false,
        status: payload.status,
        data: payload.data,
      }
    },
    getOrderHistoryFailure: (state, action:ActionFailure<{}>) => {
      const { payload } = action;
      state.orderHistory = {
        ...state.orderHistory,
        loading: false,
        status: payload.status,
        errorData: payload.errorData,
        message: payload.message,
        errorCode: payload.errorCode,
      };
    },
    cancelOrder: (state, action:PayloadAction<Array<ICancelOrderPayload>>) => {
      state.cancelOrder.loading = true
      state.cancelOrder.status = ""
    },
    cancelOrderUpdate: (state, action) => {
      const { payload } = action
      state.cancelOrder = {
        ...state.cancelOrder,
        data: { ...state.cancelOrder.data, ...payload.data },
      }
    },
    cancelOrderSuccess: (state, action) => {
      const { payload } = action
      state.cancelOrder = {
        loading: false,
        status: payload.status,
        data: payload.data,
      }
    },
    cancelOrderFailure: (state, action:ActionFailure<{}>) => {
      const { payload } = action;
      state.cancelOrder = {
        ...state.cancelOrder,
        loading: false,
        status: payload.status,
        errorData: payload.errorData,
        message: payload.message,
        errorCode: payload.errorCode,
      };
    },
    getUserTrades: (state) => {
      state.userTrades.loading = true
      state.userTrades.status = ""
    },
    getUserTradesSuccess: (state, action) => {
      const { payload } = action
      state.userTrades = {
        loading: false,
        status: payload.status,
        data: payload.data,
      }
    },
    getUserTradesFailure: (state, action:ActionFailure<{}>) => {
      const { payload } = action;
      state.userTrades = {
        ...state.userTrades,
        loading: false,
        status: payload.status,
        errorData: payload.errorData,
        message: payload.message,
        errorCode: payload.errorCode,
      };
    },
    gttOcoOrder: (state, action) => {
      state.gttOco.loading = true
      state.gttOco.status = ""
      state.gttOco.data = {} as IGttOrderRes
    },
    gttOcoOrderSuccess: (state, action) => {
      const { payload } = action
      state.gttOco = {
        loading: false,
        status: payload.status,
        data: payload.data,
      }
    },
    gttOcoOrderFailure: (state, action:ActionFailure<{}>) => {
      const { payload } = action;
      state.gttOco = {
        ...state.gttOco,
        loading: false,
        status: payload.status,
        errorData: payload.errorData,
        message: payload.message,
        errorCode: payload.errorCode,
      };
    },
    editGttOcoOrder: (state, action) => {
      state.editGttOco.loading = true
      state.editGttOco.status = ""
      state.editGttOco.data = {} as IGttOrderRes
    },
    editGttOcoOrderSuccess: (state, action) => {
      const { payload } = action
      state.editGttOco = {
        loading: false,
        status: payload.status,
        data: payload.data,
      }
    },
    editGttOcoOrderFailure: (state, action:ActionFailure<{}>) => {
      const { payload } = action;
      state.editGttOco = {
        ...state.editGttOco,
        loading: false,
        status: payload.status,
        errorData: payload.errorData,
        message: payload.message,
        errorCode: payload.errorCode,
      };
    },
    gttSingleOrder: (state, action) => {
      state.gttSingle.loading = true
      state.gttSingle.status = ""
      state.gttSingle.data = {} as IGttOrderRes
    },
    gttSingleOrderSuccess: (state, action) => {
      const { payload } = action
      state.gttSingle = {
        loading: false,
        status: payload.status,
        data: payload.data,
      }
    },
    gttSingleOrderFailure: (state, action:ActionFailure<{}>) => {
      const { payload } = action;
      state.gttSingle = {
        ...state.gttSingle,
        loading: false,
        status: payload.status,
        errorData: payload.errorData,
        message: payload.message,
        errorCode: payload.errorCode,
      };
    },
    editGttSingleOrder: (state, action) => {
      state.editGttSingle.loading = true
      state.editGttSingle.status = ""
    },
    editGttSingleOrderSuccess: (state, action) => {
      const { payload } = action
      state.editGttSingle = {
        loading: false,
        status: payload.status,
        data: payload.data,
      }
    },
    editGttSingleOrderFailure: (state, action:ActionFailure<{}>) => {
      const { payload } = action;
      state.editGttSingle = {
        ...state.editGttSingle,
        loading: false,
        status: payload.status,
        errorData: payload.errorData,
        message: payload.message,
        errorCode: payload.errorCode,
      };
    },
    gttDetails: (state, action) => {
      state.gttDetails.loading = true
      state.gttDetails.status = ""
    },
    gttDetailsSuccess: (state, action) => {
      const { payload } = action
      state.gttDetails = {
        loading: false,
        status: payload.status,
        data: payload.data,
      }
    },
    gttDetailsFailure: (state, action:ActionFailure<{}>) => {
      const { payload } = action;
      state.gttDetails = {
        ...state.gttDetails,
        loading: false,
        status: payload.status,
        errorData: payload.errorData,
        message: payload.message,
        errorCode: payload.errorCode,
      };
    },
    deleteGttOrder: (state, action) => {
      state.deleteGtt.loading = true
      state.deleteGtt.status = ""
    },
    deleteGttOrderSuccess: (state, action) => {
      const { payload } = action
      state.deleteGtt = {
        loading: false,
        status: payload.status,
        data: payload.data,
      }
    },
    deleteGttOrderFailure: (state, action:ActionFailure<{}>) => {
      const { payload } = action;
      state.deleteGtt = {
        ...state.deleteGtt,
        loading: false,
        status: payload.status,
        errorData: payload.errorData,
        message: payload.message,
        errorCode: payload.errorCode,
      };
    },

    resetOrdersSlice: () => initialState,
  },
})

// Action
export const {
  getUserOrders,
  getUserOrdersSuccess,
  getUserOrdersFailure,
  getOrderHistory,
  getOrderHistorySuccess,
  getOrderHistoryFailure,
  cancelOrder,
  cancelOrderUpdate,
  cancelOrderSuccess,
  cancelOrderFailure,
  getUserTrades,
  getUserTradesSuccess,
  getUserTradesFailure,
  gttOcoOrder,
  gttOcoOrderSuccess,
  gttOcoOrderFailure,
  editGttOcoOrder,
  editGttOcoOrderSuccess,
  editGttOcoOrderFailure,
  gttSingleOrder,
  gttSingleOrderSuccess,
  gttSingleOrderFailure,
  editGttSingleOrder,
  editGttSingleOrderSuccess,
  editGttSingleOrderFailure,
  gttDetails,
  gttDetailsSuccess,
  gttDetailsFailure,
  deleteGttOrder,
  deleteGttOrderSuccess,
  deleteGttOrderFailure,

  resetOrdersSlice,
} = orderSlice.actions

export default orderSlice.reducer
