import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { exchanges } from "src/utils/constant";
import { getFreezeQty, resetFreezeQty } from "@redux/sagas/trades/tradesSlice";
import { IFreezeQtyPayload } from "@redux/sagas/trades";

const useFetchFreezeQty = (
  currentAssetItem,
  quantity,
  priceField,
  orderTypeMLVal,
  productValue
) => {
  const dispatch = useDispatch();

  const fetchFreezeQty = useCallback((payload: IFreezeQtyPayload) => {
    dispatch(getFreezeQty(payload));
  }, [dispatch]);

  useEffect(() => {
    if (currentAssetItem && currentAssetItem.exchange === exchanges.NFO && orderTypeMLVal) {
      const payload = {
        exchange: "NFO",
        symbol: currentAssetItem.tradingSymbol,
        product: productValue,
        token: currentAssetItem.token.toString(),
        qty: quantity.toString(),
        order: orderTypeMLVal,
        price: priceField.toString(),
      };
      fetchFreezeQty(payload);
    }
    return () => {
      dispatch(resetFreezeQty())
    }
  }, [fetchFreezeQty, currentAssetItem, quantity, priceField, orderTypeMLVal, productValue]);

  return fetchFreezeQty;
};

export default useFetchFreezeQty;
