import {
  Button, Flex, Modal, Text,
} from "@mantine/core"
import { useDisclosure } from "@mantine/hooks";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@redux/store";
import { useEffect } from "react";
import { setOrderSliceConsentModal, updateUserPreferences } from "@redux/sagas/users/usersSlice";

const OrderSliceConsent = () => {
  const { orderSliceConsent } = useSelector((state: RootState) => state.user)
  const preferencesData = useSelector((state: RootState) => state.user.preferences.data)
  const [opened, { open, close }] = useDisclosure(false);
  const dispatch = useDispatch()

  useEffect(() => {
    if (orderSliceConsent.isModalOpen) {
      open()
    }
  }, [orderSliceConsent.isModalOpen])

  const handleClose = () => {
    dispatch(setOrderSliceConsentModal(false))
    close()
  }

  const handleProceed = () => {
    dispatch(updateUserPreferences({ ...preferencesData, orderSlicing: true }))
    handleClose()
  }

  return (
    <Modal
      opened={opened}
      onClose={handleClose}
      title="Consent required"
      centered
    >
      <Text>
        The quantity of the order exceeds the risk limit. Your order will be executed in parts.
      </Text>
      <Flex>
        <Button ml="auto" variant="filled" onClick={handleProceed}>
          I agree
        </Button>
      </Flex>
    </Modal>
  )
}

export default OrderSliceConsent
