import Drawer from "@uikit/drawer"
import { useDisclosure } from "@mantine/hooks";
import { useState } from "react";
import { IUserOrder } from "@redux/sagas/orders";
import ExecutedCardItem from "./executedCardItem"
import DrawerItems from "./drawerItems/drawerItems";
import { IExecutedCardContainerProps } from "./executedCard.types";

const ExecutedCardContainer = ({ executedData, handleOrderDetailsModal }: IExecutedCardContainerProps) => {
  const [opened, { open, close }] = useDisclosure(false);
  const [selectedCard, setSelectedCard] = useState({} as IUserOrder)

  const handleRowClick = (row: IUserOrder) => {
    open();
    setSelectedCard(row);
  }

  return (
    <>
      {executedData.map((row) => <ExecutedCardItem key={row.id} row={row} handleRowClick={handleRowClick} />)}
      <Drawer
        opened={opened}
        onClose={close}
        title="Asset Action"
      >
        <DrawerItems
          selectedCard={selectedCard}
          close={close}
          handleOrderDetailsModal={handleOrderDetailsModal}
        />
      </Drawer>
    </>
  )
}

export default ExecutedCardContainer
