import { Divider, Menu, createStyles } from '@mantine/core'
import {
  AlignCenter, Settings, Telescope, TrendingUp,
} from 'tabler-icons-react'
import useMarketDepth from 'src/hooks/common/useMarketDepth'
import { IGttOrderDetail, IUserOrder } from '@redux/sagas/orders'
import { IHoldingItem } from '@redux/sagas/holdings/holdingsTypes'
import { IGttSearchItem } from '@redux/sagas/misc'
import { IPositionItem } from '@redux/sagas/positions'
import { useAddToWatchlist, useCreateGttModal, useRedirectChart } from '@hooks'

export const useStyles = createStyles(() => ({
  tableDropDownDots: {
    paddingTop: 5,
    marginLeft: 4,
  },
  tableDropdown: {
    position: "absolute",
  },
}));

interface ITableDropdown {
  row: IUserOrder | IHoldingItem | IGttOrderDetail | IPositionItem
}

const CommonTableDropdown = ({
  row,
}: ITableDropdown) => {
  const { token, exchange } = row

  const { handleChart } = useRedirectChart();
  const { handleMarketDepth } = useMarketDepth();
  const { handleAddWatchlistItem } = useAddToWatchlist();
  const { handleGttModal } = useCreateGttModal(token, exchange);

  return (
    <>
      <Divider />
      <Menu.Item
        icon={<Settings size={14} />}
        onClick={() => handleGttModal(true, row as IUserOrder)}
      >
        Create GTT
      </Menu.Item>
      <Menu.Item
        icon={<AlignCenter size={14} />}
        onClick={() => handleMarketDepth(row as IUserOrder | IGttSearchItem)}
      >
        Market depth
      </Menu.Item>
      <Menu.Item
        icon={<TrendingUp size={14} />}
        onClick={() => handleChart(row as any)}
      >
        Chart
      </Menu.Item>
      <Menu.Item
        icon={<Telescope size={14} />}
        onClick={() => handleAddWatchlistItem(row as any)}
      >
        Add to marketwatch
      </Menu.Item>
    </>
  )
}

export default CommonTableDropdown
