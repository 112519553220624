import { useRef } from 'react'
import { useDispatch } from 'react-redux'
import { setMarketDepthInfo } from 'src/redux/sagas/misc/miscSlice';
import { setFullToken } from 'src/redux/sagas/sockets/socketsSlice';

const useMarketDepth = () => {
  const dispatch = useDispatch();

  const prevFullTokenRef = useRef(0)

  const handleMarketDepth = (item) => {
    dispatch(setMarketDepthInfo({
      showMarketDepthModal: true,
      marketDepthDetails: item,
    }))
    if (prevFullTokenRef.current) {
      dispatch(setFullToken({ [prevFullTokenRef.current]: 0 }))
    }
    dispatch(setFullToken({ [item.token]: 1 }))
    prevFullTokenRef.current = Number(item.token)
  }

  const handleUnsubscribeFullToken = () => {
    dispatch(setFullToken({ [prevFullTokenRef.current]: 0 }))
  }

  const handleCloseMarketDepth = () => {
    dispatch(setMarketDepthInfo({
      showMarketDepthModal: false,
      marketDepthDetails: {},
    }))
    handleUnsubscribeFullToken()
  }

  return { handleMarketDepth, handleCloseMarketDepth, handleUnsubscribeFullToken }
}

export default useMarketDepth
