import { Box, Flex, Text } from "@mantine/core"
import useMediaCustom from "@hooks/common/useMediaCustom"
import { exploreCards } from "@constants/home";
import ExploreCard from "./exploreCard";

const ExploreTrading = () => {
  const { isSmallScreen } = useMediaCustom();

  return (
    <Box pt="lg">
      <Text className="content_2xl_semibold"> Explore Trading</Text>
      <Flex gap="xl" pt="lg" direction={isSmallScreen ? "column" : "row"}>
        {exploreCards.map((card) => (
          <ExploreCard key={card.title} {...card} />
        ))}
      </Flex>

    </Box>
  )
}
export default ExploreTrading
