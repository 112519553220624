import { MantineThemeOverride } from '@mantine/core';
import {
  getMantinePrimaryColorsArray,
  getMantineSecondaryColorsArray,
  lightThemeColors,
  darkThemeColors,
} from './themeConfig';

const theme: MantineThemeOverride = {
  colorScheme: 'light',
  focusRing: 'auto',
  cursorType: 'pointer',
  defaultRadius: 'xs',

  colors: {
    ...getMantinePrimaryColorsArray(),
    ...getMantineSecondaryColorsArray(),
    // Todo
    // In colors object only above code will be there
    // all other data is of old theme setup, it;s there so that this doesn't break
    textDefault: ["#444"],
    primaryRed: ["#ff5722", "#fa5252"],
    primaryBlue: ["#4184f3"],
    background1: ["#f9f9f9"],
    colorDefault: ["#444", "#bbb"],
    colorLight: ["#8c8a8a"],
    colorPrimary: ["#666", "#aaa"],
    colorSuccess: ["#5b9a5d", "#4caf50"],
    colorDanger: ["#e25f5b", "#df514c"],
    secondaryBackground: ["#141414"],
    secondaryLightBackground: ["#4a4a4a"],
    borderPrimary: ["#eee", "#232325"],
    borderSecond: ["#bbb", "#aaa"],
  },
  fontFamily: 'Open Sans, sans-serif',
  lineHeight: '130%',
  headings: {
    sizes: {},
  },
  fontSizes: {
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.25rem',
    xl: '1.50rem',
  },
  radius: {
    xs: '0.25rem',
    sm: '0.5rem',
    md: '0.75rem',
    lg: '1rem',
    xl: '1.25rem',
  },
  spacing: {
    xs: '0.5rem',
    sm: '0.75rem',
    md: '1rem',
    lg: '1.25rem',
    xl: '1.5rem',
  },
  breakpoints: {
    xs: '480',
    sm: '768',
    md: '992',
    lg: '1200',
    semixl: '1440',
    xl: '1680',
  },
  loader: 'dots',
  primaryColor: 'primary',
  primaryShade: 5,
  datesLocale: 'en',
};

const opacities = {
  20: '20%',
  40: '40%',
  60: '60%',
  80: '80%',
};

const shadows = {
  xs: '0px 1px 2px rgba(16, 24, 40, 0.05)',
  sm: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
  md: '0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)',
  lg: '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
  xl: '0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)',
  xxl: '0px 24px 48px -12px rgba(16, 24, 40, 0.18)',
  xxxl: '0px 32px 64px -12px rgba(16, 24, 40, 0.14)',
};

export const getCustomTheme = (colorScheme): MantineThemeOverride => ({
  ...theme,
  colorScheme,
  fontSizes: {
    ...theme.fontSizes,
    xsm: "13px",
  },
  other: {
    colors: colorScheme === 'light' ? { ...lightThemeColors } : { ...darkThemeColors },
    opacity: opacities,
    shadow: shadows,
    screenWidth: 1440,
    navHeight: 56,
    watchlistWidth: 415,
  },
});
export default theme;
