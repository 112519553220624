import type { ComponentType } from 'react';
import { Loader as MantineLoader } from '@mantine/core';

const Loader: ComponentType = () => (
  <div className="renderBoundaryLoader">
    <MantineLoader />
  </div>
);

export default Loader;
