import { setGttModalInfo } from '@redux/sagas/misc/miscSlice';
import { parsedSymbol } from '@utils/index';
import { useDispatch } from 'react-redux';

const useCreateGttModal = <T>(token:string | number, exchange: string) => {
  const dispatch = useDispatch();
  const parsedSymbolObj:any = parsedSymbol("", exchange, token)

  const handleGttModal = (isBoolean: boolean, selectedOrderItem: T) => {
    const parsedObject = parsedSymbolObj
    if (selectedOrderItem) {
      if (parsedObject.exchange === "NFO") {
        dispatch(
          setGttModalInfo({
            showGttModal: isBoolean,
            gttOrderDetails: { ...selectedOrderItem, qty: parsedObject.lotSize },
          })
        )
      } else {
        dispatch(
          setGttModalInfo({ showGttModal: isBoolean, gttOrderDetails: { ...selectedOrderItem, qty: 1 } })
        )
      }
    }
  }

  return { handleGttModal }
}

export default useCreateGttModal
