import { createSlice } from "@reduxjs/toolkit"
import {
  ActionSuccess,
  ActionFailure,
} from "@redux/types";
import type {
  ILoginSuccess,
  IloginFailure,
  IValidate2Fa,
  IForgotPassSuccess,
  ICaptchaSuccess,
  IResendOtpSuccess,
  ICreatePassSuccess,
  IAppLoginGenTokenSuccess,
  IInitialState,
  ISetup2faSuccess,
  ILoginOtpSuccess,
} from "."

const initialState: IInitialState = {
  login: {
    loading: false,
    status: '',
    data: null,
  },
  validate2fa: {
    loading: false,
    status: '',
    data: null,
  },
  forgot: {
    loading: false,
    status: '',
    data: null,
  },
  captcha: {
    loading: false,
    status: '',
    data: null,
  },
  reset: {
    loading: false,
    status: '',
    data: null,
  },
  validateReset: {
    loading: false,
    status: '',
    data: null,
  },
  resendOtp: {
    loading: false,
    status: '',
    data: null,
  },
  logout: {
    loading: false,
    status: '',
    data: null,
  },
  setup2fa: {
    loading: false,
    status: '',
    data: null,
  },
  appLoginGenerateToken: {
    loading: false,
    status: '',
    data: null,
  },
  loginOtp: {
    loading: false,
    status: '',
    data: null,
  },
}

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginUser: (state, action) => {
      state.login.loading = true;
      state.login.status = '';
    },
    loginUserSuccess: (state, action: ActionSuccess<ILoginSuccess>) => {
      const { payload } = action;
      state.login = {
        loading: false,
        status: payload.status,
        data: payload.data,
      };
    },
    loginUserFailure: (state, action: ActionFailure<IloginFailure>) => {
      const { payload } = action;
      state.login = {
        ...state.login,
        loading: false,
        status: payload.status,
        errorData: payload.errorData,
        message: payload.message,
        errorCode: payload.errorCode,
      };
    },
    validate2fa: (state, action) => {
      state.validate2fa.loading = true;
      state.validate2fa.status = '';
    },
    validate2faSuccess: (state, action: ActionSuccess<IValidate2Fa>) => {
      const { payload } = action;
      state.validate2fa = {
        loading: false,
        status: payload.status,
        data: payload.data,
      };
    },
    validate2faFailure: (state, action: ActionFailure<IloginFailure>) => {
      const { payload } = action;
      state.validate2fa = {
        ...state.validate2fa,
        loading: false,
        status: payload.status,
        errorData: payload.errorData,
        message: payload.message,
        errorCode: payload.errorCode,
      };
    },
    forgotPassword: (state, action) => {
      state.forgot.loading = true;
      state.forgot.status = '';
    },
    forgotPasswordSuccess: (state, action: ActionSuccess<IForgotPassSuccess>) => {
      const { payload } = action;
      state.forgot = {
        loading: false,
        status: payload.status,
        data: payload.data,
      };
    },
    forgotPasswordFailure: (state, action: ActionFailure<{}>) => {
      const { payload } = action;
      state.forgot = {
        ...state.forgot,
        loading: false,
        status: payload.status,
        errorData: payload.errorData,
        message: payload.message,
        errorCode: payload.errorCode,
      };
    },
    getCaptcha: (state) => {
      state.captcha.loading = true;
      state.captcha.status = '';
    },
    getCaptchaSuccess: (state, action: ActionSuccess<ICaptchaSuccess>) => {
      const { payload } = action;
      state.captcha = {
        loading: false,
        status: payload.status,
        data: payload.data,
      };
    },
    getCaptchaFailure: (state, action: ActionFailure<{}>) => {
      const { payload } = action;
      state.captcha = {
        ...state.captcha,
        loading: false,
        status: payload.status,
        errorData: payload.errorData,
        message: payload.message,
        errorCode: payload.errorCode,
      };
    },
    reloadCaptcha: (state, action) => {
      state.captcha.loading = true;
      state.captcha.status = '';
    },
    reloadCaptchaSuccess: (state, action: ActionSuccess<ICaptchaSuccess>) => {
      const { payload } = action;
      state.captcha = {
        loading: false,
        status: payload.status,
        data: payload.data,
      };
    },
    reloadCaptchaFailure: (state, action: ActionFailure<{}>) => {
      const { payload } = action;
      state.captcha = {
        ...state.captcha,
        loading: false,
        status: payload.status,
        errorData: payload.errorData,
        message: payload.message,
        errorCode: payload.errorCode,
      };
    },
    resetPassword: (state, action) => {
      state.reset.loading = true;
      state.reset.status = '';
    },
    resetPasswordSuccess: (state, action: ActionSuccess<ICreatePassSuccess>) => {
      const { payload } = action;
      state.reset = {
        loading: false,
        status: payload.status,
        data: payload.data,
      };
    },
    resetPasswordFailure: (state, action: ActionFailure<{}>) => {
      const { payload } = action;
      state.reset = {
        ...state.reset,
        loading: false,
        status: payload.status,
        errorData: payload.errorData,
        message: payload.message,
        errorCode: payload.errorCode,
      };
    },
    validateReset: (state, action) => {
      state.validateReset.loading = true;
      state.validateReset.status = '';
    },
    validateResetSuccess: (state, action: ActionSuccess<IForgotPassSuccess>) => {
      const { payload } = action;
      state.validateReset = {
        loading: false,
        status: payload.status,
        data: payload.data,
      };
    },
    validateResetFailure: (state, action: ActionFailure<{}>) => {
      const { payload } = action;
      state.validateReset = {
        ...state.validateReset,
        loading: false,
        status: payload.status,
        errorData: payload.errorData,
        message: payload.message,
        errorCode: payload.errorCode,
      };
    },
    resendOtp: (state, action) => {
      state.resendOtp.loading = true;
      state.resendOtp.status = '';
    },
    resendOtpSuccess: (state, action: ActionSuccess<IResendOtpSuccess>) => {
      const { payload } = action;
      state.resendOtp = {
        loading: false,
        status: payload.status,
        data: payload.data,
      };
    },
    resendOtpFailure: (state, action: ActionFailure<{}>) => {
      const { payload } = action;
      state.resendOtp = {
        ...state.resendOtp,
        loading: false,
        status: payload.status,
        errorData: payload.errorData,
        message: payload.message,
        errorCode: payload.errorCode,
      };
    },
    logoutUser: (state) => {
      state.logout.loading = true;
      state.logout.status = '';
    },
    logoutUserSuccess: (state, action: ActionSuccess<IResendOtpSuccess>) => {
      const { payload } = action;
      state.logout = {
        loading: false,
        status: payload.status,
        data: payload.data,
      };
      state.login = {
        loading: false,
        data: null,
        status: '',
      };
      state.validate2fa = {
        loading: false,
        data: null,
        status: '',
      };
    },
    logoutUserFailure: (state, action: ActionFailure<{}>) => {
      const { payload } = action;
      state.logout = {
        ...state.logout,
        loading: false,
        status: payload.status,
        errorData: payload.errorData,
        message: payload.message,
        errorCode: payload.errorCode,
      };
    },
    resetAuthSlice: () => initialState,
    setup2fa: (state, action) => {
      state.setup2fa.loading = true;
      state.setup2fa.status = "";
    },
    setup2faSuccess: (state, action: ActionSuccess<ISetup2faSuccess>) => {
      const { payload } = action;
      state.setup2fa = {
        loading: false,
        status: payload.status,
        data: payload.data,
      };
    },
    setup2faFailure: (state, action: ActionFailure<{}>) => {
      const { payload } = action;
      state.setup2fa = {
        ...state.setup2fa,
        loading: false,
        status: payload.status,
        errorData: payload.errorData,
        message: payload.message,
        errorCode: payload.errorCode,
      };
    },
    appLoginGenerateToken: (state, action) => {
      state.appLoginGenerateToken.loading = true;
      state.appLoginGenerateToken.status = "";
    },
    appLoginGenerateTokenSuccess: (state, action: ActionSuccess<IAppLoginGenTokenSuccess>) => {
      const { payload } = action;
      state.appLoginGenerateToken = {
        loading: false,
        status: payload.status,
        data: payload.data,
      };
    },
    appLoginGenerateTokenFailure: (state, action: ActionFailure<{}>) => {
      const { payload } = action;
      state.appLoginGenerateToken = {
        ...state.appLoginGenerateToken,
        loading: false,
        status: payload.status,
        errorData: payload.errorData,
        message: payload.message,
        errorCode: payload.errorCode,
      };
    },
    loginOtp: (state, action) => {
      state.loginOtp.loading = true;
      state.loginOtp.status = "";
    },
    loginOtpSuccess: (state, action: ActionSuccess<ILoginOtpSuccess>) => {
      const { payload } = action;
      state.loginOtp = {
        loading: false,
        status: payload.status,
        data: payload.data,
      };
    },
    loginOtpFailure: (state, action: ActionFailure<{}>) => {
      const { payload } = action;
      state.loginOtp = {
        ...state.loginOtp,
        loading: false,
        status: payload.status,
        errorData: payload.errorData,
        message: payload.message,
        errorCode: payload.errorCode,
      };
    },
  },
});

// Action
export const {
  loginUser,
  loginUserSuccess,
  loginUserFailure,
  validate2fa,
  validate2faSuccess,
  validate2faFailure,
  forgotPassword,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  getCaptcha,
  getCaptchaSuccess,
  getCaptchaFailure,
  reloadCaptcha,
  reloadCaptchaSuccess,
  reloadCaptchaFailure,
  resetPassword,
  resetPasswordSuccess,
  resetPasswordFailure,
  validateReset,
  validateResetSuccess,
  validateResetFailure,

  resendOtp,
  resendOtpSuccess,
  resendOtpFailure,

  logoutUser,
  logoutUserSuccess,
  logoutUserFailure,

  resetAuthSlice,

  setup2fa,
  setup2faSuccess,
  setup2faFailure,

  appLoginGenerateToken,
  appLoginGenerateTokenSuccess,
  appLoginGenerateTokenFailure,

  loginOtp,
  loginOtpSuccess,
  loginOtpFailure,

} = authSlice.actions

export default authSlice.reducer
