import {
  Bookmark,
  BrandSafari,
  Book2,
  Briefcase,
  FileDescription,
  CurrencyRupee,
} from "tabler-icons-react";

interface LinksData {
  label: string;
  to: string;
  icon: React.ReactNode;
}

const links: Array<LinksData> = [
  {
    label: "Watchlist",
    to: "/watchlist",
    icon: <Bookmark />,
  },
  {
    label: "Home",
    to: "/home",
    icon: <BrandSafari />,
  },
  {
    label: "Orders",
    to: "/orders",
    icon: <Book2 />,
  },
  {
    label: "Holdings",
    to: "/holdings",
    icon: <Briefcase />,
  },
  {
    label: "Positions",
    to: "/positions",
    icon: <FileDescription />,
  },
  {
    label: "Funds",
    to: "/funds",
    icon: <CurrencyRupee />,
  },
];
export default links;
