const createSlicedQuantities = (totalQty: number, maxQtyPerOrder: number): number[] => {
  const quantities: number[] = [];
  const evenOrders = Math.floor(totalQty / maxQtyPerOrder);
  const oddOrder = totalQty % maxQtyPerOrder;

  for (let i = 0; i < evenOrders; i++) {
    quantities.push(maxQtyPerOrder);
  }

  if (oddOrder > 0) {
    quantities.push(oddOrder);
  }

  return quantities;
}

const extractFreezeQtyFromResponse = (responseData) => {
  const { data } = responseData
  if (data.length > 0 && data[0].freezeQty) {
    return data[0].freezeQty
  }
  return 0
}

export { createSlicedQuantities, extractFreezeQtyFromResponse }
