/* eslint-disable no-template-curly-in-string */
import axios from "../../api/axiosInterceptors"
import { watchlistsEndPoints } from "../../api/apiEndpoints"

export function requestWatchlists() {
  return axios.request({
    method: "get",
    url: watchlistsEndPoints.mwListUrl,
  })
}

export function requestRemoveWatchlistItem(mwId: number, watchlistItemId: number) {
  return axios.request({
    method: "delete",
    url: `${watchlistsEndPoints.mwUrl + mwId}/${watchlistItemId}`,
  })
}

export function requestAddWatchlistItem(watchlistItemId: string, data: any) {
  return axios.request({
    method: "post",
    url: watchlistsEndPoints.addMwItemUrl.replace("${mwId}", watchlistItemId),
    data,
  })
}

export function requestUpdateSequence(mwId: string, data: any) {
  return axios.request({
    method: "put",
    url: watchlistsEndPoints.updateSequenceUrl.replace("${mwId}", mwId),
    data,
  })
}
