import { useEffect } from "react";
// NOTE: This hook is used to prevent the scrolling of the page when the user presses
//  the arrow keys and only allows the user to scroll the watchlist items.
const usePreventKeyboardScrolling = () => {
  const onKeyDown = (e) => {
    if (
      ["ArrowUp", "ArrowDown"].indexOf(
        e.code
      ) > -1
    ) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown, false);
    return () => window.removeEventListener("keydown", onKeyDown);
  });
};

export default usePreventKeyboardScrolling
