/* eslint-disable import/prefer-default-export */
const searchTicker = (data: any, search: string) => {
  const trimmedSearchVal = search.trim().toLowerCase();
  const tempSearchData = data.filter((item) => item.symbol.toLowerCase().includes(trimmedSearchVal))
    .sort((a, b) => {
      const aStartsWith = a.symbol.toLowerCase().startsWith(trimmedSearchVal);
      const bStartsWith = b.symbol.toLowerCase().startsWith(trimmedSearchVal);
      if (aStartsWith && !bStartsWith) {
        return -1;
      } if (!aStartsWith && bStartsWith) {
        return 1;
      }
      return 0;
    });
  return tempSearchData
}

export { searchTicker }
