import { useEffect, useState } from 'react';
import { Check, X } from "tabler-icons-react"
import {
  PasswordInput, Progress, Text, Popover, Box,
} from '@mantine/core';

const PasswordRequirement = ({ meets, label }: { meets: boolean; label: string }) => (
  <Text
    color={meets ? 'teal' : 'red'}
    sx={{ display: 'flex', alignItems: 'center' }}
    mt={7}
    size="sm"
  >
    {meets ? <Check size="0.9rem" /> : <X size="0.9rem" />}
    {' '}
    <Box ml={10}>{label}</Box>
  </Text>
)

const requirements = [
  { re: /[0-9]/, label: 'Includes number' },
  { re: /[a-z]/, label: 'Includes lowercase letter' },
  { re: /[A-Z]/, label: 'Includes uppercase letter' },
  { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: 'Includes special symbol' },
];

function getStrength(password: string) {
  let multiplier = password.length > 5 ? 0 : 1;

  requirements.forEach((requirement) => {
    if (!requirement.re.test(password)) {
      multiplier += 1;
    }
  });

  return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 10);
}

interface MantinePasswordInputProps {
  label: string;
  placeholder: string;
  formHandlers: any;
  error?: any,
  setPasswordStrength?: any,
  keyProp?: string,
}

const MantinePasswordInput = ({
  label,
  placeholder,
  formHandlers,
  error,
  setPasswordStrength,
  keyProp,
}: MantinePasswordInputProps) => {
  const [popoverOpened, setPopoverOpened] = useState(false);
  const checks = requirements.map((requirement) => (
    <PasswordRequirement
      key={requirement.label}
      label={requirement.label}
      meets={requirement.re.test(formHandlers.value)}
    />
  ));

  const strength = getStrength(formHandlers.value);
  // const color = strength === 100 ? 'teal' : strength > 50 ? 'yellow' : 'red';
  let color;
  if (strength === 100) {
    color = 'teal';
  } else if (strength > 50) {
    color = 'yellow';
  } else {
    color = 'red';
  }

  useEffect(() => {
    if (keyProp) {
      setPasswordStrength((prev) => ({ ...prev, [keyProp]: strength }))
    }
  }, [strength])

  return (
    <Box maw={340} mx="auto">
      <Popover
        opened={strength === 100 ? false : popoverOpened}
        position="bottom"
        width="target"
        transitionProps={{ transition: 'pop' }}
      >
        <Popover.Target>
          <div
            onFocusCapture={() => setPopoverOpened(true)}
            onBlurCapture={() => setPopoverOpened(false)}
          >
            <PasswordInput
              withAsterisk
              label={label}
              placeholder={placeholder}
              {...formHandlers}
              mb={16}
              error={error}
            />
          </div>
        </Popover.Target>
        <Popover.Dropdown>
          <Progress color={color} value={strength} size={5} mb="xs" />
          <PasswordRequirement label="Includes at least 6 characters" meets={formHandlers.value.length > 5} />
          {checks}
        </Popover.Dropdown>
      </Popover>
    </Box>
  );
}

export default MantinePasswordInput;
