import axios from "../../api/axiosInterceptors"
import { authEndPoints } from "../../api/apiEndpoints"
import {
  ISetupInitiatePayload,
  ISetupVerifyOTPPayload,
  ISetupPasswordPayload,
  ISetup2FaPayload,
  ISetupResendOtpPayload,
} from "."

export function requestInitiateSetup(data: ISetupInitiatePayload) {
  return axios.request({
    method: "post",
    url: authEndPoints.setupInitiateRequest,
    data,
  })
}

export function requestSetupVerifyOtp(data: ISetupVerifyOTPPayload) {
  return axios.request({
    method: "post",
    url: authEndPoints.setupVerifyOTP,
    data,
  })
}
export function requestSetupPassword(data: ISetupPasswordPayload) {
  return axios.request({
    method: "post",
    url: authEndPoints.setupPassword,
    data,
  })
}
export function requestSetup2Fa(data: ISetup2FaPayload) {
  return axios.request({
    method: "post",
    url: authEndPoints.setupSet2fa,
    data,
  })
}

export function requestSetupResendOtp(data: ISetupResendOtpPayload) {
  return axios.request({
    method: "post",
    url: authEndPoints.setupResendOtp,
    data,
  })
}
