import useGetSocketVal from '@hooks/common/useGetSocketVal';
import { IUserOrder } from '@redux/sagas/orders';
import { setTradeModalInfo } from '@redux/sagas/trades/tradesSlice';
import { extractTradeModalData } from '@utils/index';
import { useDispatch } from 'react-redux';
import { TransactionType } from 'src/constants/common';

const useRepeatOrder = (token) => {
  const dispatch = useDispatch();

  const { ltp } = useGetSocketVal(Number(token), true)

  const handleRepeatOrder = (item: IUserOrder) => {
    const {
      transactionType,
    } = item;
    dispatch(
      setTradeModalInfo({
        currentAssetItem: extractTradeModalData(item, ltp),
        buySellToggle: transactionType !== TransactionType.B,
        showTradeModal: true,
      })
    );
  }

  return { handleRepeatOrder }
}

export default useRepeatOrder
