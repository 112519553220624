import { convertPascalToLowerCase } from "@utils/index";
import { useCallback } from "react";

const useCSVDownload = (keyArr: string[], fileName = "data") => {
  const convertToCSV = (downloadData) => {
    const csvRows: string[] = [];

    // Add the CSV headers
    const convertedHeader: string[] = keyArr.map((item) => convertPascalToLowerCase(item));
    csvRows.push(convertedHeader.join(','));

    // Add the downloadData rows
    for (let i = 0; i < downloadData.length; i++) {
      const row = downloadData[i];
      const values = keyArr.map((header) => {
        const tempValue = typeof row[header] === "number" ? row[header].toFixed(2) : row[header]
        const escaped = (`${tempValue}`).replace(/"/g, '\\"');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(','));
    }

    return csvRows.join('\n');
  };

  const downloadCSV = useCallback((data) => {
    // Convert the data to CSV format
    const csvData = convertToCSV(data);

    // Create a Blob with the CSV data
    const blob = new Blob([csvData], { type: 'text/csv' });

    // Create a temporary anchor element and set its attributes
    const anchor = document.createElement('a');
    anchor.download = `${fileName}.csv`;
    anchor.href = window.URL.createObjectURL(blob);
    anchor.target = '_blank';
    anchor.click();
  }, []);

  return { downloadCSV }
}

export default useCSVDownload
