import { Menu, createStyles } from '@mantine/core'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Dots, Minus, Plus,
} from 'tabler-icons-react'
import { useFocusWithin } from '@mantine/hooks'
import { IGttOrderDetail, IUserOrder } from '@redux/sagas/orders'
import { setTradeModalInfo } from '@redux/sagas/trades/tradesSlice'
import { IHoldingItem } from '@redux/sagas/holdings/holdingsTypes'
import { IPositionItem } from '@redux/sagas/positions'
import CommonTableDropdown from '@components/table/commonTableDropdown'
import { extractTradeModalData } from '@utils/index'

export const useStyles = createStyles(() => ({
  tableDropDownDots: {
    paddingTop: 5,
    marginLeft: 4,
  },
  tableDropdown: {
    position: "absolute",
  },
}));

interface ITableDropdown {
  row: IUserOrder | IHoldingItem | IGttOrderDetail | IPositionItem,
}

const TradesDropdown = ({
  row,
}: ITableDropdown) => {
  const { classes, cx } = useStyles();
  const dispatch = useDispatch();
  const { ref, focused } = useFocusWithin();

  const [showMenu, setShowMenu] = useState(false);

  const handleShowMenuDropdown = (isOpen: boolean) => {
    setShowMenu(isOpen)
  }
  useEffect(() => {
    handleShowMenuDropdown(focused)
  }, [focused])

  const handleSelectBuy = (item: IUserOrder, isBuy: boolean) => {
    dispatch(
      setTradeModalInfo({
        currentAssetItem: extractTradeModalData(item),
        buySellToggle: isBuy,
        showTradeModal: true,
      })
    );
  }

  return (
    <span ref={ref} className={cx('dots', classes.tableDropDownDots)}>
      <Menu
        shadow="md"
        width={200}
        transitionProps={{ duration: 150 }}
        position="bottom-end"
        withArrow
        offset={-25}
        opened={showMenu}
        onChange={() => handleShowMenuDropdown(!showMenu)}
      >
        <Menu.Target>
          <span>
            <Dots cursor="pointer" />
          </span>
        </Menu.Target>

        <Menu.Dropdown className={classes.tableDropdown}>
          <Menu.Item icon={<Plus size={14} />} onClick={() => handleSelectBuy(row as IUserOrder, false)}>Buy</Menu.Item>
          <Menu.Item
            icon={<Minus size={14} />}
            onClick={() => handleSelectBuy(row as IUserOrder, true)}
          >
            Sell
          </Menu.Item>
          <CommonTableDropdown row={row} />
        </Menu.Dropdown>
      </Menu>
    </span>
  )
}

export default TradesDropdown
