import {
  OrderTypeML, SUCCESS, ValidityRetention,
  productOptions,
} from '@utils/constant';
import { IPositionItem } from '@redux/sagas/positions';
import { regularOrder } from '@redux/sagas/trades/tradesSlice';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@redux/store';
import { useEffect, useState } from 'react';
import { getUserPositions } from '@redux/sagas/positions/positionsSlice';
import { isEqual } from '@utils/index';
import usePrevious from '@hooks/common/usePrevious'
import { cleanNotificationsQueue, showNotification } from '@mantine/notifications';
import axios from '@redux/api/axiosInterceptors';
import { tradesEndPoints } from '@redux/api/apiEndpoints';
import { createSlicedQuantities, extractFreezeQtyFromResponse } from '@utils/orders';

const useExitPosition = (hideCheckbox) => {
  const dispatch = useDispatch();
  const regularOrderState = useSelector((state:RootState) => state.trade.regularOrder)
  const prevProps = usePrevious({ regularOrderState })
  const DELAY = 20
  const [riskUpdatedRows, setRiskUpdatedRows] = useState<IPositionItem[]>([])

  // below 2 functions are used to slice orders of greater than freezeQty (risk management)

  // slicing orders of greater than freezeQty
  // return array with order payload having quantity sliced
  const autoSliceOrders = (orders) => {
    const finalOrdersArray: any[] = []
    for (let i = 0; i < orders.length; i++) {
      const { freezeQty, ...orderWithoutFreezeQty } = orders[i]
      if (freezeQty > 0) {
        const slicedOrders = createSlicedQuantities(orderWithoutFreezeQty.quantity, freezeQty)
        for (let j = 0; j < slicedOrders.length; j++) {
          finalOrdersArray.push({ ...orderWithoutFreezeQty, quantity: slicedOrders[j].toString() })
        }
      } else {
        finalOrdersArray.push(orderWithoutFreezeQty)
      }
    }
    return finalOrdersArray
  }

  // fetching freezeQty for the selected rows
  // and update state riskUpdatedRows with each object having previous object data + freezeQty
  const fetchFreezeQty = async (rows) => {
    try {
      const updatedRowsData = await Promise.all(
        rows.map(async (row) => {
          try {
            const { data } = await axios.post(`${tradesEndPoints.freezeQtyUrl}`, {
              exchange: row.exchange,
              symbol: row.symbol,
              product: row.product,
              token: row.token,
              qty: Math.abs(row.qty)?.toString(),
              order: OrderTypeML[0].value,
              price: "0",
            });
            return { ...row, freezeQty: extractFreezeQtyFromResponse(data) };
          } catch (error) {
            return row;
          }
        })
      );
      setRiskUpdatedRows(updatedRowsData)
    } catch (error) {
      setRiskUpdatedRows(rows)
    }
  };

  const createPayload = (position:IPositionItem & { freezeQty?: number }) => {
    const payload = {
      exchange: position.exchange,
      token: position.token,
      quantity: Math.abs(position.qty)?.toString(),
      price: "0",
      product: position.product,
      transactionType: position.qty > 0 ? "S" : "B",
      order: OrderTypeML[0].value,
      disclosedQty: "0",
      validity: ValidityRetention.DAY,
      amo: false,
      triggerPrice: "",
      tags: "",
      freezeQty: position.freezeQty,
    }
    return payload
  }

  const handleExitPosition = (selectedRows) => {
    const data = riskUpdatedRows || selectedRows
    const isCoverOrderPresent = data.filter((item) => (
      item.product === productOptions[3].value || item.product === productOptions[4].value))
    if (isCoverOrderPresent.length > 0) {
      showNotification({
        color: "orange",
        title: 'Order Failed',
        message: 'Cover and Bracket orders has to be exited from open orders',
      })
    } else {
      const payloads = data.map((item:IPositionItem) => createPayload(item))
      const sortedPayloads = payloads.sort((a) => (a.transactionType === "B" ? -1 : 1))
      const buysOrders = sortedPayloads.filter((item) => item.transactionType === "B")
      const sellOrders = sortedPayloads.filter((item) => item.transactionType === "S")
      // sending buy orders first and then sell orders after a delay
      if (buysOrders.length > 0) {
        const slicedOrders = autoSliceOrders(buysOrders)
        dispatch(regularOrder(slicedOrders))
      }
      if (sellOrders.length > 0) {
        const slicedOrders = autoSliceOrders(sellOrders)
        setTimeout(() => dispatch(regularOrder(slicedOrders)), DELAY)
      }
    }
  };

  // fetching positions after a delay of 750ms
  useEffect(() => {
    cleanNotificationsQueue()
    if (!isEqual(prevProps.regularOrderState.status, regularOrderState.status)
        && regularOrderState.status === SUCCESS) {
      if (!hideCheckbox) {
        setTimeout(() => dispatch(getUserPositions()), 750)
      }
    }
  }, [regularOrderState, prevProps.regularOrderState])

  return { handleExitPosition, fetchFreezeQty };
};

export default useExitPosition;

// NOTE
// Cover and Bracket orders cannot be exited from positions page.
// once these both orders are executed and displayed in positions page,
// then also they have to be exited/cancelled from the open orders tab only.
