import { setTradeModalInfo } from "@redux/sagas/trades/tradesSlice";
import { useDispatch } from "react-redux";
import { extractTradeModalData } from '@utils/index'

interface ITradeHoldingItem {
  qty: number
}

const useTradePositions = <T extends ITradeHoldingItem>() => {
  const dispatch = useDispatch();

  const handleExitPosition = (item: T) => {
    const { qty } = item;
    dispatch(
      setTradeModalInfo({
        currentAssetItem: { ...extractTradeModalData(item), quantity: qty },
        buySellToggle: qty > 0,
        showTradeModal: true,
      })
    );
  };

  const handleAddPosition = (item: T) => {
    const { qty } = item;
    dispatch(
      setTradeModalInfo({
        currentAssetItem: { ...extractTradeModalData(item), quantity: qty },
        buySellToggle: !(qty > 0),
        showTradeModal: true,
      })
    );
  };

  return { handleExitPosition, handleAddPosition }
}

export default useTradePositions
