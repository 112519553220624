import { tableUseStyles } from './styled';

interface TableItemProps {
  value: string | number | JSX.Element
  width: number
  rightAlign?: boolean
  className?: string
  columnBorder?: 'left' | 'right' | null
}
const TableItem = ({
  value, width = 100, rightAlign = true, className = "", columnBorder = null,
}: TableItemProps) => {
  const { cx, classes } = tableUseStyles();
  let columnBorderClass: string | null = null;
  if (columnBorder !== null) {
    if (columnBorder === 'right') {
      columnBorderClass = classes.columnBorderRight;
    } else if (columnBorder === 'left') {
      columnBorderClass = classes.columnBorderLeft;
    }
  }

  return (
    <div style={{ width: `${width}px` }} className={cx('tableDataCells', className, columnBorderClass)}>
      <div className={cx(classes.tableItem, rightAlign ? classes.textRight : classes.textLeft)}>
        {value}
      </div>
    </div>
  )
}

export default TableItem
