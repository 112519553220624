import { useDispatch } from 'react-redux';
import { cancelOrder } from '@redux/sagas/orders/ordersSlice';
import { productOptions } from '@utils/constant';
import { ICancelOrderPayload, IUserOrder } from '@redux/sagas/orders';

const useCancelOrder = () => {
  const dispatch = useDispatch();

  const isCoverOrder = (product:string) => (product === productOptions[3].value)
  const isBracketOrder = (product:string) => (product === productOptions[4].value)

  const createOrderIdList = (list:Array<any>): Array<ICancelOrderPayload> => {
    const newList = list.map((item) => {
      if (isCoverOrder(item.product) || isBracketOrder(item.product)) {
        return { product: item.product, snoOrderid: item.snoOrderID || item.id }
      }
      return { product: item.product, id: item.id }
    })
    return newList
  }

  const onCancelConfirmationPress = (selectedItems: Array<IUserOrder>) => {
    const orderList = createOrderIdList(selectedItems)
    dispatch(cancelOrder(orderList))
  };

  return { onCancelConfirmationPress };
};

export default useCancelOrder;

// NOTE
// For Cover and Bracket orders,once the order io executed
// the snoOrderID is used to cancel the order, while for other orders, the id is used.
