import logoLight from '@assets/images/logo_light.svg';
import logoDark from '@assets/images/logo_dark.svg';
import { useMantineColorScheme } from '@mantine/core';

const Logo = () => {
  const theme = useMantineColorScheme();
  const isDarkMode = theme.colorScheme === 'dark';
  return isDarkMode ? (
    <img src={logoDark} alt="logo" />
  ) : (
    <img src={logoLight} alt="logo" />)
}

export default Logo
