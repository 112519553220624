import { pnlStyles } from '@components/table/styled';
import { clsx } from '@mantine/core';
import { numberWithCommas } from '@utils/index';

type PnlCellType = {
  colorScheme: string,
  netChange: number | string,
  value: string | number
  showPercentage?: boolean
  isDisabled?: boolean
}

const PnLCell = ({
  netChange, value, colorScheme, showPercentage = false, isDisabled = false,
}: PnlCellType) => {
  const { classes } = pnlStyles();
  let className = "";
  if (isDisabled) {
    className = "row-disabled"
  } else if (Number(netChange) > 0) {
    className = `greenColor_${colorScheme}`
  } else if (Number(netChange) < 0) {
    className = `redColor_${colorScheme}`
  } else {
    className = "row-disabled"
  }
  const isInvalidValue = Number(value) === Infinity || Number(value) === -Infinity || Number.isNaN(Number(value));
  return (
    <p className={clsx(classes.text, classes[className])}>
      {isInvalidValue ? "--" : numberWithCommas(Number(value).toFixed(2)) }
      <span className={classes.percent}>{showPercentage && !isInvalidValue ? '%' : ''}</span>
    </p>
  )
}

export default PnLCell;
