import { createStyles } from "@mantine/core";
import theme from "@theme/theme";

const useStyles = createStyles({
  greenColor_dark: {
    color: theme.colors?.colorSuccess?.[0],
  },
  greenColor_light: {
    color: theme.colors?.colorSuccess?.[0],
  },
  redColor_dark: {
    color: theme.colors?.colorDanger?.[0],
  },
  redColor_light: {
    color: theme.colors?.colorDanger?.[0],
  },
});

export default useStyles
