// NOTE: Earlier this hook was also use to fetch tradeMargin,
// but now that logic is extracted to new hook useFetchTradeMargin
// this hook might still do some important things, can be refactored later
import { cleanNotificationsQueue, showNotification } from "@mantine/notifications"
import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  FAILURE, SUCCESS,
} from "src/utils/constant"
import { selectUserPreferences } from "src/redux/sagas/users/userSelectors"
import {
  setTradeModalInfo,
} from "@redux/sagas/trades/tradesSlice"
import type { RootState } from '@redux/store'
import { capitalizeFirstLetter, isEqual } from "@utils/index"
import usePrevious from "./usePrevious"

const audio = new Audio("/mess.mp3")

const useTradeMarginWL = () => {
  const dispatch = useDispatch()

  const {
    regularOrder,
    coverOrder,
    editRegular,
  } = useSelector((state: RootState) => state.trade)

  const {
    data: preferencesData,
  } = useSelector(selectUserPreferences)

  const { tradeModalInfo } = useSelector((state: RootState) => state.trade)

  const prevProps = usePrevious({ regularOrder, editRegular, coverOrder })

  const regularOrderId = regularOrder.status === SUCCESS
    && regularOrder.data
    && regularOrder.data.id;

  const previousRegularOrderId = prevProps.regularOrder.status === SUCCESS
    && prevProps.regularOrder.data
    && prevProps.regularOrder.data.id;

  useEffect(() => {
    cleanNotificationsQueue()
    if (!isEqual(prevProps.regularOrder.status, regularOrder.status) && regularOrder.status === FAILURE) {
      showNotification({
        color: "red",
        title: "Order failed",
        message: capitalizeFirstLetter(regularOrder.message),
      })
    } else if (
      !isEqual(previousRegularOrderId, regularOrderId) && regularOrder.status === SUCCESS
    ) {
      audio.play()
      dispatch(setTradeModalInfo({
        ...tradeModalInfo,
        showTradeModal: preferencesData.stickyOrderWindow,
      }));
    }
  }, [prevProps, regularOrder])

  useEffect(() => {
    cleanNotificationsQueue()
    if (!isEqual(prevProps.editRegular.status, editRegular.status) && editRegular.status === FAILURE) {
      showNotification({
        color: "red",
        title: capitalizeFirstLetter(editRegular.message),
        message: "Update order failed",
      })
    } else if (!isEqual(prevProps.editRegular.status, editRegular.status) && editRegular.status === SUCCESS) {
      audio.play()
      dispatch(setTradeModalInfo({
        ...tradeModalInfo,
        showTradeModal: preferencesData.stickyOrderWindow,
      }))
    }
  }, [prevProps, editRegular])

  const coverOrderId = coverOrder.status === SUCCESS
    && coverOrder.data
    && coverOrder.data.id;

  const previousCoverOrderId = prevProps.coverOrder.status === SUCCESS
    && prevProps.coverOrder.data
    && prevProps.coverOrder.data.id;

  useEffect(() => {
    cleanNotificationsQueue()
    if (!isEqual(prevProps.coverOrder.status, coverOrder.status) && coverOrder.status === FAILURE) {
      showNotification({
        color: "red",
        title: "Order failed",
        message: capitalizeFirstLetter(coverOrder.message),
      })
    } else if (!isEqual(previousCoverOrderId, coverOrderId) && coverOrder.status === SUCCESS) {
      audio.play()
      dispatch(setTradeModalInfo({
        ...tradeModalInfo,
        showTradeModal: preferencesData.stickyOrderWindow,
      }));
    }
  }, [prevProps, coverOrder])

  return null
}

export default useTradeMarginWL
