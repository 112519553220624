import { createStyles } from '@mantine/core';
import { Flex } from '@uikit'
import { Icon } from 'tabler-icons-react';

interface ITitleIcon {
  icon: Icon,
  title: string
  [x: string]: any;
}

const useStyles = createStyles(() => ({
  title: {
    marginLeft: 6,
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const TitleIcon = ({ icon: IconElement, title, ...props }: ITitleIcon) => {
  const { classes, cx } = useStyles();
  return (
    <Flex className={`cursor-pointer ${classes.wrapper}`} {...props}>
      <IconElement size={14} className="text_primary_default" />
      <p className={cx(classes.title, 'heading_3xs_semibold text_primary_default')}>{title}</p>
    </Flex>
  )
}

export default TitleIcon
