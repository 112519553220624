/* eslint-disable import/prefer-default-export */
const validateQty = (filled, total) => {
  if (filled === total) {
    return total;
  }
  if (filled > 0 && filled < total) {
    return `${filled} / ${total}`;
  }
  return total;
}

export { validateQty }
