/* eslint-disable no-nested-ternary */
import {
  Box,
} from "@mantine/core"
import { tableUseStyles } from "src/components/table/styled"
import {
  Table, SortDirection, AutoSizer, WindowScroller,
} from 'react-virtualized';
import {
  useCallback, useEffect, useState,
} from "react";
import { IHoldingItem } from "@redux/sagas/holdings/holdingsTypes";
import { formatSocket, isEqual, numberWithCommas } from "@utils/index";
import PnLCell from "@components/table/pnLCell";
import useCSVDownload from "@hooks/common/useCSVDownload";
import TitleHeading from "@components/atoms/titleHeading";
import NotFoundState from "@components/zeroState/NotFoundZeroState";
import { RootState } from "@redux/store";
import { connect, useSelector } from "react-redux";
import TableHeaderItem from "@components/table/tableHeaderItem";
import TableItem from "@components/table/tableItem";
import { tableContainer } from "@utils/constant";
import useMediaCustom from "@hooks/common/useMediaCustom";
import usePrevious from "@hooks/common/usePrevious";
import { searchTicker } from "@utils/search";
import HoldingIntrument from "./holdingIntrument";
import HoldingCardContainer from "./holdingCards/holdingCardContainer";
import Summary from "./summary"
import { IHoldingTableList } from "./types"

const HoldingTableList = ({
  title,
  loading,
  hydratedData,
  isNoInternet,
  data,
}: IHoldingTableList) => {
  const { cx, theme } = tableUseStyles();
  const { isLargeScreen } = useMediaCustom();

  const [sortedData, setSortedData] = useState<IHoldingItem[]>(hydratedData);
  const [sortInfo, setSortInfo] = useState({
    sortBy: '',
    sortDirection: SortDirection.ASC,
  });
  const [searchVal, setSearchVal] = useState("");
  const showDisabledState = true;
  const positionsStateData = useSelector((state: RootState) => state.position.positions.data);

  const prevProps = usePrevious({ positionsStateData })

  const { downloadCSV } = useCSVDownload(
    ["symbol", "effectiveQty", "avgPrice", "ltp", "currentVal", "pnl", "netChg", "dayChg"],
    "holdings"
  );

  const handleSearchChange = useCallback((e) => {
    const { value } = e.target;
    setSearchVal(value);
  }, []);

  const sortData = useCallback(() => {
    if (sortInfo.sortBy === '') {
      const sortedDataWithZeroQtyAtLast = hydratedData;
      setSortedData(sortedDataWithZeroQtyAtLast);
      return
    }
    const tempSortedData = Array.from(hydratedData).sort((a, b) => {
      if (a[sortInfo.sortBy] < b[sortInfo.sortBy]) {
        return sortInfo.sortDirection === SortDirection.ASC ? -1 : 1;
      }
      if (a[sortInfo.sortBy] > b[sortInfo.sortBy]) {
        return sortInfo.sortDirection === SortDirection.ASC ? 1 : -1;
      }
      return 0;
    });
    setSortedData(tempSortedData);
  }, [sortInfo, hydratedData]);

  useEffect(() => {
    sortData();
  }, [sortInfo]);

  useEffect(() => {
    if ((sortedData.length === 0 && hydratedData.length) || !isEqual(hydratedData, prevProps.positionsStateData)) {
      // check if sort is enabled then sort and set the data
      if (sortInfo.sortBy !== '') {
        sortData();
      } else {
        // else simply set the data
        setSortedData(hydratedData)
      }
    }
  }, [hydratedData]);

  useEffect(() => {
    if (searchVal) {
      const tempSearchData = searchTicker(hydratedData, searchVal)
      setSortedData(tempSearchData)
    }
  }, [hydratedData, searchVal])

  const tableClassName = sortedData.length < 10 ? "table-min-height" : ""

  // Handle sorting
  const handleSort = ({ sortBy: sortFieldBy, sortDirection: sortFieldDirection }) => {
    // Sort the data array based on the selected column
    const tempSortedData = Array.from(sortedData).sort((a, b) => {
      if (a[sortFieldBy] < b[sortFieldBy]) return sortFieldDirection === "ASC" ? -1 : 1;
      if (a[sortFieldBy] > b[sortFieldBy]) return sortFieldDirection === "ASC" ? 1 : -1;
      return 0;
    });
    setSortedData(tempSortedData);
    setSortInfo({
      sortBy: sortFieldBy,
      sortDirection: sortFieldDirection,
    })
  };

  // Return the data for a given row index
  const rowGetter = ({ index }) => sortedData[index];

  const headerRowRenderer = () => (
    <section className="table-header-row sticky">
      <TableHeaderItem
        width={200}
        label="Instruments"
        value="symbol"
        handleSort={handleSort}
        sortInfo={sortInfo}
        rightAlign={false}
        className="pl-10"
      />
      <TableHeaderItem
        width={105}
        label="Qty"
        value="effectiveQty"
        handleSort={handleSort}
        sortInfo={sortInfo}
        columnBorder="left"
      />
      <TableHeaderItem
        width={130}
        label="Avg. Cost"
        value="avgPrice"
        handleSort={handleSort}
        sortInfo={sortInfo}
      />
      <TableHeaderItem
        width={105}
        label="LTP"
        value="ltp"
        handleSort={handleSort}
        sortInfo={sortInfo}
      />
      <TableHeaderItem
        width={120}
        label="Cur. Val"
        value="currentVal"
        handleSort={handleSort}
        sortInfo={sortInfo}
      />
      <TableHeaderItem
        width={120}
        label="P&L"
        value="pnl"
        handleSort={handleSort}
        sortInfo={sortInfo}
      />
      <TableHeaderItem
        width={105}
        label="Net Chg."
        value="netChg"
        handleSort={handleSort}
        sortInfo={sortInfo}
      />
      <TableHeaderItem
        width={105}
        label="Day Chg."
        value="dayChg"
        handleSort={handleSort}
        sortInfo={sortInfo}
        className="pr-10"
      />
    </section>
  )

  // Render each row with multiple columns
  const rowRenderer = ({ index, key, style }) => {
    const row = sortedData[index];
    if (row) {
      const {
        effectiveQty, avgPrice, ltp, currentVal, pnl, netChg, close,
      } = row
      const isRowDisabled = effectiveQty === 0 && showDisabledState;
      const disabledClass = isRowDisabled ? "disabled-row" : "";
      const netChange = (((ltp - close) / close) * 100)

      return (
        <div key={key} style={style} className={cx("table-row", disabledClass)}>
          <HoldingIntrument row={row} />
          <TableItem columnBorder="left" width={105} value={effectiveQty} />
          <TableItem width={130} value={numberWithCommas(avgPrice.toFixed(2))} />
          <TableItem width={105} value={numberWithCommas(ltp.toFixed(2))} />
          <TableItem width={120} value={numberWithCommas(currentVal.toFixed(2))} />
          <TableItem
            width={120}
            value={(
              <PnLCell
                colorScheme={theme.colorScheme}
                netChange={pnl}
                value={(pnl.toFixed(2))}
              />
            )}
          />
          <TableItem
            width={105}
            value={(
              <PnLCell
                colorScheme={theme.colorScheme}
                netChange={netChg}
                value={netChg.toFixed(2)}
                showPercentage
              />
            )}
          />
          <TableItem
            width={105}
            value={(
              <PnLCell
                colorScheme={theme.colorScheme}
                netChange={netChange}
                value={netChange.toFixed(2)}
                showPercentage
              />
            )}
            className="pr-10"
          />
        </div>
      );
    }
    return null
  };

  return (
    <Box mt={12} mb={16} mih={350}>
      <TitleHeading
        title={title}
        length={data.length}
        searchVal={searchVal}
        handleSearchChange={handleSearchChange}
        downloadCSV={() => downloadCSV(hydratedData)}
        loading={Boolean(loading)}
        isNoInternet={isNoInternet}
      />
      <Summary data={hydratedData} />

      {sortedData.length ? (
        isLargeScreen
          ? (
            <section>
              <WindowScroller>
                {({
                  height, isScrolling, onChildScroll, scrollTop,
                }) => (
                  <AutoSizer style={tableContainer} disableHeight>
                    {({ width }) => (
                      <Table
                        className={tableClassName}
                        autoHeight
                        width={width}
                        height={height}
                        rowCount={sortedData.length}
                        rowGetter={rowGetter}
                        rowHeight={50}
                        rowRenderer={rowRenderer}
                        headerRowRenderer={headerRowRenderer}
                        scrollTop={scrollTop}
                        isScrolling={isScrolling}
                        onScroll={onChildScroll}
                      />
                    )}
                  </AutoSizer>
                )}
              </WindowScroller>
            </section>
          )
          : <HoldingCardContainer holdingsData={sortedData} />
      ) : <NotFoundState />}
    </Box>
  )
}

const mapStateToProps = (state: RootState, ownProps: IHoldingTableList) => {
  const hydratedData = ownProps.data.map((item) => {
    const data = state.sockets.quoteSocketData[item.token] || {};
    const {
      effectiveQty, avgPrice,
    } = item;
    const {
      ltp = 0,
      pnl = 0,
      netChg = 0,
      currentVal = 0,
    } = formatSocket(data, effectiveQty, avgPrice)
    return {
      ...item,
      ltp,
      currentVal,
      pnl,
      netChg,
      dayChg: Number(data.netChange || 0.00),
    }
  });
  return { hydratedData }
}

export default connect(mapStateToProps, null)(HoldingTableList)
