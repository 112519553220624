import Drawer from "@uikit/drawer"
import { useDisclosure } from "@mantine/hooks";
import { useState } from "react";
import { IHoldingItem } from "@redux/sagas/holdings/holdingsTypes";
import { IHoldingCardContainerProps } from "./holdingCard/holdingCard.types";
import HoldingCard from "./holdingCard/holdingCard";
import DrawerItems from "./drawerItems/drawerItems";

const HoldingCardContainer = ({ holdingsData }: IHoldingCardContainerProps) => {
  const [opened, { open, close }] = useDisclosure(false);
  const [selectedCard, setSelectedCard] = useState({} as IHoldingItem)

  const handleRowClick = (row: IHoldingItem) => {
    open();
    setSelectedCard(row);
  }

  return (
    <>
      {holdingsData.map((row) => <HoldingCard key={row.symbol} row={row} handleRowClick={handleRowClick} />)}
      <Drawer
        opened={opened}
        onClose={close}
        title="Asset Action"
      >
        <DrawerItems
          selectedCard={selectedCard}
          close={close}
        />
      </Drawer>
    </>
  )
}

export default HoldingCardContainer
