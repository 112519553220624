import { call, put } from "redux-saga/effects"
import { filterErrorResponse, isLocalhost, setLoginCookie } from "@utils/index"
import { showNotification } from "@mantine/notifications"

import {
  requestInitiateSetup,
  requestSetupVerifyOtp,
  requestSetupPassword,
  requestSetup2Fa,
  requestSetupResendOtp,
} from "./setupRequest"

import {
  setupInitiateSuccess,
  setupInitiateFailure,
  setupVerifyOTPSuccess,
  setupVerifyOTPFailure,
  setupPasswordSuccess,
  setupPasswordFailure,
  setup2FaSuccess,
  setup2FaFailure,
  setupResendOtpSuccess,
  setupResendOtpFailure,
} from './setupSlice'

import {
  IActionSetupInitiate,
  IActionSetupVerifyOTP,
  IActionSetupPassword,
  IActionSetup2Fa,
  IActionSetupResendOtp,
} from "."

export function* handleSetupInitiate(action: IActionSetupInitiate): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestInitiateSetup, action.payload)
    yield put(setupInitiateSuccess({ data, status }))
  } catch (error: any) {
    const errorData = filterErrorResponse(error)
    showNotification({
      color: "red",
      title: 'Failed',
      message: errorData.message,
    })
    yield put(setupInitiateFailure(errorData))
  }
}

export function* handleSetupOtp(action: IActionSetupVerifyOTP): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestSetupVerifyOtp, action.payload);
    yield put(setupVerifyOTPSuccess({ data, status }));
  } catch (error: any) {
    const errorData = filterErrorResponse(error)
    showNotification({
      color: "red",
      title: 'Failed',
      message: errorData.message,
    })
    yield put(setupVerifyOTPFailure(errorData))
  }
}

export function* handleSetupPassword(action: IActionSetupPassword): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestSetupPassword, action.payload);
    yield put(setupPasswordSuccess({ data, status }));
  } catch (error: any) {
    const errorData = filterErrorResponse(error)
    showNotification({
      color: "red",
      title: 'Failed',
      message: errorData.message,
    })
    yield put(setupPasswordFailure(errorData))
  }
}

export function* handleSetup2Fa(action: IActionSetup2Fa): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestSetup2Fa, action.payload);
    yield put(setup2FaSuccess({ data, status }));
    if (isLocalhost()) {
      setLoginCookie(data)
    }
  } catch (error: any) {
    const errorData = filterErrorResponse(error)
    showNotification({
      color: "red",
      title: 'Failed',
      message: errorData.message,
    })
    yield put(setup2FaFailure(errorData))
  }
}

export function* handleSetupResendOtp(action: IActionSetupResendOtp): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestSetupResendOtp, action.payload);
    yield put(setupResendOtpSuccess({ data, status }));
    showNotification({
      color: "green",
      title: 'Success',
      message: data.message,
    })
  } catch (error: any) {
    const errorData = filterErrorResponse(error)
    showNotification({
      color: "red",
      title: 'Failed',
      message: errorData.message,
    })
    yield put(setupResendOtpFailure(errorData))
  }
}
