import {
  TextInput,
  Flex,
  Button,
} from '@mantine/core'
import { useSelector } from 'react-redux';
import { RootState } from '@redux/store';
import useStyles from './styles'

interface TotpSetup2FaInputProps {
  totpVal: string;
  setTotpVal: (value: string) => void;
  handleVerifyTOTP: () => void;
  totpSourceValue: "app" | "external";
}

const TotpSetup2FaInput = ({
  totpVal, setTotpVal, handleVerifyTOTP, totpSourceValue,
}:TotpSetup2FaInputProps) => {
  const { classes } = useStyles()
  const setup2faState = useSelector((state: RootState) => state.setup.set2Fa);
  const setup2faStateAuth = useSelector((state: RootState) => state.auth.setup2fa);
  const isLoading = setup2faState.loading || setup2faStateAuth.loading
  const isAppCodeSelected = totpSourceValue === "app";
  const inputLabel = isAppCodeSelected ? "Enter 6 digit App code" : "Enter 6 digit TOTP";

  const handleTOTPChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setTotpVal(value);
  };

  return (
    <div className={classes.inputDetailsWrapper}>
      <TextInput
        label={inputLabel}
        id="totp-input"
        withAsterisk
        type="text"
        placeholder="123456"
        maxLength={6}
        value={totpVal}
        inputMode="numeric"
        pattern="[0-9]*"
        width="100%"
        onChange={handleTOTPChange}
      />
      <Flex justify="space-between">
        <Button
          fullWidth
          onClick={handleVerifyTOTP}
          mt={10}
          mb={10}
          loading={isLoading}
          disabled={totpVal.length !== 6}
        >
          Enable 2FA
        </Button>
      </Flex>
    </div>
  )
}

export default TotpSetup2FaInput
