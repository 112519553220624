import { Box, Flex } from "@mantine/core"
import {
  ReactNode, useEffect, useState, useMemo,
} from "react"
import { useSelector, useDispatch } from "react-redux"
import { RootState } from "src/redux/store"
import useRouteChange from "@hooks/common/useRouteChange"
import PositionZeroState from "@components/zeroState/positionZeroState"
import ErrorElement from "@components/errorElement"
import Loading from "@components/loading/loading"
import { getUserPositions } from "@redux/sagas/positions/positionsSlice"
import useMediaCustom from "@hooks/common/useMediaCustom"
import PositionDetails from "@components/modals/position/positionDetails"
import { IExtendedPositionItem } from "@redux/sagas/positions"
import ConvertPostion from "@components/modals/position/convertPosition"
import RenderBoundary from "@components/renderBoundary/renderBoundary"
import { FAILURE } from "@utils/constant"
import { useSubUnsub, useDeepCompareMemoize } from "@hooks/sockets"
import PositionTableList from "./positionTableList"
// import PositionDaysHistoryTable from "./positionDaysHistorytable"

const PositionContainer = ({ children }: { children: ReactNode }) => {
  const { isLargeScreen } = useMediaCustom();
  return (
    <Box
      sx={{ height: '100%' }}
      p={isLargeScreen ? "lg" : "sm"}
    >
      {children}
    </Box>
  )
}

const Positions = () => {
  const dispatch = useDispatch()
  const { isNavigateLoading } = useRouteChange()
  const [showPostionDetails, setShowPostionDetails] = useState(false)
  const [showConvertPosition, setShowConvertPosition] = useState(false)
  const [selectedPositionDetails, setSelectedPositionDetails] = useState<IExtendedPositionItem>(
    {} as IExtendedPositionItem
  )
  const positionsState = useSelector((state: RootState) => state.position.positions)
  const { status, data, loading } = positionsState

  useEffect(() => {
    dispatch(getUserPositions())
  }, [])

  const tokens = useMemo(() => {
    const tokensList = data?.map((item: any) => item.token)
    return tokensList || []
  }, [useDeepCompareMemoize(data)]);

  useSubUnsub(tokens, window.location.pathname);

  const handlePositionDetails = (item: IExtendedPositionItem) => {
    setShowPostionDetails(true)
    setSelectedPositionDetails(item)
  }

  const handlePositionDetailsClose = () => {
    setShowPostionDetails(false)
  }

  const handleConvertPosition = (item: IExtendedPositionItem) => {
    setSelectedPositionDetails(item)
    setShowConvertPosition(true);
  }

  const handleCloseConvertPosition = () => {
    setShowConvertPosition(false);
  }

  const secondLoading = (loading && isNavigateLoading && data && data.length)

  return (
    <RenderBoundary
      loading={loading}
      status={status}
      data={data}
      errorState={(
        <PositionContainer>
          <Flex>
            <ErrorElement
              component="Positions"
              message={status === FAILURE && positionsState.message}
            />
          </Flex>
        </PositionContainer>
      )}
    >
      {data && data.length === 0 ? (
        // zero state
        <PositionContainer>
          <PositionZeroState title="You don't have any positions yet" />
          {loading && <Loading />}
        </PositionContainer>
      ) : (
        // data state
        <PositionContainer>
          <PositionTableList
            title="Positions"
            data={data!}
            loading={secondLoading || false}
            hydratedData={[]}
            isNoInternet={!!(status === FAILURE && data?.length)}
            handlePositionDetails={handlePositionDetails}
            handleConvertPosition={handleConvertPosition}
            showDisabledState
          />
          {/* commenting out day history section */}
          {/* <Box mt={24}>
            <PositionDaysHistoryTable
              hideCheckbox
              title="Day's history"
              data={data!}
              loading={secondLoading || false}
              hydratedData={[]}
              isNoInternet={!!(status === FAILURE && data?.length)}
              handlePositionDetails={handlePositionDetails}
              handleConvertPosition={handleConvertPosition}
              showDisabledState
              isCollapsible
            />
          </Box> */}
          {showPostionDetails ? (
            <PositionDetails
              isVisible={showPostionDetails}
              handlePositionDetailsClose={handlePositionDetailsClose}
              selectedPositionDetails={selectedPositionDetails}
              handleConvertPosition={handleConvertPosition}
            />
          ) : null}

          {showConvertPosition ? (
            <ConvertPostion
              isVisible={showConvertPosition}
              handleCloseConvertPosition={handleCloseConvertPosition}
              selectedPositionDetails={selectedPositionDetails}
            />
          ) : null}
        </PositionContainer>
      )}
    </RenderBoundary>
  );
}

export default Positions
