import { useState, useEffect } from 'react';
import { isEqual } from '@utils/index';
import { Flex } from '@uikit';
import useStyles from './summary.styles';
import SummaryItem from './summaryItem';
import { ISummary } from '../types';

const Summary = ({ data }: ISummary) => {
  const { classes } = useStyles();
  const [tokens, setTokens] = useState<number[]>([]);

  const [totals, setTotals] = useState({
    totalInvestment: 0,
    currentValue: 0,
    daysPnL: 0,
    totalPnL: 0,
    daysPnLPercentage: 0,
    totalPnLPercentage: 0,
  });

  useEffect(() => {
    if (!data) {
      return;
    }

    const holdingsTokens: number[] = [];
    let tempTotalInvestment = 0;
    let tempCurrentValue = 0;
    let tempDaysPnL = 0;
    let tempTotalPnL = 0;

    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      if (item) {
        const {
          effectiveQty, avgPrice, token, close, ltp, pnl,
        } = item;
        holdingsTokens.push(token);
        tempTotalInvestment += Number(avgPrice) * Number(effectiveQty);
        tempCurrentValue += ltp * effectiveQty;
        tempDaysPnL += (ltp - close) * effectiveQty;
        tempTotalPnL += pnl;
      }
    }

    const daysPnLPercentage = (tempDaysPnL / tempTotalInvestment) * 100;
    const totalPnLPercentage = (tempTotalPnL / tempTotalInvestment) * 100;

    setTotals({
      totalInvestment: tempTotalInvestment,
      currentValue: tempCurrentValue,
      daysPnL: tempDaysPnL,
      totalPnL: tempTotalPnL,
      daysPnLPercentage,
      totalPnLPercentage,
    });

    if (!isEqual(tokens, holdingsTokens)) {
      setTokens(holdingsTokens);
    }
  }, [data, tokens]);

  return (
    <Flex className={classes.container}>
      <SummaryItem title="Total investment" value={totals.totalInvestment} />
      <SummaryItem title="Current value" value={totals.currentValue} />
      <SummaryItem
        title="Day's P&L"
        value={totals.daysPnL}
        percentageValue={totals.daysPnLPercentage}
        isColorApplicable
      />
      <SummaryItem
        title="Total P&L"
        value={totals.totalPnL}
        percentageValue={totals.totalPnLPercentage}
        isColorApplicable
      />
    </Flex>
  );
};

export default Summary;
