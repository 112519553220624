import { setTradeModalInfo } from "@redux/sagas/trades/tradesSlice";
import { useDispatch } from "react-redux";
import { extractTradeModalData } from '@utils/index'

interface ITradeHoldingItem {
  effectiveQty: number
}

const useTradeHoldings = <T extends ITradeHoldingItem>() => {
  const dispatch = useDispatch();

  const handleBuyHolding = (item: T) => {
    const { effectiveQty } = item;
    dispatch(
      setTradeModalInfo({
        currentAssetItem: {
          ...extractTradeModalData(item),
          quantity: effectiveQty,
          product: 'C',
        },
        buySellToggle: false,
        showTradeModal: true,
      })
    );
  };

  const handleSellHolding = (item: T) => {
    const { effectiveQty } = item;
    dispatch(
      setTradeModalInfo({
        currentAssetItem: {
          ...extractTradeModalData(item),
          quantity: effectiveQty,
          product: 'C',
        },
        buySellToggle: true,
        showTradeModal: true,
      })
    );
  };

  return { handleBuyHolding, handleSellHolding }
}

export default useTradeHoldings
