export const baseURL = process.env.REACT_APP_BASE_URL
export const omsURL = "oms"
export const authURL = "auth"
export const consoleUrl = "console"

export const authEndPoints = {
  login: `${authURL}/login`,
  appLogin: `${authURL}/app/login`,
  appLoginGenerateToken: `${authURL}/app/generate-token`,
  forgotPassword: `${authURL}/forgot-password`,
  resendOtp: `${authURL}/resend-otp`,
  logout: `${authURL}/logout`,
  captchaUrl: `${authURL}/captcha`,
  reloadCaptchaUrl: `${authURL}/captcha-reload`,
  resetPasswordUrl: `${authURL}/reset-password`,
  validateResetUrl: `${authURL}/validate-reset-request`,
  validate2faUrl: `${authURL}/validate-2fa`,
  setup2faUrl: `${authURL}/set-user-2fa`,

  setupInitiateRequest: `${authURL}/setup/initiate`,
  setupVerifyOTP: `${authURL}/setup/verify`,
  setupPassword: `${authURL}/setup/set-password`,
  setupSet2fa: `${authURL}/setup/set-2fa`,
  setupResendOtp: `${authURL}/setup/resend-otp
`,
}

export const watchlistsEndPoints = {
  mwListUrl: `${omsURL}/mw/list`,
  mwUrl: `${omsURL}/mw/`,
  addMwItemUrl: `${omsURL}/mw/\${mwId}/add`,
  updateSequenceUrl: `${omsURL}/mw/\${mwId}/update-sequence`,
}

export const usersEndPoints = {
  userLimitsUrl: `${omsURL}/user/limits`,
  userPreferencesUrl: `${omsURL}/user/preferences`,
  userDetailsUrl: `${omsURL}/user/details`,
  userAddImageUrl: `${omsURL}/user/add-image`,
  enable2faUrl: `${authURL}/enable-2fa`,
  verify2faUrl: `${authURL}/verify-2fa`,
  changePasswordUrl: `${authURL}/change-password`,
  notices: `${omsURL}/notices`,
  readNotice: `${omsURL}/notices/read`,
  contractNote: `${consoleUrl}/documents/contract-note`,
  payout: `api/payout`,
}

export const tradesEndPoints = {
  regularOrderUrl: `${omsURL}/order/regular`,
  coverOrderUrl: `${omsURL}/order/cover`,
  bracketOrderUrl: `${omsURL}/order/bracket`,
  orderMarginUrl: `${omsURL}/margin/order`,
  convertPositionUrl: `${omsURL}/order/convert`,
  freezeQtyUrl: `${omsURL}/info/risk`,
}

export const ordersEndPoints = {
  userOrdersUrl: `${omsURL}/user/orders`,
  orderHistoryUrl: `${omsURL}/order/`,
  userTradesUrl: `${omsURL}/user/trades`,
  alertOcoUrl: `${omsURL}/alert/oco`,
  alertSingleUrl: `${omsURL}/alert/gtt`,
  gttDetailsUrl: `${omsURL}/alert/details`,
  deleteGttUrl: `${omsURL}/alert/`,
}

export const positionsEndPoints = {
  userPositionsUrl: `${omsURL}/user/positions`,
}

export const holdingsEndPoints = {
  userHoldingsUrl: `${omsURL}/user/holdings`,
}
