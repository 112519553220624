import { DrawerProps, Drawer as TuiDrawer } from "@mantine/core"
import { ComponentType } from "react"

interface IDrawerProps extends DrawerProps {
  position?: "top" | "left" | "bottom" | "right" | undefined;
}

const Drawer: ComponentType<IDrawerProps> = ({ position = "bottom", ...props }) => (
  <TuiDrawer overlayProps={{ opacity: 0.6, blur: 1 }} position={position} {...props} />
)

export default Drawer
