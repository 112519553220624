import { TextProps, Text as TuiText } from "@mantine/core"
import { ComponentType } from "react"

interface ITextProps extends TextProps {
  className?: string
}

const Text: ComponentType<ITextProps> = ({ className = "text_fg_subtle", ...props }) => (
  <TuiText className={className} {...props} />
)

export default Text
