import { OrderTypeML, OrderTypeSLM, varietyValues } from "../../utils/constant"

export const handleTriggerPriceState = (
  activeTab: string,
  orderTypeMLVal: string
) => {
  if (activeTab === varietyValues.COVER) {
    return false
  } if (
    orderTypeMLVal === OrderTypeML[0].value
    || orderTypeMLVal === OrderTypeML[1].value
  ) {
    return true
  }
  return false
}

export const handleTriggerPriceError = (
  triggerPriceField: number | undefined,
  priceField: number | undefined,
  orderTypeMLVal: string,
  buySellToggle: boolean
) => {
  if (orderTypeMLVal === OrderTypeSLM[0]?.value) {
    // buy order
    if (!buySellToggle && triggerPriceField! > priceField!) {
      return "Trigger price can't be greater than price"
      // sell order
    } if (buySellToggle && triggerPriceField! < priceField!) {
      return "Trigger price can't be lesser than price"
    }
  } return ""
}

export const handlePriceState = (orderTypeMLVal: string) => {
  if (orderTypeMLVal === OrderTypeML[0]?.value
    || orderTypeMLVal === OrderTypeSLM[1]?.value) {
    return true
  }
  return false
}
