/* eslint-disable react/no-unstable-nested-components */
import {
  Badge,
  Box,
  createStyles,
} from "@mantine/core"
import {
  Table, SortDirection, WindowScroller, AutoSizer,
} from 'react-virtualized';
import {
  memo, useEffect, useRef, useState,
} from "react";
import useCSVDownload from "@hooks/common/useCSVDownload";
import TitleHeading from "@components/atoms/titleHeading";
import { IUserOrder, IUserOrders } from "@redux/sagas/orders"
import NotFoundState from "@components/zeroState/NotFoundZeroState";
import TableHeaderItem from "@components/table/tableHeaderItem";
import TableItem from "@components/table/tableItem";
import { getTypographyStyles } from "@theme/themeConfig";
import {
  productType, orderStatusColor, SHOW_MORE_COUNT, tableContainer,
} from "@utils/constant"
import { isPartialExecution } from "@utils/index";
import useMediaCustom from "@hooks/common/useMediaCustom";
import { BuySellConst } from "@constants"
import { searchTicker } from "@utils/search";
import OrderInstrument from "./orderIntrument";
import ExecutedCardContainer from "./executedCard/executedCardContainer";

type OpenOrderTableProps = {
  type: string
  title: string;
  data: IUserOrders;
  handleOrderDetailsModal: (item: IUserOrder) => void;
  loading?: number | boolean
  isNoInternet: boolean
};

const useStyles = createStyles((theme) => ({
  showMoreLess: {
    ...getTypographyStyles('content_md_regular'),
    color: theme.other.colors.primary.default,
    cursor: 'pointer',
    textAlign: 'center',
    height: 45,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderBottom: `1px solid ${theme.other.colors.disabled.muted}`,
  },
}))

const OrderTableList = ({
  type,
  title,
  data,
  handleOrderDetailsModal,
  loading,
  isNoInternet,
}: OpenOrderTableProps) => {
  const { classes } = useStyles();
  const { isLargeScreen } = useMediaCustom();

  const [sortedData, setSortedData] = useState<IUserOrders>([]);
  const [sortInfo, setSortInfo] = useState({
    sortBy: '',
    sortDirection: SortDirection.ASC,
  });
  const [searchVal, setSearchVal] = useState("");
  const [showAllData, setShowAllData] = useState(false);

  const { downloadCSV } = useCSVDownload(
    ["timeStamp", "transactionType", "symbol", "product", "quantity", "averagePrice", "orderStatus"],
    "orders"
  );

  let tableData: IUserOrders = [];
  if (sortedData.length) {
    tableData = sortedData
  } else if (sortedData.length === 0 && searchVal.length) {
    tableData = [];
  } else {
    tableData = data
  }

  const [manipulatedTable, setManipulatedTable] = useState<IUserOrders>([]);
  // const manipulatedTableRef = useRef<IUserOrders>(tableData);

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchVal(value)
    const tempSearchData = searchTicker(data, value);
    setManipulatedTable(tempSearchData.slice(0, showAllData ? undefined : SHOW_MORE_COUNT))
    setSortedData(tempSearchData);
  }

  const handleSort = ({ sortBy: sortFieldBy, sortDirection: sortFieldDirection }) => {
    // Sort the data array based on the selected column
    const tempSortedData = Array.from(tableData).sort((a, b) => {
      if (a[sortFieldBy] < b[sortFieldBy]) return sortFieldDirection === "ASC" ? -1 : 1;
      if (a[sortFieldBy] > b[sortFieldBy]) return sortFieldDirection === "ASC" ? 1 : -1;
      return 0;
    });
    // manipulatedTableRef.current = tempSortedData;
    setManipulatedTable(tempSortedData.slice(0, showAllData ? undefined : SHOW_MORE_COUNT))
    setSortedData(tempSortedData);
    setSortInfo({
      sortBy: sortFieldBy,
      sortDirection: sortFieldDirection,
    })
  };

  const handleShowMoreTable = (isShowAll: boolean) => {
    // manipulatedTableRef.current = [...tableData].slice(0, isShowAll ? -1 : SHOW_MORE_COUNT)
    const tempData = [...tableData].slice(0, isShowAll ? undefined : SHOW_MORE_COUNT)
    setManipulatedTable(tempData);
  }

  const handleShowMore = (isShowAll: boolean) => {
    setShowAllData(isShowAll);
    handleShowMoreTable(isShowAll)
  }
  const showMoreLessCheck = tableData.length >= SHOW_MORE_COUNT + 1

  const inititalRender = useRef(false);
  useEffect(() => {
    if (!inititalRender.current) {
      handleShowMore(false);
      inititalRender.current = true
    } else {
      handleShowMore(!showMoreLessCheck);
    }
  }, [data]);

  const tableClassName = manipulatedTable.length < 10 ? "table-min-height" : ""

  // Return the data for a given row index
  const rowGetter = ({ index }) => manipulatedTable[index];

  const headerRowRenderer = () => (
    <section className="table-header-row">
      <TableHeaderItem
        width={120}
        label="Time"
        value="timeStamp"
        handleSort={handleSort}
        sortInfo={sortInfo}
        rightAlign={false}
        className="pl-10"
      />
      <TableHeaderItem
        width={120}
        label="Type"
        value="transactionType"
        handleSort={handleSort}
        sortInfo={sortInfo}
        rightAlign={false}
      />
      <TableHeaderItem
        width={280}
        label="Instrument"
        value="symbol"
        handleSort={handleSort}
        sortInfo={sortInfo}
        rightAlign={false}
      />
      <TableHeaderItem
        width={85}
        label="Product"
        value="product"
        handleSort={handleSort}
        sortInfo={sortInfo}
        rightAlign={false}
      />
      <TableHeaderItem
        width={105}
        label="Qty."
        value="quantity"
        handleSort={handleSort}
        sortInfo={sortInfo}
      />
      <TableHeaderItem
        width={125}
        label="Avg.Price"
        value="averagePrice"
        handleSort={handleSort}
        sortInfo={sortInfo}
      />
      <TableHeaderItem
        width={145}
        label="Status"
        value="orderStatus"
        handleSort={handleSort}
        sortInfo={sortInfo}
        className="pr-10"
        columnBorder="left"
      />
    </section>
  )

  // Render each row with multiple columns
  const rowRenderer = ({ index, key, style }) => {
    const row = manipulatedTable[index];
    if (row) {
      return (
        <div key={key} style={style} className="table-row">
          <TableItem width={120} value={row.timeStamp.slice(0, 9)} rightAlign={false} className="pl-10" />
          <TableItem
            width={120}
            value={(
              <Badge size="md" radius="xs" color={row.transactionType === "B" ? "blue" : "red"}>
                {BuySellConst[row.transactionType]}
              </Badge>
            )}
            rightAlign={false}
          />
          <OrderInstrument
            width={280}
            row={row}
            handleOrderDetailsModal={handleOrderDetailsModal}
            type={type}
          />
          <TableItem width={85} value={productType[row.product]} rightAlign={false} />
          <TableItem
            width={105}
            value={`${row.fillShares || 0} / ${row.quantity}`}
          />
          <TableItem width={125} value={row.averagePrice?.toFixed(2) || '0.00'} />
          <TableItem
            width={145}
            value={(
              <Badge
                radius="xs"
                color={
                  orderStatusColor[isPartialExecution(row.cancelQuantity, row.fillShares) ? 'PARTIAL' : row.orderStatus]
                }
                onClick={() => handleOrderDetailsModal(row)}
                className="cursor-pointer"
              >
                {isPartialExecution(row.cancelQuantity, row.fillShares) ? 'PARTIALLY EXECUTED' : row.orderStatus}
              </Badge>
            )}
            className="pr-10"
            columnBorder="left"
          />
        </div>
      );
    }
    return null
  };

  return (
    <Box mt={12} mb={20}>
      <TitleHeading
        title={title}
        length={tableData.length}
        searchVal={searchVal}
        handleSearchChange={handleSearchChange}
        downloadCSV={() => downloadCSV(data)}
        loading={Boolean(loading)}
        isNoInternet={isNoInternet}
      />
      {tableData.length ? (
        <Box>
          {isLargeScreen ? (
            <WindowScroller>
              {({
                height, registerChild, isScrolling, onChildScroll, scrollTop,
              }) => (
                <AutoSizer style={tableContainer} ref={() => registerChild(null)} disableHeight>
                  {({ width }) => (
                    <Table
                      className={tableClassName}
                      autoHeight
                      width={width}
                      height={height}
                      rowCount={manipulatedTable.length}
                      rowGetter={rowGetter}
                      rowHeight={50}
                      rowRenderer={rowRenderer}
                      headerRowRenderer={headerRowRenderer}
                      scrollTop={scrollTop}
                      isScrolling={isScrolling}
                      onScroll={onChildScroll}
                    />
                  )}
                </AutoSizer>
              )}
            </WindowScroller>
          ) : (
            <ExecutedCardContainer
              executedData={manipulatedTable}
              handleOrderDetailsModal={handleOrderDetailsModal}
            />
          )}

          {showMoreLessCheck ? (
            <p className={classes.showMoreLess} aria-hidden="true" onClick={() => handleShowMore(!showAllData)}>
              Show
              {' '}
              {showAllData ? "Less" : "More"}
            </p>
          ) : null}
        </Box>
      ) : <NotFoundState />}
    </Box>
  )
}
export default memo(OrderTableList)
