import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => {
  const { colors } = theme.other
  return {
    totalContainer: {
      display: 'flex',
      flexDirection: 'row-reverse',
      height: 45,
      alignItems: 'center',
      borderBottom: `1px solid ${colors.disabled.muted}`,
      fontSize: 14,
      [`@media (max-width: ${theme.breakpoints.md}px)`]: {
        height: 'auto',
        flexDirection: 'column-reverse',
        border: `1px solid ${colors.disabled.muted}`,
        padding: 12,
      },
    },
    fixContainer: {
      position: "fixed",
      height: "2.625rem",
      bottom: "0",
      left: "26.0625rem",
      width: "calc(100% - 435px)",
      borderTop: `1px solid ${colors.disabled.muted}`,
      background: colors.base.white,
      zIndex: 1,
      [`@media (min-width: ${theme.breakpoints.semixl}px)`]: {
        left: "calc((100% - 1440px) / 2 + 26.0625rem)",
        width: "62.8125rem",
      },
    },
    fixContainerMobile: {
      zIndex: 1,
      position: "fixed",
      bottom: "0",
      width: "calc(100% - 20px)",
      background: colors.base.white,
    },
    totalText: {
      width: 100,
      textAlign: 'right',
      marginRight: 5,
      [`@media (max-width: ${theme.breakpoints.md}px)`]: {
        width: '100%',
        textAlign: 'center',
        marginRight: 0,
      },
    },
    totalValue: {
      marginRight: 135,
      width: 120,
      textAlign: 'right',
      [`@media (max-width: ${theme.breakpoints.md}px)`]: {
        marginRight: 0,
        textAlign: 'center',
      },
    },
    tableColumnBgBlue: {
      background: "#F5FAFF",
    },
    checkboxInput: {
      '&:disabled': {
        backgroundColor: colors.bg.muted,
      },
    },
    qtyBuyColor: {
      color: theme.other.colors.blue.default,
    },
    qtySellColor: {
      color: theme.other.colors.error.default,
    },
    qtyDisabledColor: {
      color: theme.other.colors.disabled.default,
    },
  }
});

export default useStyles;
