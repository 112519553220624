import {
  useCallback, useEffect, useRef, useState,
} from "react";
import { useSelector } from "react-redux";
import type { RootState } from "src/redux/store";
import { IGttSearchItem } from "src/redux/sagas/misc";

const useMarketDepthShortcut = (handleMarketDepthCall: (item: IGttSearchItem) => void) => {
  const { gttSearchResults } = useSelector((state: RootState) => state.misc);
  const selectedSearchWLIndexRef = useRef(-1);
  const tempSearchWatchlists = useRef<IGttSearchItem[]>([]);
  const [selectedWLItemIndex, setSelectedWLItemIndex] = useState(-1);

  // to hold the search result data to use in events
  useEffect(() => {
    tempSearchWatchlists.current = gttSearchResults;
  }, [gttSearchResults]);

  const handleWLItemIndex = (value: number) => {
    setSelectedWLItemIndex(value);
    selectedSearchWLIndexRef.current = value;
  };

  // handling marketdepth search result arrow shortcut
  const memoized = useCallback(
    () => {
      document.addEventListener(
        "keydown",
        (event) => {
          const name = event.key;
          const tempSelectedWlIndex = selectedSearchWLIndexRef?.current;

          switch (name) {
            case "ArrowDown":
              if (
                tempSelectedWlIndex
                === tempSearchWatchlists.current.length - 1
              ) {
                handleWLItemIndex(0);
              } else {
                handleWLItemIndex(tempSelectedWlIndex + 1);
              }
              return;
            case "ArrowUp":
              if (tempSelectedWlIndex === 0) {
                handleWLItemIndex(tempSearchWatchlists.current.length - 1);
              } else {
                handleWLItemIndex(tempSelectedWlIndex - 1);
              }
              return;
            case "Enter": {
              const tempSelectedWLData = tempSearchWatchlists.current[tempSelectedWlIndex] || {};
              if (Object.keys(tempSelectedWLData)?.length) {
                handleMarketDepthCall(tempSelectedWLData)
              }
              break;
            }

            default:
              break;
          }
        },
        false
      );
    },
    []
  );

  useEffect(() => {
    memoized();
  }, []);

  return { selectedWLItemIndex }
}

export default useMarketDepthShortcut;
