import { Tooltip, createStyles } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import React from 'react'
import { getTernaryValue } from 'src/utils';

type ClickHandlerEventType = React.MouseEvent<HTMLButtonElement | HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>;

type SymbolButtonProps = {
  title: React.ReactNode;
  onClick: (event: ClickHandlerEventType) => void;
  hoverText: string;
  isDisabled?: boolean;
  color?: string;
  isSearch: boolean;
};

interface ThProps {
  color: string
  isDisabled: boolean
  isSearch: boolean
}

const useStyles = createStyles((theme, { color, isDisabled, isSearch }: ThProps) => ({
  symbolItem: {
    padding: getTernaryValue(
      [Boolean(color) && isSearch, Boolean(color) && !isSearch],
      ["0.063rem 0.875rem", "0.125rem 0.875rem", "0.313rem 0.563rem"]
    ),
    height: isSearch ? "1.25rem" : "1.75rem",
    border: color
      ? "none"
      : `1px solid ${theme.colorScheme === "dark"
        ? theme.colors.borderSecond[1]
        : theme.colors.borderSecond[0]
      }`,
    borderRadius: 2,
    margin: "auto 0.25rem",
    display: "flex",
    alignItems: "center",
    color: getTernaryValue(
      [Boolean(color), theme.colorScheme === "dark"],
      ["#ffffff", theme.colors.colorPrimary[1], theme.colors.colorPrimary[0]]
    ),
    fontSize: '0.7rem',
    background: isDisabled ? "#bababa" : color || "transparent",
    pointerEvents: isDisabled ? "none" : "inherit",
    cursor: "pointer",
    transform: "scale(1)",
    transition: "all 0.2s ease-in",
    opacity: 0.85,
    "&:hover": {
      backgroundColor: getTernaryValue(
        [theme.colorScheme === "dark", Boolean(color)],
        [theme.colors.secondaryLightBackground[0], "", "#eee"]
      ),
      transform: "scale(1.05)",
      transition: "all 0.15s ease-in",
      opacity: 1,
    },
  },
}))

const SymbolButton = ({
  title, onClick, hoverText, isSearch, isDisabled = false, color = "",
}: SymbolButtonProps) => {
  const { classes } = useStyles({ color, isDisabled, isSearch })
  const [, { close, open }] = useDisclosure(false);

  return (
    <Tooltip
      label={hoverText}
      withArrow
      transitionProps={{ transition: 'slide-up', duration: 150 }}
      offset={5}
    >
      <div
        role="presentation"
        onMouseEnter={open}
        onMouseLeave={close}
        className={classes.symbolItem}
        onClick={onClick}
        onKeyDown={onClick}
      >
        {title}
      </div>
    </Tooltip>
  )
}

export default SymbolButton
