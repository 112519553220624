import {
  Copy, InfoCircle, Edit, X, Settings, AlignCenter, TrendingUp, Telescope,
} from 'tabler-icons-react'
import { useRepeatOrder } from '@hooks'
import { DrawerItem } from '@components/drawers'
import {
  useAddToWatchlist, useCreateGttModal, useModifyOrder, useRedirectChart,
} from '@hooks/orders'
import useMarketDepth from '@hooks/common/useMarketDepth'
import { IUserOrder } from '@redux/sagas/orders'
import IDrawerItemsProps from './drawerItems.types'

const DrawerItems = ({
  selectedCard, close, handleOrderDetailsModal, handleShowCancel,
}: IDrawerItemsProps) => {
  const { token, exchange } = selectedCard

  const { handleRepeatOrder } = useRepeatOrder(token);
  const { handleModifyOrder } = useModifyOrder()
  const { handleMarketDepth } = useMarketDepth();
  const { handleChart } = useRedirectChart<IUserOrder>();
  const { handleAddWatchlistItem } = useAddToWatchlist<IUserOrder>();
  const { handleGttModal } = useCreateGttModal(token, exchange);

  const handleCancelItem = () => {
    handleShowCancel(selectedCard);
    close();
  }

  const handleModifyItem = () => {
    handleModifyOrder(selectedCard);
    close();
  }

  const handleRepeatItem = () => {
    handleRepeatOrder(selectedCard);
    close();
  }

  const handleInfoItem = () => {
    handleOrderDetailsModal(selectedCard);
    close();
  }

  const handleDrawerGttModal = () => {
    handleGttModal(true, selectedCard)
    close();
  }

  const handleDrawerMarketDepth = () => {
    handleMarketDepth(selectedCard)
    close();
  }

  const handleDrawerChart = () => {
    handleChart(selectedCard)
    close();
  }

  const handleDrawerAddWatchlist = () => {
    handleAddWatchlistItem(selectedCard)
    close();
  }

  return (
    <div>
      <DrawerItem icon={X} title="Cancel" handleDrawerItem={handleCancelItem} />
      <DrawerItem icon={Edit} title="Modify" handleDrawerItem={handleModifyItem} />
      <DrawerItem icon={Copy} title="Repeat" handleDrawerItem={handleRepeatItem} />
      <DrawerItem icon={InfoCircle} title="Info" handleDrawerItem={handleInfoItem} />
      {/* Repeating this common just for mainting types */}
      <DrawerItem icon={Settings} title="Create GTT" handleDrawerItem={handleDrawerGttModal} />
      <DrawerItem icon={AlignCenter} title="Market depth" handleDrawerItem={handleDrawerMarketDepth} />
      <DrawerItem icon={TrendingUp} title="Chart" handleDrawerItem={handleDrawerChart} />
      <DrawerItem icon={Telescope} title="Add to marketwatch" handleDrawerItem={handleDrawerAddWatchlist} />
    </div>
  )
}

export default DrawerItems
