import {
  Button,
  Group,
  Anchor,
  Box,
} from "@mantine/core"
import { useForm } from "@mantine/form"
import { Link } from "wouter"
import useResetPassword from "@hooks/handleApiCall/useResetPassword"
import Logo from "@components/logo";
import { useSelector } from "react-redux";
import { RootState } from "@redux/store";
import MantinePasswordInput from "@components/inputs/passwordInput"
import { useEffect, useState } from "react";
import { FAILURE } from "@utils/constant";
import usePrevious from "src/hooks/common/usePrevious"
import { getQueryItem, isEqual } from "../../utils"

const ResetPassword = () => {
  const { handleResetPassword } = useResetPassword()
  const [passwordStrength, setPasswordStrength] = useState({
    password: 0,
    repeatPassword: 0,
  })
  const resetPasswordState = useSelector((state: RootState) => state.auth.reset)
  const { loading } = resetPasswordState
  const prevProps = usePrevious({ resetPasswordState })
  const requestId = getQueryItem(window.location.search, "requestId")

  const form = useForm({
    initialValues: { password: "", repeatPassword: "" },

    validate: {
      password: (value) => (value?.length < 3 ? "User Id must have at least 3 letters" : null),
      repeatPassword: (value, values) => (value !== values.password ? "Passwords did not match" : null),
    },
  })

  const handleSubmit = async (val: typeof form.values) => {
    if (requestId) {
      const payload = {
        newPassword: val.password,
        requestId,
      }
      handleResetPassword(payload)
    }
  }
  useEffect(() => {
    if (!isEqual(prevProps.resetPasswordState.status, resetPasswordState.status)
      && resetPasswordState.status === FAILURE) {
      form.reset()
    }
  }, [resetPasswordState])

  const passwordStrengthCheck = passwordStrength.password < 99 || passwordStrength.repeatPassword < 99

  return (
    <div className="loggedOutCard">
      <div className="loggedOutCardHeader">
        <Logo />
        <h2 className="loggedOutCardTitle">Reset Password  </h2>
      </div>
      <Box p={16}>
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <MantinePasswordInput
            label="New Password"
            placeholder="******"
            formHandlers={{ ...form.getInputProps("password") }}
            setPasswordStrength={setPasswordStrength}
            keyProp="password"
            error={form.errors.password}
          />

          <MantinePasswordInput
            label="Repeat New Password"
            placeholder="******"
            formHandlers={{ ...form.getInputProps("repeatPassword") }}
            setPasswordStrength={setPasswordStrength}
            keyProp="repeatPassword"
            error={form.errors.repeatPassword}
          />

          <Button disabled={passwordStrengthCheck} fullWidth mt="xl" type="submit" loading={loading}>
            Continue
          </Button>

          <Group position="center" mt="lg">
            <Anchor component={Link} to="/" size="sm">
              Back to login
            </Anchor>
          </Group>
        </form>
      </Box>
    </div>
  )
}

export default ResetPassword
