import {
  Menu, createStyles, Box, Portal,
} from '@mantine/core'
import { useEffect, useState } from 'react'
import {
  Copy,
  Dots, Minus, Plus,
} from 'tabler-icons-react'
import { useFocusWithin } from '@mantine/hooks'
import { IExtendedPositionItem, IPositionItem } from '@redux/sagas/positions'
import CommonTableDropdown from '@components/table/commonTableDropdown'
import { getDropdownCoordinates } from '@utils/index'
import { useTradePositions } from '@hooks'

export const useStyles = createStyles((theme, { showMenu }: { showMenu: boolean }) => {
  const { colors } = theme.other
  return {
    menuWrapper: {
      border: `1px solid ${colors.disabled.subtle}`,
      borderRadius: 3,
      padding: '0 2px',
      height: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      visibility: showMenu ? 'visible' : 'hidden',
      paddingTop: '5px',
    },
    menuPopupWrapper: {
      position: "absolute",
      zIndex: 500,
      height: 'min-content',
    },
  }
});

interface ITableDropdown {
  row: IPositionItem,
  handlePositionDetails: (item: IExtendedPositionItem) => void;
  handleConvertPosition: (item: IExtendedPositionItem) => void;
}

const PositionDropdown = ({
  row,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handlePositionDetails = () => { },
  handleConvertPosition = () => { },
}: ITableDropdown) => {
  const { handleExitPosition, handleAddPosition } = useTradePositions();
  const [showMenu, setShowMenu] = useState(false);
  const { classes, cx } = useStyles({ showMenu });
  const { ref, focused } = useFocusWithin();

  const handleShowMenuDropdown = (isOpen: boolean) => {
    setShowMenu(isOpen);
  };
  useEffect(() => {
    handleShowMenuDropdown(focused);
  }, [focused]);

  return (
    <span
      ref={ref}
      className={cx(!showMenu ? "dots" : null, classes.menuWrapper)}
    >
      <Menu
        shadow="md"
        width={200}
        transitionProps={{ duration: 150 }}
        position="bottom-end"
        withArrow
        offset={-25}
        opened={showMenu}
        onChange={() => handleShowMenuDropdown(!showMenu)}
      >
        <Box onMouseLeave={() => handleShowMenuDropdown(false)}>
          <Menu.Target>
            <span>
              <Dots cursor="pointer" />
            </span>
          </Menu.Target>
          <Portal>
            <Menu.Dropdown
              sx={{
                top: getDropdownCoordinates().top,
                right: getDropdownCoordinates().right,
                bottom: getDropdownCoordinates().bottom,
                left: getDropdownCoordinates().left,
              }}
              className={cx(classes.menuPopupWrapper, "tableDropDowns")}
            >
              {
                row.qty === 0 ? (
                  <>
                    <Menu.Item
                      icon={<Plus size={14} />}
                      onClick={() => handleExitPosition(row)}
                    >
                      Buy
                    </Menu.Item>
                    <Menu.Item
                      icon={<Minus size={14} />}
                      onClick={() => handleAddPosition(row)}
                    >
                      Sell
                    </Menu.Item>
                  </>
                ) : (
                  <>
                    <Menu.Item
                      icon={<Minus size={14} />}
                      onClick={() => handleExitPosition(row)}
                    >
                      Exit
                    </Menu.Item>
                    <Menu.Item
                      icon={<Plus size={14} />}
                      onClick={() => handleAddPosition(row)}
                    >
                      Add
                    </Menu.Item>
                    <Menu.Item
                      icon={<Copy size={14} />}
                      onClick={() => handleConvertPosition(row as IExtendedPositionItem)}
                    >
                      Convert
                    </Menu.Item>
                  </>
                )
              }
              {/* Position Info modal */}
              {/* <Menu.Item
                icon={<InfoCircle size={14} />}
                onClick={() => handlePositionDetails(row as IExtendedPositionItem)}
              >
                Info
              </Menu.Item> */}
              <CommonTableDropdown row={row} />
            </Menu.Dropdown>
          </Portal>
        </Box>
      </Menu>
    </span>
  );
}

export default PositionDropdown
