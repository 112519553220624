import { RootState } from '@redux/store';
import { SUCCESS } from '@utils/constant';
import { useSelector } from 'react-redux';
import { useDisclosure } from "@mantine/hooks";
import { useLocation } from "wouter";
import Banner from './Banner';
import NoticesModal from './noticesModal';

const Notices = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const [location] = useLocation();
  const isChartRoute = location.includes("/charts");

  const notices = useSelector((state: RootState) => state.user.notices);

  const unreadNotices = notices.data?.filter((notice) => !notice.read) || [];

  if (isChartRoute) {
    return null;
  }

  return notices.status === SUCCESS && unreadNotices?.length > 0 ? (
    <div>
      <Banner
        title={unreadNotices[0].message}
        viewMore={unreadNotices.length > 1}
        viewMoreHandler={open}
        type={unreadNotices[0].type}
        id={unreadNotices[0].id}
      />
      <NoticesModal opened={opened} close={close} data={unreadNotices} />
    </div>
  ) : null;
}

export default Notices
