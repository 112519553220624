import { takeLatest } from "redux-saga/effects"
import {
  forgotPassword,
  getCaptcha,
  loginUser,
  logoutUser,
  reloadCaptcha,
  resendOtp,
  resetPassword,
  validate2fa,
  validateReset,
  setup2fa,
  appLoginGenerateToken,
  loginOtp,
} from "./authSlice"

import {
  handleCaptcha,
  handleForgotPassword,
  handleLogin,
  handleLogoutUser,
  handleReloadCaptcha,
  handleResetPassword,
  handleValidateReset,
  handleValidate2fa,
  handleResendOtp,
  handleSetup2fa,
  handleAppLoginGenerateToken,
  handleValidateLoginOtp,
} from "./authHandlers"

export function* loginSaga() {
  yield takeLatest(loginUser.type, handleLogin)
}

export function* resendOtpSaga() {
  yield takeLatest(resendOtp.type, handleResendOtp)
}

export function* validate2faSaga() {
  yield takeLatest(validate2fa.type, handleValidate2fa)
}

export function* validateLoginOtp() {
  yield takeLatest(loginOtp.type, handleValidateLoginOtp)
}

export function* forgotPasswordSaga() {
  yield takeLatest(forgotPassword.type, handleForgotPassword)
}

export function* captchaSaga() {
  yield takeLatest(getCaptcha.type, handleCaptcha)
}

export function* reloadCaptchaSaga() {
  yield takeLatest(reloadCaptcha.type, handleReloadCaptcha)
}

export function* resetPasswordSaga() {
  yield takeLatest(resetPassword.type, handleResetPassword)
}

export function* validateResetSaga() {
  yield takeLatest(validateReset.type, handleValidateReset)
}

export function* logoutUserSaga() {
  yield takeLatest(logoutUser.type, handleLogoutUser)
}

export function* setup2faSaga() {
  yield takeLatest(setup2fa.type, handleSetup2fa)
}

export function* appLoginGenerateTokenSaga() {
  yield takeLatest(appLoginGenerateToken.type, handleAppLoginGenerateToken)
}
