import { Flex } from '@uikit';
import { Text, useMantineTheme } from '@mantine/core';
import { IHoldingItem } from '@redux/sagas/holdings/holdingsTypes';
import { parsedSymbol } from '@utils/index';
import FormattedName from '@components/watchlist/formattedName';
import HoldingsDropdown from '@containers/holdings/holdingsDropdown';

interface IHoldingIntrument {
  row: IHoldingItem,
  width?: number,
}

const HoldingIntrument = ({
  row,
  width = 200,
}: IHoldingIntrument) => {
  const theme = useMantineTheme()
  const { symbol, exchange } = row;
  const parsedSymbolObj = parsedSymbol(symbol, exchange, row.token)

  return (
    <div style={{ width: `${width}px` }} className="holding-intrument">
      <Flex align="baseline">
        {parsedSymbolObj ? (
          <FormattedName
            name={parsedSymbolObj?.niceName}
            expiryDay={parsedSymbolObj?.expiryDay || 0}
            isWeekly={parsedSymbolObj?.isWeekly}
          />
        ) : (
          <p>N/A</p>
        )}
        <Text size={10} ml={3} color={theme.other.colors.fg.muted}>
          {row.exchange}
        </Text>
      </Flex>
      <HoldingsDropdown row={row} />
    </div>
  );
}

export default HoldingIntrument
