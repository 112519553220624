import { useMantineTheme } from "@mantine/core";

const TextColor = (change: number): any => {
  const theme = useMantineTheme();
  if (change < 0) {
    return { color: theme.colors.colorDanger[1] };
  }
  if (change > 0) {
    return { color: theme.other.colors.success.default };
  }
  return { color: theme.colors.gray[6] };
}

export default TextColor
