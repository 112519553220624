import { showNotification } from "@mantine/notifications"
import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useLocation } from "wouter"
import { RootState } from "src/redux/store"
import { capitalizeFirstLetter, isEqual } from "src/utils"
import { ErrorFallbackMsg, FAILURE, SUCCESS } from "src/utils/constant"
import { resetPassword } from "@redux/sagas/auth/authSlice"
import { Setup2FaRoutes } from "@constants/routes"
import usePrevious from "../common/usePrevious"

interface PayloadProps {
  newPassword: string
  requestId: string
}
const useResetPassword = () => {
  const dispatch = useDispatch()
  const [, setLocation] = useLocation()

  const passwordResetState = useSelector(
    (state: RootState) => state.auth.reset
  )
  const { status } = passwordResetState

  const prevProps = usePrevious({ passwordResetState })

  const handleResetPassword = (payload: PayloadProps) => {
    dispatch(resetPassword(payload))
  }

  useEffect(() => {
    if (!isEqual(prevProps.passwordResetState.status, passwordResetState.status) && status === FAILURE) {
      if (passwordResetState.errorCode === 422) {
        showNotification({
          color: "red",
          title: "Request timeout",
          message: "Please start the flow again",
        })
        setLocation("/forgot")
      } else {
        showNotification({
          color: "red",
          title: capitalizeFirstLetter(passwordResetState.message) || ErrorFallbackMsg,
          message: "",
        })
      }
    } else if (!isEqual(prevProps.passwordResetState.status, passwordResetState.status) && status === SUCCESS) {
      showNotification({
        color: "green",
        title: "Password validation successful",
        message: "",
      })
      setLocation(Setup2FaRoutes.RESET_PASSWORD)
    }
  }, [prevProps, passwordResetState])

  return { handleResetPassword }
}

export default useResetPassword
