import { memo } from 'react'
import {
  Box,
  Button,
  Loader,
  Text,
  createStyles,
} from "@mantine/core"
import { IUserLimits } from "src/redux/sagas/users"
import { Flex } from '@uikit';
import ReloadIcons from '@components/atoms/ReloadIcons'
import addFunds from "@assets/icons/add.svg"
import { handleAddFunds } from '@utils/redirection';
import ErrorBoundary from "../../../ErrorBoundary"
import { numberWithCommas } from "../../../utils"

const useStyles = createStyles((theme) => {
  const { colors } = theme.other
  return {
    wrapper: {
      width: "50%",
      height: "12rem",
      background: colors.base.white,
      boxShadow: theme.other.shadow.xs,
      padding: "1.5rem",
      border: `1px solid ${colors.disabled.muted}`,
      borderRadius: ".75rem",
      [`@media (max-width: ${theme.breakpoints.md}px)`]: {
        width: "100%",
        height: "auto",
      },
    },
    iconContainer: {
      height: "2.875rem",
      width: "2.875rem",
      borderRadius: "50%",
      border: `1px solid ${colors.primary.subtle}`,
      justifyContent: "center",
      alignItems: "center",
    },
    borderBottom: {
      borderBottom: `1px solid ${colors.disabled.muted}`,
    },
  }
})

const RenderMarginCard = memo(({
  limitsLoading,
  limitsData,
  limitsIsError,
  // limitsErrMessage,
  loadingCheck,
}: {
  limitsLoading: boolean;
  limitsData: IUserLimits;
  // limitsErrMessage: string;
  limitsIsError: boolean;
  loadingCheck: boolean | number;
}) => {
  const { classes } = useStyles()
  const {
    cash = 0, marginUsed = 0, payIn = 0, payOut = 0, dayCash = 0, collateral = 0,
  } = limitsData.length > 0 ? limitsData[0] : {}

  const totalCash = Number(cash) + Number(dayCash) + Number(payIn) - Number(payOut)
  const marginAvailable = totalCash - Number(marginUsed) + Number(collateral)

  return (
    <ErrorBoundary>
      <Box className={classes.wrapper}>
        <Flex justify="space-between" className={classes.borderBottom}>
          <Flex justify="space-between" w="100%" mb="xs">
            <Box>
              <Flex align="center" gap="xs">
                <Text className="content_sm_regular text_fg_subtle"> Margin Available</Text>
                {loadingCheck || limitsLoading ? <Loader size="sm" ml="md" /> : null}
                {limitsIsError ? (
                  <ReloadIcons iconSize={16} marginTop={0} />
                ) : null}
              </Flex>

              <Text className="heading_md_semibold">
                ₹
                {numberWithCommas(marginAvailable.toFixed(2))}
              </Text>
            </Box>

            <Button
              p="0"
              m="0"
              className="content_sm_semibold"
              variant="white"
              leftIcon={(
                <img src={addFunds} alt="Add Funds" />
              )}
              onClick={handleAddFunds}
            >
              Add Funds
            </Button>
          </Flex>

        </Flex>
        <Box>
          <Flex py="sm" className={classes.borderBottom} justify="space-between">
            <Text className="content_sm_regular text_fg_subtle"> Margin Used</Text>
            <Text className="heading_2xs_medium">
              ₹
              {numberWithCommas(Number(marginUsed).toFixed(2))}
            </Text>
          </Flex>
          <Flex py="sm" justify="space-between">
            <Text className="content_sm_regular text_fg_subtle"> Total Funds</Text>
            <Text className="heading_2xs_medium">
              ₹
              {numberWithCommas(totalCash.toFixed(2))}
            </Text>
          </Flex>
        </Box>
      </Box>
    </ErrorBoundary>
  );
})

export default RenderMarginCard
