import Box from "@uikit/box"
import { NumberInput } from "@mantine/core"
import useStyles from "../styled"
import { IBracketOrderInputs } from "./types"

const BracketOrderInputs = ({
  bracketInputValues,
  setBracketInputValues,
  error,
  setError,
}:IBracketOrderInputs) => {
  const { classes, cx } = useStyles();
  const handleInputChange = (key: string, val: number) => {
    setError(error.filter((err) => err.name !== key))
    setBracketInputValues({ ...bracketInputValues, [key]: val })
  }
  return (
    <Box className={cx("trade-content", classes.tradeItemContainer)}>
      <Box className={classes.tradeItem}>
        <NumberInput
          type="number"
          id="stop-loss-price"
          mt="xl"
          step={0.05}
          precision={2}
          placeholder="Stop Loss"
          description="Stop Loss (in points)"
          disabled={false}
          value={bracketInputValues.stopLossPrice || 0}
          onChange={(val: number) => handleInputChange('stopLossPrice', val)}
          error={error.find((err) => err.name === 'stopLossPrice')?.error}
          classNames={{
            control: classes.numberInputControl,
            rightSection: classes.numberInputRightSection,
          }}
        />
      </Box>
      <Box className={classes.tradeItem}>
        <NumberInput
          type="number"
          mt="xl"
          id="profit-price"
          placeholder="Profit Price"
          description="Profit Price (in points)"
          precision={2}
          step={0.05}
          value={bracketInputValues.profitPrice || 0}
          onChange={(val: number) => handleInputChange('profitPrice', val)}
          error={error.find((err) => err.name === 'profitPrice')?.error}
          classNames={{
            control: classes.numberInputControl,
            rightSection: classes.numberInputRightSection,
          }}
        />
      </Box>
      <Box className={classes.tradeItem}>
        <NumberInput
          type="number"
          mt="xl"
          id="trailing-sl"
          placeholder="Trailing SL"
          precision={2}
          step={0.05}
          description="Trailing SL (in points)"
          value={bracketInputValues.trailingSL || 0}
          onChange={(val: number) => handleInputChange('trailingSL', val)}
          // error={error.find((err) => err.name === 'trailingSL')?.error}
          classNames={{
            control: classes.numberInputControl,
            rightSection: classes.numberInputRightSection,
          }}
        />
      </Box>
    </Box>
  )
}

export default BracketOrderInputs
