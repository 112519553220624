import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { selectQuoteSockets } from 'src/redux/sagas/sockets/socketSelectors'
import { setQuoteTokens } from 'src/redux/sagas/sockets/socketsSlice'

const useGetSocketVal = (token: number, isTokenExist = false) => {
  const dispatch = useDispatch();
  const socketWatchlist = useSelector(selectQuoteSockets)

  const currentSocket = (token ? socketWatchlist[token] : {}) || {}
  const ltp = currentSocket.ltp / 100

  useEffect(() => {
    if (!isTokenExist && !socketWatchlist[token]) {
      dispatch(setQuoteTokens({ [token]: 1, source: "from use get socket val" }))
      return () => {
        dispatch(setQuoteTokens({ [token]: 0 }))
      }
    }
    return () => {}
  }, [dispatch, token])

  return { ltp: ltp || 0 }
}

export default useGetSocketVal
