import { gttOrderConst } from "@utils/constant";

const validateOrderValues = (
  triggerTypeVal,
  targetTriggerPrice,
  stopLossTriggerPrice,
  targetPrice,
  stopLossPrice,
  targetQty,
  stopLossQty,
  ltp
) => {
  const errorsList: Array<string> = [];

  const addError = (message: string) => {
    errorsList.push(message);
  };

  if (triggerTypeVal === gttOrderConst.SINGLE) {
    if (targetQty <= 0) {
      addError("Invalid quantity value");
    }
    if (targetTriggerPrice < 0.5) {
      addError("Trigger price cannot be less than 0.5");
    }
    if (targetPrice <= 0) {
      addError("Price cannot be less than 1");
    }
  } else if (triggerTypeVal === gttOrderConst.OCO) {
    if (stopLossQty <= 0) {
      addError("Invalid quantity value");
    }
    if (stopLossTriggerPrice < 0.5) {
      addError("Trigger price cannot be less than 0.5");
    }
    if (stopLossPrice <= 0) {
      addError("Price cannot be less than 1");
    }
    if (stopLossTriggerPrice === targetTriggerPrice) {
      addError("Both trigger prices cannot be the same");
    }
    if (targetPrice < (Number(ltp) / 100)) {
      addError("Target price cannot be less than the current price");
    }
    if (stopLossPrice > (Number(ltp) / 100)) {
      addError("Stoploss price cannot be greater than the current price");
    }
  }

  return errorsList;
};

export default validateOrderValues;
