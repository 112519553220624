import {
  Box, Button, Divider, Group, MantineNumberSize, Modal, Text, Title, createStyles,
} from '@mantine/core'
import { useSelector, useDispatch } from 'react-redux';
import useMediaCustom from 'src/hooks/common/useMediaCustom';
import { setChargesModalInfo } from 'src/redux/sagas/trades/tradesSlice';
import { RootState } from 'src/redux/store';
import { InfoCircle } from 'tabler-icons-react';

export const useStyles = createStyles(() => ({
  confirmTrContainer: {
    th: {
      padding: "12px 10px !important",
    },
  },
  firstTd: {
    minWidth: 140,
  },
}))
interface IConfirmCancel {
  isVisible: boolean
}

interface IChargesItem {
  title: string
  value: number
  size?: MantineNumberSize
}

const ChargesItem = ({ title, value, size = "sm" }: IChargesItem) => (
  <Group my={8} position="apart">
    <Text size={size} color="dimmed">{title}</Text>
    <Text size={size}>
      ₹
      {value.toFixed(2)}
    </Text>
  </Group>
)

const TradeCharges = ({
  isVisible,
}: IConfirmCancel) => {
  const { isMobile } = useMediaCustom();
  const dispatch = useDispatch();
  const {
    chargesModal: { data: chargesData },
  } = useSelector((state: RootState) => state.trade);

  const handleCloseModal = () => {
    dispatch(setChargesModalInfo({
      showModal: false,
      data: chargesData,
    }))
  }

  const {
    brokerage,
    sebiCharges,
    exchangeTxnFee,
    stampDuty,
    transactionTax,
    gst,
    ipft,
    total,
  } = chargesData;

  return (
    <Modal
      onClose={() => null}
      opened={isVisible}
      size="md"
      withCloseButton={false}
      fullScreen={!!isMobile}
      centered
    >
      <Group mb={10} position="apart">
        <Title order={5}>Charges & taxes</Title>
        <InfoCircle size={16} />
      </Group>

      <Divider />
      <Box my={12}>
        <ChargesItem title="Brokerage" value={brokerage} />
        <ChargesItem title="SEBI turnover fee" value={sebiCharges} />
        <ChargesItem title="Exchange turnover fee" value={exchangeTxnFee} />
        <ChargesItem title="Stamp duty" value={stampDuty} />
        <ChargesItem title="Transaction tax (STT)" value={transactionTax} />
        <ChargesItem title="Investor protection fund trust" value={ipft} />
        <ChargesItem title="GST" value={gst.total} />
        <Divider />
        <ChargesItem title="Total charges & taxes" size="md" value={total} />
      </Box>

      {/* modal button */}
      <Divider />
      <Group position="right" align="flex-start" mt="md">
        <Button
          variant="outline"
          onClick={handleCloseModal}
        >
          Close
        </Button>
      </Group>
    </Modal>
  )
}

export default TradeCharges
