import {
  Badge, Box, Button, createStyles, Divider, Group, Modal, Text,
} from '@mantine/core'
import {
  useCallback, useEffect, useState, ChangeEvent,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Search } from 'tabler-icons-react'
import useMarketDepth from 'src/hooks/common/useMarketDepth'
import useMarketDepthShortcut from 'src/hooks/events/useMDShortcut'
import { RootState } from 'src/redux/store'
import { IGttSearchItem } from 'src/redux/sagas/misc'
import { IFullSocketData } from 'src/redux/sagas/sockets'
import { EXCHANGE_COLOR, INDICES } from '@utils/constant'
import { setGttSearchResults } from '@redux/sagas/misc/miscSlice'
import { IUserOrder } from '@redux/sagas/orders'
import { selectFullSocket } from '@redux/sagas/sockets/socketSelectors'
import { setTradeModalInfo } from '@redux/sagas/trades/tradesSlice'
import useMediaCustom from '@hooks/common/useMediaCustom'
import {
  debounce, extractTradeModalData, getNiceName, instrumentSearch,
} from '@utils/index'
import { Flex } from '@uikit';
import { useCreateGttModal } from '@hooks/orders'
import MarketDepth from '../watchlist/marketDepth'
import FormattedName from '../watchlist/formattedName'
import useStyles from "../watchlist/styled";

interface OrderHistoryModalProps {
  selectedOrderDetails: IUserOrder,
  isVisible: boolean
}
export const useStylesMarket = createStyles((theme) => {
  const color = theme.other.colors;
  return {
    searchInputContainer: {
      position: "relative",
    },
    searchBorder: {
      border: "1px solid",
      borderColor: color.disabled.default,
    },
    searchResultContainer: {
      position: 'absolute',
      top: 50,
      width: '100%',
      background: color.bg.subtle,
      zIndex: 100,
    },
    searchNameContainer: {
      display: "flex",
      fontSize: 14,
    },
    companyName: {
      fontSize: 10,
    },
  }
})

const MarketDepthModal = ({
  selectedOrderDetails,
  isVisible,
}: OrderHistoryModalProps) => {
  const { isMobile } = useMediaCustom();

  const { classes } = useStylesMarket()
  const { cx, classes: classesWL } = useStyles()
  const dispatch = useDispatch();
  const marketDepth = useSelector(selectFullSocket)

  const selectedMD: IFullSocketData = marketDepth[selectedOrderDetails.token] || {}
  const { handleMarketDepth, handleCloseMarketDepth } = useMarketDepth()
  const { token, exchange, symbol } = selectedOrderDetails
  const { handleGttModal } = useCreateGttModal(token, exchange)

  // market depth event shortcut

  const { gttSearchResults } = useSelector((state: RootState) => state.misc);

  const [searchValue, setSearchValue] = useState("");

  const ltp = (selectedMD.ltp / 100 || 0).toFixed(2)

  const handleMarketDepthCall = (item: IGttSearchItem) => {
    handleMarketDepth(item)
    setSearchValue("");
  }

  const { selectedWLItemIndex } = useMarketDepthShortcut(handleMarketDepthCall)

  const processChange = useCallback(
    debounce((val: string) => {
      const searchResults = instrumentSearch.search(val);
      const updatedSearchResults = searchResults.map((item: any) => ({
        ...item,
        token: item.exchangeToken,
      }))
      dispatch(setGttSearchResults(updatedSearchResults.slice(0, 16)));
    }, 60),
    []
  )

  useEffect(() => {
    if (searchValue) {
      processChange(searchValue)
    } else {
      dispatch(setGttSearchResults([]));
    }
  }, [dispatch, processChange, searchValue])

  const handleGttModalOpen = (isBoolean: boolean, selectedOrderItem?: IUserOrder) => {
    handleGttModal(isBoolean, selectedOrderItem)
    handleCloseMarketDepth()
  }

  const handleSelectOrder = (assetItem: IUserOrder, isBoolean: boolean) => {
    dispatch(
      setTradeModalInfo({
        currentAssetItem: extractTradeModalData(assetItem, Number(ltp)),
        buySellToggle: isBoolean,
        showTradeModal: true,
      })
    );
    handleCloseMarketDepth()
  };

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.currentTarget.value);
  }
  const instrumentName = getNiceName(token, exchange, symbol)
  return (
    <Modal
      onClose={handleCloseMarketDepth}
      opened={isVisible}
      size="lg"
      fullScreen={!!isMobile}
      withCloseButton={false}
    >
      <>
        {/* market depth search */}
        <Box className={classes.searchInputContainer}>
          <Box className={classesWL.searchIcon}>
            <Search size={15} color="#444" />
          </Box>
          <input
            id="watchlist-search"
            className={cx(classesWL.searchInput, classes.searchBorder)}
            type="search"
            autoComplete="off"
            placeholder="Search eg: reliance, nifty 50, tcs"
            value={searchValue}
            onChange={handleSearch}
          />
          {/* Search Results */}
          <Box className={classes.searchResultContainer}>
            {gttSearchResults.map((item: IGttSearchItem, index: number) => (
              <div
                aria-hidden="true"
                key={item?.exchangeToken}
                className={cx(
                  classesWL.searchItemContainer,
                  selectedWLItemIndex === index
                    && classesWL.styledSelectedWatchlistItem
                )}
                onClick={() => handleMarketDepthCall(item)}
              >
                <Flex justify="space-between" align="center">
                  <span className={classes.searchNameContainer}>
                    <FormattedName
                      name={item.niceName}
                      expiryDay={0}
                      isWeekly={item.isWeekly}
                    />
                  </span>
                  <span className={classes.companyName}>
                    {item.company.toUpperCase()}
                    <Badge
                      color={EXCHANGE_COLOR[item.exchange]}
                      ml="sm"
                      size="xs"
                      radius="xs"
                    >
                      {item.exchange}
                    </Badge>
                  </span>
                </Flex>
              </div>
            ))}
          </Box>
        </Box>
        <Group position="apart" mt="lg" mb="md">
          <Flex align="baseline">
            <p className="heading_sm_semibold">{instrumentName}</p>
            <Text className="text_fg_muted content_2xs_regular">
              {selectedOrderDetails?.exchange}
            </Text>
          </Flex>
          <Flex>
            <Text mr={5} weight={400}>
              {ltp}
            </Text>
            <Text color={selectedMD.netChange > 0 ? "green" : "red"} size="sm">
              {" "}
              (
              {selectedMD.netChange?.toFixed(2)}
              %)
            </Text>
          </Flex>
        </Group>
        <MarketDepth
          selectedToken={selectedMD.token}
          item={selectedOrderDetails}
        />

        {/* buttons */}
        <Divider my="lg" />
        <Flex justify="space-between">
          {selectedOrderDetails?.exchange === INDICES ? (
            <div />
          ) : (
            <Button
              variant="outline"
              onClick={() => handleGttModalOpen(true, selectedOrderDetails)}
            >
              Create GTT
            </Button>
          )}
          <Flex>
            {selectedOrderDetails?.exchange === INDICES ? null : (
              <>
                <Button
                  color="blue"
                  variant="outline"
                  onClick={() => handleSelectOrder(selectedOrderDetails, false)}
                >
                  Buy
                </Button>
                <Button
                  ml="xs"
                  color="red"
                  variant="outline"
                  onClick={() => handleSelectOrder(selectedOrderDetails, true)}
                >
                  Sell
                </Button>
              </>
            )}
            <Button
              ml="xs"
              color="gray"
              variant="outline"
              onClick={handleCloseMarketDepth}
            >
              Close
            </Button>
          </Flex>
        </Flex>
      </>
    </Modal>
  );
}

export default MarketDepthModal
