import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => {
  const { colors } = theme.other;

  return {
    container: {
      height: "21rem",
      width: "33%",
      border: `1px solid ${colors.disabled.muted}`,
      borderRadius: "1rem",
      overflow: "hidden",
      cursor: "pointer",
      boxShadow: theme.other.shadow.xs,
      [`@media (max-width: ${768}px)`]: {
        width: "100%",
      },
    },
    imgBlock: {
      width: "100%",
      height: "12.5rem",
      background: colors.bg.subtle,
      padding: "2rem 0 0 2rem",
    },
    imgContainer: {
      borderTop: `3px solid ${theme.black}`,
      borderLeft: `3px solid ${theme.black}`,
      borderRadius: "8px 0 0 0",
      height: "100%",
      overflow: "hidden",
    },
    img: {
      width: "100%",
      height: "100%",
    },
    blueGradient: {
      background: "linear-gradient(180deg, #FFF 0%, #A2C6F9 100%)",
    },
    greenGradient: {
      background:
        "linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #D1FADF 100%)",
    },
    yellowGradient: {
      background: "linear-gradient(180deg, #FFF 0%, #FFFAEB 100%)",
    },
  };
});

export default useStyles;
