import { parsedSymbol } from "@utils/index"
import FormattedName from "@components/watchlist/formattedName"
import { Text, Flex } from "@uikit"
import { productType } from "@utils/constant"
import PnLText from "@components/atoms/pnlText"
import theme from "@theme/theme"
import useStyles from "./positionCard.styles"
import { IPositionCardProps } from "./positionCard.types"
import PositionCardItem from "./positionCardItem"

const PositionCard = ({ row, handleRowClick }: IPositionCardProps) => {
  const { cx, classes } = useStyles();

  const {
    symbol, exchange, product, avgPrice, qty, ltp, netChg, pnl, realisedPnL,
  } = row;
  const parsedSymbolObj = parsedSymbol(symbol, exchange, row.token);

  const isRowDisabled = qty === 0;

  const disabledClass = isRowDisabled ? "disabled-row" : "";

  const handleRowItemClick = () => {
    if (isRowDisabled) return
    handleRowClick(row)
  }

  return (
    <Flex className={cx(classes.holdingCardItem, disabledClass)} onClick={handleRowItemClick}>
      <Flex justify="space-between">
        {parsedSymbolObj && (
          <FormattedName
            name={parsedSymbolObj.niceName}
            expiryDay={parsedSymbolObj.expiryDay}
            isWeekly={parsedSymbolObj.isWeekly}
            className="content_sm_medium"
          />
        )}
        <Flex gap={4}>
          <PnLText
            colorScheme={theme.colorScheme}
            netChange={qty === 0 ? 0 : Number(realisedPnL) + pnl}
            value={qty === 0 ? Number(realisedPnL) : Number(realisedPnL) + pnl}
            textClass="content_sm_medium"
          />
          <PnLText
            colorScheme={theme.colorScheme}
            netChange={qty === 0 ? 0 : netChg}
            value={netChg === Infinity ? "0.00" : netChg || "0.00"}
            showPercentage={netChg === Infinity ? false : true || false}
          />
        </Flex>
      </Flex>

      <Flex justify="space-between">
        <Flex gap={4}>
          <Text className="content_xs_regular text_fg_subtle">{productType[product]}</Text>
          <Text className="content_xs_regular text_fg_subtle">{exchange}</Text>
        </Flex>
        <PositionCardItem title="Avg. Cost" value={avgPrice.toFixed(2)} />
      </Flex>

      <Flex justify="space-between">
        <PositionCardItem title="Qty:" value={qty} />
        <Flex gap={4}>
          <Text className="content_xs_regular text_fg_muted">LTP:</Text>
          <PnLText
            colorScheme={theme.colorScheme}
            netChange={qty === 0 ? 0 : ltp}
            value={ltp}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default PositionCard
