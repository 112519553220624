import { takeLatest } from "redux-saga/effects"
import {
  handleAddWatchlistItem,
  handleRemoveWatchlistItem,
  handleUpdateSequence,
  handleWatchlists,
} from "./watchlistsHandlers"
import {
  removeWlItem,
  getWatchlists,
  updateSequence,
  addWLItem,
} from "./watchlistsSlice"

export function* watchlistsSaga() {
  yield takeLatest(getWatchlists.type, handleWatchlists)
}

export function* removeWatchlistItemSaga() {
  yield takeLatest(removeWlItem.type, handleRemoveWatchlistItem)
}

export function* addWatchlistItemSaga() {
  yield takeLatest(addWLItem.type, handleAddWatchlistItem)
}

export function* UpdateSequenceSaga() {
  yield takeLatest(updateSequence.type, handleUpdateSequence)
}
