function loadData(key: string): string | null {
  try {
    const data = localStorage.getItem(key)
    if (data) {
      return JSON.parse(data)
    }
    return data
  } catch (err) {
    return null
  }
}

function saveData(key: string, data: any): void {
  localStorage.setItem(key, JSON.stringify(data))
}

function clearLocalStorage(): void {
  localStorage.clear()
}

export { loadData, saveData, clearLocalStorage }
