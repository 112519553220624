import { TransactionType } from '@constants/common';
import { TransactionTypes } from '@redux/sagas/orders';
import { setTradeModalInfo } from '@redux/sagas/trades/tradesSlice';
import { extractTradeModalData } from '@utils/index';
import { useDispatch } from 'react-redux';

interface IModifyOrder {
  transactionType: keyof TransactionTypes;
  id: string;
}

const useModifyOrder = <T extends IModifyOrder>() => {
  const dispatch = useDispatch();

  const handleModifyOrder = (item: T) => {
    const { transactionType, id } = item;
    dispatch(
      setTradeModalInfo({
        currentAssetItem: extractTradeModalData(item),
        buySellToggle: transactionType !== TransactionType.B,
        showTradeModal: true,
        editOrderId: id,
        isOpenOrder: true,
      })
    );
  }

  return { handleModifyOrder }
}

export default useModifyOrder
