import usePrevious from '@hooks/common/usePrevious';
import {
  Badge,
  Box, Button, Divider, Flex, Group, Modal, Table, Text, createStyles,
} from '@mantine/core'
import { IPositionItem, IPositionsItem } from '@redux/sagas/positions';
import { RootState } from '@redux/store';
import { getTypographyStyles } from '@theme/themeConfig';
import { getNiceName, isEqual } from '@utils/index';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useMediaCustom from 'src/hooks/common/useMediaCustom';
import {
  OrderTypeML, SUCCESS, ValidityRetention, productType,
} from 'src/utils/constant';

export const useStyles = createStyles(() => ({
  confirmTrContainer: {
    th: {
      padding: "12px 10px !important",
      ...getTypographyStyles("heading_2xs_semibold"),
    },
  },
  firstTd: {
    minWidth: 60,
  },
}))
interface IConfirmExitPosition {
  isVisible: boolean
  handleCloseModal: (isBoolean: boolean) => void
  handleConfirmExit: () => void
  selectedRows: IPositionsItem
  handleSelectAll: (isVal: Boolean) => void
}

const ConfirmExitPosition = ({
  isVisible,
  handleCloseModal,
  handleConfirmExit,
  selectedRows,
  handleSelectAll,
}: IConfirmExitPosition) => {
  const { classes } = useStyles();
  const { isMobile } = useMediaCustom();
  const [showLoader, setShowLoader] = useState(false);
  const {
    regularOrder,
  } = useSelector((state: RootState) => state.trade)
  const { loading } = regularOrder

  const prevProps = usePrevious({ regularOrder })

  useEffect(() => {
    if (!isEqual(regularOrder.status, prevProps.regularOrder.status) && regularOrder.status === SUCCESS) {
      setShowLoader(false);
      handleCloseModal(false)
      handleSelectAll(true)
    }
  }, [regularOrder, prevProps])

  return (
    <Modal
      onClose={() => null}
      opened={isVisible}
      size="xl"
      withCloseButton={false}
      fullScreen={!!isMobile}
    >
      <Box mb={10}>
        <p className="heading_sm_semibold">Exit Order</p>
      </Box>
      <Divider />
      <Table
        verticalSpacing="xs"
        highlightOnHover
      >
        <thead>
          <tr className={classes.confirmTrContainer}>
            <th>Type</th>
            <th>Instrument</th>
            <th>Qty.</th>
            <th>Price</th>
            <th>Order Type</th>
            <th>Product</th>
            <th>Validity</th>
          </tr>
        </thead>
        <tbody>
          {selectedRows?.map((selectedItem: IPositionItem) => {
            const item = selectedItem
            const instrumentName = getNiceName(item.token, item.exchange, item.symbol)
            return (
              <tr className="content_sm_regular text_fg_default" key={item.symbol}>
                <td className={classes.firstTd}>
                  <Badge mr="sm" size="md" radius="xs" color={item?.qty > 0 ? "red" : "blue"}>
                    {item?.qty > 0 ? "Sell" : "Buy"}
                  </Badge>
                </td>
                <td>
                  <Flex align="baseline">
                    {instrumentName}
                    <Text size={12} ml={3} className="text_fg_muted">
                      {`(${item.exchange})`}
                    </Text>
                  </Flex>
                </td>
                <td>{item.qty}</td>
                <td>-</td>
                {/* While Exiting the Positions, Order type will go
                as Market and Validity will be Day */}
                <td>{OrderTypeML[0].value}</td>
                <td>{productType[item.product!]}</td>
                <td>{ValidityRetention.DAY}</td>
              </tr>
            )
          })}
        </tbody>
      </Table>
      <Divider />
      <Group position="right" align="flex-start" mt="md">
        <Button
          variant="outline"
          color="red"
          onClick={handleConfirmExit}
          loading={showLoader || loading}
          w={100}
        >
          Exit
        </Button>
        <Button
          variant="outline"
          onClick={() => handleCloseModal(false)}
          w={100}
        >
          Close
        </Button>
      </Group>
    </Modal>
  )
}

export default ConfirmExitPosition
