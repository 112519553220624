/* eslint-disable react/no-array-index-key */
import {
  Badge,
  Button,
  Divider,
  Group,
  Loader,
  Modal,
  Table,
  Text,
  createStyles,
} from "@mantine/core"
import { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { getOrderHistory } from "@redux/sagas/orders/ordersSlice"
import { IOrderHistory } from "@redux/sagas/orders"
import useMediaCustom from "@hooks/common/useMediaCustom"
import type { RootState } from '@redux/store'
import { getTypographyStyles } from "@theme/themeConfig"
import {
  epochToTimeFormat, getNiceName,
} from "@utils/index"
import { BuySellConst } from "@constants"
import {
  orderStatusColor, productType,
} from "../../../utils/constant"

interface OrderHistoryModalProps {
  selectedOrderId: string
  isVisible: boolean
  handleOrderDetailsHistoryModal: () => void
  handleOrderHistoryCloseModal: () => void
}

export const useStyles = createStyles(() => ({
  trContainer: {
    th: {
      padding: "10px 10px !important",
      ...getTypographyStyles("heading_2xs_semibold"),
    },
    td: {
      padding: "10px 10px !important",
    },
  },
  tbody: {
    td: {
      textAlign: "left",
    },
  },
  loader: {
    height: 300,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
}));

const OrderHistoryModal = ({
  selectedOrderId,
  isVisible,
  handleOrderDetailsHistoryModal,
  handleOrderHistoryCloseModal,
}: OrderHistoryModalProps) => {
  const { isMobile } = useMediaCustom();
  const { classes } = useStyles();
  const dispatch = useDispatch()
  const [instrumentName, setInstrumentName] = useState()
  const { data } = useSelector((state: RootState) => state.order.orderHistory)
  useEffect(() => {
    dispatch(getOrderHistory({ orderId: selectedOrderId }))
  }, [dispatch, selectedOrderId])

  useEffect(() => {
    if (data && data.length > 0) {
      setInstrumentName(getNiceName(data[0].token, data[0].exchange, data[0].symbol))
    }
  }, [data])

  return (
    <Modal
      onClose={handleOrderHistoryCloseModal}
      opened={isVisible}
      size="xl"
      withCloseButton={false}
      fullScreen={!!isMobile}
    >
      {
        data && data.length ? (
          <>
            <Group position="apart" mb="sm">
              {data && data.length > 0 && (
                <Group position="apart">
                  <Badge color={data[0].transactionType === 'B' ? 'blue' : 'red'} radius="xs" variant="light">
                    {BuySellConst[data[0].transactionType as keyof typeof BuySellConst]}
                  </Badge>
                  <p className="heading_md_semibold">{instrumentName}</p>
                  <p className="text_fg_muted content_md_regular">{data[0].exchange}</p>
                </Group>
              )}
              <p className="content_sm_regular text_fg_default">
                <b>
                  #
                  {selectedOrderId}
                </b>
              </p>
            </Group>
            {/* modal data */}
            <Table className="table-container" verticalSpacing="xs" highlightOnHover>
              <thead className="tableHeader">
                <tr className={classes.trContainer}>
                  <th>Time</th>
                  <th>Order Time</th>
                  <th>Exch. Update Time</th>
                  <th>Products</th>
                  <th>Qty.</th>
                  <th>Filled qty.</th>
                  <th>Avg. Price</th>
                  <th className="align-right">Status</th>
                </tr>
              </thead>
              <tbody className={classes.tbody}>
                {data && data.map((row: IOrderHistory, index: number) => (
                  <tr className={classes.trContainer} key={index}>
                    <td className="align-left">
                      <Text className="content_sm_regular text_fg_default" my="md">{row.timeStamp.slice(0, 9)}</Text>
                    </td>
                    <td className="align-left">
                      <Text className="content_sm_regular text_fg_default" my="md">
                        {row.orderTime ? epochToTimeFormat(row.orderTime) : "---"}
                      </Text>
                    </td>
                    <td className="align-left">
                      <Text className="content_sm_regular text_fg_default" my="md">
                        {row.exchangeUpdateTime.slice(10, 20) || "---"}
                      </Text>
                    </td>
                    <td className="align-left">
                      <Text className="content_sm_regular text_fg_default">{productType[row.product!]}</Text>
                    </td>
                    <td className="align-left">
                      <Text className="content_sm_regular text_fg_default">{row.quantity}</Text>
                    </td>
                    <td className="align-left">
                      <Text className="content_sm_regular text_fg_default">
                        {Number(row.cancelQuantity) > 0 ? `${row.cancelQuantity} (cancelled)` : (row.fillShares || 0)}
                      </Text>
                    </td>
                    <td className="align-left">
                      <Text className="content_sm_regular text_fg_default">{row.averagePrice || 0}</Text>
                    </td>
                    <td className="align-right">
                      <Badge radius="xs" color={orderStatusColor[row.orderStatus!]}>
                        {row.orderStatus}
                      </Badge>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        ) : (
          <div className={classes.loader}>
            <Loader />
          </div>
        )
      }
      {/* modal button */}
      <Divider />
      <Group position="right" align="flex-start" mt="md">
        <Button
          variant="outline"
          color="blue"
          onClick={handleOrderDetailsHistoryModal}
        >
          Back
        </Button>
        <Button
          variant="outline"
          color="gray"
          onClick={handleOrderHistoryCloseModal}
        >
          Close
        </Button>
      </Group>
    </Modal>
  )
}

export default OrderHistoryModal
