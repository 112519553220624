import { createSelector } from "reselect"
import { RootState } from "src/redux/store"

export const getSelectedUsersDetails = (state: RootState) => state.user.details
export const getSelectedUserPreferences = (state: RootState) => state.user.preferences
export const getSelectedUserLimits = (state: RootState) => state.user.limits

export const selectUsersDetails = createSelector(
  [getSelectedUsersDetails],
  (data) => data
)

export const selectUserPreferences = createSelector(
  [getSelectedUserPreferences],
  (data) => data
)

export const selectUserLimits = createSelector(
  [getSelectedUserLimits],
  (data) => data
)
