import {
  Box, Button, Space,
} from "@mantine/core"
import positionBag from "@assets/icons/candle.svg"

interface ZeroStateProps {
  title?: string
}

const PositionZeroState = ({
  title = "You don’t have any positions here",
}: ZeroStateProps) => (
  <Box sx={{ textAlign: "center", marginTop: 60 }}>
    <img src={positionBag} alt="position-bag" />
    <Space h={20} />
    <p className="heading_2xs_semibold">
      {title}
    </p>
    <Box mt={15}>
      <Button>Get Started</Button>
    </Box>
  </Box>
)

export default PositionZeroState
