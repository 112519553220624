import { useCallback, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { OrderTypeML, OrderTypeSLM, varietyValues } from "src/utils/constant";
import { orderMargin } from "@redux/sagas/trades/tradesSlice";
import type { RootState } from '@redux/store';
import { debounce } from "@utils/index";
import { IBracketInputValues } from "@components/watchlist/tradeModal/types"

interface IUseFetchTradeMargin {
  selectedExchange: string;
  token: number;
  quantity: number;
  priceField: number;
  buySellToggle: boolean;
  orderTypeMLVal: string;
  productValue: string;
  activeTab: string;
  triggerPriceField: number;
  bracketInputValues:IBracketInputValues
}

const useFetchTradeMargin = ({
  selectedExchange,
  token,
  quantity,
  priceField,
  buySellToggle,
  orderTypeMLVal,
  productValue,
  activeTab,
  triggerPriceField,
  bracketInputValues,
}: IUseFetchTradeMargin) => {
  const dispatch = useDispatch();
  const { data: tradeMarginData } = useSelector((state: RootState) => state.trade.orderMargin);

  const initialLoadRef = useRef(true);

  const processChange = useCallback(
    debounce((payload: any) => dispatch(orderMargin(payload)), 250),
    []
  );

  const handleGeneratePayload = () => {
    const { stopLossPrice } = bracketInputValues

    const priceVal = (orderTypeMLVal === OrderTypeML[0].value)
      || (orderTypeMLVal === OrderTypeSLM[1].value)
      ? "0" : priceField?.toString();
    const triggerPriceVal = (orderTypeMLVal === OrderTypeSLM[0].value)
      || (orderTypeMLVal === OrderTypeSLM[1].value)
      ? triggerPriceField?.toString() : "0";
    const payload = {
      exchange: selectedExchange,
      token: token?.toString(),
      quantity: quantity?.toString(),
      price: priceVal,
      product: productValue,
      triggerPrice: triggerPriceVal,
      transactionType: buySellToggle ? "S" : "B",
      order: orderTypeMLVal,
      bookLossPrice: "0",
    };
    // if bracket order is selected
    if (activeTab === varietyValues.BRACKET && stopLossPrice) {
      let bookLossPrice = 0;
      if (buySellToggle) {
        bookLossPrice = priceField + stopLossPrice
      } else {
        bookLossPrice = priceField - stopLossPrice
      }
      const bookLossPriceValue = bookLossPrice > 0 ? bookLossPrice : 0
      payload.bookLossPrice = bookLossPriceValue.toString();
    }
    return payload;
  };

  const handleFetchTradeMargin = () => {
    dispatch(orderMargin(handleGeneratePayload()));
  };

  useEffect(() => {
    if (token && orderTypeMLVal) {
      if (initialLoadRef.current) {
        handleFetchTradeMargin();
        initialLoadRef.current = false;
      } else {
        processChange(handleGeneratePayload());
      }
    }
  }, [
    token,
    quantity,
    selectedExchange,
    priceField,
    orderTypeMLVal,
    buySellToggle,
    productValue,
    activeTab,
    triggerPriceField,
    bracketInputValues.stopLossPrice,
  ]);

  return tradeMarginData ? {
    marginUsed: tradeMarginData.marginUsed,
    charge: tradeMarginData.charge,
    handleFetchTradeMargin,
  } : {
    marginUsed: 'N/A',
    charge: {
      total: 0,
    },
    handleFetchTradeMargin,
  };
};

export default useFetchTradeMargin;
