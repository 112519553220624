import { clsx } from '@mantine/core';
import Flex from '@uikit/flex';
import PnlTextType from './pnlText.types';
import useStyles from './pnlText.styles';

const PnLText = ({
  netChange, value, colorScheme, textClass = "content_xs_regular", showPercentage = false,
}: PnlTextType) => {
  const { classes } = useStyles();
  let className = "";
  if (Number(netChange) > 0) {
    className = `greenColor_${colorScheme}`
  } else if (Number(netChange) < 0) {
    className = `redColor_${colorScheme}`
  } else {
    className = "row-disabled"
  }
  return (
    <Flex align="flex-end" className={clsx(textClass, classes[className])}>
      {showPercentage && "("}
      <p className={clsx(textClass, classes[className])}>
        {value ? Number(value).toFixed(2) : "0.00"}
      </p>
      {showPercentage && <p className={textClass}>%</p>}
      {showPercentage && ")"}
    </Flex>
  )
}

export default PnLText;
