import PlainCard from "@components/plainCard"
import {
  Button, Flex, Tooltip, createStyles,
} from "@mantine/core"
import { DateInput } from '@mantine/dates';
import { useState } from "react";
import { getContractNote } from "@redux/sagas/users/usersSlice";
import { useDispatch } from "react-redux";
import { formatDate } from "@utils/date";

const useStyles = createStyles((theme) => ({
  contentWrapper: {
    justifyContent: "space-evenly",
    alignContent: "self-end",
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      flexDirection: "column",
      gap: 16,
    },
  },

}))

const ContractNote = () => {
  const [value, setValue] = useState<Date | null>(null);
  const dispatch = useDispatch();
  const { classes } = useStyles();

  const handleGetContractNote = () => {
    const formattedDate = formatDate(value);
    dispatch(getContractNote(formattedDate));
  }

  return (
    <Flex p={24}>
      <PlainCard
        leftContent={<p className="cardTitle"> Get Contract Note</p>}
        mainContent={(
          <Flex className={classes.contentWrapper}>
            <DateInput
              value={value}
              onChange={setValue}
              placeholder="Select Date"
              width={300}
              minDate={new Date(2023, 3, 1)}
              maxDate={new Date(Date.now() - 86400000)}
              valueFormat="DD / MM / YYYY"
              clearable
              hideWeekdays
              excludeDate={(date) => date.getDay() === 0 || date.getDay() === 6}
            />
            <Tooltip label="You will receive the contract note on your email." withArrow>
              <Button
                onClick={handleGetContractNote}
                variant="outline"
              >
                Get Contract Note
              </Button>
            </Tooltip>
          </Flex>
        )}
      />
    </Flex>
  )
}

export default ContractNote
